import { useEffect } from 'react'
import { useAuth } from '@auth/useAuth'
import { updateTenantMemberDefaultTimezone } from '@kudos/http-client'

/**
 * 96.8% support by browsers
 * https://caniuse.com/mdn-javascript_builtins_intl_datetimeformat_resolvedoptions
 */
const getCurrentTimezone = (): string | undefined => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch {
    return undefined
  }
}

/**
 * This component doesn't render anything but exists in order to detect
 * the browser's timezone and report it back to the server so it can set a
 * suitable *default* without the user needing to intervene.
 *
 * Currently this will trigger on every hard-page load, but we could set a
 * cookie or find ways to throttle this in the future if it becomes a performance
 * issue. API request is back-grounded so it does not affect render times.
 */
export default () => {
  const { tenantId } = useAuth()

  useEffect(() => {
    if (tenantId) {
      const tz = getCurrentTimezone()
      if (tz) {
        updateTenantMemberDefaultTimezone({ timezone: tz })
      }
    }
  }, [tenantId])

  return null
}
