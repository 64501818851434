import { Quality, Value, listValues } from '@kudos/http-client'
import { Selectable } from '@shared/filters/selections'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

const mapQualitySelectable = (value: Value, quality: Quality) => {
  const selectable: Selectable = {
    id: quality.id,
    name: quality.name,
    color: value.color,
    tooltipValue: value.name,
  }
  return selectable
}

const useQualities = () => {
  const [qualities, setQualities] = useState<Selectable[]>()

  const { data: values, isFetched } = useQuery({
    queryKey: ['listValuesAndQualities'],
    queryFn: () =>
      listValues({
        embeds: ['qualities'],
      }),
  })

  useEffect(() => {
    if (!!values) {
      setQualities(
        values.flatMap(
          value => value.qualities?.map(quality => mapQualitySelectable(value, quality)) ?? [],
        ),
      )
    }
  }, [isFetched, values])

  return { qualities, isFetched }
}

export default useQualities
