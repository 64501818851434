import { tabletQuery } from '@globals/themes/themeConstants'
import { SxProps, useMediaQuery } from '@mui/material'
import Dialog from '@mui/material/Dialog'

import FullScreenAnimation from '@shared/animations/FullScreenAnimation'
import { T } from '@transifex/react'
import { ReactNode, useState } from 'react'
import DraftAndSchduledView from './DraftAndSchduledView/DraftAndSchduledView'
import RecognitionMobileDrawer from './RecognitionMobileDrawer'
import SendRecognitionView from './sendRecognitionView/SendRecognitionView'
import useRecognitionModal, { RecognitionModalViews } from './useRecognitionModal'

export const modalStyle: SxProps = {
  borderRadius: 4,
  overflowX: 'hidden',
}

export type RecognitionModalContent = {
  [key in RecognitionModalViews]: {
    content: ReactNode
  }
}

const RecognitionModal: React.FC = () => {
  const [isAnimationShowing, setIsAnimationShowing] = useState<boolean>(false)
  const { dialogData, isOpen, closeDialog } = useRecognitionModal()
  const isTablet = useMediaQuery(tabletQuery)

  const contents: RecognitionModalContent = {
    [RecognitionModalViews.SendRecognition]: {
      content: <SendRecognitionView setShowAnimation={setIsAnimationShowing} />,
    },
    [RecognitionModalViews.DraftAndInProgressRecognitions]: {
      content: <DraftAndSchduledView />,
    },
  }

  return (
    <>
      {isTablet ? (
        <RecognitionMobileDrawer
          isOpen={isOpen}
          recognitionDialogData={dialogData}
          onClose={() => closeDialog()}
          contents={contents}
        />
      ) : (
        <Dialog
          open={isOpen}
          onClose={() => closeDialog()}
          PaperProps={{
            sx: modalStyle,
          }}>
          {dialogData && contents[dialogData.view].content}
        </Dialog>
      )}
      <FullScreenAnimation
        title={<T _str="You just made someone’s day!" />}
        isVisible={isAnimationShowing}
        onClose={() => setIsAnimationShowing(false)}
      />
    </>
  )
}

export default RecognitionModal
