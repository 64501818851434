import { colors } from '@globals/themes/defaultTheme'
import { spacing } from '@globals/themes/themeConstants'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import { SxProps, alpha } from '@mui/material/styles'
import AvatarIcon from '@shared/components/AvatarIcon'

const avatarIconStyles: SxProps = {
  border: `4px solid ${colors.blueUltraLight}`,
  marginTop: spacing(-4),
}

const cardStyles: SxProps = {
  p: 3,
  pt: 6,
  borderRadius: spacing(2),
  width: 'auto',
  margin: 'auto',
  maxWidth: 600,
  backgroundColor: colors.bluePrimary,
}

const cardContentStyles: SxProps = {
  backgroundColor: alpha(colors.white, 0.98),
  borderRadius: spacing(2),
  width: '100%',
  boxSizing: 'border-box',
}

type AnnouncementCardWrapperProps = {
  styles?: SxProps
  children: React.ReactNode
}

const AnnouncementCardWrapper: React.FC<AnnouncementCardWrapperProps> = ({ styles, children }) => {
  return (
    <Card sx={{ ...cardStyles, ...styles }}>
      <Stack sx={{ ...cardContentStyles, alignItems: 'center' }}>
        <AvatarIcon
          avatarBackground={colors.blueUltraLight}
          avatarColor={colors.blueUltraLight}
          avatarIcon="📢"
          size="large"
          customStyles={avatarIconStyles}
        />
        <Stack sx={{ ...cardContentStyles, p: 3, pt: 0 }}>{children}</Stack>
      </Stack>
    </Card>
  )
}

export default AnnouncementCardWrapper
