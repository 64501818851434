import { MediaObjectUrl } from '@kudos/http-client'
import { SxProps } from '@mui/material/styles'
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline'
import Box from '@mui/material/Box'
import useAnimation from '@shared/animations/useAnimation'
import { AnimationStateEnum } from '@shared/animations/useAnimationTimer'
import HtmlContent from '@shared/components/HtmlContent'
import usePrevious from '@shared/hooks/usePrevious'
import { useEffect, useMemo, useRef, useState } from 'react'
import TvAttachments from './TvAttachments'

const SCROLL_DOWN_DURATION = 25000
const SCROLL_UP_DURATION = 1000
const PAUSE_AT_BOTTOM_DURATION = 0

const containerStyle = (maxHeight: string) => ({
  paddingBottom: 2,
  position: 'relative',
  maxHeight,
  overflow: 'auto',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

enum ScrollStateEnum {
  UP = 'up',
  DOWN = 'down',
}

type ScollingContentProps = {
  header?: React.ReactNode
  htmlContent: string
  maxHeight: string
  contentSx: SxProps
  attachmentUrls?: MediaObjectUrl[]
  gifs?: string[]
  children?: React.ReactNode
}

const ScrollingContent: React.FC<ScollingContentProps> = ({
  header,
  htmlContent,
  maxHeight,
  attachmentUrls,
  gifs,
  contentSx,
  children,
}) => {
  const scrollListRef = useRef<HTMLElement>(null)
  const [scrollValue, setScrollValue] = useState<number>(0)
  const [scrollState, setScrollState] = useState<ScrollStateEnum>(ScrollStateEnum.DOWN)
  const [scrollHeight, setScrollHeight] = useState<number>(0)
  const [offsetHeight, setOffsetHeight] = useState<number>(0)

  const durationTime =
    scrollState === ScrollStateEnum.DOWN ? SCROLL_DOWN_DURATION : SCROLL_UP_DURATION

  const prevScrollState = usePrevious(scrollState)

  const isUp = useMemo(() => scrollState === ScrollStateEnum.UP, [scrollState])

  const { value: scrollAnimation, animationState } = useAnimation(
    'linear',
    durationTime,
    PAUSE_AT_BOTTOM_DURATION,
  )

  const scrollMax = useMemo(() => scrollHeight - offsetHeight, [scrollHeight, offsetHeight])

  useEffect(() => {
    const { current } = scrollListRef
    if (current) {
      const element = current
      setScrollHeight(element.scrollHeight)
      setOffsetHeight(element.offsetHeight)
    }
  }, [])

  useEffect(() => {
    if (!scrollMax) return

    if (scrollValue === 0 && scrollState !== ScrollStateEnum.DOWN) {
      setScrollState(ScrollStateEnum.DOWN)
    } else if (
      animationState === AnimationStateEnum.FINISHED &&
      scrollState !== ScrollStateEnum.UP
    ) {
      setScrollState(ScrollStateEnum.UP)
    }
  }, [scrollValue, scrollMax, animationState, scrollState])

  useEffect(() => {
    if (animationState === AnimationStateEnum.IDLE) return

    const value = scrollMax * scrollAnimation

    const isSameScroll = prevScrollState === scrollState

    if (animationState === AnimationStateEnum.FINISHED && isSameScroll) {
      const finishedValue = isUp ? 0 : scrollMax
      if (value !== finishedValue) {
        setScrollValue(finishedValue)
        return
      }
    }
    if (animationState !== AnimationStateEnum.STARTED) return

    setScrollValue(isUp ? scrollMax - value : value)
  }, [scrollAnimation, scrollMax, isUp, animationState, prevScrollState, scrollState])

  useEffect(() => {
    const { current } = scrollListRef
    if (!current) {
      return
    }

    current.scrollTop = scrollValue
  }, [scrollValue])

  return (
    <Box style={{ overflowWrap: 'anywhere' }}>
      <Box ref={scrollListRef} id="locationsListScroll" sx={containerStyle(maxHeight)}>
        {header}
        {/* For Froala rich editor content */}
        <ScopedCssBaseline classes={{ root: 'fr-view' }} sx={{ backgroundColor: 'transparent' }}>
          <HtmlContent htmlContent={htmlContent} sx={{ fontWeight: 'normal', ...contentSx }} />
        </ScopedCssBaseline>
        {((attachmentUrls?.length ?? 0) > 0 || (gifs?.length ?? 0) > 0) && (
          <Box sx={{ pt: 4 }}>
            <TvAttachments
              attachments={attachmentUrls as MediaObjectUrl[]}
              gifs={gifs as string[]}
            />
          </Box>
        )}
        {children}
      </Box>
    </Box>
  )
}

export default ScrollingContent
