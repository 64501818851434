import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import NoAppBarLayout from '@layouts/NoAppBarLayout'
import RouteLoading from '@shared/routing/RouteLoading'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const MyTenantsRoute = lazyWithRetries(() => import('./myTenants'))

const routes: RouteObject[] = [
  {
    path: AppPaths.user.tenants(),
    element: (
      <NoAppBarLayout maxWidth={1080}>
        <Suspense fallback={<RouteLoading />}>
          <MyTenantsRoute />
        </Suspense>
      </NoAppBarLayout>
    ),
  },
]

export default routes
