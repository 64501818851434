import { MediaObjectUpload, MediaObjectUrl } from '@kudos/http-client'
import Stack from '@mui/material/Stack'
import AttachmentUploader from '@shared/components/mediaUploads/AttachmentUploader'
import ErrorText from '@shared/forms/ErrorText'
import { useState } from 'react'
import { UseControllerProps, useController, useFieldArray } from 'react-hook-form'
import { AttachmentType } from '../hooks/useFileMediaUpload'

type AttachmentFieldProps = UseControllerProps & {
  file?: File | null
  index: number
  isDeleteDisabled?: boolean
  onError?: (error: Error) => void
  onDelete?: (index: number) => void
  isUploadDisabled?: boolean
  attachmentSrc?: MediaObjectUrl
  attachmentType: AttachmentType
  mutationFn?: (params: {
    originalFilename: string
    contentType: string
  }) => Promise<MediaObjectUpload>
}

const AttachmentField = ({
  control,
  file,
  index,
  onError,
  onDelete,
  attachmentSrc,
  attachmentType,
  disabled,
  mutationFn,
}: AttachmentFieldProps) => {
  const [uploadError, setUploadError] = useState<Error | undefined>()

  const {
    field: { name },
    fieldState: { error },
  } = useController({
    control,
    name: 'attachments',
  })

  const { update } = useFieldArray({ control, name })

  const handleAttachmentUploaded = (mediaObjectId?: string, attachmentSrc?: MediaObjectUrl) => {
    if (!!mediaObjectId) {
      update(index, { mediaObjectId, attachmentType, attachmentSrc })
    }
  }

  const handleError = (error: Error) => {
    setUploadError(error)
    if (!!onError) onError(error)
  }

  return (
    <Stack useFlexGap spacing={3}>
      <AttachmentUploader
        index={index}
        fileAttachment={file}
        attachmentSrc={attachmentSrc}
        attachmentType={attachmentType}
        onAttachmentUploaded={handleAttachmentUploaded}
        onDelete={!!onDelete ? () => onDelete(index) : undefined}
        onError={handleError}
        disabled={disabled}
        mutationFn={mutationFn}
      />
      {!!error && <ErrorText>{error.message}</ErrorText>}
      {!!uploadError && <ErrorText>{uploadError.message}</ErrorText>}
    </Stack>
  )
}

export default AttachmentField
