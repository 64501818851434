import { tabletQuery } from '@globals/themes/themeConstants'
import { Box, SxProps } from '@mui/material'

const constrainedWidthLayoutStyle: SxProps = {
  margin: 'auto',
  overflow: 'visible',
  [tabletQuery]: {
    mx: 0,
    maxWidth: '100%',
  },
}

type ConstrainedWidthLayoutProps = {
  maxWidth?: 600 | 900 | 1080
  children?: React.ReactNode
}
const ConstrainedWidthLayout: React.FC<ConstrainedWidthLayoutProps> = ({
  maxWidth = 900,
  children,
}) => (
  <Box sx={constrainedWidthLayoutStyle} maxWidth={maxWidth}>
    {children}
  </Box>
)

export default ConstrainedWidthLayout
