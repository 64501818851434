import {
  getInAppNotificationsCount,
  NotificationsCount,
  getInAppNotifications,
  markInAppNotificationAsRead,
  markAllInAppNotificationsAsRead,
  MemberInAppNotification,
  PageResult,
} from '@kudos/http-client'
import { useInvalidateQuery } from '@shared/hooks/useInvalidateQuery'
import { useQuery, useMutation, useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

const NOTIFICATION_LIMIT = 20
const NOTIFICATION_COUNT_QUERY_KEY = 'getInAppUnreadNotificationsCount'

const useInAppNotifications = (
  fetchNotifications: boolean = false,
  hasNotificationBrowsePermission = false,
) => {
  const invalidateQuery = useInvalidateQuery()

  const { data: unreadNotificationsCount } = useQuery({
    queryKey: [NOTIFICATION_COUNT_QUERY_KEY],
    queryFn: () =>
      getInAppNotificationsCount({
        read: false,
      }),
    enabled: hasNotificationBrowsePermission,
    select: (data: NotificationsCount) => data.total,
  })

  const { data, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: ['getInAppNotifications'],
    queryFn: ({ pageParam = 1 }) =>
      getInAppNotifications({
        page: pageParam,
        pageSize: NOTIFICATION_LIMIT,
        sorting: ['-createdAt'],
        embeds: ['fromMember'],
      }),
    enabled: fetchNotifications,
    initialPageParam: 1,
    getNextPageParam: (lastFetch: PageResult<MemberInAppNotification>) => lastFetch.next,
  })

  const { mutateAsync: markAsRead } = useMutation({
    mutationFn: markInAppNotificationAsRead,
    onSuccess: async () => {
      await invalidateQuery([NOTIFICATION_COUNT_QUERY_KEY])
    },
  })

  const { mutateAsync: markAllAsRead } = useMutation({
    mutationFn: markAllInAppNotificationsAsRead,
    onSuccess: async () => {
      await invalidateQuery([NOTIFICATION_COUNT_QUERY_KEY])
    },
  })

  const hasUnreadNotifications = useMemo(() => {
    if (unreadNotificationsCount === undefined) return false

    return unreadNotificationsCount > 0
  }, [unreadNotificationsCount])

  const markAllNotificationsAsRead = () => {
    markAllAsRead()
  }

  const markNotificationAsRead = (id: string) => {
    markAsRead(id)
  }

  return {
    hasUnreadNotifications,
    notifications: data,
    fetchNextPage,
    isFetchingNextPage,
    markNotificationAsRead,
    markAllNotificationsAsRead,
  }
}

export default useInAppNotifications
