import { TenantMemberDisplayData } from '../identity'
import { WithPermissions } from '../shared'

export type TenantCelebrationPref = {
  tenantId: string
  birthdayPublic: boolean
  birthdayCards: boolean
  anniversaryPublic: boolean
  anniversaryCards: boolean
  welcomePublic: boolean
  welcomeCards: boolean
  onboardingDays: number[] | []
  onboardingCards: boolean
  createdAt: string | null
  updatedAt: string | null
  __entity_type__: 'tenantPreference'
}

export type TenantCelebrationPrefParams = Pick<
  TenantCelebrationPref,
  | 'birthdayPublic'
  | 'birthdayCards'
  | 'anniversaryPublic'
  | 'anniversaryCards'
  | 'welcomePublic'
  | 'welcomeCards'
  | 'onboardingDays'
  | 'onboardingCards'
>

export type MemberCelebrationPref = {
  memberId: string
  tenantId: string
  birthdayPublic: boolean
  anniversaryPublic: boolean
  onboardingPublic: boolean
  createdAt: string | null
  updatedAt: string | null
  __entity_type__: 'memberPreference'
}

export type MemberCelebrationPrefParams = {
  birthdayPublic: boolean
  anniversaryPublic: boolean
  onboardingPublic: boolean
}

/** Calendar events for Celebrations */
export enum CalendarEventTypeEnum {
  BIRTHDAY = 'birthday',
  ANNIVERSARY = 'anniversary',
  WELCOME = 'welcome',
  ONBOARDING = 'onboarding',
}

export enum SimplifiedEventTypeEnum {
  BIRTHDAY = CalendarEventTypeEnum.BIRTHDAY,
  WELCOME = CalendarEventTypeEnum.WELCOME,
  MILESTONES = 'milestones',
}

export type CalendarEvent = WithPermissions<{
  type: CalendarEventTypeEnum
  celebratedOn: string
  memberId: string
  interval: number
  memberDisplayData?: TenantMemberDisplayData
  __entity_type__: 'calendarEvent'
}>

export type ListCalendarEventsEmbeds = 'member'

export type ListCalendarEventsParams = {
  typeIn?: string[]
  dateRange?: string[]
  dateRangePreset?: string
  memberIdIn?: string[]
  teamIdIn?: string[]
  embeds?: ListCalendarEventsEmbeds[]
}

export type CreateCelebrationCardParams = {
  celebratedOn: string
  memberId: string
  type: CalendarEventTypeEnum
}

export type CreateCelebrationCardResponse = {
  cardId: string
}
