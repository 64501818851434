import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import { SxProps } from '@mui/material/styles'
import { PropsWithChildren } from 'react'
import TenantLogo from '../shared/tenantLogo/TenantLogo'
import { StyledDrawer } from './Nav.styled'
import RecognizeSomeoneBtn from './RecognizeSomeoneBtn'

const toolbarStyle: SxProps = {
  padding: { sm: 0 },
  marginBottom: 2,
}

export const NavDrawerDesktop: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledDrawer variant="permanent" anchor="left">
      <Toolbar sx={toolbarStyle}>
        <Stack alignItems="center" flex={1}>
          <TenantLogo />
          <RecognizeSomeoneBtn />
        </Stack>
      </Toolbar>
      {children}
    </StyledDrawer>
  )
}
