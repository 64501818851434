import { TenantMemberDisplayData } from '../identity'
import {
  DateRange,
  DateRangePresetEnum,
  PaginatedSearch,
  Percentage,
  WithPermissions,
} from '../shared'
import {
  BucketAggChart,
  MemberChartData,
  TeamChartData,
  TimeAggChart,
} from '../shared/charts/types'
import { ListEngagementParams } from './engagement'

export type ReportType =
  | 'userActivitySummary'
  | 'recognitionSummary'
  | 'redemptionSummary'
  | 'awardSummary'
  | 'engagement'

/**
 * The type/duration of a reporting interval. If a number is supplied,
 * it represents the number of days
 */
export type IntervalDuration = 'm' | '3m' | 'y' | number

export type PreviousCurrentPercent = {
  duration: IntervalDuration
  current: {
    value: number
    total: number
    percent: Percentage
    dateRange: DateRange
    activated?: number
    invited?: number
  }
  previous: {
    value: number
    total: number
    percent: Percentage
    dateRange: DateRange
  }
  diff: {
    value: number
    percent: Percentage
  }
}

export type PreviousCurrentCount = {
  duration: IntervalDuration
  current: {
    value: number
    dateRange: DateRange
  }
  previous: {
    value: number
    dateRange: DateRange
  }
  diff: {
    value: number
    percent: Percentage
  }
}

export type Currency = {
  value: number
  currency: string
}

export type TopRedemption = {
  name: string
  value: number
}

export type RedemptionTotalsData = {
  value: number
  currencies: Currency[]
}

export type RedemptionsChartData = BucketAggChart

export type RedemptionPointsCount = {
  pointsPerRedemption: number
  pointsPerEmployee: number
}

export type AllReportViewer = {
  name: string
  id: string
}

export type Report = WithPermissions<{
  type: ReportType
  // embed
  name: string
  reportViewers?: AllReportViewer[]
  scheduledEmails?: string[]
  __entity_type__: 'report'
}>

export type ReportViewer = {
  id: string
  reportType: ReportType
  tenantId: string
  viewerId: string
  createdAt: string
  updatedAt: string
  // embeds
  memberDisplayData: TenantMemberDisplayData
  __entity_type__: 'reportViewer'
}

export type UserActivitySummaryType =
  | 'reachRate'
  | 'recognitionsSent'
  | 'awardsSent'
  | 'cardsSent'
  | 'redemptionsChart'
  | 'redemptionTotals'
  | 'activationRate'
  | 'participationRate'

export type UserActivitySummaryData =
  | PreviousCurrentPercent
  | PreviousCurrentCount
  | RedemptionTotalsData
  | RedemptionsChartData

export type UserActivitySummary = {
  type: UserActivitySummaryType
  data: UserActivitySummaryData
}

export type RedemptionSummaryType =
  | 'redemptionsChart'
  | 'redemptionTotals'
  | 'averageSpending'
  | 'redemptionTypeAgg'
  | 'topRedemptions'

export type RedemptionSummaryData =
  | RedemptionsChartData
  | RedemptionTotalsData
  | RedemptionPointsCount
  | TimeAggChart
  | TopRedemption[]

export type RedemptionSummary = {
  type: RedemptionSummaryType
  data: RedemptionSummaryData
}

export enum ReportingIntervalEnum {
  LAST_YEAR = DateRangePresetEnum.LAST_YEAR,
  CURRENT_YEAR = DateRangePresetEnum.CURRENT_YEAR,
  LAST_3_MONTHS = DateRangePresetEnum.LAST_3_MONTHS,
  LAST_MONTH = DateRangePresetEnum.LAST_MONTH,
  CURRENT_MONTH = DateRangePresetEnum.CURRENT_MONTH,
}

export type ReportFilterParams =
  | ListEngagementParams
  | ListUserActivitySummaryParams
  | ListRedemptionSummaryParams
  | ListAwardSummaryParams

export type ListUserActivitySummaryParams = {
  dateRange?: string[]
  dateRangePreset?: string
  memberIdIn?: string[]
  teamIdIn?: string[]
  indirectManagerIdIn?: string[]
  ownReports?: boolean
}

export type ListRedemptionSummaryParams = ListUserActivitySummaryParams & {
  typeIn?: string[]
}

export type ReportEmbeds = 'name' | 'reportViewers' | 'scheduledEmails'

export type ReportViewerEmbeds = 'members'

export type ListReportVeiwersParams = PaginatedSearch<object, ReportViewerEmbeds>

export type ListReportsParams = {
  embeds?: ReportEmbeds[]
}

export type AwardSummaryType =
  | 'totalAwardPoints'
  | 'averageAwardPoints'
  | 'totalAwards'
  | 'pointsPerBankChart'
  | 'topAwardRecipients'
  | 'topAwardTeams'

export type CurrentCount = {
  value: number
}

export type PointsPerBankChartData = BucketAggChart

export type AwardSummaryData =
  | CurrentCount
  | PointsPerBankChartData
  | MemberChartData[]
  | TeamChartData[]

export type AwardSummary = {
  type: AwardSummaryType
  data: AwardSummaryData
}

export type ListAwardSummaryParams = ListUserActivitySummaryParams & {
  pointsBankIdIn?: string[]
}

export enum ScheduledEmailReportFrequency {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  WEEKLY = 'weekly',
}

export type ScheduledEmailReport = {
  email: string
  frequency: ScheduledEmailReportFrequency
  reportType: ReportType
  tenantId: string
  createdById: string
  createdAt: Date
  // embeds
  nextSendAt: Date
  createdBy: TenantMemberDisplayData
  __entity_type__: 'scheduledEmailReport'
}

export type ScheduledEmailReportEmbeds = 'createdBy' | 'nextSendAt'

export type ListScheduledEmailReportsParams = PaginatedSearch<
  {
    frequencyIn?: ScheduledEmailReportFrequency[]
  },
  ScheduledEmailReportEmbeds
>

export type EmailedReportsEmbeds = 'sharedBy' | 'name'

export type ListEmailedReportsParams = PaginatedSearch<object, EmailedReportsEmbeds>

export type DeleteScheduledEmailParams = {
  emails: string[]
}

export type DateRangePreset =
  | DateRangePresetEnum.LAST_ISO_WEEK
  | DateRangePresetEnum.LAST_MONTH
  | DateRangePresetEnum.LAST_3_MONTHS

export type EmailedReport = {
  id: string
  email: string
  reportType: ReportType
  tenantId: string
  mediaObjectId: string | null
  secret: string
  sentById: string
  scheduled: boolean
  batchId: string
  startDate: string
  endDate: string
  dateRangePreset: DateRangePreset
  createdAt: string
  updatedAt: string
  // embeds
  sharedBy: string
  __entity_type__: 'emailedReport'
}

export type ScheduleEmailParams = {
  emails: string
  frequency: ScheduledEmailReportFrequency
}

export type SendEmailParams = {
  emails: string
  dateRangePreset: DateRangePreset
}

export type CreateViewersParams = {
  memberIds: string[]
}
