import SplitButton from '@components/button/SplitButton'
import { ActionButton } from '@components/button/types'
import { tabletQuery } from '@globals/themes/themeConstants'
import Button, { ButtonProps } from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import useMediaQuery from '@mui/material/useMediaQuery'
import { MOBILE_BOTTOM_NAV_HEIGHT } from '@nav/Nav.constants'
import { find } from 'lodash/fp'
import { Link } from 'react-router-dom'

const mobileStyle = {
  position: 'fixed',
  bottom: MOBILE_BOTTOM_NAV_HEIGHT + 8,
  left: 16,
  right: 16,
}

type ActionButtonsProps = {
  actions: ActionButton[]
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ actions }) => {
  const isTablet = useMediaQuery(tabletQuery)

  const visibleActions = actions.filter(action =>
    action.visible === undefined ? true : action.visible,
  )

  if (visibleActions.length === 0) return null

  const renderButton = (action: ActionButton) => {
    const buttonProps: ButtonProps = {
      id: `btn-${action.key}`,
      key: action.key,
      disabled: action.disabled,
      variant: action.variant === 'primary' ? 'contained' : 'outlined',
    }

    if ('actions' in action) {
      return (
        <SplitButton
          elementId={action.key}
          actions={action.actions}
          label={action.name}
          labelStartIcon={action.labelStartIcon}
          popperPlacement={action.popperPlacement}
          variant={action.variant === 'primary' ? 'contained' : 'outlined'}
          disabled={action.disabled}
        />
      )
    }

    if (action.to) {
      // Button acts like a link
      return (
        <Button
          component={Link}
          to={action.to}
          type={action.type || 'button'}
          startIcon={action.startIcon}
          {...buttonProps}>
          {action.name}
        </Button>
      )
    }
    // Button acts as JS trigger
    return (
      <Button
        onClick={action.onClick}
        type={action.type || 'button'}
        startIcon={action.startIcon}
        {...buttonProps}>
        {action.name}
      </Button>
    )
  }

  const primaryAction = find({ variant: 'primary' }, visibleActions)
  const secondaryAction = find({ variant: 'secondary' }, visibleActions)

  return isTablet ? (
    <Stack sx={mobileStyle}>{primaryAction && renderButton(primaryAction)}</Stack>
  ) : (
    <Stack spacing={2} justifyContent="flex-end" direction="row">
      {!!secondaryAction && renderButton(secondaryAction)}
      {!!primaryAction && renderButton(primaryAction)}
    </Stack>
  )
}

export default ActionButtons
