import { Account, AccountTypeEnum, listAccounts } from '@kudos/http-client'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

export const allPointsQueryKey = 'POINTS'

const getAccountTotal = (
  account: Account | undefined,
  field: 'balance' | 'allTimeReceived' = 'balance',
): number => {
  if (!account || !!account.lockedAt) return 0

  return account[field] ?? 0
}

// Defaults to the current member's points if a memberId is not provided
export const useMemberPoints = (memberId?: string) => {
  const [availablePoints, setAvailablePoints] = useState<number>(0)
  const [redeemablePoints, setRedeemablePoints] = useState<number>(0)
  const [allTimeRedeemablePoints, setAllTimeRedeemablePoints] = useState<number>(0)
  const [arePointsLoaded, setArePointsLoaded] = useState<boolean>(false)
  const [arePointsEnabled, setArePointsEnabled] = useState<boolean>(false)

  const { data: accounts, isFetched: isAccountsFetched } = useQuery({
    queryKey: [allPointsQueryKey, memberId],
    queryFn: () =>
      listAccounts(
        {
          embeds: ['allTimeReceived'],
        },
        memberId,
      ),
  })

  useEffect(() => {
    if (isAccountsFetched && accounts) {
      // Get the available points balance
      const available = getAccountTotal(
        accounts.results?.find((account: Account) => account.type === AccountTypeEnum.ALLOCATION),
      )

      const redeemableAccount = accounts.results?.find(
        (account: Account) => account.type === AccountTypeEnum.REDEEMABLE,
      )

      // Get redeemable points & all time redeemed
      const redeemable = getAccountTotal(redeemableAccount)
      const allTime = getAccountTotal(redeemableAccount, 'allTimeReceived')

      setAvailablePoints(available)
      setRedeemablePoints(redeemable)
      setAllTimeRedeemablePoints(allTime)

      setArePointsLoaded(true)
      setArePointsEnabled(!redeemableAccount?.lockedAt)
    }
  }, [isAccountsFetched, accounts])

  return {
    availablePoints,
    redeemablePoints,
    allTimeRedeemablePoints,
    arePointsLoaded,
    arePointsEnabled,
  }
}
