import { AuthProvider } from '@auth/useAuth'
import Language from '@globals/Language'
import Timezone from '@globals/Timezone'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useState } from 'react'
import AppLayout from './AppLayout'
import RollbarProvider from './RollbarProvider'
import RouteRenderer from './routes'

const queryClientConfig = {
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: true,
      // Data immediately considered stale. Developers can override this on a per-query basis
      // by specifying {staleTime: xxx} in the query options.
      staleTime: 0,
      // How long to keep the data in memory for before evicting it from the cache. Cached
      // data may be shown as placeholder while new data is being fetched.
      cacheTime: 5 * (60 * 1000),
    },
  },
}

const queryClient: QueryClient = new QueryClient(queryClientConfig)

export const App = () => {
  const [languageKey, setLanguageKey] = useState<string>('en')

  const handleLanguageChange = (locale: string) => {
    setLanguageKey(locale)
  }

  return (
    <RollbarProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <>
            {/* NOTE: Changes to the 'key' prop would trigger App re-render */}
            <AppLayout key={languageKey}>
              <RouteRenderer />
            </AppLayout>
            <Language onLanguageChange={handleLanguageChange} />
            <Timezone />
          </>
        </AuthProvider>
      </QueryClientProvider>
    </RollbarProvider>
  )
}
