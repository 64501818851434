import { SxProps } from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { ReactNode } from 'react'
import QRCode from 'react-qr-code'

const QR_CODE_SIZE = '8vh'

type ScanToQRCodeProps = {
  text: ReactNode
  path: string
  textSx?: SxProps
}

const ScanToQRCode: React.FC<ScanToQRCodeProps> = ({ text, path, textSx = {} }) => {
  return (
    <Stack
      spacing={2}
      direction="row"
      justifyContent="start"
      alignItems="center"
      position="absolute"
      bottom={32}
      right={32}>
      <Typography variant="subtitle1" sx={textSx}>
        {text}
      </Typography>
      <QRCode
        size={256}
        style={{ height: QR_CODE_SIZE, width: QR_CODE_SIZE, padding: '4px 4px' }}
        value={`${window.location.origin}${path}`}
        viewBox="0 0 256 256"
      />
    </Stack>
  )
}

export default ScanToQRCode
