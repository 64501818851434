import { tabletQuery } from '@globals/themes/themeConstants'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import { ReactNode } from 'react'

type RecognitionModalViewLayoutProps = {
  children: ReactNode
}
const RecognitionModalViewLayout: React.FC<RecognitionModalViewLayoutProps> = ({ children }) => {
  const isTablet = useMediaQuery(tabletQuery)
  return isTablet ? (
    <Box p={2}>{children}</Box>
  ) : (
    <Box
      p={2}
      maxHeight={800}
      width={600}
      sx={{
        overflowX: 'hidden',
      }}>
      {children}
    </Box>
  )
}

export default RecognitionModalViewLayout
