import AppPaths from '@globals/paths'
import theme from '@globals/themes/defaultTheme'
import { tabletQuery } from '@globals/themes/themeConstants'
import { useCurrentMember } from '@globals/useCurrentMember'
import { useMediaQuery } from '@mui/material'
import Stack from '@mui/material/Stack'
import { SxProps } from '@mui/material/styles'
import { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import UserAvatar from './UserAvatar'

const userAvatarStyles: SxProps = {
  position: 'fixed',
  top: 8, // NOTE: This should match 'top' in DesktopInAppNotifications' buttonStyle
  textDecoration: 'none',
  zIndex: theme.zIndex.appBar + 1,
}

const TenantMemberAvatar: FC = () => {
  const isTablet = useMediaQuery(tabletQuery)
  const { member } = useCurrentMember()

  if (!member) {
    return null
  }

  const { avatarUrls, firstName, lastName } = member

  return (
    <Stack
      sx={{ ...userAvatarStyles, right: isTablet ? 60 : 76 }}
      component={RouterLink}
      to={AppPaths.tenant.editMe()}>
      <UserAvatar size={36} media={avatarUrls} firstName={firstName} lastName={lastName} />
    </Stack>
  )
}

export default TenantMemberAvatar
