import { TypographyVariant, colors } from '@globals/themes/defaultTheme'
import Typography from '@mui/material/Typography'
import { format } from 'date-fns'

type WallPostDateProps = {
  date: string
  textVariant?: TypographyVariant
}

const WallPostDate: React.FC<WallPostDateProps> = ({ date, textVariant = 'caption' }) => {
  return (
    <Typography variant={textVariant} color={colors.grayPrimary}>
      {format(date, 'PP')}
    </Typography>
  )
}

export default WallPostDate
