import { API, unwrapData } from '../axios-instance'
import { InitiateUploadParams, MediaObjectUpload } from '../media/types'

export const initiateRecognitionAttachmentUpload = async (params: InitiateUploadParams) => {
  return await API.post<MediaObjectUpload>('recognition/uploads/recognition', params).then(
    unwrapData,
  )
}

export const initiateAvatarUpload = async (params: InitiateUploadParams) => {
  return await API.post<MediaObjectUpload>('/identity/uploads/avatar', params).then(unwrapData)
}

export const initiateLogoUpload = async (params: InitiateUploadParams) => {
  return await API.post<MediaObjectUpload>('/identity/uploads/logo', params).then(unwrapData)
}

export const initiateAwardUpload = async (params: InitiateUploadParams) => {
  return await API.post<MediaObjectUpload>('/award/uploads/award', params).then(unwrapData)
}

export const initiateIncentiveUpload = async (params: InitiateUploadParams) => {
  return await API.post<MediaObjectUpload>('/incentive/uploads/badges', params).then(unwrapData)
}

export const initiateAnnouncementUpload = async (
  params: InitiateUploadParams,
  isInline: boolean = false,
) => {
  return await API.post<MediaObjectUpload>(
    `/announcement/uploads/announcement?inline=${isInline}`,
    params,
  ).then(unwrapData)
}

export const initiateBannerUpload = async (params: InitiateUploadParams) => {
  return await API.post<MediaObjectUpload>('/identity/uploads/banner', params).then(unwrapData)
}
