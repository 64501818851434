import { useApps } from '@auth/useApps'
import AppPaths from '@globals/paths'
import { useCurrentMember } from '@globals/useCurrentMember'
import { CountResponse, RecognitionStatus, recognitionCount } from '@kudos/http-client'
import { AppPermission } from '@shared/permissions'
import { useQuery } from '@tanstack/react-query'
import { useT } from '@transifex/react'
import { useMemo } from 'react'

export type RecognitionSubTabs = 'new' | 'in-progress'
export type RecognitionActions = 'edit'

export const RECOGNITION_COUNT_QUERY_KEY = ['recognition', 'myDraftCount']

export const useConfigureRecognitionTabs = () => {
  const { member } = useCurrentMember()
  const [hasAppPermission] = useApps()
  const t = useT()

  const hasRecognitionCreatePermission = hasAppPermission(
    'recognitionApp',
    AppPermission.RecognitionAppCreateRecognition,
  )

  const { data: draftRecognitionCount, refetch: refetchDraftRecogitionCount } =
    useQuery<CountResponse>({
      queryKey: RECOGNITION_COUNT_QUERY_KEY,
      queryFn: () =>
        recognitionCount({
          statusIn: [RecognitionStatus.DRAFT],
          senderIdIn: member?.id ? [member.id] : [],
        }),
      enabled: !!member?.id,
    })

  const inProgressTabName = useMemo(() => {
    if (!draftRecognitionCount) return t('In progress')
    return t('In progress ({count})', { count: draftRecognitionCount.total })
  }, [draftRecognitionCount?.total, t])

  return {
    subTabs: useMemo(() => {
      return [
        {
          name: t('New'),
          alias: 'new',
          to: AppPaths.tenant.sendRecognition(),
          visible: hasRecognitionCreatePermission,
        },
        {
          name: inProgressTabName,
          alias: 'in-progress',
          to: AppPaths.tenant.viewDraftRecognitions(),
          visible:
            hasRecognitionCreatePermission &&
            !!draftRecognitionCount?.total &&
            draftRecognitionCount.total > 0,
        },
      ]
    }, [draftRecognitionCount, t]),
    refetchDraftRecogitionCount,
  }
}
