export { hasPermission } from './hasPermission'

export enum AppPermission {
  IdentityAppBrowse = 'identityApp:browse',
  IdentityAppExpireAllPasswords = 'identityApp:expireAllPasswords',
  IdentityAppImportRecords = 'identityApp:importStaffRecords',
  IdentityAppExportRecords = 'identityApp:exportStaffRecords',
  IdentityAppConfigureTeamAutomation = 'identityApp:configureTeamAutomation',
  HrIntegrationBrowse = 'hrIntegrationApp:browse',
  CheckInAppManage = 'checkInApp:manage',
  CheckInAppViewAnalytics = 'checkInApp:viewAnalytics',
  IdentityAppCreateTeam = 'identityApp:createTeam',
  IdentityAppEditSegments = 'identityApp:editSegments',
  MetricAppBrowse = 'metricApp:browse',
  MetricAppOwnReportsBrowse = 'metricApp:browseOwnReports',
  RecognitionAppBrowse = 'recognitionApp:browse',
  RecognitionAppCreateRecognition = 'recognitionApp:createRecognition',
  RecognitionAppScheduleRecognition = 'recognitionApp:scheduleRecognition',
  RecognitionAppCreateValue = 'recognitionApp:createValue',
  RecognitionAppModerate = 'recognitionApp:moderate',
  RecognitionAppManageNotificationSettings = 'recognitionApp:manageNotificationSettings',
  ResourceAppBrowse = 'resourceApp:browse',
  RewardsAppAdmin = 'rewardsApp:admin',
  RewardsAppRedeem = 'rewardsApp:redeem',
  RewardsAppCreateTangoProvider = 'rewardsApp:createTangoProvider',
  CommentAppModerate = 'commentApp:moderate',
  TvAppBrowse = 'tvApp:browse',
  TvAppAddChannel = 'tvApp:addChannel',
  PointsAppBrowse = 'pointsApp:browse',
  PointsAppCreateBudgetItem = 'pointsApp:createBudgetItem',
  PointsAppEditBudgetSettings = 'pointsApp:editBudgetSettings',
  NotificationAppBrowseMessages = 'notificationApp:browseMessages',
  AuditingAppBrowse = 'auditingApp:browse',
  CelebrationAppBrowse = 'celebrationApp:browse',
  CelebrationAppEditTenantPreferences = 'celebrationApp:editTenantPreferences',
  CelebrationAppEditMemberPreferences = 'celebrationApp:editMemberPreferences',
  AwardAppCreateAwardType = 'awardApp:createAwardType',
  AwardAppManage = 'awardApp:manage',
  AwardAppSendOwnAwardType = 'awardApp:sendOwnAwardType',
  AwardAppBrowse = 'awardApp:browse',
  AwardAppModerate = 'awardApp:moderate',
  CardAppCreateCard = 'cardApp:createCard',
  CardAppManage = 'cardApp:manage',
  CardAppModerate = 'cardApp:moderate',
  CardAppViewAnyCard = 'cardApp:viewAnyCard',
  CardAppBrowse = 'cardApp:browse',
  ReportingAppViewSummary = 'reportingApp:viewSummary',
  ReportingAppBrowse = 'reportingApp:browse',
  ReportingAppManage = 'reportingApp:manage',
  AnnouncementAppBrowse = 'announcementApp:browse',
  AnnouncementAppManage = 'announcementApp:manage',
  TimelineAppBrowse = 'timelineApp:browse',
  TimelineAppManage = 'timelineApp:manage',
  IncentiveAppCreateIncentive = 'incentiveApp:createIncentive',
  IncentiveAppManage = 'incentiveApp:manage',
  IncentiveAppReview = 'incentiveApp:review',
  IncentiveAppBrowse = 'incentiveApp:browse',
}

export enum ChannelPermission {
  Edit = 'channel:edit',
  Delete = 'channel:delete',
}

export enum TenantPermission {
  ManageSamlProviders = 'tenant:manageSamlProviders',
  AddSamlProvider = 'tenant:addSamlProvider',
  ManageAuthSettings = 'tenant:manageAuthSettings',
  CreateMember = 'tenant:createMember',
}

export enum TenantMemberPermission {
  ChangeRole = 'tenantMember:changeRole',
  Suspend = 'tenantMember:suspend',
  Unsuspend = 'tenantMember:unsuspend',
  Edit = 'tenantMember:edit',
}

export enum SamlProviderPermission {
  Edit = 'samlProvider:edit',
  Delete = 'samlProvider:delete',
  Activate = 'samlProvider:activate',
  Deactivate = 'samlProvider:deactivate',
}

export enum UserPermission {
  ChangePassword = 'user:changePassword',
  CreateAuthFactor = 'user:createAuthFactor',
}

export enum AuthFactorPermission {
  Delete = 'auth_factor:delete',
}

export enum CheckInPermission {
  Configure = 'checkInCategory:configure',
}

export enum TeamAutomationPermission {
  Edit = 'teamAutomation:edit',
  Disable = 'teamAutomation:disable',
  Sync = 'teamAutomation:sync',
  Delete = 'teamAutomation:delete',
}

export enum TeamPermission {
  Automate = 'team:automate',
  Edit = 'team:edit',
  Delete = 'team:delete',
  ManageMembers = 'team:manageMembers',
}

export enum ValuePermission {
  Edit = 'value:edit',
  AddQuality = 'value:addQuality',
  Delete = 'value:delete',
}

export enum RecognitionPermission {
  Edit = 'recognition:edit',
  EditPublished = 'recognition:editPublished',
  Endorse = 'recognition:endorse',
  Schedule = 'recognition:schedule',
  React = 'recognition:react',
  Flag = 'recognition:flag',
  Comment = 'recognition:comment',
  Moderate = 'recognition:moderate',
  Delete = 'recognition:delete',
}

export enum CommentPermission {
  Flag = 'comment:flag',
  Moderate = 'comment:moderate',
  DismissFlags = 'comment:dismissFlags',
  React = 'comment:react',
}

export enum BudgetItemPermission {
  Edit = 'budgetItem:edit',
  Delete = 'budgetItem:delete',
}

export enum ResourcePermission {
  React = 'resource:react',
}

export enum RewardProviderPermission {
  Edit = 'rewardProvider:edit',
  Delete = 'rewardProvider:delete',
  Redeem = 'rewardProvider:redeem',
}

export enum AwardTypePermission {
  Edit = 'awardType:edit',
  Send = 'awardType:send',
  Archive = 'awardType:archive',
  Unarchive = 'awardType:unarchive',
}

export enum AwardPermission {
  Flag = 'award:flag',
  Endorse = 'award:endorse',
  Comment = 'award:comment',
  React = 'award:react',
  Edit = 'award:edit',
}

export enum CalendarEventPermission {
  ViewCard = 'calendarEvent:viewCard',
  CreateCard = 'calendarEvent:createCard', // unused by the FE, including for completeness
}

export enum CardPermission {
  Sign = 'card:sign',
  Edit = 'card:edit',
  Invite = 'card:invite',
  View = 'card:view',
  Download = 'card:download',
  Delete = 'card:delete',
}

export enum CardContributorPermission {
  Flag = 'cardContributor:flag',
  Edit = 'cardContributor:edit',
}

export enum ReportPermissions {
  View = 'report:view',
  ExportXLSX = 'report:exportXlsx',
  Share = 'report:share',
  ShareViaEmail = 'report:shareViaEmail',
}

export enum AnnouncementPermission {
  Edit = 'announcement:edit',
  EditActive = 'announcement:editActive',
  Clone = 'announcement:clone',
  Delete = 'announcement:delete',
  Participate = 'announcement:participate',
}

export enum TangoProviderPermission {
  Edit = 'tangoProvider:edit',
  AddCreditCard = 'tangoProvider:addCreditCard',
  ManageCreditCard = 'tangoProvider:manageCreditCard',
  Fund = 'tangoProvider:fund',
  BrowseDeposits = 'tangoProvider:browseDeposits',
}

export enum IncentivePermission {
  Edit = 'incentive:edit',
  Claim = 'incentive:claim',
  Review = 'incentive:review',
  ExportClaims = 'incentive:exportClaims',
  Start = 'incentive:start',
  End = 'incentive:end',
  Delete = 'incentive:delete',
}
