import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import StandardPageLayout from '@layouts/standardPageLayout'
import RouteLoading from '@shared/routing/RouteLoading'
import { T } from '@transifex/react'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const ConfigureAwardTypeRoute = lazyWithRetries(() => import('./configure'))
const AwardsRoute = lazyWithRetries(() => import('.'))

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.admin.configureAwardType(),
    element: (
      <StandardPageLayout maxWidth={900} elementId="editAwardTypeRoute">
        <Suspense fallback={<RouteLoading />}>
          <ConfigureAwardTypeRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.configureAwardType(':awardTypeId'),
    element: (
      <StandardPageLayout maxWidth={900} elementId="configureAwardTypeRoute">
        <Suspense fallback={<RouteLoading />}>
          <ConfigureAwardTypeRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.awards(),
    element: (
      <StandardPageLayout maxWidth={1080} title={<T _str="Awards" />} elementId="awardsRoute">
        <Suspense fallback={<RouteLoading />}>
          <AwardsRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
]

export default routes
