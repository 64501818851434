import SvgIcon from '@mui/material/SvgIcon'

type EngagementIconProps = {
  fill?: string
}

const EngagementIcon: React.FC<EngagementIconProps> = ({ fill }) => (
  <SvgIcon sx={{ fill }} viewBox="0 0 24 24">
    <rect width="24" height="24" fillOpacity={0} />
    <path d="M12.7249 12.2L14.4999 11.125L16.2749 12.2C16.3749 12.2667 16.4707 12.2667 16.5624 12.2C16.6541 12.1333 16.6832 12.0417 16.6499 11.925L16.1749 9.9L17.7249 8.55C17.8082 8.46667 17.8374 8.37917 17.8124 8.2875C17.7874 8.19583 17.7166 8.14167 17.5999 8.125L15.5499 7.95L14.7249 6.05C14.6916 5.95 14.6166 5.9 14.4999 5.9C14.3832 5.9 14.3082 5.95 14.2749 6.05L13.4499 7.95L11.3999 8.125C11.2832 8.14167 11.2124 8.19583 11.1874 8.2875C11.1624 8.37917 11.1916 8.46667 11.2749 8.55L12.8249 9.9L12.3499 11.925C12.3166 12.0417 12.3457 12.1333 12.4374 12.2C12.5291 12.2667 12.6249 12.2667 12.7249 12.2ZM8.99989 17C8.44989 17 7.97905 16.8042 7.58739 16.4125C7.19572 16.0208 6.99989 15.55 6.99989 15V4C6.99989 3.45 7.19572 2.97917 7.58739 2.5875C7.97905 2.19583 8.44989 2 8.99989 2H19.9999C20.5499 2 21.0207 2.19583 21.4124 2.5875C21.8041 2.97917 21.9999 3.45 21.9999 4V15C21.9999 15.55 21.8041 16.0208 21.4124 16.4125C21.0207 16.8042 20.5499 17 19.9999 17H8.99989ZM8.99989 15H19.9999V4H8.99989V15ZM5.69989 21.875C5.14989 21.9417 4.65405 21.8083 4.21239 21.475C3.77072 21.1417 3.51655 20.7 3.44989 20.15L2.12489 9.225C2.05822 8.675 2.19989 8.18333 2.54989 7.75C2.89989 7.31667 3.34989 7.05833 3.89989 6.975H3.94989C4.23322 6.925 4.48739 7 4.71239 7.2C4.93739 7.4 5.04989 7.65 5.04989 7.95C5.04989 8.2 4.96239 8.41667 4.78739 8.6C4.61239 8.78333 4.39989 8.9 4.14989 8.95H4.12489L5.47489 19.9L17.3249 18.45C17.6082 18.4167 17.8582 18.4833 18.0749 18.65C18.2916 18.8167 18.4166 19.0417 18.4499 19.325C18.4832 19.6083 18.4166 19.8542 18.2499 20.0625C18.0832 20.2708 17.8582 20.3917 17.5749 20.425L5.69989 21.875Z" />
  </SvgIcon>
)

export default EngagementIcon
