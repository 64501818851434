import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { pathTitlesDynamic } from './pathTitlesDynamic'
import { pathTitlesStatic } from './pathTitlesStatic'

export const KUDOS = 'Kudos\u00AE'

export const useDocumentTitle = () => {
  const location = useLocation()

  let title = pathTitlesStatic[location.pathname]

  if (!title) {
    const dynamicPath = pathTitlesDynamic.find(path => path.pattern.test(location.pathname))
    title = dynamicPath ? dynamicPath.title : KUDOS
  }

  useEffect(() => {
    document.title = `${KUDOS} - ${title}`
  }, [location])
}
