import InputLabel from '@mui/material/InputLabel'
import { SxProps } from '@mui/material/styles'
import { T } from '@transifex/react'
import { ReactNode } from 'react'

type FieldLabelProps = {
  required?: boolean
  children: string | ReactNode
  htmlFor?: string
  disabled?: boolean
  labelStyle?: SxProps
}

/**
 * Design System Notes:
 * - Do not make field labels red on error
 */

const FieldLabel: React.FC<FieldLabelProps> = ({
  required = true,
  children,
  htmlFor,
  disabled = false,
  labelStyle,
}) => {
  return (
    <InputLabel
      sx={{ fontWeight: 600, marginBottom: 0.5, ...labelStyle }}
      htmlFor={htmlFor}
      disabled={disabled}>
      {children}
      {!required && (
        <>
          &nbsp; <T _str=" (optional)" />
        </>
      )}
    </InputLabel>
  )
}

export default FieldLabel
