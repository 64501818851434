import { useDialog } from '@globals/dialog/useDialogStore'
import { TeamSendTo, TenantMemberSendTo } from '@shared/components/recipientSearch/types'
import { Selectable } from '@shared/filters/selections'

export enum RecognitionModalViews {
  SendRecognition,
  DraftAndInProgressRecognitions,
}

export type RecognitionDialogData = {
  view: RecognitionModalViews
  recognitionId?: string
  recipents?: (TenantMemberSendTo | TeamSendTo)[]
  qualities?: Selectable[]
}

export const RECOGNITION_MODAL_KEY = 'recognitionModalKey'

const useRecognitionModal = () => {
  const { setData, ...dialogDetails } = useDialog<RecognitionDialogData>(RECOGNITION_MODAL_KEY)
  return {
    setView: setData,
    ...dialogDetails,
  }
}

export default useRecognitionModal
