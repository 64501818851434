import theme from '@globals/themes/defaultTheme'
import { spacing } from '@globals/themes/themeConstants'
import List from '@mui/material/List'
import Paper from '@mui/material/Paper'
import { SxProps } from '@mui/material/styles'
import { FC, useEffect } from 'react'
import MentionsTypeaheadMenuItem, { MentionTypeaheadOption } from './MentionsTypeaheadMenuItem'

const listStyle: SxProps = {
  display: 'flex',
  flex: '1 0 auto',
  flexDirection: 'column',
  margin: 0,
  padding: `${spacing(1)} 0`,
  listStyle: 'none',
  borderRadius: spacing(1),
  minHeight: 0,
  minWidth: spacing(30),
  overflowY: 'scroll',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  zIndex: theme.zIndex.modal + 1,
}

type MentionsTypeaheadListProps = {
  options: MentionTypeaheadOption[]
  selectedIndex: number | null
  onClick: (i: number, option: MentionTypeaheadOption) => void
  onMouseEnter: (i: number) => void
  setAreMentionsOpen: (isOpen: boolean) => void
}
const MentionsTypeaheadList: FC<MentionsTypeaheadListProps> = ({
  options,
  selectedIndex,
  onClick,
  onMouseEnter,
  setAreMentionsOpen,
}) => {
  useEffect(() => {
    setAreMentionsOpen(true)
    return () => {
      setAreMentionsOpen(false)
    }
  }, [])
  return (
    <List component={Paper} sx={listStyle}>
      {options.map((option, i: number) => (
        <MentionsTypeaheadMenuItem
          index={i}
          isSelected={selectedIndex === i}
          onClick={() => !!onClick && onClick(i, option)}
          onMouseEnter={() => onMouseEnter(i)}
          key={option.key}
          option={option}
        />
      ))}
    </List>
  )
}

export default MentionsTypeaheadList
