import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import StandardPageLayout from '@layouts/standardPageLayout'
import RouteLoading from '@shared/routing/RouteLoading'
import { T } from '@transifex/react'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const ConfigureCelebrationsRoute = lazyWithRetries(() => import('.'))

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.admin.configureCelebrations(),
    element: (
      <StandardPageLayout
        elementId="configureCelebrationsRoute"
        maxWidth={900}
        title={<T _str="Celebrations" />}>
        <Suspense fallback={<RouteLoading />}>
          <ConfigureCelebrationsRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
]

export default routes
