import SvgIcon from '@mui/material/SvgIcon'

const CelebrationIcon = () => (
  <SvgIcon viewBox="-1 1 24 24" stroke="currentColor" fillOpacity="0">
    <path
      d="M15.2915 13.7557C15.2915 13.7557 20.5458 10.4026 24 14.8734"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.9654 4.57629C19.1363 4.7975 19.0937 5.10898 18.8809 5.27336C18.6681 5.43774 18.356 5.40016 18.1851 5.17895C18.0143 4.95774 18.0568 4.64626 18.2696 4.48188C18.4825 4.3175 18.7946 4.35509 18.9654 4.57629Z"
      fill="currentColor"
    />
    <path
      d="M18.0225 15.7937C18.1933 16.0149 18.1508 16.3264 17.9379 16.4908C17.7251 16.6551 17.413 16.6176 17.2422 16.3964C17.0713 16.1751 17.1138 15.8637 17.3267 15.6993C17.5395 15.5349 17.8516 15.5725 18.0225 15.7937Z"
      fill="currentColor"
    />
    <path
      d="M23.6588 8.55319C23.6588 8.55319 19.2657 6.51549 13.4397 11.6497"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0186 10.2661C12.0186 10.2661 16.5246 5.86366 13.6468 1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1688 5.92607C11.3112 6.16663 11.2306 6.4705 10.9992 6.60744C10.7677 6.74437 10.4626 6.66866 10.3203 6.4281C10.178 6.18755 10.2585 5.88367 10.49 5.74674C10.7214 5.6098 11.0265 5.68552 11.1688 5.92607Z"
      fill="currentColor"
    />
    <path
      d="M0.830847 21.6356L1.46313 20.4079C2.30667 21.0435 3.19954 21.6194 4.13597 22.1295L1.83702 22.8392L1.837 22.8391L1.82908 22.8417C1.5561 22.9293 1.22774 22.8285 0.980453 22.533C0.733159 22.2375 0.692152 21.8968 0.826432 21.6441L0.826485 21.6441L0.830847 21.6356Z"
      strokeWidth="1.5"
    />
    <path
      d="M13.881 19.0336L13.9836 19.0867L13.9361 19.1015C10.4314 18.338 7.25375 16.6248 4.7017 14.1228L6.69172 10.2591L6.71717 10.3464C6.97101 11.2179 7.9142 12.9702 9.72468 15.1647C11.5357 17.3599 13.076 18.617 13.881 19.0336Z"
      strokeWidth="1.5"
    />
    <path
      d="M3.39023 16.3914C5.28769 18.1428 7.46692 19.5275 9.84128 20.4937L7.98743 21.0667C5.99318 20.3459 4.16409 19.3067 2.55312 18.0168L3.39023 16.3914Z"
      strokeWidth="1.25"
    />
  </SvgIcon>
)

export default CelebrationIcon
