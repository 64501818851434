import { MediaObjectUpload, MediaObjectUrl } from '@kudos/http-client'
import { AttachmentType, useFileMediaUpload } from '@shared/hooks/useFileMediaUpload'
import { useT } from '@transifex/react'
import { FC, useEffect } from 'react'
import DocumentAttachmentChip from './DocumentAttachmentChip'
import MediaAttachmentTile from './MediaAttachmentTile'

type AttachmentUploaderProps = {
  fileAttachment?: File | null
  attachmentSrc?: MediaObjectUrl
  index: number
  onError?: (error: Error) => void
  onAttachmentUploaded: (mediaObjectId?: string, attachmentSrc?: MediaObjectUrl) => void
  onDelete?: (mediaObjectId?: string) => void
  attachmentType: AttachmentType
  disabled?: boolean
  mutationFn?: (params: {
    originalFilename: string
    contentType: string
  }) => Promise<MediaObjectUpload>
}
const AttachmentUploader: FC<AttachmentUploaderProps> = ({
  fileAttachment,
  attachmentSrc: src,
  onAttachmentUploaded,
  onDelete,
  onError,
  index,
  attachmentType,
  disabled,
  mutationFn,
}) => {
  const t = useT()

  const { createObjectUrl, initiateUpload, attachmentSrc, mediaObjectId, isUploading, error } =
    useFileMediaUpload({ mutationFn })

  useEffect(() => {
    // do some basic cleanup to revoke the attachments object url
    return () => createObjectUrl()
  }, [])

  useEffect(() => {
    if (!!fileAttachment && !isUploading && !mediaObjectId && !error) {
      initiateUpload(fileAttachment)
      createObjectUrl(fileAttachment)
    }
  }, [fileAttachment, isUploading, mediaObjectId, error])

  useEffect(() => {
    if (!!error && !!onError) {
      onError(error)
    }
  }, [error])

  useEffect(() => {
    if (!!mediaObjectId && !!attachmentSrc) {
      onAttachmentUploaded(mediaObjectId, {
        originalFilename: fileAttachment?.name || '',
        url: attachmentSrc,
        variants: [],
      })
    }
  }, [mediaObjectId, attachmentSrc])

  if (attachmentType === 'image' || attachmentType === 'gif') {
    return (
      <MediaAttachmentTile
        mediaObjectId={mediaObjectId}
        isUploading={isUploading}
        onDelete={!!onDelete ? () => onDelete(mediaObjectId) : undefined}
        src={src?.url || attachmentSrc}
        index={index}
        alt={t('Attachment image {index}', { index })}
        disabled={disabled}
      />
    )
  }

  return (
    <DocumentAttachmentChip
      mediaObjectId={mediaObjectId}
      fileName={fileAttachment?.name || src?.originalFilename}
      isUploading={isUploading}
      onDelete={!!onDelete ? () => onDelete(mediaObjectId) : undefined}
      index={index}
      disabled={disabled}
    />
  )
}

export default AttachmentUploader
