import { API, unwrapData } from '../axios-instance'
import { TenantMemberDisplayData } from '../identity/types'
import { PageResult, WithPermissions } from '../shared/types'

// * WEB NOTIFICATIONS

export type CreateWebPushSubscriptionParams = {
  domain: string
  endpoint: string
  keys: {
    auth: string
    p256dh: string
  }
}

export const createWebPushSubscription = async ({
  domain,
  endpoint,
  keys,
}: CreateWebPushSubscriptionParams) => {
  return await API.post<boolean>('/notifications/webPushSubscriptions/', {
    endpoint,
    keys,
    domain,
  })
}

type DeleteWebPushSubscriptionParams = Pick<CreateWebPushSubscriptionParams, 'endpoint'>

export const deleteWebPushSubscription = async ({ endpoint }: DeleteWebPushSubscriptionParams) => {
  return await API.delete<boolean>('/notifications/webPushSubscriptions/', {
    data: {
      endpoint,
    },
  })
}

// MY NOTIFICATION PREFERENCES

export type ChannelPreferencesParams = {
  category: string
  channels: string[]
}

export type Channel = 'email' | 'webPush'

export type Channels = Channel[] | []

export type ChannelPreferences = {
  name: string
  category: string
  preferredChannels: Channels
  availableChannels: Channels
  supportedChannels: Channels
}

export const updateChannelPreferences = async (params: ChannelPreferencesParams[]) => {
  return await API.patch<boolean>('/notifications/channelPreferences/', params).then(unwrapData)
}

export const listChannelPreferences = async () => {
  return await API.get<ChannelPreferences[]>('/notifications/channelPreferences/').then(unwrapData)
}

// IN-APP NOTIFICATIONS

export enum RecognitionReminderType {
  WEEK = 'week',
  DISABLED = 'disabled',
}

export type RecognitionNotificationSetting = WithPermissions<{
  tenantId: string
  reminderPeriod: 'week' | null
  createdAt: string
  updatedAt: string
  __entity_type__: 'notificationSetting'
}>

export type RecognitionNotificationSettingParams = Pick<
  RecognitionNotificationSetting,
  'reminderPeriod'
>

export type NotificationsCountParams = {
  read?: boolean
}

export type NotificationsCount = {
  total: number
}

export type InAppNotification = {
  id: string
  tenantId: string
  memberId: string
  notificationId: string
  readAt: string | null
  createdAt: string
  message: string
  resourcePath: string | null
  __entity_type__: 'message'
}

export type MemberInAppNotification = InAppNotification & {
  fromMember: TenantMemberDisplayData
}

export type NotificationsParams = {
  read?: boolean
  page?: number
  pageSize?: number
  sorting?: ['-createdAt']
  embeds?: ['fromMember']
}

export const getRecognitionNotificationSettings = async () => {
  return await API.get<RecognitionNotificationSetting>('/recognition/notificationSettings/').then(
    unwrapData,
  )
}

export const updateRecognitionNotificationSettings = async (
  params: RecognitionNotificationSettingParams,
) => {
  return await API.put<RecognitionNotificationSetting>(
    '/recognition/notificationSettings/',
    params,
  ).then(unwrapData)
}

export const getInAppNotifications = async (params: NotificationsParams) => {
  return await API.get<PageResult<MemberInAppNotification>>('/notifications/messages/', {
    params,
  }).then(unwrapData)
}

export const getInAppNotificationsCount = async (params?: NotificationsCountParams) => {
  return await API.get<NotificationsCount>('/notifications/messages/count/', { params }).then(
    unwrapData,
  )
}

export const markInAppNotificationAsRead = async (id: string) => {
  return await API.delete<boolean>(`/notifications/messages/${id}`)
}

export const markAllInAppNotificationsAsRead = async () => {
  return await API.delete<boolean>('/notifications/messages/')
}
