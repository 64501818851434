import { colors } from '@globals/themes/defaultTheme'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import { Chip } from '@mui/material'
import { T } from '@transifex/react'
import useDraftRecognitionsCount from '../useDraftRecognitionCount'

type DraftAndScheludedButton = {
  onClick: () => void
}
const DraftAndScheludedButton: React.FC<DraftAndScheludedButton> = ({ onClick }) => {
  const { draftRecognitionCount } = useDraftRecognitionsCount()
  return (
    draftRecognitionCount &&
    draftRecognitionCount.total > 0 && (
      <Chip
        onClick={onClick}
        sx={{
          backgroundColor: colors.blueExtraLight,
          color: colors.grayDark,
          borderColor: 'transparent',
          width: 225,
          '&.MuiChip-root': {
            minHeight: 32,
          },
        }}
        icon={<FolderOpenIcon />}
        label={<T _str="Drafts & scheduled ({count})" count={draftRecognitionCount.total} />}
        clickable
      />
    )
  )
}

export default DraftAndScheludedButton
