import {
  FederatedSearchRecipientsParams,
  FederatedSearchRecipientsResults,
  SendTargetType,
  SendTo,
  TeamDisplayData,
  TenantMemberDisplayData,
} from '@kudos/http-client'
import { useQuery } from '@tanstack/react-query'
import { isEmpty } from 'lodash/fp'
import { useEffect, useMemo, useRef } from 'react'
import { useDebounce } from 'use-debounce'
import { TeamSendTo, TenantMemberSendTo } from './types'

export type SelectedCache<SelectableT> = Record<string, SelectableT>
export type RecipientSearchFn = (
  params: FederatedSearchRecipientsParams,
) => Promise<FederatedSearchRecipientsResults>

const isNotInList = (value: TeamDisplayData | TenantMemberDisplayData, list: SendTo[]) => {
  return !list.find(x => x.id === value.id)
}

type RecipientSearchParams = {
  isSearchEnabled: boolean
  selected: Array<TeamSendTo | TenantMemberSendTo>
  searchText?: string
  recipientSearchFn: RecipientSearchFn
}

const useRecipientSearch = ({
  isSearchEnabled,
  selected,
  searchText,
  recipientSearchFn,
}: RecipientSearchParams) => {
  const [search, setSearch] = useDebounce(searchText, 250)
  // Reference to track successfully matched results.
  // Updated only after new matches are available.
  const lastMatches = useRef<FederatedSearchRecipientsResults>()

  // This query is responsible for handling keyword searches
  const {
    data: matches,
    isSuccess,
    isFetching: isSearching,
  } = useQuery({
    queryKey: ['listRecipientsFederatedSearch', search],
    queryFn: async () => {
      const result = await recipientSearchFn({
        search: search ?? '',
        limit: 4,
      })
      return result
    },
    // Use last search result as placeholder for next search. This isn't
    // strictly necessary but results in much smoother search experience
    // because the options no longer momentarily switch to empty for
    // new searches
    placeholderData: lastMatches.current,
    // Unless we are in eager mode, only fetch if the user is interacting with
    // this field.
    enabled: isSearchEnabled && !isEmpty(search),
  })

  // this value will be used as the placeholder data for the next search
  if (isSuccess) lastMatches.current = matches

  const results = useMemo(() => {
    if (isEmpty(search) || !matches) {
      return null
    }

    // gets selected items for each type
    const selectedTeams = selected.filter(x => x.type === SendTargetType.TEAM)
    const selectedMembers = selected.filter(x => x.type === SendTargetType.TENANT_MEMBER)

    // only returns matches that are not in the selected items lists
    const newResults: FederatedSearchRecipientsResults = {
      teams: (matches?.teams ?? []).filter(m => isNotInList(m, selectedTeams)),
      tenantMembers: (matches?.tenantMembers ?? []).filter(m => isNotInList(m, selectedMembers)),
    }

    return newResults
  }, [matches, selected, searchText])

  useEffect(() => {
    setSearch(searchText)
  }, [searchText])

  return {
    results,
    isSearching,
  }
}

export default useRecipientSearch
