import { API, Token, accessTokenInfo } from '@kudos/http-client'
import { AxiosError } from 'axios'
import { createContext, useCallback, useContext, useMemo, useState } from 'react'

const TOKEN_STORAGE_KEY = 'accessToken'

const clearStorage = () => window.localStorage.removeItem(TOKEN_STORAGE_KEY)

const saveToStorage = (accessToken: Token) => {
  window.localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(accessToken))
}

const retrieveFromStorage = (): Token | null => {
  const value = window.localStorage.getItem(TOKEN_STORAGE_KEY)
  if (!value) return null
  let accessToken: Token
  try {
    accessToken = JSON.parse(value)
  } catch {
    return null
  }

  // Basic validation to make sure this isn't just garbage json data from previous bad release
  if (!accessToken.token || !accessToken.userId || accessToken.tenantId === undefined) {
    return null
  }
  return accessToken
}

export type AuthContextType = {
  isLoading: boolean
  isAuthenticated: boolean
  tenantId: string | null
  userId: string | null
  isTenantScope: boolean
  isUserScope: boolean
  initialize: (accessToken: Token) => void
  initializeFromStorage: () => Promise<void>
  clearAuth: () => void
  clearStorage: () => void
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [accessToken, setAccessToken] = useState<Token | null>(null)
  const [isLoading, setLoading] = useState<boolean>(true)

  const clearAuth = useCallback((resetStorage: boolean = true) => {
    setAccessToken(null)
    resetStorage && clearStorage()
    delete API.defaults.headers.common['Authorization']
    setLoading(false)
  }, [])

  const initialize = useCallback((accessToken: Token) => {
    setLoading(true)
    saveToStorage(accessToken)
    setAccessToken(accessToken)
    API.defaults.headers.common['Authorization'] = accessToken.token
    setLoading(false)
  }, [])

  const initializeFromStorage = useCallback(async () => {
    setLoading(true)
    const retrievedAccessToken = retrieveFromStorage()
    if (retrievedAccessToken === null) {
      clearAuth()
    } else {
      await accessTokenInfo(retrievedAccessToken!.token)
        .then(accessToken => {
          // Token is valid, continue with initialization
          initialize(accessToken)
        })
        .catch(error => {
          const axiosError = error as AxiosError
          // NOTE: RE: SYN-1115: We only want to clear the access token from local storage when we know for sure that it is invalid i.e. we do not clear it on any other network error.
          clearAuth(axiosError.response?.status === 401)
        })
    }

    setLoading(false)
  }, [])

  const memoedValue = useMemo(
    () => ({
      isLoading,
      isAuthenticated: Boolean(accessToken),
      isTenantScope: Boolean(accessToken && accessToken.tenantId),
      isUserScope: Boolean(accessToken && accessToken.tenantId === null),
      tenantId: accessToken ? accessToken.tenantId : null,
      userId: accessToken ? accessToken.userId : null,
      clearStorage,
      clearAuth,
      initialize,
      initializeFromStorage,
    }),
    [accessToken, isLoading],
  )

  return <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
