import { useAuth } from '@auth/useAuth'
import AppPaths from '@globals/paths'
import { tabletQuery } from '@globals/themes/themeConstants'
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined'
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { SxProps } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import TenantMemberAvatar from '@shared/avatar/TenantMemberAvatar'
import InAppNotifications from '@shared/inAppNotifications/InAppNotifications'
import { T } from '@transifex/react'
import { ReactNode, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  KudosLogo,
  NavContainer,
  PoweredBy,
  StyledArrowBackIcon,
  StyledListItem,
} from './Nav.styled'
import { NavContents } from './NavContents'
import { NavDrawerDesktop } from './NavDrawerDesktop'
import { NavDrawerMobile } from './NavDrawerMobile'
import MobileProgressiveWebAppInstallPrompt from './PWAInstallationPrompt'
import { useNav } from './useNav'

export type RenderContext = {
  onCollapse: (key: string) => void
  expanded: string[]
  onRouteChange: () => void
}

const mobileNavProps: SxProps = {
  '> ul': {
    padding: 0,
  },
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  justifyContent: 'end',
}

const desktopNavProps: SxProps = {
  flexGrow: 1,
  marginTop: 2,
}

export const Nav: React.FC = () => {
  const { isAuthenticated, isTenantScope } = useAuth()
  const { visibleItems, mode, setMode, expanded, onCollapse, canViewAdmin } = useNav(
    isAuthenticated,
    isTenantScope,
  )

  const { pathname } = useLocation()
  const isTablet = useMediaQuery(tabletQuery)
  const [isMobileDrawerOpen, setisMobileDrawerOpen] = useState<boolean>(false)

  const toggleDrawer = () => {
    setisMobileDrawerOpen(!isMobileDrawerOpen)
  }

  // Nav is not supported outside of authenticated context
  if (!isAuthenticated || mode === 'hidden') return null

  const renderContext: RenderContext = {
    onCollapse,
    expanded,
    onRouteChange: toggleDrawer,
  }

  const navContainerContent: ReactNode = (
    <>
      <NavContainer>
        {mode === 'admin' && (
          <>
            <nav>
              <List sx={{ mt: 2 }}>
                <StyledListItem>
                  <ListItemButton onClick={() => setMode('default')}>
                    <ListItemIcon>
                      <StyledArrowBackIcon />
                    </ListItemIcon>
                    <ListItemText primary={<T _str="Back to main menu" />} />
                  </ListItemButton>
                </StyledListItem>
              </List>
            </nav>
            <Divider />
          </>
        )}

        {isAuthenticated && mode === 'default' && isTablet && (
          <MobileProgressiveWebAppInstallPrompt />
        )}

        <Box component="nav" sx={!isTablet ? desktopNavProps : mobileNavProps}>
          <NavContents navItems={visibleItems} context={renderContext} isMobile={isTablet} />
        </Box>

        {isAuthenticated && mode === 'default' && (
          <nav>
            <List sx={{ paddingTop: 0 }}>
              {isTenantScope && (
                <StyledListItem>
                  <ListItemButton
                    component={Link}
                    to={AppPaths.tenant.mySettings('notifications')}
                    selected={[
                      AppPaths.tenant.mySettings('notifications'),
                      AppPaths.tenant.mySettings('security'),
                      AppPaths.tenant.mySettings('privacy'),
                    ].includes(pathname)}
                    onClick={toggleDrawer}>
                    <ListItemIcon>
                      <SettingsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={<T _str="My settings" />} />
                  </ListItemButton>
                </StyledListItem>
              )}
              {canViewAdmin && (
                <StyledListItem>
                  <ListItemButton onClick={() => setMode('admin')}>
                    <ListItemIcon>
                      <BadgeOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={<T _str="Admin" />} />
                  </ListItemButton>
                </StyledListItem>
              )}
              {/* TODO: RE:SYN-919 - hide unused nav items */}
              {/* eslint-disable-next-line no-constant-binary-expression*/}
              {false && (
                <StyledListItem>
                  <ListItemButton disabled>
                    <ListItemIcon>
                      <HelpOutlineOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={<T _str="Help" />} />
                  </ListItemButton>
                </StyledListItem>
              )}
              <StyledListItem>
                <ListItemButton
                  component={Link}
                  to={AppPaths.tenant.feedback()}
                  onClick={toggleDrawer}>
                  <ListItemIcon>
                    <FeedbackOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={<T _str="Provide feedback" />} />
                </ListItemButton>
              </StyledListItem>
              <StyledListItem>
                <ListItemButton component={Link} to={AppPaths.logout()}>
                  <ListItemIcon>
                    <LogoutOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={<T _str="Sign out" />} />
                </ListItemButton>
              </StyledListItem>
            </List>
          </nav>
        )}
      </NavContainer>

      {!isTablet && (
        <PoweredBy variant="subtitle1">
          Powered by <KudosLogo alt="Kudos®" loading="lazy" src="logo-300x90.png" />
        </PoweredBy>
      )}
    </>
  )

  return (
    <>
      <TenantMemberAvatar />
      <InAppNotifications />
      {isTablet ? (
        <NavDrawerMobile
          isMobileDrawerOpen={isMobileDrawerOpen}
          onMobileDrawerToggle={toggleDrawer}>
          {navContainerContent}
        </NavDrawerMobile>
      ) : (
        <NavDrawerDesktop>{navContainerContent}</NavDrawerDesktop>
      )}
    </>
  )
}
