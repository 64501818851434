import { useDocumentTitle } from '@globals/paths/useDocumentTitle'
import React, { PropsWithChildren } from 'react'

const DocumentTitleWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  useDocumentTitle()

  return children
}

export default DocumentTitleWrapper
