import { RecognitionEmbeds, RecognitionStatus, listRecognitions } from '@kudos/http-client'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

export const ALL_RECOGNITION_QK = 'LIST_PAGINATED_RECOGNITIONS_QUERY_KEY'

export const listRecognitionsEmbeds: RecognitionEmbeds[] = [
  'sender',
  'qualities',
  'sendTo',
  'attachmentUrls',
  'endorsementStats',
  'reactionCounts',
  'comments',
  'ownReaction',
  'recipientCount',
  'ownRecipient',
]

type PaginatedRecognitionsOptions = {
  enabled?: boolean
  recipientIdIn?: string
  recognitionIdIn?: string
  sentByIdIn?: string
  sentByOrToIdIn?: string
  status?: RecognitionStatus[]
}

const usePaginatedRecognitions = (options: PaginatedRecognitionsOptions = {}) => {
  const {
    enabled = true,
    recipientIdIn,
    recognitionIdIn,
    sentByIdIn,
    sentByOrToIdIn,
    status,
  } = options
  const pageSize = 20

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isRefetching, refetch } =
    useInfiniteQuery({
      queryKey: [
        ALL_RECOGNITION_QK,
        recipientIdIn,
        recognitionIdIn,
        sentByIdIn,
        sentByOrToIdIn,
        status,
      ],
      queryFn: ({ pageParam = 1 }) =>
        listRecognitions({
          ...{ idIn: recognitionIdIn ? [recognitionIdIn] : undefined },
          ...{ sentByOrToIdIn: sentByOrToIdIn ? [sentByOrToIdIn] : undefined },
          ...{ sentByIdIn: sentByIdIn ? [sentByIdIn] : undefined },
          ...{ statusIn: status ? status : [RecognitionStatus.PUBLISHED] },
          ...{ recipientIdIn: recipientIdIn ? [recipientIdIn] : undefined },
          page: pageParam,
          pageSize,
          sorting: ['-createdAt'],
          embeds: listRecognitionsEmbeds,
        }),
      initialPageParam: 1,
      getNextPageParam: lastPage => lastPage.next,
      enabled,
    })

  const recognitionsData = useMemo(() => {
    return data?.pages.flatMap(page => page.results) || []
  }, [data])
  return {
    fetchMoreRecognitions: fetchNextPage,
    hasMoreRecognitions: hasNextPage,
    isFetchingNextPage,
    isLoading,
    isRefetching,
    recognitionsData,
    refetch,
  }
}

export default usePaginatedRecognitions
