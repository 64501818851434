import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import StandardPageLayout from '@layouts/standardPageLayout'
import RouteLoading from '@shared/routing/RouteLoading'
import { T } from '@transifex/react'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const RewardsRoute = lazyWithRetries(() => import('.'))
const UpdateRewardProvider = lazyWithRetries(() => import('./UpdateRewardProvider'))
const ConfigureTangoRewardProvider = lazyWithRetries(() => import('./tango'))
const MyRewardStoreRoute = lazyWithRetries(() => import('./tango/myRewardStore/MyRewardStore'))
const AddTangoRewardFunds = lazyWithRetries(() => import('./tango/addFunds/AddFunds'))

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.admin.rewards(':tab?'),
    element: (
      <StandardPageLayout elementId="rewardsRoute" title={<T _str="Rewards" />} maxWidth={1440}>
        <Suspense fallback={<RouteLoading />}>
          <RewardsRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.editRewardProvider(':rewardProviderId'),
    element: (
      <StandardPageLayout elementId="updateRewardProvider" maxWidth={600}>
        <Suspense fallback={<RouteLoading />}>
          <UpdateRewardProvider />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.configureTangoRewardProvider(':rewardProviderId?'),
    element: (
      <StandardPageLayout elementId="configureTangoRewardProvider" maxWidth={900}>
        <Suspense fallback={<RouteLoading />}>
          <ConfigureTangoRewardProvider />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.tangoRewardProvider(':rewardProviderId'),
    element: (
      <StandardPageLayout
        elementId="tangoRewardProvider"
        maxWidth={900}
        title={<T _str="My reward store" />}>
        <Suspense fallback={<RouteLoading />}>
          <MyRewardStoreRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.addTangoRewardFunds(':rewardProviderId'),
    element: (
      <StandardPageLayout
        elementId="addTangoRewardFunds"
        maxWidth={600}
        title={<T _str="Add funds" />}>
        <Suspense fallback={<RouteLoading />}>
          <AddTangoRewardFunds />
        </Suspense>
      </StandardPageLayout>
    ),
  },
]

export default routes
