import { colors } from '@globals/themes/defaultTheme'
import { RecognitionRecipients, SendTargetType, TenantMemberDisplayData } from '@kudos/http-client'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid2'
import Stack from '@mui/material/Stack'
import TeamAvatar from '@shared/avatar/TeamAvatar'
import UserAvatar from '@shared/avatar/UserAvatar'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import AvatarAndNameDisplay from './AvatarAndNameDisplay'
import FadeUserDisplay from './FadeUserDisplay'
import SenderAvatarAndNameDisplay from './SenderAvatarAndNameDisplay'

const RECIPIENT_ROTATIONAL_INTERVAL = 20000
const ACTIVE_RECIPIENT_AVATAR_SIZE = 24

const arrowDropUpIconStyle = {
  height: 60,
  width: 60,
}

type SenderAndReceiverProps = {
  sender: TenantMemberDisplayData
  receivers: RecognitionRecipients[]
  textColor?: string
  SingleRecipientAvatar?: React.FC<{ recipientDisplayData?: TenantMemberDisplayData }>
}

const SenderAndReceivers: React.FC<SenderAndReceiverProps> = ({
  sender,
  receivers,
  textColor = colors.white,
  SingleRecipientAvatar,
}) => {
  const hasMultipleReceivers = receivers.length > 1

  const [activeReceiver, setActiveReceiver] = useState(receivers[0])
  const [otherReceivers, setOtherReceivers] = useState(
    hasMultipleReceivers ? receivers.slice(1) : [],
  )

  const GroupAvatar: { [key in string]: JSX.Element } = {
    [SendTargetType.TEAM]: <TeamAvatar size={10} fontUnit="vh" />,
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (hasMultipleReceivers) {
        const nextReceiver = otherReceivers[0]
        const newOtherReceivers = otherReceivers.slice(1)
        setOtherReceivers([...newOtherReceivers, activeReceiver])
        setActiveReceiver(nextReceiver)
      }
    }, RECIPIENT_ROTATIONAL_INTERVAL)

    return () => clearInterval(interval)
  }, [activeReceiver])

  return (
    <>
      {hasMultipleReceivers && (
        <Grid size={{ xs: 1 }}>
          <Stack alignItems="center">
            <AnimatePresence>
              {otherReceivers.map(receiver => (
                <motion.div
                  key={receiver?.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.75, ease: 'easeOut' }}>
                  <Box sx={{ pb: 3 }}>
                    {receiver.displayData && 'firstName' in receiver.displayData ? (
                      <UserAvatar
                        firstName={receiver.displayData.firstName}
                        lastName={receiver.displayData.lastName}
                        size={10}
                        fontUnit="vh"
                      />
                    ) : (
                      GroupAvatar[receiver.type as SendTargetType]
                    )}
                  </Box>
                </motion.div>
              ))}
            </AnimatePresence>
          </Stack>
        </Grid>
      )}
      <Grid size={{ xs: hasMultipleReceivers ? 2 : 3 }}>
        <Stack alignItems="center">
          {hasMultipleReceivers ? (
            <FadeUserDisplay
              key={activeReceiver.id}
              rotationInterval={RECIPIENT_ROTATIONAL_INTERVAL}>
              <AvatarAndNameDisplay
                textColor={textColor}
                displayee={activeReceiver}
                avatarSize={ACTIVE_RECIPIENT_AVATAR_SIZE}
              />
            </FadeUserDisplay>
          ) : (
            <AvatarAndNameDisplay
              textColor={textColor}
              displayee={activeReceiver}
              avatarSize={!!SingleRecipientAvatar ? 14 : ACTIVE_RECIPIENT_AVATAR_SIZE}
              SingleRecipientAvatar={SingleRecipientAvatar}
            />
          )}
          <ArrowDropUpIcon sx={{ ...arrowDropUpIconStyle, color: textColor }} />
          <SenderAvatarAndNameDisplay sender={sender} avatarSize={15} textColor={textColor} />
        </Stack>
      </Grid>
    </>
  )
}

export default SenderAndReceivers
