import { Permissions } from '@kudos/http-client'
import {
  AnnouncementPermission,
  AppPermission,
  AuthFactorPermission,
  AwardPermission,
  AwardTypePermission,
  BudgetItemPermission,
  CalendarEventPermission,
  CardContributorPermission,
  CardPermission,
  ChannelPermission,
  CheckInPermission,
  CommentPermission,
  IncentivePermission,
  RecognitionPermission,
  ReportPermissions,
  ResourcePermission,
  RewardProviderPermission,
  SamlProviderPermission,
  TangoProviderPermission,
  TeamAutomationPermission,
  TeamPermission,
  TenantMemberPermission,
  TenantPermission,
  UserPermission,
  ValuePermission,
} from '.'

/**
 * @method hasPermission
 * @description takes in an object which contains a __permissions__ prop and checks to see if the string exists in the array of strings
 * @prop entity
 * @prop permission
 * @returns boolean
 */
export const hasPermission = (
  entity: Permissions,
  permission:
    | TenantMemberPermission
    | SamlProviderPermission
    | AppPermission
    | ChannelPermission
    | TenantPermission
    | UserPermission
    | AuthFactorPermission
    | CheckInPermission
    | TeamPermission
    | ValuePermission
    | RecognitionPermission
    | CommentPermission
    | BudgetItemPermission
    | ResourcePermission
    | TeamAutomationPermission
    | RewardProviderPermission
    | AwardTypePermission
    | AwardPermission
    | CalendarEventPermission
    | CardPermission
    | CardContributorPermission
    | ReportPermissions
    | AnnouncementPermission
    | TangoProviderPermission
    | IncentivePermission,
): boolean =>
  !!entity?.__permissions__?.length ? entity.__permissions__.includes(permission) : false
