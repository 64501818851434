import { ActionButton } from '@components/button/types'
import { useDialog } from '@globals/dialog/useDialogStore'
import { colors } from '@globals/themes/defaultTheme'
import { tabletQuery } from '@globals/themes/themeConstants'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { Typography, useMediaQuery } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { SxProps } from '@mui/material/styles'
import { DRAWER_WIDTH } from '@nav/Nav.constants'
import RightDrawer from '@shared/dialog/RightDrawer'
import { T, useT } from '@transifex/react'
import { filter } from 'lodash/fp'
import React, { ReactNode, createContext, useEffect, useState } from 'react'
import ActionButtons from './ActionButtons'
import BackArrow from './BackArrow'
import MoreActionsMenu from './MoreActionsMenu'

const appBarStyle: SxProps = {
  width: '100%',
  boxShadow: 'none',
  background: colors.blueGradient,
  color: colors.white,
  borderBottom: `1px solid ${colors.grayExtraLight}`,
  borderRadius: 0,
}

const constrainedWidthLayoutStyle: SxProps = {
  margin: 'auto',
  overflow: 'visible',
}

const helpButtonStyle: SxProps = {
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:focus': {
    backgroundColor: 'transparent',
  },
}

const btnMobileStyle = {
  [tabletQuery]: {
    position: 'fixed',
    top: 42,
    right: 22,
  },
}

export const StandardPageLayoutContext = createContext<StandardPageLayoutContextProps | undefined>(
  undefined,
)

type StandardPageLayoutProps = {
  elementId: string
  maxWidth: 600 | 900 | 1080 | 1440
  title?: ReactNode
  children: React.ReactNode
}

type StandardPageLayoutContextProps = {
  setPageTitle: (title: ReactNode) => void
  setBackToPath: (path: string | number) => void
  setChips: (options: ReactNode[]) => void
  setActionButtons: (actions: ActionButton[]) => void
  setHelpContent: (content: ReactNode) => void
  setTabPanel: (tabPanel: ReactNode) => void
  appBarHeight: number
  setSubTabPanel: (subTabPanel: ReactNode) => void
}

const StandardPageLayout: React.FC<StandardPageLayoutProps> = ({
  elementId,
  title,
  maxWidth,
  children,
}) => {
  const t = useT()
  const topBarRef = React.createRef<HTMLDivElement>()
  const isTablet = useMediaQuery(tabletQuery)
  const [appBarHeight, setAppBarHeight] = useState<number>(50)
  const [pageTitle, setPageTitle] = useState<ReactNode>(title)
  const [backToPath, setBackToPath] = useState<string | number>()
  const [chips, setChips] = useState<ReactNode[]>()
  const [actionButtons, setActionButtons] = useState<ActionButton[]>([])
  const [helpContent, setHelpContent] = useState<ReactNode>()
  const [tabPanel, setTabPanel] = useState<ReactNode>()
  const [subTabPanel, setSubTabPanel] = useState<ReactNode>()

  useEffect(() => {
    if (topBarRef.current) {
      setAppBarHeight(topBarRef.current.clientHeight)
    }
  }, [topBarRef])

  const HELP_DIALOG_KEY = `${elementId}helpDialog`
  const { openDialog: openHelpDialog } = useDialog(HELP_DIALOG_KEY)

  const moreActionButtons = actionButtons?.filter(action => action.variant !== 'primary')

  if ((moreActionButtons?.length ?? 0) > 0 || isTablet) {
    if (!!helpContent) {
      moreActionButtons.push({
        key: 'help',
        name: t('Help'),
        variant: 'other',
        onClick: () => openHelpDialog(),
      })
    }
  }

  const contextValue = {
    setPageTitle,
    setBackToPath,
    setChips,
    setActionButtons,
    setHelpContent,
    setTabPanel,
    appBarHeight,
    setSubTabPanel,
  }

  return (
    <>
      <AppBar
        ref={topBarRef}
        position="fixed"
        color="default"
        elevation={0}
        sx={{ ...appBarStyle, left: isTablet ? 0 : DRAWER_WIDTH }}>
        <Stack
          direction={isTablet ? 'column' : 'row'}
          alignItems={isTablet ? 'flex-start' : 'center'}
          spacing={1}
          mt={isTablet ? 3 : 5.5}
          py={2}
          px={3}
          width={isTablet ? '100%' : `calc(100% - ${DRAWER_WIDTH}px)`}>
          <Stack direction="row" alignItems="center" spacing={1}>
            {!!backToPath && <BackArrow to={backToPath} />}
            <Typography variant="h1">{pageTitle}</Typography>
          </Stack>
          {!!chips && (
            <Stack direction="row" spacing={1}>
              {chips.map((chip, index) => (
                <Box key={`standardPageChip-${index}`}>{chip}</Box>
              ))}
            </Stack>
          )}
          <Box sx={{ flexGrow: 1 }} />
          {!!actionButtons && (
            <ActionButtons
              actions={
                isTablet
                  ? filter({ variant: 'primary' }, actionButtons)
                  : actionButtons?.filter(action => action.variant !== 'other')
              }
            />
          )}
          {!!moreActionButtons && (moreActionButtons?.length ?? 0) > 0 ? (
            <MoreActionsMenu
              actions={
                isTablet ? moreActionButtons : filter({ variant: 'other' }, moreActionButtons)
              }
              buttonStyle={btnMobileStyle}
            />
          ) : (
            !!helpContent &&
            !isTablet && (
              <Button
                id="btnHelp"
                aria-label={t('Help information')}
                sx={helpButtonStyle}
                onClick={() => openHelpDialog()}>
                <HelpOutlineOutlinedIcon />
              </Button>
            )
          )}
        </Stack>
        {!!tabPanel && <Box sx={{ px: 3 }}>{tabPanel}</Box>}
      </AppBar>
      <Box
        sx={{ ...constrainedWidthLayoutStyle, pt: `${appBarHeight + 20}px` }}
        maxWidth={maxWidth}>
        <StandardPageLayoutContext.Provider value={contextValue}>
          {!!subTabPanel && <Box sx={{ mb: 3 }}>{subTabPanel}</Box>}
          {children}
          {!!helpContent && (
            <RightDrawer dialogKey={HELP_DIALOG_KEY} title={<T _str="Help" />}>
              {helpContent}
            </RightDrawer>
          )}
        </StandardPageLayoutContext.Provider>
      </Box>
    </>
  )
}

export default StandardPageLayout
