import { useDialog } from '@globals/dialog/useDialogStore'
import { colors } from '@globals/themes/defaultTheme'
import { setSvgIconSize } from '@globals/themes/globalStyles'
import { useApiActionToast } from '@globals/toaster/useToasterStore'
import { deleteRecognition } from '@kudos/http-client'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { ConfirmDialog } from '@shared/dialog/ConfirmDialog'
import { useInvalidateQuery } from '@shared/hooks/useInvalidateQuery'
import { useMutation } from '@tanstack/react-query'
import { T, useT } from '@transifex/react'
import { FC } from 'react'

import { useApiError } from '@shared/errors/useApiError'
import { RECOGNITION_COUNT_QUERY_KEY } from '../../useConfigureRecognitionTabs'
import { ALL_RECOGNITION_QK } from '../../usePaginatedRecognitions'

type DeleteRecognitonProps = {
  recognitionId: string
}

const DeleteRecognition: FC<DeleteRecognitonProps> = ({ recognitionId }) => {
  const t = useT()
  const [showSuccess] = useApiActionToast()
  const { handleApiError } = useApiError()
  const invalidateQuery = useInvalidateQuery()

  const deleteRecognitionConfirmDialog = `DeleteRecognitionConfirmDialog-${recognitionId}`

  const { openDialog: openDeleteRecognition, closeDialog: closeDeleteRecogntion } =
    useDialog<string>(deleteRecognitionConfirmDialog)

  const { mutate: deleteRecognitionMutation } = useMutation({
    mutationFn: deleteRecognition,
    onSuccess: async () => {
      await invalidateQuery([ALL_RECOGNITION_QK])
      await invalidateQuery(RECOGNITION_COUNT_QUERY_KEY)
      showSuccess(t('Recognition deleted successfully'))
    },
    onError: error => handleApiError(error),
  })

  const handleConfirm = () => {
    if (!!recognitionId) {
      deleteRecognitionMutation(recognitionId)
      closeDeleteRecogntion()
    }
  }

  return (
    <>
      <IconButton
        sx={{ ...setSvgIconSize('1.5rem') }}
        id={`btnDeleteRecognition-${recognitionId}`}
        onClick={() => openDeleteRecognition(recognitionId)}
        aria-label={t('Delete draft recognition')}
        aria-labelledby="tooltipDeleteDraftRecognition">
        <Tooltip
          id="tooltipDeleteDraftRecognition"
          title={<T _str="Delete draft recognition" />}
          placement="top">
          <DeleteForeverOutlinedIcon sx={{ color: `${colors.redDark} !important` }} />
        </Tooltip>
      </IconButton>
      <ConfirmDialog
        dialogKey={deleteRecognitionConfirmDialog}
        content={<T _str="Are you sure you want to delete this recognition?" />}
        confirmButtonText={<T _str="Delete" />}
        cancelButtonText={<T _str="Keep" />}
        onConfirm={handleConfirm}
      />
    </>
  )
}

export default DeleteRecognition
