/* eslint-disable no-console */
import { lazy } from 'react'

/**
 * A utility wrapper around `React.lazy` that enhances dynamic imports with retry logic.
 * This function attempts to reload a failed module import multiple times using an exponential
 * backoff strategy and cache-busting query parameters.
 *
 * References:
 * - https://medium.com/@alonmiz1234/retry-dynamic-imports-with-react-lazy-c7755a7d557a
 * - https://stackoverflow.com/a/76200536/6136566
 * @param importer  - A dynamic import function that returns a module
 * @returns A React component wrapped in `React.lazy` with retry logic applied
 */
export const lazyWithRetries: typeof lazy = importer => {
  const retryImport = async () => {
    try {
      return await importer()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // Retry 5 times with exponential backoff (i.e. 2, 4, 8, 16, 32 seconds)
      for (let i = 0; i < 5; i++) {
        await new Promise(resolve => setTimeout(resolve, 1000 * 2 ** i))

        // Extract the URL from error message using regex matching
        // e.g. error.message = "Failed to fetch dynamically imported module: https://example.com/assets/Home.tsx"
        const errorMessage: string = error?.message || ''
        const urlMatch = errorMessage.match(/https?:\/\/[^\s]+/)

        // If there is no url (therefore no chunk data), throw caught error
        if (!urlMatch) {
          console.error('Failed to extract URL from error message:', errorMessage)
          throw error
        }

        const url = new URL(urlMatch[0])

        // Add a timestamp to the url to force the browser to reload the module (and not use the cached version - hence "cache busting")
        url.searchParams.set('t', `${+new Date()}`)

        try {
          // Adds vite-ignore as recommended since it is intended to be left as is
          return await import(/* @vite-ignore */ url.href)
        } catch (e: unknown) {
          console.warn(`Retrying dynamic import (${i + 1}/5)`, e)
        }
      }
      throw error
    }
  }
  return lazy(retryImport)
}
