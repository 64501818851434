import Box from '@mui/material/Box'
import AwardsDefaults from '../images/AwardsDefault.png'

const iconStyle = {
  width: '100%',
  height: '100%',
}

const DefaultAwardIcon = () => <Box component="img" src={AwardsDefaults} sx={iconStyle} />

export default DefaultAwardIcon
