import { useAuth } from '@auth/useAuth'
import { getTenantMetadata, TenantMetadata } from '@kudos/http-client'
import { useQuery } from '@tanstack/react-query'

export const TENANT_METADATA_QK = 'TENANT_METADATA_QUERY_KEY'

// This data will show by default and in the event of failure to fetch tenant info
const defaultMetadata: TenantMetadata = {
  language: 'en',
  name: 'Kudos',
  logoUrls: null,
  bannerUrls: null,
}

const getCurrentMetadata = (): Promise<TenantMetadata> => {
  // API needs to know what domain we are on so it can fetch appropriate tenant
  // display data even if we are logged out or not logged into the tenant yet.
  return getTenantMetadata(window.location.hostname).catch(() => defaultMetadata)
}

/**
 * This hook makes information about the current tenant context available for
 * UI, branding purposes. This data is cached because it is slow moving and mostly
 * used for display and therefore doesn't matter if it lags behind server. This data
 * should *NOT* be used to infer authentication state or for any kind of functionality.
 */
export const useTenantMetadata = (): [TenantMetadata, boolean] => {
  const { tenantId } = useAuth()
  const { data: metadata, isSuccess } = useQuery<TenantMetadata>({
    queryKey: [TENANT_METADATA_QK, tenantId],
    queryFn: getCurrentMetadata,
    staleTime: 15 * 60 * 1000,
  })

  return [metadata || defaultMetadata, isSuccess]
}
