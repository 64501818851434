import { useThemeStore } from '@globals/themes/useThemeStore'
import { ToasterUI } from '@globals/toaster/ToasterUI'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3'
import { ErrorBoundary } from '@rollbar/react'
import { ReactNode } from 'react'
import ErrorBoundaryUI from './routes/ErrorBoundaryUI'

const AppLayout = ({ children }: { children: ReactNode }) => {
  const getCurrentTheme = useThemeStore(state => state.getCurrentTheme)

  // Must listen to this to trigger render when the theme changes
  useThemeStore(state => state.isDarkTheme)

  return (
    <ThemeProvider theme={getCurrentTheme()}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <ErrorBoundary fallbackUI={ErrorBoundaryUI}>
          {children}
          <ToasterUI />
        </ErrorBoundary>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default AppLayout
