import { useCurrentMember } from '@globals/useCurrentMember'
import { RecognitionStatus } from '@kudos/http-client'
import usePaginatedRecognitions from '../../usePaginatedRecognitions'

const useInProgressRecognitions = () => {
  const { member } = useCurrentMember()

  const {
    fetchMoreRecognitions,
    hasMoreRecognitions,
    isFetchingNextPage,
    isLoading,
    isRefetching,
    recognitionsData,
    refetch: refetchDraftRecognitions,
  } = usePaginatedRecognitions({
    sentByIdIn: member?.id ? member.id : '',
    status: [RecognitionStatus.DRAFT],
    enabled: !!member?.id,
  })

  return {
    fetchMoreRecognitions,
    hasMoreRecognitions,
    isFetchingNextPage,
    isLoading,
    isRefetching,
    recognitionsData,
    refetchDraftRecognitions,
  }
}

export default useInProgressRecognitions
