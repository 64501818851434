import { useAuth } from '@auth/useAuth'
import { getTenantMember, TenantMember, TenantMemberEmbeds } from '@kudos/http-client'
import { useQuery } from '@tanstack/react-query'

type CurrentMember = {
  member: TenantMember | null
  userId: string | null
  isManager: boolean
  isFetched: boolean
}

/**
 * Get the TenantMember entity and associated user id for the current member.
 * @returns Tuple of the Member & loaded status
 */
export const useCurrentMember = (
  embeds: TenantMemberEmbeds[] = ['avatar', 'publicStaffProfile', 'hasReports', 'email'],
): CurrentMember => {
  const { userId, isAuthenticated, isTenantScope } = useAuth()
  const {
    data: member,
    isFetched,
    isError,
  } = useQuery<TenantMember>({
    queryKey: ['useCurrentMember', userId, ...embeds],
    queryFn: () => getTenantMember('me', { embeds }),
    staleTime: 5 * 60 * 1000,
    enabled: isAuthenticated && isTenantScope && !!userId,
  })

  if (!userId || isError) {
    return {
      member: null,
      userId: null,
      isManager: false,
      isFetched,
    }
  }

  return {
    member: member || null,
    userId: userId || null,
    isManager: member?.hasReports || false,
    isFetched,
  }
}
