import { API } from '../axios-instance'
import { Tenant, TenantLoginRedirect, Token } from './types'

// * TENANT

export const listTenants = async () => {
  return await API.get<Tenant[]>('/identity/tenants/my').then(r => r.data)
}

export const initiateTenantLogin = async ({ tenantId }: { tenantId: string }) => {
  return await API.post<TenantLoginRedirect>(`/identity/tenants/${tenantId}/login`, undefined, {
    withCredentials: true,
  }).then(r => r.data)
}

export const retrieveTenantAccessToken = async ({ state }: { state: string }) => {
  return await API.get<Token>('/identity/tenants/accessToken', {
    params: {
      state,
    },
    withCredentials: true,
    headers: { Authorization: undefined },
  }).then(r => r.data)
}
