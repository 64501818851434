import { useState, useEffect } from 'react'

export enum AnimationStateEnum {
  IDLE = 'idle',
  STARTED = 'started',
  FINISHED = 'finished',
}

const useAnimationTimer = (duration = 1000, delay = 0) => {
  const [elapsed, setTime] = useState<number>(0)
  const [animationState, setAnimationState] = useState<AnimationStateEnum>(AnimationStateEnum.IDLE)

  useEffect(
    () => {
      let animationFrame: number,
        timerStop: NodeJS.Timeout | undefined,
        start: number = 0
      const onFrame = () => {
        setTime(Date.now() - start)
        loop()
      }

      // Call onFrame() on next animation frame
      const loop = () => {
        animationFrame = requestAnimationFrame(onFrame)
      }

      const onStart = () => {
        setAnimationState(AnimationStateEnum.STARTED)
        // Set a timeout to stop things when duration time elapses
        timerStop = setTimeout(() => {
          setAnimationState(AnimationStateEnum.FINISHED)
          cancelAnimationFrame(animationFrame)
          setTime(Date.now() - start)
        }, duration)

        // Start the loop
        start = Date.now()
        loop()
      }

      // Reset animation state
      setAnimationState(AnimationStateEnum.IDLE)

      // Start after specified delay (defaults to 0)
      const timerDelay = setTimeout(onStart, delay)

      // Clean things up
      return () => {
        setTime(0)
        clearTimeout(timerStop)
        clearTimeout(timerDelay)
        cancelAnimationFrame(animationFrame)
      }
    },
    [duration, delay], // Only re-run effect if duration or delay changes
  )

  return { elapsed, animationState }
}

export default useAnimationTimer
