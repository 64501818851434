import { getRecognition, Recognition } from '@kudos/http-client'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { listRecognitionsEmbeds } from './usePaginatedRecognitions'

export const GET_RECOGNITION_QK = 'GET_RECOGNITION_QUERY_KEY'

const useGetRecognition = (recognitionId?: string, enabled: boolean = true) => {
  const [recognition, setRecognition] = useState<Recognition>()

  const { data, isFetched, isLoading, refetch } = useQuery({
    queryKey: [GET_RECOGNITION_QK, recognitionId],
    queryFn: () => getRecognition(recognitionId!, listRecognitionsEmbeds),
    enabled: !!recognitionId && enabled,
  })

  useEffect(() => {
    if (recognitionId && data) {
      setRecognition(data)
    } else {
      setRecognition(undefined)
    }
  }, [data, recognitionId])

  return {
    isFetched,
    isLoading,
    recognition,
    refetch,
  }
}

export default useGetRecognition
