import { AppEntityTypes, listApps } from '@kudos/http-client'
import { AppPermission, hasPermission } from '@shared/permissions'
import { useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useAuth } from './useAuth'

export const USE_APPS_QK = 'USE_APPS_QUERY_KEY'

export type AppPermissionChecker = (a: AppEntityTypes, p: AppPermission) => boolean

/**
 * This hook makes information about the apps/modules enabled in the tenant available
 * to the wider application. This includes the permissions available in each app
 * for the current auth context. The hook exposes a checker function that can test
 * if permission is available on the specified app. This data is slow moving and suitable
 * for short-term caching.
 */
export const useApps = (): [AppPermissionChecker, boolean] => {
  const { tenantId } = useAuth()
  const { data: apps, isSuccess } = useQuery({
    queryKey: [USE_APPS_QK, tenantId],
    queryFn: listApps,
    enabled: Boolean(tenantId),
    staleTime: 5 * 60 * 1000,
  })

  const hasAppPermission = useCallback(
    (appEntityType: AppEntityTypes, permission: AppPermission) => {
      // App permissions only meaningful in tenant context
      if (!tenantId || !apps) return false
      // Find the app being checked
      const app = apps.find(({ __entity_type__ }) => __entity_type__ === appEntityType)
      // Test the permission on the app
      if (app) return hasPermission(app, permission)
      // Assume no permission if app missing
      else return false
    },
    [apps, tenantId],
  )

  return [hasAppPermission, isSuccess]
}
