import SvgIcon from '@mui/material/SvgIcon'

const KudosStarIcon = () => (
  <SvgIcon viewBox="0 0 22 22">
    <path
      d="M12.1556 12.5331L9.06396 16.7564L4.64307 22.5751L7.51261 13.9521L9.53165 13.2692L12.1556 12.5331Z"
      fill="#A44F9E"
    />
    <path
      d="M19.669 22.5751L12.1557 17.246L12.0881 15.0061L12.1557 12.5331L15.2915 16.6339L19.669 22.5751Z"
      fill="#F6895B"
    />
    <path
      d="M9.28603 8.62299H0L6.97728 10.933L12.1556 12.5331L10.4428 10.1066L9.28603 8.62299Z"
      fill="#37AFE4"
    />
    <path
      d="M24.3118 8.62299L16.7992 13.9521L14.9078 13.4403L12.1555 12.5331L17.4856 10.7525L24.3118 8.62299Z"
      fill="#FAA21E"
    />
    <path
      d="M12.1557 0L12.0881 6.81208L12.1557 12.5331L13.9451 10.2205L15.026 8.62302L12.1557 0Z"
      fill="#7FA53F"
    />
    <path d="M0 8.62299L7.51256 13.9521L12.1556 12.5331L0 8.62299Z" fill="#0E85C4" />
    <path d="M15.0258 8.62299H24.3118L12.1555 12.5331L15.0258 8.62299Z" fill="#F8C517" />
    <path d="M4.64307 22.5751L12.1556 17.246V12.5331L4.64307 22.5751Z" fill="#822C82" />
    <path d="M12.1555 12.5331L19.6688 22.5751L16.7992 13.9521L12.1555 12.5331Z" fill="#E96D24" />
    <path d="M12.1557 0V12.5331L9.28613 8.62302L12.1557 0Z" fill="#A9CC54" />
  </SvgIcon>
)

export default KudosStarIcon
