import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import StandardPageLayout from '@layouts/standardPageLayout'
import RouteLoading from '@shared/routing/RouteLoading'
import { T } from '@transifex/react'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const MemberManageRoute = lazyWithRetries(() => import('.'))
const MemberRoute = lazyWithRetries(() => import('./member'))

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.admin.members(':tab?'),
    element: (
      <StandardPageLayout
        elementId="memberManageRoute"
        maxWidth={1080}
        title={<T _str="Users & teams" />}>
        <Suspense fallback={<RouteLoading />}>
          <MemberManageRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.member(':id'),
    element: (
      <StandardPageLayout elementId="membersRoute" maxWidth={600}>
        <Suspense fallback={<RouteLoading />}>
          <MemberRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
]

export default routes
