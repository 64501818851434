import { useApps } from '@auth/useApps'
import SplitButton from '@components/button/SplitButton'
import { SplitButtonActionItem } from '@components/button/types'
import { tabletQuery } from '@globals/themes/themeConstants'
import { Recognition, initiateRecognitionAttachmentUpload } from '@kudos/http-client'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import useMediaQuery from '@mui/material/useMediaQuery'
import InfoTooltip from '@shared/components/InfoTooltip'
import { Selectable } from '@shared/filters/selections'
import AttachmentsField from '@shared/forms/AttachmentsField'
import CheckmarkChipSelectorField from '@shared/forms/CheckmarkChipSelectorField'
import FieldLabel from '@shared/forms/FieldLabel'
import FormActionTray from '@shared/forms/FormActionTray'
import GiphyPluginField from '@shared/forms/mentionsTextareaField/GiphyPluginField'
import MentionsTextareaField from '@shared/forms/mentionsTextareaField/MentionsTextareaField'
import { AppPermission } from '@shared/permissions'
import stripHtml from '@shared/utils/stripHtml'
import { MAX_MESSAGE_CHARS } from '@shared/wallPost/constants'
import SendToAutoCompleteSelection from '@tenant/recognition/RecognitionModal/sendRecognitionView/SendToAutoComplete/SendToAutoComplete'
import useRecognitionModal, {
  RecognitionModalViews,
} from '@tenant/recognition/RecognitionModal/useRecognitionModal'
import { T, useT } from '@transifex/react'
import { Control, FieldValues, useFormContext, useWatch } from 'react-hook-form'
import PointsSelectionField from './PointsSelectionField'
import SaveAsDraftButton from './SaveAsDraftButton'
import { RecognitionFormData } from './useSendRecognitionForm'

type RecognitionFormProps = {
  recognition?: Recognition
  qualities?: Selectable[]
  isEditMode: boolean
  onSend: () => void
  onScheduleSend: () => void
  onSaveAsDraft: () => void
}
const SendRecognitionForm: React.FC<RecognitionFormProps> = ({
  qualities,
  isEditMode,
  onSend,
  onScheduleSend,
  onSaveAsDraft,
}) => {
  const [hasAppPermission] = useApps()
  const canScheduleRecognition = hasAppPermission(
    'recognitionApp',
    AppPermission.RecognitionAppScheduleRecognition,
  )
  const isTablet = useMediaQuery(tabletQuery)
  const t = useT()
  const { setView } = useRecognitionModal()
  const { control, formState, setValue } = useFormContext<RecognitionFormData>()

  const actions: SplitButtonActionItem[] = [
    {
      key: 'send',
      name: <T _str="Send" />,
      onClick: () => {
        setValue('scheduledAt', null)
        onSend()
      },
    },
    {
      key: 'scheduledSend',
      name: <T _str="Schedule Send" />,
      disabled: !canScheduleRecognition,
      onClick: () => onScheduleSend(),
    },
  ]

  const sendTo = useWatch({ control, name: 'sendTo' })
  const message = useWatch({ control, name: 'message' })

  const showDraftButton = formState.isDirty && (sendTo.length > 0 || stripHtml(message).length > 0)
  return (
    <Stack mt={2}>
      <SendToAutoCompleteSelection />
      <Box display="unset">
        <MentionsTextareaField
          name="message"
          control={control}
          placeholder={
            <T _str="Make it meaningful! Describe their contributions and the positive impact they’ve had on you or the organization. Type @ to tag someone." />
          }
          maxChars={MAX_MESSAGE_CHARS}
          ariaLabel={t('Write a message')}
          label={<T _str="Message" />}
          isMentionsSupported>
          <GiphyPluginField name="gif" control={control} />
        </MentionsTextareaField>
        <AttachmentsField
          name="attachments"
          control={control as unknown as Control<FieldValues>}
          mutationFn={initiateRecognitionAttachmentUpload}
        />
        {qualities && qualities.length > 0 && (
          <>
            <Stack direction="row" alignItems="center" mt={3} height={25} useFlexGap gap={1}>
              <FieldLabel labelStyle={{ m: 0 }}>
                <T _str="Select qualities" />
              </FieldLabel>
              <Box height={25}>
                <InfoTooltip
                  title={
                    <T _str="Qualities are traits and skills that align with core company values" />
                  }
                />
              </Box>
            </Stack>
            <Box mt={2}>
              <CheckmarkChipSelectorField
                name="qualities"
                options={qualities}
                control={control}
                tooltipTitle={(item: Selectable): string => `${t('Value')}: ${item?.tooltipValue}`}
                hideScrollbar
              />
            </Box>
          </>
        )}
        <Stack useFlexGap gap={1}>
          <FieldLabel>
            <T _str="Add points" />
          </FieldLabel>
          <PointsSelectionField />
        </Stack>
        <FormActionTray>
          {isEditMode && (
            <Button
              id="btnCancelEditRecognition"
              variant="text"
              onClick={() =>
                setView({ view: RecognitionModalViews.DraftAndInProgressRecognitions })
              }
              fullWidth={isTablet}>
              <T _str="Cancel" />
            </Button>
          )}
          {showDraftButton && (
            <SaveAsDraftButton
              buttonId="btnSaveRecognitionAsDraft"
              buttonText={isEditMode ? <T _str="Save" /> : <T _str="Save as draft" />}
              variant="outlined"
              onSaveAsDraftClick={() => onSaveAsDraft()}
              fullWidth={isTablet}
            />
          )}
          <SplitButton
            elementId="splitBtn-sendOrScheduleRecognition"
            variant="contained"
            label={<T _str="Send" />}
            actions={actions}
            disabled={!formState.isValid}
          />
        </FormActionTray>
      </Box>
    </Stack>
  )
}

export default SendRecognitionForm
