import { KUDOS } from '@globals/paths/useDocumentTitle'
import ConstrainedWidthLayout from '@layouts/ConstrainedWidthLayout'
import { Button, Typography } from '@mui/material'
import { CodeOutput } from '@shared/CodeOutput.styled'
import { T } from '@transifex/react'
import { useEffect } from 'react'

type ErrorPageProps = {
  error: Error | null
  resetError: () => void
}

export const ErrorBoundaryUI = ({ error, resetError }: ErrorPageProps) => {
  useEffect(() => {
    document.title = `${KUDOS} - Error!`
  }, [])

  return (
    <ConstrainedWidthLayout>
      <Typography variant="h1">
        <T _str="Error caught" />
      </Typography>
      <CodeOutput>{JSON.stringify(error?.message ?? 'no errors', null, 2)}</CodeOutput>
      <Button variant="contained" onClick={resetError} id="btnResetErrors">
        <T _str="Reset errors" />
      </Button>
    </ConstrainedWidthLayout>
  )
}

export default ErrorBoundaryUI
