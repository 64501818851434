import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper, { PopperPlacementType } from '@mui/material/Popper'
import { SxProps } from '@mui/material/styles'
import { useT } from '@transifex/react'
import { MouseEvent, ReactNode, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SplitButtonMenuItem from './SplitButtonMenuItem'
import { SplitButtonActionItem } from './types'

type SplitButtonProps = {
  elementId: string
  actions: SplitButtonActionItem[]
  label: ReactNode
  labelStartIcon?: ReactNode
  popperPlacement?: PopperPlacementType
  variant?: 'outlined' | 'contained' | 'text'
  disabled?: boolean
}

const labelButtonStyle: SxProps = {
  mt: 0,
  flexGrow: 1,
}

const expandButtonStyle: SxProps = {
  mt: 0,
  width: 50,
}

/**
 * A component that renders a split button group with customizable actions.
 * @param props -
 * - elementId: HTML element id
 * - actions: action options rendered in the Popper dropdown
 * - size(optional): size of the buttons, defaults to 'medium'
 * - labelKey: translatable string used for the primary CTA
 * - labelStartIcon(optional): StartIcon for the primary CTA
 * - popperPlacement(optional): placement for Popper, defaults to 'bottom' (centered)
 *
 * NOTE: Translate 'action.name' in the SplitButtonActionItem[] array
 */
const SplitButton: React.FC<SplitButtonProps> = ({
  elementId,
  actions,
  label,
  labelStartIcon,
  popperPlacement,
  variant = 'outlined',
  disabled = false,
}) => {
  const [open, setOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(
    actions.findIndex(action => action.visible !== false) || 0,
  )
  const anchorRef = useRef<HTMLDivElement>(null)
  const t = useT()
  const navigate = useNavigate()

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (e: Event) => {
    if (anchorRef.current && anchorRef.current.contains(e.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }

  const handleItemClick = (e: MouseEvent, index: number, item: SplitButtonActionItem) => {
    if (!!item.onClick) {
      item.onClick(e)
    }
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleButtonClick = (e: MouseEvent) => {
    const action = actions[selectedIndex]
    if (!action) return
    if (!!action.to) {
      navigate(action.to)
    } else if (!!action.onClick) {
      action.onClick(e)
    }
  }

  return (
    <Box>
      <ButtonGroup
        id={elementId}
        variant={variant}
        ref={anchorRef}
        aria-label={t('Split button')}
        sx={{ display: 'flex' }}>
        {/* Clicking this triggers the selected action */}
        <Button
          id={`${elementId}-Label`}
          onClick={handleButtonClick}
          startIcon={labelStartIcon}
          sx={labelButtonStyle}
          disabled={disabled}>
          {actions[selectedIndex]?.name || label}
        </Button>
        {/* Clicking this opens the dropdown menu */}
        <Button
          id={`${elementId}-Expand`}
          onClick={handleToggle}
          disabled={disabled}
          sx={expandButtonStyle}
          aria-label={t('Expand button')}>
          <ExpandMoreIcon />
        </Button>
      </ButtonGroup>
      {/* Popper with menu list*/}
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement={popperPlacement}
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper sx={{ maxWidth: { xs: '80vw' } }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id={`${elementId}-Menu`} autoFocusItem>
                  {actions
                    .filter(item => item.visible !== false)
                    .map((item, index) => (
                      <SplitButtonMenuItem
                        id={`${elementId}Menu-${item.key}`}
                        key={item.key}
                        onClick={e => handleItemClick(e, index, item)}
                        to={item.to}
                        selected={index === selectedIndex}
                        disabled={item.disabled}>
                        {item.name}
                      </SplitButtonMenuItem>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}

export default SplitButton
