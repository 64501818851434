import AppPaths from '@globals/paths'
import { colors } from '@globals/themes/defaultTheme'
import { spacing } from '@globals/themes/themeConstants'
import { TenantMemberDisplayData } from '@kudos/http-client'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import UserAvatar from '@shared/avatar/UserAvatar'
import { T } from '@transifex/react'
import { format, parseISO } from 'date-fns'
import { ReactNode } from 'react'
import ScanToQRCode from '../ScanToQRCode'

const paperStyle = {
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: spacing(3),
  height: '78vh',
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const cardStyle = {
  backgroundColor: 'rgba(255, 255, 255, 0.95)',
  width: '80%',
  maxHeight: '60%',
  borderRadius: spacing(3),
  p: 3,
}

type TvCelebrationWrapperProps = {
  backgroundUrl: string
  memberDisplayData?: TenantMemberDisplayData
  greeting: ReactNode
  date: string
}

const TvCelebrationWrapper: React.FC<TvCelebrationWrapperProps> = ({
  backgroundUrl,
  memberDisplayData,
  greeting,
  date,
}) => {
  return (
    <Paper sx={{ background: `url(${backgroundUrl})`, ...paperStyle }}>
      <Paper sx={cardStyle}>
        <Stack alignItems="center" mt={-13}>
          <UserAvatar
            size={160}
            media={memberDisplayData?.avatarUrls}
            firstName={memberDisplayData?.firstName}
            lastName={memberDisplayData?.lastName}
          />
          <Typography variant="h1" fontWeight="normal" mt={3}>
            {greeting}
          </Typography>
          <Typography variant="h2" fontWeight={400} my={2} color={colors.grayPrimary}>
            {format(parseISO(date), 'PP')}
          </Typography>
        </Stack>
      </Paper>
      <ScanToQRCode
        text={<T _str="Scan to view celebrations" />}
        path={AppPaths.tenant.celebrate()}
        textSx={{ backgroundColor: colors.white, borderRadius: spacing(2), p: 1 }}
      />
    </Paper>
  )
}

export default TvCelebrationWrapper
