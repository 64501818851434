import { colors } from '@globals/themes/defaultTheme'
import { setSvgIconSize } from '@globals/themes/globalStyles'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Button, { ButtonProps } from '@mui/material/Button'
import { useT } from '@transifex/react'
import { isNumber } from 'lodash/fp'
import { Link as RouterLink, useNavigate } from 'react-router-dom'

type BackArrowProps = {
  to: string | number
}

const BackArrow: React.FC<BackArrowProps> = ({ to }) => {
  const t = useT()
  const navigate = useNavigate()

  const buttonProps: ButtonProps = {
    id: 'btnBackToAllocation',
    'aria-label': t('Back button'),
    variant: 'text',
    sx: { mt: 6, ...setSvgIconSize('2rem') },
    startIcon: <ArrowBackIcon htmlColor={colors.bluePrimary} />,
  }

  if (isNumber(to)) {
    return <Button {...buttonProps} onClick={() => navigate(to)} />
  }

  return <Button component={RouterLink} to={to} {...buttonProps} />
}

export default BackArrow
