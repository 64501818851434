import { useDialog } from '@globals/dialog/useDialogStore'
import theme, { colors } from '@globals/themes/defaultTheme'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/material/styles'
import { FC, ReactNode } from 'react'
import { DialogCloseButton } from './Dialog.styled'

type BottomDrawerProps = {
  drawerKey: string
  children: ReactNode
  onClose?: () => void
  title?: ReactNode
  drawerStyles?: SxProps
  hasActions?: boolean
}

const BottomDrawer: FC<BottomDrawerProps> = ({
  drawerKey,
  children,
  onClose,
  title,
  hasActions = false,
  drawerStyles,
}) => {
  const { closeDialog, isOpen } = useDialog(drawerKey)

  const handleOnClose = () => {
    if (onClose) {
      onClose()
    }
    closeDialog()
  }

  const bottomMargin = (): number => {
    return hasActions ? 10 : 0
  }

  return (
    <Drawer
      anchor="bottom"
      variant="temporary"
      open={isOpen}
      onClose={closeDialog}
      sx={{ ...drawerStyles, zIndex: theme.zIndex.drawer + 1 }}>
      <DialogCloseButton id={`${drawerKey}-close`} onClose={handleOnClose} />
      {!!title && (
        <Box p={2}>
          <Typography variant="h3" mt={{ xs: 0, sm: 0 }} textAlign="center">
            {title}
          </Typography>
        </Box>
      )}
      <Box
        px={2}
        pt={title ? 0 : 6}
        pb={2}
        mb={bottomMargin()}
        sx={{ backgroundColor: colors.grayUltraLight }}>
        {children}
      </Box>
    </Drawer>
  )
}

export default BottomDrawer
