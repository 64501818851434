import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import StandardPageLayout from '@layouts/standardPageLayout'
import RouteLoading from '@shared/routing/RouteLoading'
import { T } from '@transifex/react'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const SecurityRoute = lazyWithRetries(() => import('./index'))
const AddSamlProviderRoute = lazyWithRetries(() => import('./addSamlProvider'))
const SamlProviderInfoRoute = lazyWithRetries(() => import('./samlProviderInfo'))

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.admin.samlProvider(':samlProviderId'),
    element: (
      <StandardPageLayout
        maxWidth={600}
        elementId="samlProviderInfoRoute"
        title={<T _str="Kudos provider information" />}>
        <Suspense fallback={<RouteLoading />}>
          <SamlProviderInfoRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.addSSO(),
    element: (
      <StandardPageLayout
        maxWidth={600}
        elementId="addSamlProviderRoute"
        title={<T _str="Add SSO connection" />}>
        <Suspense fallback={<RouteLoading />}>
          <AddSamlProviderRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.security(),
    element: (
      <StandardPageLayout maxWidth={900} elementId="securityRoute" title={<T _str="Security" />}>
        <Suspense fallback={<RouteLoading />}>
          <SecurityRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
]

export default routes
