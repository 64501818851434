import { Team, TeamDisplayData, TenantMemberDisplayData } from '../identity/types'

export type AppEntityTypes =
  | 'identityApp'
  | 'notificationsApp'
  | 'auditingApp'
  | 'hrIntegrationApp'
  | 'checkInApp'
  | 'metricApp'
  | 'recognitionApp'
  | 'resourceApp'
  | 'tvApp'
  | 'commentApp'
  | 'pointsApp'
  | 'notificationApp'
  | 'rewardsApp'
  | 'celebrationApp'
  | 'awardApp'
  | 'cardApp'
  | 'reportingApp'
  | 'announcementApp'
  | 'timelineApp'
  | 'incentiveApp'

/**
 * API Pagination
 */
export type PageResult<ResultT> = {
  total: number
  results: ResultT[]
  page: number
  next: number | null
  previous: number | null
}

export type CursorResult<ResultT> = {
  results: ResultT[]
  next: string
}

export type Permissions = { __permissions__?: string[] }
export type WithPermissions<T> = T & Permissions

// If you don't need sorting, embeds or search, just pass in `never` as the type param
// If you want more strict typing, pass in sort/embed options instead of string e.g.
// PaginatedSearch<{blah?: string}, 'locations'|'departments'>
export type PaginatedSearch<
  FiltersT,
  EmbedsT extends string = string,
  SearchT extends string = string,
  SortT extends string = string,
> = FiltersT & {
  page: number
  pageSize: number
  search?: SearchT
  sorting?: SortT[]
  embeds?: EmbedsT[]
}

export type EmbedParams<T> = T[]

export type TranslatedContent = {
  default: string
  [key: string]: string
}

export type DateRange = string[]

export type FederatedSearchRecipientsParams = {
  search: string
  limit?: number
}

export type FederatedSearchRecipientsResults = {
  teams: TeamDisplayData[]
  tenantMembers: TenantMemberDisplayData[]
}

export type Percentage = number

export type AudienceTeam = Pick<Team, 'id' | 'name' | 'teamType' | 'segmentType'>

export type Audience =
  | true
  | {
      teams: AudienceTeam[]
    }

export enum DateRangePresetEnum {
  LAST_WEEK = 'lastWeek',
  TODAY = 'today',
  // ISO weeks start on monday, therefore the last iso week is the
  // most recently elapsed week ending on sunday.
  LAST_ISO_WEEK = 'lastIsoWeek',
  NEXT_2_WEEKS = 'next2Weeks',
  NEXT_4_WEEKS = 'next4Weeks',
  LAST_YEAR = 'lastYear',
  CURRENT_YEAR = 'currentYear',
  LAST_3_MONTHS = 'last3Months',
  CURRENT_MONTH = 'currentMonth',
  LAST_MONTH = 'lastMonth',
}
