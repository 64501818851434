import AnonPaths from './anon'
import UserScopedPaths from './user'
import TenantScopedPaths from './tenant'

export default class AppPaths {
  public static logout() {
    return '/logout'
  }

  public static anon = AnonPaths
  public static user = UserScopedPaths
  public static tenant = TenantScopedPaths
}
