import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { PageResult } from './shared/types'

// * this axios instance will be used for authenticated requests
// * with Authorization header defined
export const API: AxiosInstance = axios.create({})

export const initAPI = ({ apiUrl }: { apiUrl: string }) => {
  API.defaults.baseURL = apiUrl
}

// Keep track of interceptor so we can clean it up later
let _interceptor: number | undefined = undefined
export const unauthenticatedListener = (listener: () => void) => {
  if (_interceptor !== undefined) {
    // Clean up previous interceptors
    API.interceptors.response.eject(_interceptor)
  }

  // Register the new listener
  _interceptor = API.interceptors.response.use(undefined, error => {
    if (error.response && error.response.status === 401) listener()
    // Continue with normal error handling
    return Promise.reject(error)
  })
}

export const unwrapData = <T>(response: AxiosResponse<T>): T => response.data

export const paginateData = <T>(data: T): PageResult<T> => ({
  total: 1,
  results: [data],
  page: 1,
  next: null,
  previous: null,
})
