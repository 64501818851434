import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import StandardPageLayout from '@layouts/standardPageLayout'
import RouteLoading from '@shared/routing/RouteLoading'
import { T } from '@transifex/react'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const StaffListRoute = lazyWithRetries(() => import('./index'))
const BatchInviteRoute = lazyWithRetries(() => import('./batchInvite'))
const IntegrationsRoute = lazyWithRetries(() => import('./integrations'))
const ImportCsvRoute = lazyWithRetries(() => import('./importCsv'))
const StaffMemberRoute = lazyWithRetries(() => import('./StaffMemberRoute'))

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.admin.batchInviteStaff(':batchId'),
    element: (
      <StandardPageLayout
        elementId="batchInviteRoute"
        title={<T _str="HR records" />}
        maxWidth={600}>
        <Suspense fallback={<RouteLoading />}>
          <BatchInviteRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.staffIntegrations(),
    element: (
      <StandardPageLayout
        elementId="integrationsRoute"
        title={<T _str="Integrations" />}
        maxWidth={900}>
        <Suspense fallback={<RouteLoading />}>
          <IntegrationsRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.staff(),
    element: (
      <StandardPageLayout
        elementId="staffListRoute"
        title={<T _str="HR records" />}
        maxWidth={1440}>
        <Suspense fallback={<RouteLoading />}>
          <StaffListRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.staffImportCsv(),
    element: (
      <StandardPageLayout
        elementId="importCsvRoute"
        maxWidth={900}
        title={<T _str="Import HR records manually from csv file" />}>
        <Suspense fallback={<RouteLoading />}>
          <ImportCsvRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.staffMember(':staffId'),
    element: (
      <StandardPageLayout elementId="staffMemberRoute" maxWidth={600}>
        <Suspense fallback={<RouteLoading />}>
          <StaffMemberRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
]

export default routes
