import { tabletQuery } from '@globals/themes/themeConstants'
import { Recognition } from '@kudos/http-client'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import useMediaQuery from '@mui/material/useMediaQuery'
import DateTimePickerField from '@shared/forms/DateTimePickerField'
import { T } from '@transifex/react'
import { addDays, setHours, setMinutes } from 'date-fns'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { RecognitionFormData } from '../useSendRecognitionForm'

type ScheduledRecognitionSendProps = {
  onScheduleClicked: () => void
  recognition?: Recognition
}
const ScheduledRecognitionSend: React.FC<ScheduledRecognitionSendProps> = ({
  onScheduleClicked,
  recognition,
}) => {
  const { control, setValue, formState } = useFormContext<RecognitionFormData>()

  const isTablet = useMediaQuery(tabletQuery)

  useEffect(() => {
    const initialScheduledDateTime = setMinutes(
      setHours(addDays(new Date(), 1), 9),
      0,
    ).toISOString()
    setValue('scheduledAt', recognition?.scheduledAt || initialScheduledDateTime, {
      shouldValidate: true,
    })
  }, [])
  return (
    <Stack>
      <DateTimePickerField
        control={control}
        label={<T _str="Send date" />}
        labelStyle={{ mt: 0 }}
        name="scheduledAt"
        disablePast
        timeSteps={{
          hours: 1,
          minutes: 15,
        }}
      />
      <Stack direction="row" justifyContent="end">
        <Button
          fullWidth={isTablet}
          disabled={!formState.isValid}
          id="scheduleRecognitionBtn"
          variant="contained"
          onClick={() => onScheduleClicked()}>
          <T _str="Schedule" />
        </Button>
      </Stack>
    </Stack>
  )
}

export default ScheduledRecognitionSend
