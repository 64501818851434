import { Button, CircularProgress } from '@mui/material'
import { T } from '@transifex/react'
import { ReactNode } from 'react'
import { FieldValues, FormState } from 'react-hook-form'

type SubmitProps<T extends FieldValues> = {
  elementId?: string
  disabled?: boolean
  submitText?: ReactNode
  submittingText?: ReactNode
  formState: FormState<T>
  fullWidth?: boolean
}

const Submit = <T extends FieldValues>({
  elementId,
  formState,
  submittingText,
  submitText,
  fullWidth = false,
  // Use this prop if you need to disable the form for reasons beyond what can
  // be tracked in formState, e.g. a permission check, missing pre-requisite
  disabled = false,
}: SubmitProps<T>) => {
  const { isSubmitting, isLoading: formStateLoading } = formState

  const isDisabled = isSubmitting || formStateLoading || disabled

  const renderSubmitText = () => {
    return submitText ? submitText : <T _str="Save" />
  }

  const renderSubmittingText = () => {
    return submittingText ? submittingText : <T _str="Saving..." />
  }

  return (
    <Button
      id={elementId ?? 'btnSubmit'}
      variant="contained"
      type="submit"
      disabled={isDisabled}
      fullWidth={fullWidth}
      endIcon={
        isSubmitting ? <CircularProgress size={14} sx={{ color: 'text.disabled' }} /> : undefined
      }>
      {isSubmitting ? renderSubmittingText() : renderSubmitText()}
    </Button>
  )
}

export default Submit
