import {
  AwardEmbeds,
  RecognitionEmbeds,
  listTvAnnouncements,
  listTvAwards,
  listTvCalendarEvents,
  listTvRecognitions,
} from '@kudos/http-client'
import { useQuery } from '@tanstack/react-query'
import { calendarEmbeds } from '@tenant/celebrate/calendar/filters/utils'

const STALE_TIME = 60 * 60 * 1000 // 1 hour

const recognitionEmbeds: RecognitionEmbeds[] = ['sender', 'qualities', 'sendTo', 'attachmentUrls']

const awardEmbeds: AwardEmbeds[] = ['badgeUrls', 'sender', 'sendTo']

const useFetchKudosTv = (tvToken: string) => {
  const {
    data: tvCelebrations,
    refetch: refetchCelebrations,
    isFetched: celebrationsFetched,
    isError: celebrationsError,
  } = useQuery({
    queryKey: ['kudosTvWatchCelebrations', tvToken],
    queryFn: () => listTvCalendarEvents(tvToken as string, calendarEmbeds),
    staleTime: STALE_TIME,
    enabled: !!tvToken,
  })

  const {
    data: tvAnnouncements,
    refetch: refetchAnnouncements,
    isFetched: announcementsFetched,
    isError: announcementsError,
  } = useQuery({
    queryKey: ['kudosTvWatchAnnouncements', tvToken],
    queryFn: () => listTvAnnouncements(tvToken as string, ['attachmentUrls']),
    staleTime: STALE_TIME,
    enabled: !!tvToken,
  })

  const {
    data: tvRecognitions,
    refetch: refetchRecognitions,
    isFetched: recognitionsFetched,
    isError: recognitionsError,
  } = useQuery({
    queryKey: ['kudosTvWatchRecognitions', tvToken],
    queryFn: () => listTvRecognitions(tvToken as string, recognitionEmbeds),
    staleTime: STALE_TIME,
    enabled: !!tvToken,
  })

  const {
    data: tvAwards,
    refetch: refetchAwards,
    isFetched: awardsFetched,
    isError: awardsError,
  } = useQuery({
    queryKey: ['kudosTvWatchAwards', tvToken],
    queryFn: () => listTvAwards(tvToken as string, awardEmbeds),
    staleTime: STALE_TIME,
    enabled: !!tvToken,
  })

  const refetchContent = () => {
    refetchCelebrations()
    refetchAnnouncements()
    refetchRecognitions()
    refetchAwards()
  }

  return {
    tvContent: [
      ...(tvAnnouncements ?? []),
      ...(tvCelebrations ?? []),
      ...(tvRecognitions ?? []),
      ...(tvAwards ?? []),
    ],
    refetchContent,
    isFetched: announcementsFetched && recognitionsFetched && awardsFetched && celebrationsFetched,
    isError: announcementsError || recognitionsError || awardsError || celebrationsError,
  }
}

export default useFetchKudosTv
