import { MediaObjectUrl, MediaVariantEnum } from '@kudos/http-client'
import { useEffect, useState } from 'react'

// Variants and dimensions defined in Syndicate codebase here:
// https://github.com/KudosInc/syndicate/blob/main/project/src/apps/media/images.ts

const useMediaUrl = (media?: MediaObjectUrl | null, variant?: MediaVariantEnum) => {
  const [url, setUrl] = useState<string | undefined>(media?.url)

  useEffect(() => {
    if (!!media && !!variant) {
      const mediaObjVariant = media?.variants?.find(x => x.variant === variant)
      if (!!mediaObjVariant) {
        setUrl(mediaObjVariant.url)
        return
      }
    }

    // by default, set the base url if variant is not found
    setUrl(media?.url ?? '')
  }, [variant, media])

  return { url }
}

export default useMediaUrl
