import theme, { colors } from '@globals/themes/defaultTheme'
import { tabletQuery } from '@globals/themes/themeConstants'
import { SxProps } from '@mui/material/styles'
import { FC, SetStateAction, useState } from 'react'
import NotificationsPopover from './DesktopNotificationsPopover'
import InAppNotificationsButton from './InAppNotificationsButton'

const buttonStyle: SxProps = {
  p: 1,
  position: 'fixed',
  width: 36,
  height: 36,
  boxShadow: colors.boxShadow,
  right: 24,
  top: 8,
  [tabletQuery]: {
    right: 16,
  },
}

const badgeStyle: SxProps = {
  '& .MuiBadge-badge': {
    backgroundColor: colors.coralPrimary,
  },
  position: 'fixed',
  right: 36,
  top: 24,
  zIndex: theme.zIndex.appBar + 1,
  [tabletQuery]: {
    right: 24,
  },
}

const InAppNotifications: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleOpenNotifications = (event: {
    currentTarget: SetStateAction<HTMLButtonElement | null>
  }) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseNotifications = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'inAppNotificationsPopover' : undefined

  return (
    <>
      <InAppNotificationsButton
        badgeStyle={badgeStyle}
        buttonStyle={buttonStyle}
        onClick={handleOpenNotifications}
      />
      <NotificationsPopover
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseNotifications}
      />
    </>
  )
}

export default InAppNotifications
