import { AppPermissionChecker } from '@auth/useApps'
import { adminNavConfig, tenantNavHiddenConfig } from './config'
import { NavHiddenItem, NavItem } from './types'

export const calculateVisible = (
  items: NavItem[],
  hasAppPermission: AppPermissionChecker,
  pathname: string,
): NavItem[] => {
  const visible: NavItem[] = []

  for (const i of items) {
    if (i.type === 'link') {
      if (i.visible === undefined || i.visible(hasAppPermission)) {
        visible.push({
          ...i,
          isSelected: i.path === pathname || i.pathMatch?.some(match => pathname.match(match)),
        })
      }
    } else {
      const { children, ...rest } = i
      const visibleChildren = calculateVisible(children, hasAppPermission, pathname)
      if (visibleChildren.length) {
        visible.push({ ...rest, children: visibleChildren })
      }
    }
  }

  return visible
}

export const defaultExpanded = (items: NavItem[], pathname: string) => {
  let expanded: string[] = []

  for (const i of items) {
    if (i.type === 'group') {
      // Check if any child is a link and matches the current pathname
      const hasActiveChild = i.children.some(
        child => child.type === 'link' && child.pathMatch?.some(pattern => pattern.test(pathname)),
      )

      // Expand if defaultExpanded is true OR if any child matches the path
      if (i.defaultExpanded || hasActiveChild) {
        expanded = [...expanded, i.key, ...defaultExpanded(i.children, pathname)]
      }
    }
  }

  return expanded
}

/**
 * This function will recursively check all the NavItems given and
 * return true if the path given matches with one of the paths.
 *
 * This is an expensive operation with the recursion, so make sure
 * you have this memo'd when you use it.
 * @param path
 * @param items
 * @returns
 */
const checkPaths = (path: string, items: NavItem[] | NavHiddenItem[]): boolean => {
  return items.some(item => {
    if (item.type === 'group' && item.children?.length) {
      return checkPaths(path, item.children)
    } else if (item.type === 'link') {
      return path === item.path || item.pathMatch?.some(match => path.match(match))
    } else if (item.type === 'hiddenNav') {
      return path === item.path || item.pathMatch?.some(match => path.match(match))
    }

    return false
  })
}

export const isAdminPath = (path: string): boolean => {
  return checkPaths(path, adminNavConfig)
}

export const isTenantNavHiddenPath = (path: string): boolean => {
  return checkPaths(path, tenantNavHiddenConfig)
}
