import { TenantMemberDisplayData } from '../identity'
import { MediaObjectUrl } from '../media/types'
import { Audience, PaginatedSearch, WithPermissions } from '../shared/types'

export type PointsPeriod = string

export enum AccountTypeEnum {
  REDEEMABLE = 'redeemable',
  ALLOCATION = 'allocation',
  FUND = 'fund',
  PAYOUT = 'payout',
}

export type Account = {
  id: string
  ownerId: string
  tenantId: string
  balance: number
  initialBalance: number
  allTimeReceived?: number
  type: AccountTypeEnum
  lockedAt: string | null
  createdAt: string
  updatedAt: string
  period: PointsPeriod | null
  // embeds
  memberDisplayData: TenantMemberDisplayData
  __entity_type__: 'account'
}
// For more information on points/periods, see the Syndicate repo here:
// https://github.com/KudosInc/syndicate/blob/main/project/src/apps/points/periods.ts
export enum PointsPeriodType {
  NEVER = 'never',
  MONTH = 'month',
  YEAR = 'year',
  QUARTER = 'quarter',
}

export interface BudgetSetting {
  id: string
  tenantId: string
  allocationPeriodType: PointsPeriodType
  redeemablePeriodType: PointsPeriodType | null
  defaultCanReceivePoints: boolean
  nextAllocationAt: string
  createdAt: string
  updatedAt: string
  __entity_type__: 'budgetSetting'
}

export type BudgetSettingParams = Pick<
  BudgetSetting,
  'allocationPeriodType' | 'redeemablePeriodType' | 'defaultCanReceivePoints'
>

export type GetBudgetSettingsParams = {
  embeds?: 'nextAllocationAt'
}

export type BudgetItemAllocationConfig = {
  points: number
}

export type OnboardingConfig = {
  points: number
  days: number
}

export type BudgetItemOnboardingConfig = {
  milestones: OnboardingConfig[]
}

export type AnniversaryConfig = {
  points: number
  year: number
}

export type BudgetItemAnniversaryConfig = {
  points: number
  overrides: AnniversaryConfig[]
}

export enum BudgetItemType {
  ALLOCATION = 'allocation',
  BIRTHDAY = 'birthday',
  ONBOARDING = 'onboarding',
  WELCOME = 'welcome',
  ANNIVERSARY = 'anniversary',
  POINTS_BANK = 'pointsBank',
}

export type BudgetItemConfig =
  | BudgetItemAllocationConfig
  | BudgetItemOnboardingConfig
  | BudgetItemAnniversaryConfig

export type CreateBudgetItemParams = {
  name: string
  config: BudgetItemConfig
  teamIds?: string[]
  itemType: BudgetItemType
  tenantWide?: boolean
  description?: string
}

export type EstimateBudgetItemParams = CreateBudgetItemParams & {
  idIn?: string[]
}

export type BudgetItem = WithPermissions<{
  id: string
  name: string
  description: string
  tenantId: string
  itemType: BudgetItemType
  teamIds: string[]
  tenantWide: boolean
  config: BudgetItemConfig
  createdAt: string
  updatedAt: string
  //embeds
  estimate: BudgetItemEstimate
  balance: PointsBankBalance
  audience: Audience
  __entity_type__: 'budgetItem'
}>

export type BudgetItemsEmbeds = 'estimate' | 'balance' | 'audience'

export type ListBudgetItemsParams = PaginatedSearch<
  {
    idIn?: string[]
    itemTypeIn?: BudgetItemType[]
  },
  BudgetItemsEmbeds
>

export type BudgetItemEstimate = {
  periodType?: PointsPeriodType
  numberAffected: number
  yearlySpend: number
}

export type PointBalanceWarning = 'nearLimit' | 'limitReached'

export type PointsBankBalance = {
  available: number
  spent: number
  limit: number
  warnings: PointBalanceWarning[]
}

export type AccountEmbeds = 'allTimeReceived'

export type ListAccountsParams = {
  embeds?: AccountEmbeds[]
}

export type TransactionEmbeds = 'sender' | 'recipient' | 'expirationDate' | 'periodName'

export type ListTransactionParams = {
  recipientIdIn?: string[]
  senderIdIn?: string[]
  transactionTypeIn?: string[]
  createdInTimeSpan?: string[]
  teamIdIn?: string[]
  ownerIdIn?: string[]
  budgetItemIdIn?: string[]
}

export type ListTransactionRecipientsParams = PaginatedSearch<
  ListTransactionParams,
  TransactionEmbeds
>

export type ListTransactionPointsParams = PaginatedSearch<ListTransactionParams, TransactionEmbeds>

export type MemberData = {
  id: string
  email: string
  firstName: string
  lastName: string
  active: boolean
  avatarUrls?: MediaObjectUrl | null
  jobTitle: string
}

export enum TransactionTypeEnum {
  ALLOCATION = 'allocation',
  EXPIRE_ALLOCATION = 'expire_allocation',
  EXPIRE_REDEEMABLE = 'expire_redeemable',
  GIFT = 'gift',
  RECOGNITION = 'recognition',
  BIRTHDAY = 'birthday',
  ANNIVERSARY = 'anniversary',
  WELCOME = 'welcome',
  ONBOARDING = 'onboarding',
  ENDORSEMENT = 'endorsement',
  AWARD = 'award',
}

export type TransactionRecipient = {
  senderId: string
  recipientId: string
  senderPeriod: PointsPeriod
  recipientPeriod: PointsPeriod
  transactionType: TransactionTypeEnum
  createdAt: string
  amount: number
  recipient: MemberData
  sender?: MemberData
  expirationDate?: string
  periodName?: string
}

export type RedeemablePointsParams = {
  teamIdIn?: string[]
  memberIdIn?: string[]
}

export type TotalRedeemablePoints = {
  total: number
}

export type RedeemableAccountsEmbeds = 'members'

export type RedeemableAccountsParams = PaginatedSearch<
  RedeemablePointsParams,
  RedeemableAccountsEmbeds
>
