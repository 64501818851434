import DraftIcon from '@assets/icons/DraftIcon'
import { colors } from '@globals/themes/defaultTheme'
import { SxProps } from '@mui/material'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { T } from '@transifex/react'

const draftIconStyle: SxProps = {
  backgroundColor: colors.grayExtraLight,
  borderColor: 'transparent',
  '&.MuiChip-root': {
    minHeight: 32,
    px: 2,
    '.MuiChip-label': {
      pl: 0,
    },
  },
}

const DraftChip = () => {
  return (
    <Chip
      label={
        <Typography ml={0.5} variant="body1">
          <T _str="Draft" />
        </Typography>
      }
      sx={draftIconStyle}
      icon={<DraftIcon />}
    />
  )
}

export default DraftChip
