import { SxProps, styled } from '@mui/material/styles'

export const PageTitleImage = styled('img')({
  marginTop: 8,
  width: 128,
  height: 32,
})

export const ButtonStyles: SxProps = {
  width: 342,
  alignSelf: 'center',
}

export const StackStyles: SxProps = {
  alignItems: 'center',
  mt: { sm: 11, xs: 0 },
  maxHeight: '100dvh',
}
