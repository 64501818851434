import { colors } from '@globals/themes/defaultTheme'
import { RecognitionRecipients } from '@kudos/http-client'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { T } from '@transifex/react'

type RecipientHeaderProps = {
  recipients: RecognitionRecipients[]
}
const RecognitionRecipientsTitle: React.FC<RecipientHeaderProps> = ({ recipients }) => {
  return (
    <Typography
      component={Stack}
      direction="row"
      variant="body2"
      useFlexGap
      gap={0.5}
      noWrap
      maxWidth={400}
      color={colors.blueDark}>
      <T
        _str="To: {recipients}"
        recipients={
          <Typography
            color={colors.blueDark}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}>
            {recipients.length > 0 ? recipients.map(recipient => recipient.name).join(', ') : '-'}
          </Typography>
        }
      />
    </Typography>
  )
}

export default RecognitionRecipientsTitle
