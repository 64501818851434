import { useAuth } from '@auth/useAuth'
import { unauthenticatedListener } from '@kudos/http-client'
import RouteLoading from '@shared/routing/RouteLoading'
import { useEffect, useRef } from 'react'
import { Outlet } from 'react-router-dom'
import RootLayout from '../layouts/RootLayout'

export const AwaitAuth = () => {
  const { isAuthenticated, isLoading, initializeFromStorage, clearAuth } = useAuth()
  const hasInitialized = useRef<boolean>(false)

  useEffect(() => {
    // We should only initialize the auth exactly once
    if (!hasInitialized.current) {
      initializeFromStorage()
      // Register listener to detect 401s and clear the auth
      unauthenticatedListener(clearAuth)
    }
    hasInitialized.current = true
  }, [])

  if (isLoading) {
    return <RouteLoading />
  }

  return (
    <RootLayout isAuthenticated={isAuthenticated}>
      <Outlet />
    </RootLayout>
  )
}
