import { PointsExceptionType } from '@kudos/http-client'
import Typography from '@mui/material/Typography'
import StandardCard from '@shared/components/StandardCard'
import { T } from '@transifex/react'
import PointsExceptionChip from './PointsExceptionChip'

type PointsExceptionProps = {
  type: PointsExceptionType
  affected: number
}

const PointsException: React.FC<PointsExceptionProps> = ({ type, affected }) => {
  const AffectedUsers = (
    <b>
      <T _str="{userCount} {userCount, plural, one {user} other {users}}" userCount={affected} />
    </b>
  )

  const exceptionTitle: { [key in PointsExceptionProps['type']]: React.ReactNode } = {
    restriction: <T _str="Too much love" />,
    insufficientPoints: <T _str="Insufficient points" />,
    notEligible: <T _str="Not eligible to receive points" />,
  }

  const exceptionMessage: { [key in PointsExceptionProps['type']]: React.ReactNode } = {
    restriction: (
      <T
        _str="{affectedUsers} have reached the maximum amount of points you’re allowed to send them within a 30-day period. Points have been adjusted to keep them within the limit."
        affectedUsers={AffectedUsers}
      />
    ),
    insufficientPoints: (
      <T
        _str="Points for {affectedUsers} have been adjusted to stay within your point balance."
        affectedUsers={AffectedUsers}
      />
    ),
    notEligible: (
      <T
        _str="{affectedUsers} won't receive points because of your organization’s eligibility rules."
        affectedUsers={AffectedUsers}
      />
    ),
  }

  return (
    <StandardCard
      title={exceptionTitle[type] || <T _str="Unknown exception" />}
      chip={<PointsExceptionChip exceptionType={type} />}>
      <Typography variant="body1">
        {exceptionMessage[type] || <T _str="Unknown exception message" />}
      </Typography>
    </StandardCard>
  )
}

export default PointsException
