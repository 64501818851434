import EllipseBackground from '@assets/images/EllipseBackground.svg'
import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import { tabletQuery } from '@globals/themes/themeConstants'
import ConstrainedWidthLayout from '@layouts/ConstrainedWidthLayout'
import RootLayoutWrapper from '@layouts/RootLayoutWrapper'
import { SxProps } from '@mui/material/styles'
import RouteLoading from '@shared/routing/RouteLoading'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const arcBgStyle: SxProps = {
  backgroundOrigin: 'border-box',
  backgroundImage: `url("${EllipseBackground}")`,
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'center',
  backgroundPositionY: '300px',
  [tabletQuery]: {
    backgroundPositionY: '360px',
  },
}

const CheckInQuestionRoute = lazyWithRetries(() => import('./question'))

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.nextCheckInQuestion(':categoryId'),
    element: (
      <RootLayoutWrapper styles={arcBgStyle}>
        <ConstrainedWidthLayout>
          <Suspense fallback={<RouteLoading />}>
            <CheckInQuestionRoute />
          </Suspense>
        </ConstrainedWidthLayout>
      </RootLayoutWrapper>
    ),
  },
]

export default routes
