import { colors } from '@globals/themes/defaultTheme'
import { onFocus, onHover, spacing, tabletQuery } from '@globals/themes/themeConstants'
import { $generateHtmlFromNodes } from '@lexical/html'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { SxProps, styled } from '@mui/material/styles'
import { EditorState, LexicalEditor } from 'lexical'
import React, { FC, PropsWithChildren, useState } from 'react'
import KudosToolbar from './KudosToolbar'
import { MentionNode } from './MentionNode'
import MentionsPlugin from './MentionsPlugin'
import { SetValuePlugin } from './SetValuePlugin'
import SubmitOnEnterPlugin from './SubmitOnEnterPlugin'

type ContentEditableProps = {
  error: boolean
}

const MuiContentEditable = styled(ContentEditable, {
  shouldForwardProp: prop => prop !== 'error',
})<ContentEditableProps>(({ error, theme }) => ({
  ...theme.components?.MuiInputBase?.styleOverrides,
  backgroundColor: colors.white,
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: spacing(1),
  borderColor: error ? colors.redPrimary : colors.blueLight,
  minHeight: '100%',
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  padding: spacing(0, 2, 4),
  [onHover]: {
    borderColor: error ? colors.redPrimary : colors.bluePrimary,
  },
  [onFocus]: {
    borderColor: error ? colors.redPrimary : colors.bluePrimary,
    borderWidth: 1,
    outlineWidth: 0,
  },
  '& .mention': {
    color: colors.bluePrimary,
    fontWeight: 600,
    cursor: 'pointer',
  },
}))

const Placeholder = styled(Box)({
  padding: spacing(2),
  position: 'absolute',
  top: 0,
  left: 0,
  userSelect: 'none',
  pointerEvents: 'none',
  color: colors.grayPrimary,
  opacity: 1,
  display: 'inline-block',
})

const wrapperStyle: SxProps = {
  position: 'relative',
  background: 'transparent',
  minHeight: 160,
  display: 'flex',
  flex: '1 1 auto',
}

const commentWrapperStyle: SxProps = {
  direction: 'row',
  justifyContent: 'center',
  width: '100%',
  minHeight: 56,
}

const commentContentEditableStyle: SxProps = {
  scrollbarWidth: 'none',
  borderRadius: spacing(4),
  '& p': {
    margin: '15px 0px',
  },
  padding: spacing(0, 10, 0, 2),
  [tabletQuery]: {
    padding: spacing(0, 6, 0, 2),
  },
}

type MentionsTextareaModel = string | object | null | undefined

type MentionsTextareaProps = PropsWithChildren & {
  elementId: string
  value: string
  ariaLabel: string
  placeholder?: React.ReactNode
  error?: boolean
  onChange: (value: MentionsTextareaModel) => void
  isComment?: boolean
  isMentionsSupported?: boolean
  onFormSubmit?: () => void
}

const MentionsTextarea: FC<MentionsTextareaProps> = ({
  elementId,
  value = '',
  ariaLabel,
  placeholder,
  error = false,
  onChange,
  isComment,
  children,
  isMentionsSupported = false,
  onFormSubmit,
}) => {
  const [areMentionsOpen, setAreMentionsOpen] = useState(false)
  const handleChange = (editorState: EditorState, editor: LexicalEditor) => {
    editorState.read(() => {
      const raw = $generateHtmlFromNodes(editor, null)
      if (!!onChange) {
        onChange(raw)
      }
    })
  }

  // Catch any errors that occur during Lexical updates and log them
  // or throw them as needed. If you don't throw them, Lexical will
  // try to recover gracefully without losing user data.
  const onError = (_error: unknown) => {
    return
  }

  const initialConfig = {
    namespace: 'KudosEditor',
    nodes: [MentionNode],
    defaultValue: value,
    onError,
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <Stack id={elementId} sx={{ ...wrapperStyle, ...(isComment ? commentWrapperStyle : null) }}>
        <RichTextPlugin
          contentEditable={
            <MuiContentEditable
              ariaLabel={ariaLabel}
              error={error}
              sx={{ ...(isComment ? commentContentEditableStyle : null) }}
            />
          }
          placeholder={<Placeholder>{placeholder ?? ''}</Placeholder>}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <KudosToolbar>{children}</KudosToolbar>
        {isMentionsSupported && <MentionsPlugin setAreMentionsOpen={setAreMentionsOpen} />}
        <HistoryPlugin />
        <SetValuePlugin value={value} />
        <OnChangePlugin onChange={handleChange} />
        {areMentionsOpen || <SubmitOnEnterPlugin onEnter={onFormSubmit} />}
      </Stack>
    </LexicalComposer>
  )
}

export default MentionsTextarea
