import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getRoot, CLEAR_HISTORY_COMMAND } from 'lexical'
import { useEffect } from 'react'

export const SetValuePlugin: React.FC<{ value: string | undefined }> = ({ value = '' }) => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!!editor) {
      editor.update(
        () => {
          // pull content of editor
          const content = $generateHtmlFromNodes(editor, null)
          if (content !== value) {
            // if there are any differences, clear the control
            const root = $getRoot()
            root.clear()

            // check for content. if null, we can bail, as we have already
            // cleared the box. Otherwise, parse the markup and set it into the component.
            const parser = new DOMParser()
            const dom = parser.parseFromString(value ?? '', 'text/html')
            const nodes = $generateNodesFromDOM(editor, dom)

            const selection = root.select()
            selection.removeText()

            // IMPORTANT: this is the line that triggers auto-scroll into view during update.
            // Ading the 'skip-scroll-into-view' tag fixes this, but I'm leaving this here
            // for extra context, since it is not well documented, and we're likely to
            // run into this again: https://github.com/facebook/lexical/pull/3220
            selection.insertNodes(nodes)

            editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined)
          }
        },
        { tag: 'skip-scroll-into-view' },
      )
    }
  }, [value])

  return null
}
