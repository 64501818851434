import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import StandardPageLayout from '@layouts/standardPageLayout'
import RouteLoading from '@shared/routing/RouteLoading'
import { T } from '@transifex/react'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const IncentivesRoute = lazyWithRetries(() => import('.'))
const ConfigureIncentiveRoute = lazyWithRetries(() => import('./configureIncentive'))
const DuplicateIncentiveRoute = lazyWithRetries(() => import('./duplicateIncentive'))

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.admin.incentives(),
    element: (
      <StandardPageLayout
        maxWidth={1440}
        title={<T _str="Incentives" />}
        elementId="incentivesRoute">
        <Suspense fallback={<RouteLoading />}>
          <IncentivesRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.configureIncentive(':id?'),
    element: (
      <StandardPageLayout maxWidth={600} elementId="configureIncentiveRoute">
        <Suspense fallback={<RouteLoading />}>
          <ConfigureIncentiveRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.duplicateIncentive(':id'),
    element: (
      <StandardPageLayout maxWidth={600} elementId="duplicateIncentiveRoute">
        <Suspense fallback={<RouteLoading />}>
          <DuplicateIncentiveRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
]

export default routes
