import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import StandardPageLayout from '@layouts/standardPageLayout'
import RouteLoading from '@shared/routing/RouteLoading'
import { T } from '@transifex/react'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const KudosTvRoute = lazyWithRetries(() => import('./index'))

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.admin.kudosTvs(),
    element: (
      <StandardPageLayout elementId="kudosTvRoute" maxWidth={1080} title={<T _str="Kudos TV" />}>
        <Suspense fallback={<RouteLoading />}>
          <KudosTvRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
]

export default routes
