import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/material/styles'
import React from 'react'

const htmlContentStyle: SxProps = {
  '& > p': {
    marginBlockStart: 0,
  },
  '& > p:last-child': {
    marginBlockEnd: 0,
  },
}

type HtmlContentWithMentionsProps = {
  htmlContent: string
  sx?: SxProps
}

const HtmlContent: React.FC<HtmlContentWithMentionsProps> = ({ htmlContent, sx }) => {
  return (
    <Typography
      component="span"
      sx={{ ...htmlContentStyle, ...sx }}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  )
}

export default HtmlContent
