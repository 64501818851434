import { RouteObject } from 'react-router-dom'
import announcementsRoutes from './announcements/routes'
import awardsRoutes from './awards/routes'
import cardsRoutes from './cards/routes'
import celebrationsRoutes from './celebrations/routes'
import checkInsRoutes from './checkIns/routes'
import incentivesRoutes from './incentives/routes'

const routes: RouteObject[] = [
  ...announcementsRoutes,
  ...awardsRoutes,
  ...cardsRoutes,
  ...celebrationsRoutes,
  ...checkInsRoutes,
  ...incentivesRoutes,
]

export default routes
