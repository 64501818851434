import { createStore } from '@globals/createStore'
import { AlertColor } from '@mui/material'
import { useT } from '@transifex/react'
import { useMemo } from 'react'

type Toast = {
  message: string
  severity?: AlertColor
  autoHideDuration?: number
}

export type ToasterStoreState = {
  toasts: Toast[]
  showToast: (t: Toast) => void
  hideToast: () => void
}

/**
 * State management for toast messages. Currently new toasts replace old toasts immediately, but this
 * can be easily changed in the future by not replacing existing toast array on each call to showToast.
 */
export const useToasterStore = createStore<ToasterStoreState>(set => ({
  showToast: ({ message, severity = 'info', autoHideDuration = 6000 }: Toast) =>
    set(
      () => ({
        toasts: [
          {
            message,
            severity,
            autoHideDuration,
          },
        ],
      }),
      false,
      'ShowToast',
    ),
  hideToast: () => {
    // Remove oldest toast from array
    set(({ toasts }) => ({ toasts: toasts.slice(1) }), false, 'HideToast')
  },
  toasts: [],
}))

/**
 * Minimal hook to expose only the show function so we can hide internal state structure
 */
export const useShowToast = () => {
  return useToasterStore(state => state.showToast)
}

/**
 * Convenience hook for simplifying success/fail notifications for api actions. Supplies approved
 * default copy for generic success/fail actions.
 */
export const useApiActionToast = () => {
  const t = useT()
  const showToast = useToasterStore(state => state.showToast)

  return useMemo(() => {
    return [
      (message?: string) =>
        showToast({ message: message || t('Update successful'), severity: 'success' }),
      (message?: string) =>
        showToast({ message: message || t('Oops. Something went wrong!'), severity: 'error' }),
    ]
  }, [showToast, t])
}
