type DynamicPaths = {
  pattern: RegExp
  title: string
}

export const pathTitlesDynamic: DynamicPaths[] = [
  // AdminPaths
  { pattern: /^\/user\/([^/]+)$/, title: 'User details' },
  { pattern: /^\/staffing\/batch-invite\/([^/]+)$/, title: 'Batch invite users' },
  { pattern: /^\/staffing\/([^/]+)$/, title: 'Staff details' },
  { pattern: /^\/tenant\/security\/sso\/([^/]+)$/, title: 'SSO provider details' },
  { pattern: /^\/check-ins\/category\/([^/]+)\/audience$/, title: 'Edit audience' },
  { pattern: /^\/team\/([^/]+)$/, title: 'Team details' },
  { pattern: /^\/team\/([^/]+)\/automation$/, title: 'Configure team automation' },
  { pattern: /^\/team\/batch-add\/([^/]+)$/, title: 'Batch add team members' },
  { pattern: /^\/team\/([^/]+)\/edit$/, title: 'Edit team' },
  { pattern: /^\/analytics\/insight\/([^/]+)$/, title: 'Insight details' },
  { pattern: /^\/tv\/watch\/([^/]+)$/, title: 'Watch Kudos TV' },
  { pattern: /^\/values\/([^/]+)\/edit$/, title: 'Edit value and qualities' },
  { pattern: /^\/points\/allocation\/configure\/([^/]+)$/, title: 'Edit points allocation' },
  { pattern: /^\/points\/birthdays\/configure\/([^/]+)$/, title: 'Edit birthday gifts' },
  { pattern: /^\/points\/onboarding\/configure\/([^/]+)$/, title: 'Edit onboarding gifts' },
  { pattern: /^\/points\/reporting\/([^/]+)$/, title: 'Individual points reporting' },
  { pattern: /^\/rewards\/([^/]+)\/edit$/, title: 'Edit reward provider' },
  { pattern: /^\/rewards\/configure\/([^/]+)$/, title: 'Store settings' },
  { pattern: /^\/rewards\/store\/([^/]+)$/, title: 'My reward store' },
  { pattern: /^\/rewards\/store\/([^/]+)\/add-funds$/, title: 'Add funds' },
  { pattern: /^\/points\/welcome\/configure\/([^/]+)$/, title: 'Edit welcome gifts' },
  { pattern: /^\/points\/anniversary\/configure\/([^/]+)$/, title: 'Edit anniversary gifts' },
  { pattern: /^\/points\/bank\/configure\/([^/]+)$/, title: 'Edit points bank' },
  { pattern: /^\/awards\/configure\/([^/]+)$/, title: 'Edit award' },
  { pattern: /^\/celebrate(?:\/)?(?:\?.*)?$/, title: 'Celebrations calendar' },
  { pattern: /^\/celebrate\/cards\/(all|invited|own|received)(?:\?.*)?$/, title: 'Cards' },
  { pattern: /^\/celebrate\/card\/([^/]+)$/, title: 'Edit card' },
  { pattern: /^\/reports\/([^/]+)$/, title: 'Reports' },
  { pattern: /^\/reports\/([^/]+)\/share$/, title: 'Share report' },
  { pattern: /^\/reports\/([^/]+)\/share\/access$/, title: 'Share report' },
  { pattern: /^\/reports\/([^/]+)\/share\/email$/, title: 'Share email report' },
  { pattern: /^\/announcements\/configure\/([^/]+)$/, title: 'Edit announcement' },
  { pattern: /^\/announcements\/([^/]+)$/, title: 'Announcement' },
  { pattern: /^\/incentives\/([^/]+)$/, title: 'Incentive' },
  { pattern: /^\/incentives\/configure\/([^/]+)$/, title: 'Edit incentive' },
  { pattern: /^\/incentives\/duplicate\/([^/]+)$/, title: 'Create incentive' },

  // TenantScopedPaths
  { pattern: /^\/check-ins\/category\/([^/]+)\/question$/, title: 'Check-in question' },
  { pattern: /^\/recognitions\/([^/]+)$/, title: 'Recognition' },
  { pattern: /^\/profile\/([^/]+)$/, title: 'Member profile' },
  { pattern: /^\/resource-hub\/resources\/([^/]+)$/, title: 'Resource hub' },
  { pattern: /^\/resource-hub\/resources\/([^/]+)\/complete$/, title: 'Resource complete' },
  { pattern: /^\/award\/([^/]+)$/, title: 'Award' },
  { pattern: /^\/card\/([^/]+)(\/signatures)?$/, title: 'Card' },
  { pattern: /^\/rewards\/providers\/([^/]+)$/, title: 'Rewards catalogue' },
  { pattern: /^\/rewards\/providers\/([^/]+)\/rewards\/([^/]+)$/, title: 'Redeem reward' },
  { pattern: /^\/rewards\/tango\/([^/]+)$/, title: 'Reward' },
]
