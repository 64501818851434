import { styled } from '@mui/material/styles'

/**
 * Ref. VisuallyHiddenInput in FileField.tsx. This replaces the html
 * native <input type="file">
 * This can be triggered by specifying a ref that can be used to programatically
 * trigger a click event,
 * OR
 * This can be triggered by an adjacent Label(For), or a button nested within
 * that label.
 */
const HiddenFileInput = styled('input')(({ theme }) => ({
  ...theme.components?.MuiButton?.styleOverrides,
  display: 'none',
}))

export default HiddenFileInput
