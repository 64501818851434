import { Provider } from '@rollbar/react'
import { FC, ReactNode } from 'react'
import Rollbar, { Configuration } from 'rollbar'
import { envConfig } from './globals/env'

type RollbarProviderProps = {
  children: ReactNode
}

const ENV: string = envConfig.DEPLOY_ENVIRONMENT
const ROLLBAR_ACCESS_TOKEN: string = envConfig.ROLLBAR_SDK_ID

const RollBarProvider: FC<RollbarProviderProps> = ({ children }) => {
  const rollbarConfig: Configuration = {
    accessToken: ROLLBAR_ACCESS_TOKEN,
    environment: ENV,
  }

  const rollbar = new Rollbar(rollbarConfig)
  return <Provider instance={rollbar}>{children}</Provider>
}

export default RollBarProvider
