export type DeployEnvironment =
  | 'local'
  | 'staging'
  | 'athena'
  | 'galactica'
  | 'sandbox'
  | 'production'

export type KudosEnv = {
  API_URL: `https://${string}`
  DEBUG_MODE: boolean
  ROLLBAR_SDK_ID: string
  DEPLOY_ENVIRONMENT: DeployEnvironment
  WEB_PUSH_VAPID_PUBLIC_KEY: string
  TRANSIFEX_TOKEN: string | null
  GIPHY_API_KEY: string
  DEFAULT_DOMAIN: string
  MUI_PRO_KEY: string
  FROALA_ENTERPRISE_KEY: string
}

const localEnv: KudosEnv = {
  API_URL: 'https://api.kudosapp.dev',
  DEBUG_MODE: true,
  ROLLBAR_SDK_ID: 'dummy',
  DEPLOY_ENVIRONMENT: 'local',
  WEB_PUSH_VAPID_PUBLIC_KEY:
    'BPmjX3blGUzOGv-ECzgbf4fmsal8mPT4uzWwYg-xy_vLR0zEWWB5a4mCfM4bTK4YQ0vLhDoQqS64YP92UIox1Ow',
  TRANSIFEX_TOKEN: '1/c007c8aa8b1ec099eed4b2fa675309096c71e3fe',
  GIPHY_API_KEY: '9jvcqKi1AIrPAgNIC1AlvXEV932r4AQe',
  DEFAULT_DOMAIN: 'https://my.kudosapp.dev',
  MUI_PRO_KEY:
    'bb1aa625642ddea6b455de1657cdf031Tz05NDEzNyxFPTE3NTIzNDA5MjYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  FROALA_ENTERPRISE_KEY: 'aLF3c1A6B5D5E2F2B1F2xROKLJKYHROLDXDRH1e1YYGRe1Bg1G3I3A2A4D6B3D3E4E2A2==',
}

const sandboxEnv: KudosEnv = {
  API_URL: 'https://syndicate.kudosnow-development-sandbox.com',
  DEBUG_MODE: true,
  ROLLBAR_SDK_ID: 'f7104f833fb649b39c741e4e8ba60a63',
  DEPLOY_ENVIRONMENT: 'sandbox',
  WEB_PUSH_VAPID_PUBLIC_KEY:
    'BGPUKy4wC4w4jB2OryhQvQlMT9pJDnlrO3JCOHwAJ7wNgudTUa1JM2EnmRlSFkBH9F6l8tErLfKI539Zr47xSPE',
  TRANSIFEX_TOKEN: '1/c007c8aa8b1ec099eed4b2fa675309096c71e3fe',
  GIPHY_API_KEY: '9jvcqKi1AIrPAgNIC1AlvXEV932r4AQe',
  DEFAULT_DOMAIN: 'https://my.kudosnow-development-sandbox.com',
  MUI_PRO_KEY:
    'bb1aa625642ddea6b455de1657cdf031Tz05NDEzNyxFPTE3NTIzNDA5MjYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  FROALA_ENTERPRISE_KEY: 'aLF3c1A6B5D5E2F2B1F2xROKLJKYHROLDXDRH1e1YYGRe1Bg1G3I3A2A4D6B3D3E4E2A2==',
}

const athenaEnv: KudosEnv = {
  API_URL: 'https://syndicate.kudosnow-development-sandbox-athena.com',
  DEBUG_MODE: true,
  ROLLBAR_SDK_ID: 'f7104f833fb649b39c741e4e8ba60a63',
  DEPLOY_ENVIRONMENT: 'athena',
  WEB_PUSH_VAPID_PUBLIC_KEY:
    'BGPUKy4wC4w4jB2OryhQvQlMT9pJDnlrO3JCOHwAJ7wNgudTUa1JM2EnmRlSFkBH9F6l8tErLfKI539Zr47xSPE',
  TRANSIFEX_TOKEN: '1/c007c8aa8b1ec099eed4b2fa675309096c71e3fe',
  GIPHY_API_KEY: '9jvcqKi1AIrPAgNIC1AlvXEV932r4AQe',
  DEFAULT_DOMAIN: 'https://my.kudosnow-development-sandbox-athena.com',
  MUI_PRO_KEY:
    'bb1aa625642ddea6b455de1657cdf031Tz05NDEzNyxFPTE3NTIzNDA5MjYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  FROALA_ENTERPRISE_KEY: 'aLF3c1A6B5D5E2F2B1F2xROKLJKYHROLDXDRH1e1YYGRe1Bg1G3I3A2A4D6B3D3E4E2A2==',
}

export const galacticaEnv: KudosEnv = {
  API_URL: 'https://syndicate.kudosnow-development-sandbox-galactica.com',
  DEBUG_MODE: true,
  ROLLBAR_SDK_ID: 'f7104f833fb649b39c741e4e8ba60a63',
  DEPLOY_ENVIRONMENT: 'galactica',
  WEB_PUSH_VAPID_PUBLIC_KEY:
    'BGPUKy4wC4w4jB2OryhQvQlMT9pJDnlrO3JCOHwAJ7wNgudTUa1JM2EnmRlSFkBH9F6l8tErLfKI539Zr47xSPE',
  TRANSIFEX_TOKEN: '1/c007c8aa8b1ec099eed4b2fa675309096c71e3fe',
  GIPHY_API_KEY: '9jvcqKi1AIrPAgNIC1AlvXEV932r4AQe',
  DEFAULT_DOMAIN: 'https://my.kudosnow-development-sandbox-galactica.com',
  MUI_PRO_KEY:
    'bb1aa625642ddea6b455de1657cdf031Tz05NDEzNyxFPTE3NTIzNDA5MjYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  FROALA_ENTERPRISE_KEY: 'aLF3c1A6B5D5E2F2B1F2xROKLJKYHROLDXDRH1e1YYGRe1Bg1G3I3A2A4D6B3D3E4E2A2==',
}

const stagingEnv: KudosEnv = {
  API_URL: 'https://k-api.kudosnow-staging-k2.com',
  DEBUG_MODE: true,
  ROLLBAR_SDK_ID: 'f7104f833fb649b39c741e4e8ba60a63',
  DEPLOY_ENVIRONMENT: 'staging',
  WEB_PUSH_VAPID_PUBLIC_KEY:
    'BGE88FHDlO69sZd74oznmlkMVbzjj3m7SDA5dOtJHtvItNf0yPRLZbtMELCWj1Mf9cSln4cSpDud01NwDrbMi1s',
  TRANSIFEX_TOKEN: '1/c007c8aa8b1ec099eed4b2fa675309096c71e3fe',
  GIPHY_API_KEY: '3bQe5vdPJ6910XcLN2uj18MmQ1DH1ttr',
  DEFAULT_DOMAIN: 'https://my.kudosnow-staging-k2.com',
  MUI_PRO_KEY:
    'bb1aa625642ddea6b455de1657cdf031Tz05NDEzNyxFPTE3NTIzNDA5MjYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  FROALA_ENTERPRISE_KEY: 'aLF3c1A6B5D5E2F2B1F2xROKLJKYHROLDXDRH1e1YYGRe1Bg1G3I3A2A4D6B3D3E4E2A2==',
}

const prodEnv: KudosEnv = {
  API_URL: 'https://k-api.kudos.com',
  DEBUG_MODE: false,
  ROLLBAR_SDK_ID: 'f7104f833fb649b39c741e4e8ba60a63',
  DEPLOY_ENVIRONMENT: 'production',
  WEB_PUSH_VAPID_PUBLIC_KEY:
    'BNjyCqg37j_e94gBEqswFxwMjJ7M4saVRC6d3fa8BHLdFF_VqStK0yopPZtmPYMDmF8-qqu9uI62ipU4k8r3ZzI',
  TRANSIFEX_TOKEN: '1/c007c8aa8b1ec099eed4b2fa675309096c71e3fe',
  GIPHY_API_KEY: 'EspnLsorzUFYzXcKueH1qgKy7qBvlslF',
  DEFAULT_DOMAIN: 'https://my.kudos.com',
  MUI_PRO_KEY:
    'bb1aa625642ddea6b455de1657cdf031Tz05NDEzNyxFPTE3NTIzNDA5MjYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  FROALA_ENTERPRISE_KEY: 'aLF3c1A6B5D5E2F2B1F2xROKLJKYHROLDXDRH1e1YYGRe1Bg1G3I3A2A4D6B3D3E4E2A2==',
}

const isLocal = (hostname: string) => /.*\.kudosapp\.dev$/.test(hostname)
const isSandbox = (hostname: string) => /.*\.kudosnow-development-sandbox\.com$/.test(hostname)
const isAthena = (hostname: string) =>
  /.*\.kudosnow-development-sandbox-athena\.com$/.test(hostname)
const isGalactica = (hostname: string) =>
  /.*\.kudosnow-development-sandbox-galactica\.com$/.test(hostname)
const isStaging = (hostname: string) => /.*\.kudosnow-staging-k2\.com$/.test(hostname)
const isProd = (hostname: string) => /.*\.kudos.com$/.test(hostname)

export const envConfig: KudosEnv = ((hostname: string): KudosEnv => {
  // ! please note, working within localhost is discouraged
  // ! ensure you have setup local development environment
  // ! to work with the proxy for {my.kudosapp.dev}

  if (isLocal(hostname)) {
    return localEnv
  } else if (isSandbox(hostname)) {
    return sandboxEnv
  } else if (isAthena(hostname)) {
    return athenaEnv
  } else if (isGalactica(hostname)) {
    return galacticaEnv
  } else if (isStaging(hostname)) {
    return stagingEnv
  } else if (isProd(hostname)) {
    return prodEnv
  } else {
    // Default to local
    return localEnv
  }
})(window.location.hostname)
