import { SxProps } from '@mui/material/styles'
import { ReactNode, RefObject, createContext, useContext, useRef, useState } from 'react'

type RouteUpdateContextProps = {
  pageTitle: string
  setPageTitle: (title: string) => void
  routeStyles: SxProps | undefined
  setRouteStyles: (styles: SxProps | undefined) => void
  parentRef: RefObject<HTMLDivElement>
}

const RouteUpdateContext = createContext<RouteUpdateContextProps | undefined>(undefined)

export const useRouteUpdate = () => {
  const context = useContext(RouteUpdateContext)
  if (context === undefined) {
    throw new Error('useRouteUpdate must be used within RouteUpdateProvider')
  }
  return context
}

export const RouteUpdateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const parentRef = useRef<HTMLDivElement>(null)
  const [pageTitle, setPageTitle] = useState('')
  const [routeStyles, setRouteStyles] = useState<SxProps | undefined>(undefined)
  const contextValue = {
    pageTitle,
    setPageTitle,
    routeStyles,
    setRouteStyles,
    parentRef,
  }

  return <RouteUpdateContext.Provider value={contextValue}>{children}</RouteUpdateContext.Provider>
}
