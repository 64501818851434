import { spacing, tabletQuery } from '@globals/themes/themeConstants'
import Stack from '@mui/material/Stack'
import { SxProps } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Children, FC, PropsWithChildren, createRef, useEffect, useState } from 'react'

const tileContainerBoxStyle: SxProps = {
  display: 'flex',
  overflowX: 'auto',
  pb: '2px',
  [tabletQuery]: {
    flexWrap: 'nowrap',
    flexDirection: 'row',
    WebkitOverflowScrolling: 'touch',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': { display: 'none' },
    scrollSnapType: 'x mandatory',
    scrollPaddingInlineStart: spacing(2),
    mx: -2,
    px: 2,
  },
}

/** @description Provides a full width container of children that will render the following:
 *  Desktop: a wrapped container, tiling all children to fit the space, pushing following
 *           content down the screen.
 *  Mobile: a horizontally scrolling/sliding container that renders each tile inline,
 *          allowing the overflow to scroll. This also will auto scroll to the newest
 *          item in the list when updated.
 * @property {string | undefined} id: string identifier for the underlying html component
 * @property {string | undefined} ariaLabel: optional pre-translated parameter for aria-label on the component
 * @property {string | number | undefined} spacing: optional spacing parameter. Default is 16px
 * @property {string | number | undefined} marginTop: optional parameter for marginTop. Default is 0px
 * @property {boolean | undefined} scrollToLastItem: optional boolean prop to indicate the the newest child
 * should be scrolled into view when the list changes. Default is false
 */
type ResponsiveScrollingtiledListProps = PropsWithChildren & {
  id?: string
  ariaLabel?: string
  spacing?: string | number
  marginTop?: string | number
  scrollToLastItem?: boolean
  minWidth?: string | number
}
const ResponsiveScrollingTiledList: FC<ResponsiveScrollingtiledListProps> = ({
  children,
  id,
  ariaLabel,
  spacing = 1,
  marginTop = 0,
  scrollToLastItem = false,
  minWidth = '100%',
}) => {
  const [numberSelected, setNumberSelected] = useState<number>(0)

  const isTablet = useMediaQuery(tabletQuery)
  const ref = createRef<HTMLDivElement>()

  const childNodes = Children.toArray(children)

  useEffect(() => {
    if (isTablet && !!childNodes?.length && scrollToLastItem) {
      // scroll to last element only when we're adding
      if (childNodes.length > numberSelected && !!ref?.current) {
        ref.current.scrollTo({
          top: 0,
          left: ref.current.scrollWidth,
          behavior: 'smooth',
        })
      }
      setNumberSelected(childNodes.length)
    }
  }, [childNodes])

  return (
    !!childNodes?.length && (
      <Stack
        id={id ?? 'responsive-tiled-list'}
        aria-label={ariaLabel}
        ref={ref}
        direction="row"
        justifyContent="flex-start"
        alignContent="center"
        maxWidth={600}
        spacing={spacing}
        marginTop={marginTop}
        flexWrap="wrap"
        sx={{ ...tileContainerBoxStyle, minWidth }}
        useFlexGap>
        {children}
      </Stack>
    )
  )
}

export default ResponsiveScrollingTiledList
