import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import CenteredBoxLayout from '@layouts/CenteredBoxLayout'
import RouteLoading from '@shared/routing/RouteLoading'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const PasswordRecoveryRoute = lazyWithRetries(() => import('./passwordRecovery'))
const PasswordResetRoute = lazyWithRetries(() => import('./passwordReset'))
const LoginRoute = lazyWithRetries(() => import('./login'))

const routes: RouteObject[] = [
  {
    path: AppPaths.anon.userLogin(),
    element: (
      <Suspense fallback={<RouteLoading />}>
        <LoginRoute />
      </Suspense>
    ),
  },
  {
    path: AppPaths.anon.passwordRecovery(),
    element: (
      <CenteredBoxLayout>
        <Suspense fallback={<RouteLoading />}>
          <PasswordRecoveryRoute />
        </Suspense>
      </CenteredBoxLayout>
    ),
  },
  {
    path: AppPaths.anon.resetPassword(),
    element: (
      <CenteredBoxLayout>
        <Suspense fallback={<RouteLoading />}>
          <PasswordResetRoute />
        </Suspense>
      </CenteredBoxLayout>
    ),
  },
]

export default routes
