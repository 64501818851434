import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

type TimelineFilters = {
  teamIdIn: string[]
  itemTypeIn: string[]
}

type TimelineFiltersStoreState = {
  timelineFilters: TimelineFilters
  setTimelineFilters: (filters: TimelineFilters) => void
}

export const useTimelineFiltersStore = create<TimelineFiltersStoreState>()(
  persist(
    set => ({
      timelineFilters: { teamIdIn: [], itemTypeIn: [] },
      setTimelineFilters: filters =>
        set(() => ({
          timelineFilters: {
            ...filters,
          },
        })),
    }),
    {
      name: 'timelineFilters-storage',
      /**
       * NOTE: Ref. https://docs.pmnd.rs/zustand/integrations/persisting-store-data
       * By default persist uses localStorage, adding optional "storage" for clarity
       */
      storage: createJSONStorage(() => localStorage),
    },
  ),
)
