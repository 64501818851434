import { useAuth } from '@auth/useAuth'
import AppPaths from '@globals/paths'
import { motion } from 'framer-motion'
import { Navigate, Outlet, createSearchParams, useLocation } from 'react-router-dom'

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
}

const pageTransition = {
  type: 'tween',
  ease: 'easeInOut',
  duration: 0.35,
}

export const TenantScopeRoutes = () => {
  const { isAuthenticated, isTenantScope } = useAuth()
  const { pathname } = useLocation()

  // grab the destination url here so that if the user is unauthenticated
  // we can set it as a search parameter for use after the login flow.
  // the tenant login page will handle this and send the user to the
  // intended destination after login, falling back to the Home page if it
  // isn't set.
  const deepLinkParams = createSearchParams({
    next: pathname,
  })

  const redirectPath = isAuthenticated
    ? `${AppPaths.user.tenants()}?${deepLinkParams}`
    : `${AppPaths.anon.userLogin()}?${deepLinkParams}`

  return (
    <motion.div
      key={pathname}
      initial="initial"
      animate="in"
      variants={pageVariants}
      transition={pageTransition}>
      {isTenantScope ? <Outlet /> : <Navigate to={redirectPath} />}
    </motion.div>
  )
}
