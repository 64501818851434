import { Recognition } from '@kudos/http-client'
import Box from '@mui/material/Box'
import DisplayOnlyChipList from '@shared/components/DisplayOnlyChipList'
import React from 'react'
import ScrollingContent from '../ScrollingContent'
import TvTimeAgo from './TvTimeAgo'
import { colors } from '@globals/themes/defaultTheme'

type TvRecognitionContentProps = {
  recognition: Recognition
}

const TvRecognitionContent: React.FC<TvRecognitionContentProps> = ({ recognition }) => {
  return (
    <ScrollingContent
      htmlContent={recognition.message}
      maxHeight="82vh"
      contentSx={{ color: colors.white, fontSize: '1.75rem' }}
      attachmentUrls={recognition.attachmentUrls}
      gifs={recognition.gifs}>
      <Box sx={{ pt: 4, pb: 4 }}>
        <DisplayOnlyChipList options={recognition.qualities} />
      </Box>
      <TvTimeAgo date={recognition.createdAt} />
    </ScrollingContent>
  )
}

export default TvRecognitionContent
