import { colors } from '@globals/themes/defaultTheme'
import { getRecognitionGoal } from '@kudos/http-client'
import StarIcon from '@mui/icons-material/Star'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'
import { T } from '@transifex/react'
import { FC, Fragment } from 'react'

export const RECONITION_GOAL_QK = 'recognitionGoal'

const RecognitionCounter: FC = () => {
  const { data } = useQuery({
    queryKey: [RECONITION_GOAL_QK],
    queryFn: () => getRecognitionGoal(),
    staleTime: 60 * 1000 * 60, // 1 hour
  })

  if (!data) {
    return null
  }

  const { count, goal } = data

  return (
    <Stack direction="column" useFlexGap spacing={1} alignItems="center">
      <Typography variant="body1" color={colors.white}>
        <T _str="Reach your monthly goal!" />
      </Typography>
      <Stack direction="row" alignItems="center" mt={{ xs: 0 }}>
        {Array.from({ length: goal }).map((_, index) => (
          <Fragment key={index}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width={24}
              height={24}
              borderRadius="50%"
              sx={{
                backgroundColor: index < count ? colors.goldPrimary : colors.goldExtraLight,
                border: '4px solid',
                borderColor: colors.goldPrimary,
              }}>
              {index < count && <StarIcon sx={{ color: colors.goldExtraLight }} />}
            </Box>
            {index < goal - 1 && <Box width={16} height={4} bgcolor={colors.goldPrimary} />}
          </Fragment>
        ))}
      </Stack>
    </Stack>
  )
}

export default RecognitionCounter
