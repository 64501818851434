import { spacing } from '@globals/themes/themeConstants'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { SxProps } from '@mui/material/styles'
import { Selectable } from '@shared/filters/selections'
import { ReactNode } from 'react'

type DisplayOnlyChipListProps = {
  color?: string
  backgroundColor?: string
  textColor?: string
  options: Selectable[]
  overflowButton?: ReactNode
}

const chipStyle: SxProps = {
  borderStyle: 'solid',
  borderWidth: '1.5px',
  minWidth: 68, // NOTE: Needed for single digit values e.g. number
}

/**
 * @param color CSS border-color property for the chip, e.g. '#fff555'
 * @returns Chips with custom border
 */
const DisplayOnlyChipList: React.FC<DisplayOnlyChipListProps> = ({
  color,
  backgroundColor,
  textColor,
  options,
  overflowButton,
}) => {
  return (
    <Stack direction="row" flexWrap="wrap" alignItems="center" gap={`${spacing(2)} ${spacing(1)}`}>
      {options.map(option => (
        <Chip
          key={option.id}
          id={`customChip-${option.id}`}
          label={option.name}
          variant="nonInteractive"
          sx={{
            ...chipStyle,
            borderColor: option.color || color,
            backgroundColor: option.backgroundColor || backgroundColor,
            color: option.textColor || textColor,
          }}
        />
      ))}
      {overflowButton}
    </Stack>
  )
}

export default DisplayOnlyChipList
