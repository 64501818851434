import { useApiActionToast } from '@globals/toaster/useToasterStore'
import { useT } from '@transifex/react'
import { isAxiosError } from 'axios'
import { FieldValues, Path, UseFormSetError } from 'react-hook-form'
import { setErrorsFromApiError } from './setErrorsFromApiError'
import { ApiError, ApiErrorResponseData } from './types'

/**
 * Custom hook that returns hanlder for API errors.
 * Handler sets form errors or shows a toast with error message.
 * @returns handleApiError()
 */
export const useApiError = <T extends FieldValues>() => {
  const t = useT()
  const [_, showFailure] = useApiActionToast()

  const handleApiError = (error: ApiError, setError?: UseFormSetError<T>, fields?: Path<T>[]) => {
    if (isAxiosError(error) && error.response?.data) {
      const { msg, details } = error.response.data as ApiErrorResponseData

      const hasFieldErrors = details?.some(detail => detail.path?.length) || false

      if (setError && fields) {
        setErrorsFromApiError(error, setError, fields)
      }

      if (!hasFieldErrors || !setError || !fields) {
        showFailure(t(msg))
      }
    } else {
      showFailure(t(error.message))
    }
  }

  return { handleApiError }
}
