// Search state is always string-ly typed with assumed multiple values per key
export type SearchState<T extends string = string> = { [key in T]: string[] }

export const firstValue = (values: string[] | undefined): string | undefined =>
  values && values.length ? values[0] : undefined

export const boolValue = (values: string[] | undefined): boolean | undefined => {
  const v = firstValue(values)
  if (v === 'true') {
    return true
  }
  if (v === 'false') {
    return false
  }
  return undefined
}

export const integerValue = (values: string[] | undefined): number | undefined => {
  const v = firstValue(values)
  if (v === undefined) {
    return undefined
  }

  try {
    return Number.parseInt(v, 10)
  } catch {
    return undefined
  }
}

export const enumValue = <T extends string>(values: string[] | undefined): T[] | undefined =>
  values?.map(value => value as T)

export const dateRangeValue = (dateRange: string[] | undefined) => {
  return dateRange?.[0] === 'undefined' ? undefined : dateRange
}
