import { InputAdornment } from '@mui/material'
import Box from '@mui/material/Box'
import { InputProps } from '@mui/material/Input'
import Stack from '@mui/material/Stack'
import MuiTextField from '@mui/material/TextField'
import { SxProps } from '@mui/material/styles'
import { ChangeEvent, HTMLInputTypeAttribute, ReactNode } from 'react'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import ErrorText from './ErrorText'
import FieldLabel from './FieldLabel'
import HelpText from './HelpText'

export type TextFieldProps<T extends FieldValues> = {
  name: Path<T>
  control?: Control<T>
  label?: ReactNode
  placeholder?: string
  required?: boolean
  fullWidth?: boolean
  help?: ReactNode
  disabled?: boolean
  type?: HTMLInputTypeAttribute
  onBlur?: () => void
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  value?: string
  labelStyle?: SxProps
  InputProps?: InputProps | undefined
  startAdornment?: ReactNode
  endAdornment?: ReactNode
  maxChars?: number
}

const TextField = <T extends FieldValues>({
  control,
  label,
  name,
  type,
  help,
  placeholder,
  fullWidth = true,
  required = true,
  disabled = false,
  onBlur,
  onChange,
  value,
  labelStyle,
  InputProps = undefined,
  startAdornment,
  endAdornment,
}: TextFieldProps<T>) => {
  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({
          field: {
            onChange: onControllerChange,
            value: controllerValue,
            onBlur: onControllerBlur,
            ref,
          },
          fieldState: { error },
        }) => (
          <>
            {!!label && (
              <FieldLabel
                htmlFor={name}
                required={required}
                disabled={disabled}
                labelStyle={labelStyle}>
                {label}
              </FieldLabel>
            )}
            {help && <HelpText disabled={disabled}>{help}</HelpText>}

            <Stack spacing={1} direction="row" alignItems="center">
              <MuiTextField
                placeholder={placeholder}
                type={type}
                disabled={disabled}
                ref={ref}
                error={Boolean(error)}
                id={name}
                fullWidth={fullWidth}
                value={value ?? controllerValue ?? ''}
                onChange={!!onChange ? onChange : onControllerChange}
                variant="outlined"
                name={name}
                onBlur={!!onBlur ? onBlur : onControllerBlur}
                slotProps={{
                  input: {
                    startAdornment: startAdornment && (
                      <InputAdornment position="start">{startAdornment}</InputAdornment>
                    ),
                    endAdornment: endAdornment && (
                      <InputAdornment position="end">{endAdornment}</InputAdornment>
                    ),
                  },
                }}
                InputProps={InputProps}
              />
            </Stack>
            {error && <ErrorText>{error.message}</ErrorText>}
          </>
        )}
      />
    </Box>
  )
}

export default TextField
