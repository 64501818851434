import Box from '@mui/material/Box'
import CheckmarkChipSelector, {
  CheckmarkChipSelectorProps,
} from '@shared/components/CheckmarkChipSelector'
import { Selectable } from '@shared/filters/selections'
import { ReactNode } from 'react'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import ErrorText from './ErrorText'
import FieldLabel from './FieldLabel'
import HelpText from './HelpText'

type BaseProps = Pick<CheckmarkChipSelectorProps, 'options' | 'itemsSelectedLabel'>

type CheckmarkChipSelectorFieldProps<T extends FieldValues> = BaseProps & {
  control: Control<T>
  name: Path<T>
  label?: ReactNode
  tooltipTitle?: (item: Selectable) => string
  required?: boolean
  help?: string
  disabled?: boolean
  onChipsChange?: (values: Selectable[]) => void
  fetchNextPage?: () => void
  hasNextPage?: boolean
  isFetchingNextPage?: boolean
  selectedChips?: Selectable[]
  onRemoveChip?: (id: string) => void
  searchValue?: string
  hideScrollbar?: boolean
}

const CheckmarkChipSelectorField = <T extends FieldValues>({
  control,
  name,
  itemsSelectedLabel,
  options,
  required = false,
  help,
  disabled = false,
  label,
  tooltipTitle,
  onChipsChange,
  hideScrollbar = false,
  ...chipSelectorProps
}: CheckmarkChipSelectorFieldProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          {!!label && (
            <FieldLabel htmlFor={name} required={required} disabled={disabled}>
              {label}
            </FieldLabel>
          )}
          {!!help && <HelpText disabled={disabled}>{help}</HelpText>}
          <CheckmarkChipSelector
            elementId={name}
            values={value || []}
            onChange={values => {
              if (onChipsChange) {
                onChipsChange(values)
              } else {
                onChange(values)
              }
            }}
            itemsSelectedLabel={itemsSelectedLabel}
            options={options}
            tooltipTitle={tooltipTitle}
            hideScrollbar={hideScrollbar}
            {...chipSelectorProps}
          />
          <Box mt={2}>{error && <ErrorText>{error.message}</ErrorText>}</Box>
        </>
      )}
    />
  )
}

export default CheckmarkChipSelectorField
