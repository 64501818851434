import { API, unwrapData } from '../axios-instance'
import { Comment, ThreadType } from '../comment/types'
import { FlagCommentParams } from '../recognition'
import {
  FederatedSearchRecipientsParams,
  FederatedSearchRecipientsResults,
  PageResult,
} from '../shared/types'
import {
  CreateCommentParams,
  CreateEndorsementParams,
  CreateReactionParams,
  FlagParams,
  GetCommentsParams,
  ListCommentsReactionsParams,
  ListEndorsementsParams,
  ListReactionsParams,
  PreviewPointsRecipientsParams,
  PreviewPointsRecipientsResults,
  UpdateWallPostMessageParams,
} from '../shared/wallPost/types'
import {
  Award,
  AwardEmbeds,
  AwardEndorsement,
  AwardReaction,
  AwardWithFlags,
  CreateAwardParams,
  GetAwardParams,
  ListAwardsParams,
} from './types'

export const createAward = async (params: CreateAwardParams, awardTypeId: string) => {
  return await API.post<Award>('/award/awards', { awardTypeId, ...params }).then(unwrapData)
}

export const getAward = async (awardId: string, params: GetAwardParams) => {
  return await API.get<Award>(`/award/awards/${awardId}`, { params }).then(unwrapData)
}

export const listAwardRecipients = async (params: FederatedSearchRecipientsParams) => {
  return await API.get<FederatedSearchRecipientsResults>('/award/recipients', { params }).then(
    unwrapData,
  )
}

export const listAwards = async (params: ListAwardsParams) => {
  return await API.get<PageResult<Award>>('/award/awards', { params }).then(unwrapData)
}

export const endorseAward = async ({ amount, id }: CreateEndorsementParams) => {
  return await API.post<AwardEndorsement>(`/award/awards/${id}/endorsements`, {
    amount,
  }).then(unwrapData)
}

export const listAwardEndorsements = async (awardId: string, params: ListEndorsementsParams) => {
  return await API.get<PageResult<AwardEndorsement>>(`/award/awards/${awardId}/endorsements`, {
    params,
  }).then(unwrapData)
}

export const listAwardComments = async (id: string, params?: GetCommentsParams) => {
  return await API.get<PageResult<Comment>>(`/award/awards/${id}/comments`, {
    params,
  }).then(unwrapData)
}

export const createAwardComment = async ({
  body,
  id,
  tenantMemberId,
  threadId,
}: CreateCommentParams) => {
  return await API.post<Comment>(`/award/awards/${id}/comments`, {
    body,
    tenantMemberId,
    threadId,
    threadType: ThreadType.AWARD,
  }).then(unwrapData)
}

export const listAwardCommentReactions = async (
  commentId: string,
  id: string,
  params: ListCommentsReactionsParams,
) => {
  return await API.get<PageResult<AwardReaction>>(
    `/award/awards/${id}/comments/${commentId}/reactions`,
    { params },
  ).then(unwrapData)
}

export const reactToAward = async ({ reaction, postId }: CreateReactionParams) => {
  return await API.put<AwardReaction>(`/award/awards/${postId}/reactions`, {
    reaction,
    awardId: postId,
  }).then(unwrapData)
}

export const removeAwardReaction = async ({ postId }: Pick<CreateReactionParams, 'postId'>) => {
  return await API.delete<boolean>(`/award/awards/${postId}/reactions`).then(unwrapData)
}

export const listAwardReactions = async (postId: string, params: ListReactionsParams) => {
  return await API.get<PageResult<AwardReaction>>(`/award/awards/${postId}/reactions`, {
    params,
  }).then(unwrapData)
}

export const reactToAwardComment = async ({
  reaction,
  postId,
  commentId,
}: CreateReactionParams) => {
  return await API.put<AwardReaction>(`/award/awards/${postId}/comments/${commentId}/reactions`, {
    reaction,
    awardId: postId,
    commentId,
  }).then(unwrapData)
}

export const removeAwardCommentReaction = async ({
  postId,
  commentId,
}: Pick<CreateReactionParams, 'postId' | 'commentId'>) => {
  return await API.delete<boolean>(`/award/awards/${postId}/comments/${commentId}/reactions`).then(
    unwrapData,
  )
}

export const flagAward = async (id: string) => {
  return await API.put<boolean>(`/award/awards/${id}/flags`).then(unwrapData)
}

export const restoreFlaggedAward = async ({ postId }: FlagParams) => {
  return await API.delete<boolean>(`/award/awards/${postId}/flags`)
}

export const listFlaggedAwards = async (params?: ListAwardsParams) => {
  return await API.get<PageResult<AwardWithFlags>>('/award/awards/flagged', {
    params,
  }).then(unwrapData)
}

export const flagAwardComment = async ({ id, commentId }: FlagCommentParams) => {
  return await API.put<boolean>(`/award/awards/${id}/comments/${commentId}/flags`).then(unwrapData)
}

export const previewAwardRecipients = async (params: PreviewPointsRecipientsParams) => {
  return await API.post<PreviewPointsRecipientsResults>('/award/recipients/preview', params).then(
    unwrapData,
  )
}

export const editAwardMessage = async (params: UpdateWallPostMessageParams, awardId: string) => {
  return await API.patch<Award>(`/award/awards/${awardId}`, params).then(unwrapData)
}

export const listTvAwards = async (token: string, embeds?: AwardEmbeds[]) => {
  return await API.get<Award[]>(`/award/tv/${token}/awards`, { params: { embeds } }).then(
    unwrapData,
  )
}
