import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import StandardPageLayout from '@layouts/standardPageLayout'
import RouteLoading from '@shared/routing/RouteLoading'
import { T } from '@transifex/react'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const ResourceHubRoute = lazyWithRetries(() => import('./'))
const ResourceRoute = lazyWithRetries(() => import('./Resource'))
const ResourceCompleteRoute = lazyWithRetries(() => import('./ResourceComplete'))

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.resourceHub(':tab?'),
    element: (
      <StandardPageLayout
        elementId="resourceHubRoute"
        maxWidth={900}
        title={<T _str="Resource hub" />}>
        <Suspense fallback={<RouteLoading />}>
          <ResourceHubRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.viewResource(':resourceId'),
    element: (
      <StandardPageLayout elementId="resourceRoute" maxWidth={1080}>
        <Suspense fallback={<RouteLoading />}>
          <ResourceRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.viewResourceComplete(':resourceId'),
    element: (
      <Suspense fallback={<RouteLoading />}>
        <ResourceCompleteRoute />
      </Suspense>
    ),
  },
]

export default routes
