import { QueryKey, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

/**
 * Custom hook for invalidating a cached query.
 *
 * usage:
 * const invalidateQuery = useInvalidateQuery()
 *
 * example:
 * const handleSomeEvent = () => {
 *   invalidateQuery(['someQueryKey'])
 * }
 *
 * @returns invalidateQuery
 */
export const useInvalidateQuery = () => {
  const queryClient = useQueryClient()

  const invalidateQuery = useCallback(
    async (queryKey: QueryKey) => {
      await queryClient.invalidateQueries({ queryKey })
    },
    [queryClient],
  )

  return invalidateQuery
}
