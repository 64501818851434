import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import StandardPageLayout from '@layouts/standardPageLayout'
import RouteLoading from '@shared/routing/RouteLoading'
import { T } from '@transifex/react'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const RedemptionsReportRoute = lazyWithRetries(() => import('./redemptionSummary'))
const EngagementReportRoute = lazyWithRetries(() => import('./engagement'))
const AwardsReportRoute = lazyWithRetries(() => import('./awardSummary'))
const ReportsOverviewRoute = lazyWithRetries(() => import('./userActivitySummary'))
const ReportSharingRoute = lazyWithRetries(() => import('./reportSharing'))
const AllReportsRoute = lazyWithRetries(() => import('./all'))

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.admin.allReports(),
    element: (
      <StandardPageLayout
        maxWidth={1080}
        elementId="allReportsRoute"
        title={<T _str="All reports" />}>
        <Suspense fallback={<RouteLoading />}>
          <AllReportsRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.reports('redemptionSummary'),
    element: (
      <StandardPageLayout
        elementId="redemptionsReportRoute"
        title={<T _str="Redemption summary" />}
        maxWidth={1080}>
        <Suspense fallback={<RouteLoading />}>
          <RedemptionsReportRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.reports('engagement'),
    element: (
      <StandardPageLayout
        elementId="engagementReportRoute"
        title={<T _str="Engagement" />}
        maxWidth={1080}>
        <Suspense fallback={<RouteLoading />}>
          <EngagementReportRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.reports('awardSummary'),
    element: (
      <StandardPageLayout
        elementId="awardsReportRoute"
        title={<T _str="Award summary" />}
        maxWidth={1080}>
        <Suspense fallback={<RouteLoading />}>
          <AwardsReportRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.reports('userActivitySummary'),
    element: (
      <StandardPageLayout
        elementId="reportsOverviewRoute"
        title={<T _str="User activity summary" />}
        maxWidth={1080}>
        <Suspense fallback={<RouteLoading />}>
          <ReportsOverviewRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.reports(':type?', 'share', ':tab?'),
    element: (
      <StandardPageLayout
        maxWidth={1080}
        elementId="reportSharingRoute"
        title={<T _str="Report sharing" />}>
        <Suspense fallback={<RouteLoading />}>
          <ReportSharingRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
]

export default routes
