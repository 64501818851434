import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import ConstrainedWidthLayout from '@layouts/ConstrainedWidthLayout'
import RootLayoutWrapper from '@layouts/RootLayoutWrapper'
import RouteLoading from '@shared/routing/RouteLoading'
import { Suspense } from 'react'
import { Outlet, RouteObject } from 'react-router-dom'

const YourDirectReportsRoute = lazyWithRetries(() => import('.'))

const routes: RouteObject[] = [
  {
    element: (
      <RootLayoutWrapper>
        <Outlet />
      </RootLayoutWrapper>
    ),
    children: [
      {
        path: AppPaths.tenant.yourDirectReports(),
        element: (
          <ConstrainedWidthLayout maxWidth={1080}>
            <Suspense fallback={<RouteLoading />}>
              <YourDirectReportsRoute />
            </Suspense>
          </ConstrainedWidthLayout>
        ),
      },
    ],
  },
]

export default routes
