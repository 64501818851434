import { TenantMemberDisplayData } from '../identity'
import { PaginatedSearch, WithPermissions } from '../shared/types'
import { Reaction, ReactionCount } from '../shared/wallPost/types'

export enum ThreadType {
  RECOGNITION = 'recognition',
  AWARD = 'award',
}

export type CommentFlag = {
  id: string
  commentId: string
  flaggedById: string
  flaggedBy?: TenantMemberDisplayData
  dismissedById: string | null
  dismissedAt: string | null
  createdAt: string
  __entity_type__: 'commentFlag'
}

export type Comment = WithPermissions<{
  id: string
  body: string
  tenantId: string
  authorId: string
  threadId: string
  threadType: ThreadType
  createdAt: string
  updatedAt: string
  mentionIds: string[]
  //Embeds
  author?: TenantMemberDisplayData
  flags: CommentFlag[]
  reactionCounts: ReactionCount[]
  ownReaction: Reaction
  __entity_type__: 'comment'
}>

export type CommentWithFlags = Comment & {
  flags: CommentFlag[]
}

export type CommentEmbeds = 'author' | 'flags' | 'reactionCounts' | 'ownReaction'

export type ListCommentsParams = PaginatedSearch<object, CommentEmbeds>

export type CommentFlagParams = {
  commentId: string
}
