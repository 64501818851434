import { Stack } from '@mui/material'
import FormContextWrapper from '@shared/forms/FormContextWrapper'
import useQualities from '@shared/hooks/useQualities'
import useGetRecognition from '@tenant/recognition/useGetRecognition'
import { T } from '@transifex/react'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import BackModalHeader from '../BackModalHeader'
import RecognitionModalHeader from '../RecognitionModalHeader'
import RecognitionModalViewLayout from '../RecognitionModalViewLayout'
import useRecognitionModal, { RecognitionModalViews } from '../useRecognitionModal'
import DirtyRecognitionFormBlocker from './DirtyRecognitionFormBlocker'
import DraftAndScheludedButton from './DraftAndScheduledButton'
import SendRecognitionForm from './SendRecognitionForm'
import FormSubmissionExceptions from './formSubmissionExceptions/FormSubmissionExceptions'
import ScheduledRecognitionSend from './scheduledRecognitionSend/ScheduledRecognitionSend'
import useSendRecognitionForm, { RecognitionFormData } from './useSendRecognitionForm'
const UnsavedRecognitionDialogKey = 'ConfirmUnsavedRecognitionDialogKey'
type SendRecognitionViewProps = {
  setShowAnimation: (showAnimation: boolean) => void
}

enum SendRecognitionViewState {
  Send,
  ScheduledSend,
  PointsException,
}
const SendRecognitionView: React.FC<SendRecognitionViewProps> = ({ setShowAnimation }) => {
  const { dialogData, setView } = useRecognitionModal()
  const { recognition } = useGetRecognition(dialogData?.recognitionId)
  const { qualities } = useQualities()
  const [viewState, setViewState] = useState<SendRecognitionViewState>(
    SendRecognitionViewState.Send,
  )
  const {
    form,
    isFormDirty,
    isEditMode,
    pointsExceptions,
    setPointsExceptions,
    publishRecognition,
    handleSaveAsDraft,
    handleDiscardChanges,
    handleKeepChanges,
    handleResetRecognitionForm,
  } = useSendRecognitionForm(setShowAnimation, !isEmpty(qualities), recognition)
  const { formState, setValue, handleSubmit } = form
  const { isSubmitSuccessful } = formState

  const submitForm = (data: RecognitionFormData) => {
    return publishRecognition(data, !!pointsExceptions, handleResetRecognitionForm)
  }

  useEffect(() => {
    if (pointsExceptions) {
      setViewState(SendRecognitionViewState.PointsException)
    }
  }, [pointsExceptions])

  useEffect(() => {
    if (dialogData?.recipents) {
      const recipients = dialogData?.recipents

      if (recipients && recipients.length > 0) {
        setValue('sendTo', recipients, { shouldValidate: true })
      }
    }
  }, [dialogData])

  const renderHeader = () => {
    switch (viewState) {
      case SendRecognitionViewState.ScheduledSend:
        return (
          <BackModalHeader
            onBackClicked={() => {
              setValue('scheduledAt', recognition?.scheduledAt || null, { shouldValidate: true })
              setViewState(SendRecognitionViewState.Send)
            }}
            title={<T _str="Schedule send" />}
          />
        )
      case SendRecognitionViewState.PointsException:
        return (
          <BackModalHeader
            onBackClicked={() => {
              setPointsExceptions(undefined)
              setViewState(SendRecognitionViewState.Send)
            }}
            title={<T _str="Point exceptions" />}
          />
        )
      case SendRecognitionViewState.Send:
        return <RecognitionModalHeader />
    }
  }

  return (
    <>
      {renderHeader()}
      <RecognitionModalViewLayout>
        <FormContextWrapper formMethods={form} onSubmit={submitForm}>
          {viewState === SendRecognitionViewState.Send && (
            <Stack>
              <DraftAndScheludedButton
                onClick={() =>
                  setView({
                    view: RecognitionModalViews.DraftAndInProgressRecognitions,
                  })
                }
              />
              <SendRecognitionForm
                isEditMode={isEditMode}
                recognition={recognition}
                qualities={qualities}
                onSend={handleSubmit(submitForm)}
                onScheduleSend={() => setViewState(SendRecognitionViewState.ScheduledSend)}
                onSaveAsDraft={() => handleSaveAsDraft()}
              />
            </Stack>
          )}
          {viewState === SendRecognitionViewState.ScheduledSend && (
            <ScheduledRecognitionSend
              recognition={recognition}
              onScheduleClicked={() => handleSaveAsDraft()}
            />
          )}
          {viewState === SendRecognitionViewState.PointsException && (
            <FormSubmissionExceptions pointsExceptions={pointsExceptions} />
          )}
        </FormContextWrapper>
      </RecognitionModalViewLayout>
      <DirtyRecognitionFormBlocker
        isFormDirty={isFormDirty && !isSubmitSuccessful}
        onDiscardData={handleDiscardChanges}
        onKeepData={handleKeepChanges}
        dialogKey={UnsavedRecognitionDialogKey}
      />
    </>
  )
}

export default SendRecognitionView
