import { useDialog } from '@globals/dialog/useDialogStore'
import { ConfirmDialog } from '@shared/dialog/ConfirmDialog'
import { T } from '@transifex/react'
import { FC, useEffect } from 'react'
import { useBlocker } from 'react-router-dom'

type DirtyFormBlockerProps = {
  isFormDirty: boolean
  onDiscardData?: () => void
  onKeepData?: () => Promise<void>
  dialogKey: string
}
const DirtyRecognitionFormBlocker: FC<DirtyFormBlockerProps> = ({
  isFormDirty,
  onDiscardData,
  onKeepData,
  dialogKey,
}) => {
  const { openDialog, closeDialog } = useDialog(dialogKey)

  const navBlocker = useBlocker(() => {
    return isFormDirty
  })

  useEffect(() => {
    if (navBlocker.state === 'blocked') {
      openDialog()
    }
  }, [navBlocker.state])

  const handleClose = () => {
    navBlocker?.reset?.()
  }

  const handleKeepData = () => {
    !!onKeepData && onKeepData()
    closeDialog()
    navBlocker?.proceed?.()
  }

  const handleDiscard = () => {
    !!onDiscardData && onDiscardData()
    closeDialog()
    navBlocker?.proceed?.()
  }

  return (
    <ConfirmDialog
      dialogKey={dialogKey}
      content={<T _str="Discard unsaved changes?" />}
      onClose={handleClose}
      onConfirm={handleDiscard}
      onCancel={handleKeepData}
      cancelButtonText={<T _str="Keep" />}
      confirmButtonText={<T _str="Discard" />}
    />
  )
}

export default DirtyRecognitionFormBlocker
