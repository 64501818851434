import CloseButton from '@components/button/CloseButton'
import { colors } from '@globals/themes/defaultTheme'
import { spacing, tabletQuery } from '@globals/themes/themeConstants'
import { MediaObjectUrl, MediaVariantEnum } from '@kudos/http-client'
import { useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import useMediaUrl from '@shared/hooks/useMediaUrl'
import { useT } from '@transifex/react'
import { useState } from 'react'
import { StyledMediaAttachment } from './StyledMediaAttachments'

const boxStyle = {
  margin: 1,
  '& img': {
    maxWidth: '100%',
    height: 'auto',
    border: `3px solid ${colors.white}`,
    borderRadius: spacing(1),
  },
}

type MediaAttachmentProps = {
  mediaObject?: MediaObjectUrl
  gifUrl?: string
  id: string
}

const MediaAttachment: React.FC<MediaAttachmentProps> = ({ mediaObject, gifUrl, id }) => {
  const t = useT()
  const isTablet = useMediaQuery(tabletQuery)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const attachemntId = open ? `mediaAttachmentPopover-${id}` : undefined

  const preferredVariant = isTablet
    ? MediaVariantEnum.RECOGNITION_ATTACHMENT_MOBILE
    : MediaVariantEnum.RECOGNITION_ATTACHMENT_DESKTOP

  const { url: attachmentUrl } = useMediaUrl(mediaObject, preferredVariant)

  return (
    <>
      <StyledMediaAttachment>
        <Button onClick={handleClick} id="btnShowMediaAttachment">
          {!!mediaObject && <img src={attachmentUrl} alt={t('Recognition image attachment')} />}
          {!!gifUrl && <img src={gifUrl} alt={t('Recognition gif attachment')} />}
        </Button>
      </StyledMediaAttachment>
      <Popover
        id={attachemntId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <Box sx={boxStyle}>
          <CloseButton
            id="closeMediaAttachment"
            ariaLabel={t('Close media attachment')}
            onClose={handleClose}
          />
          <img
            onClick={handleClose}
            src={mediaObject?.url || gifUrl}
            alt=""
            onKeyDown={handleClose}
            role="presentation"
          />
        </Box>
      </Popover>
    </>
  )
}

export default MediaAttachment
