import { MediaObjectTypeEnum, MediaObjectUrl } from '@kudos/http-client'
import Stack from '@mui/material/Stack'
import DocumentAttachment from '@shared/attachments/DocumentAttachment'
import MediaAttachment from '@shared/attachments/MediaAttachment'
import { StyledMediaContainer } from '@shared/attachments/StyledMediaAttachments'

export const DISPLAY_AS_IMAGE = [MediaObjectTypeEnum.IMAGE, MediaObjectTypeEnum.GIF]

type AttachmentsProps = {
  mediaObjects: MediaObjectUrl[]
  gifUrls?: string[]
}

const Attachments: React.FC<AttachmentsProps> = ({ mediaObjects, gifUrls }) => {
  return (
    <>
      <StyledMediaContainer>
        {mediaObjects.map((mediaObject, index) => {
          if (mediaObject.type === MediaObjectTypeEnum.IMAGE) {
            return (
              <MediaAttachment
                key={`imageAttachment-${index}`}
                mediaObject={mediaObject}
                id={`imageAttachment-${index}`}
              />
            )
          }
          if (mediaObject.type === MediaObjectTypeEnum.GIF) {
            return (
              <MediaAttachment
                key={`gifAttachment-${index}`}
                gifUrl={mediaObject.url}
                id={`gifAttachment-${index}`}
              />
            )
          }
          return null
        })}
        {gifUrls?.map((gifUrl, index) => (
          <MediaAttachment
            key={`gifAttachment-${index}`}
            gifUrl={gifUrl}
            id={`gifAttachment-${index}`}
          />
        ))}
      </StyledMediaContainer>
      <Stack direction="row" useFlexGap flexWrap="wrap" spacing={2}>
        {mediaObjects.map(
          (mediaObject, index) =>
            mediaObject.type &&
            !DISPLAY_AS_IMAGE.includes(mediaObject.type) && (
              <DocumentAttachment key={`documentAttachment-${index}`} mediaObject={mediaObject} />
            ),
        )}
      </Stack>
    </>
  )
}

export default Attachments
