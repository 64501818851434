import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import StandardPageLayout from '@layouts/standardPageLayout'
import RouteLoading from '@shared/routing/RouteLoading'
import { T } from '@transifex/react'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const AnnouncementsRoute = lazyWithRetries(() => import('./'))
const ConfigureAnnouncementRoute = lazyWithRetries(() => import('./configureAnnouncement'))
const ViewAnnouncementRoute = lazyWithRetries(() => import('./viewAnnouncement'))

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.admin.announcements(),
    element: (
      <StandardPageLayout
        elementId="announcementsRoute"
        maxWidth={1440}
        title={<T _str="Announcements" />}>
        <Suspense fallback={<RouteLoading />}>
          <AnnouncementsRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.announcements(':id?'),
    element: (
      <Suspense fallback={<RouteLoading />}>
        <ViewAnnouncementRoute />
      </Suspense>
    ),
  },
  {
    path: AppPaths.tenant.admin.configureAnnouncement(':id?'),
    element: (
      <StandardPageLayout elementId="configureAnnouncementRoute" maxWidth={600}>
        <Suspense fallback={<RouteLoading />}>
          <ConfigureAnnouncementRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
]

export default routes
