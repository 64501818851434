import AttachFileRounded from '@mui/icons-material/AttachFileRounded'
import Close from '@mui/icons-material/Close'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { T, useT } from '@transifex/react'
import { useEffect, useState } from 'react'

type DocumentAttachmentChipProps = {
  mediaObjectId?: string
  fileName?: string
  isUploading?: boolean
  index?: number
  onDelete?: (mediaObjectId?: string) => void
  disabled?: boolean
}

const DocumentAttachmentChip: React.FC<DocumentAttachmentChipProps> = ({
  mediaObjectId,
  fileName,
  index = -1,
  onDelete,
  isUploading,
  disabled,
}) => {
  const t = useT()
  const [label, setLabel] = useState<string | undefined>(fileName)

  useEffect(() => {
    if (fileName) {
      setLabel(fileName)
    }
  }, [fileName])

  const handleDeleteClick = async () => {
    if (!!onDelete) onDelete(mediaObjectId)
  }

  return (
    <Chip
      label={<Typography variant="body1">{label}</Typography>}
      onDelete={!!onDelete ? handleDeleteClick : undefined}
      variant="outlined"
      color="primary"
      icon={<AttachFileRounded />}
      deleteIcon={
        <Tooltip id="tooltipDeleteDocument" title={<T _str="Delete attachment" />} placement="top">
          <Close aria-labelledby="tooltipDeleteDocument" />
        </Tooltip>
      }
      aria-label={t('Attachment document {index}', { index })}
      disabled={isUploading || disabled}
    />
  )
}

export default DocumentAttachmentChip
