import { colors } from '@globals/themes/defaultTheme'
import { tabletQuery } from '@globals/themes/themeConstants'
import Close from '@mui/icons-material/Close'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { useT } from '@transifex/react'

export const DialogCloseButton = ({ onClose, id }: { onClose: () => void; id: string }) => {
  const t = useT()

  return (
    <IconButton
      aria-label={t('Close dialog button')}
      id={id}
      onClick={onClose}
      sx={{
        position: 'absolute',
        right: 4,
        top: 4,
        color: colors.grayDark,
      }}>
      <Close />
    </IconButton>
  )
}

export const DialogContentStyled = styled(DialogContent)({
  maxWidth: '100%',
  maxHeight: 'inherit',
  padding: '12px 24px',
  backgroundColor: colors.grayUltraLight,
})

export const DialogActionsStyled = styled(DialogActions)({
  padding: '16px 8px',
  '& > button:first-of-type': {
    marginRight: 8,
  },
})

export const DialogTitleImage = styled('img')({
  marginTop: 8,
  width: 190,
  height: 'auto',
  [tabletQuery]: {
    width: 125,
  },
})
