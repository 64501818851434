import { spacing } from '@globals/themes/themeConstants'
import Stack from '@mui/material/Stack'
import { SxProps } from '@mui/material/styles'
import { FC, PropsWithChildren } from 'react'

const toolBarStyle: SxProps = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  justifyContent: 'flex-end',
  padding: spacing(1, 2),
}

const KudosToolbar: FC<PropsWithChildren> = ({ children }) => (
  <Stack direction="row" sx={toolBarStyle} spacing={1}>
    {children}
  </Stack>
)

export default KudosToolbar
