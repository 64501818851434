import { colors } from '@globals/themes/defaultTheme'
import { MediaObjectUrl, MediaVariantEnum } from '@kudos/http-client'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import { SxProps } from '@mui/material/styles'
import useAvatarConfig from './useAvatarConfig'

const contentStyle: SxProps = {
  color: colors.grayDark,
  alignItems: 'center',
  justifyContent: 'center',
}

const avatarStyle: SxProps = {
  display: 'inline-flex',
  borderRadius: '50%',
  borderStyle: 'solid',
  borderWidth: 2,
  borderColor: colors.white,
  backgroundColor: colors.blueUltraLight,
}

export type AvatarSize = number

type AvatarFontUnit = 'px' | 'vh'

type AvatarWrapperProps = {
  size?: AvatarSize
  media?: MediaObjectUrl | null
  src?: string
  alt?: string
  fontUnit?: AvatarFontUnit
  children?: React.ReactNode
  hideDropShadow?: boolean
  /* optional override for images that do not have avatar variants */
  mediaVariant?: MediaVariantEnum
  avatarBoxStyle?: SxProps
  tooltipTitle?: string
}

const AvatarWrapper: React.FC<AvatarWrapperProps> = ({
  size = 40,
  media,
  src,
  alt,
  fontUnit = 'px',
  children,
  hideDropShadow = false,
  mediaVariant,
  avatarBoxStyle = {},
  tooltipTitle,
}) => {
  const { fontSize, avatarUrl } = useAvatarConfig(size, media || null, mediaVariant)
  const sizing = `${size}${fontUnit}`

  return (
    <Box sx={avatarBoxStyle}>
      <Tooltip title={tooltipTitle} placement="top">
        <Avatar
          src={src ?? avatarUrl}
          alt={alt}
          sx={{
            ...avatarStyle,
            boxShadow: hideDropShadow ? 'unset' : colors.boxShadow,
            height: sizing,
            width: sizing,
            borderWidth: size >= 88 ? 4 : 2,
          }}>
          <Stack
            sx={{
              ...contentStyle,
              fontSize: `${fontSize}${fontUnit}`,
              // below is intended for fallback icon only
              '& .MuiSvgIcon-root': {
                fontSize: `${fontSize * 2}${fontUnit}`,
                color: colors.blueLight,
              },
            }}>
            {children}
          </Stack>
        </Avatar>
      </Tooltip>
    </Box>
  )
}

export default AvatarWrapper
