import GifBoxOutlined from '@mui/icons-material/GifBoxOutlined'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import GiphySearchDialog from '@tenant/recognition/RecognitionModal/sendRecognitionView/giphySearch/GiphySearchDialog'
import useGiphyDialog from '@tenant/recognition/RecognitionModal/sendRecognitionView/giphySearch/useGiphyDialog'
import { T, useT } from '@transifex/react'
import { Control, FieldValues, Path, useController } from 'react-hook-form'

type GiphyPluginProps<T extends FieldValues> = {
  name: Path<T>
  control: Control<T>
}

const GiphyPluginField = <T extends FieldValues>({ control, name }: GiphyPluginProps<T>) => {
  const t = useT()
  const { openGiphyDialog } = useGiphyDialog()

  const {
    field: { onChange },
  } = useController({ control, name })

  const handleAddGifClick = () => {
    !!openGiphyDialog && openGiphyDialog()
  }

  const handleGifSelected = (url: string) => {
    onChange?.(url)
  }

  return (
    <>
      <Tooltip id="tooltipAddGif" title={<T _str="Add GIF" />} placement="top">
        <IconButton
          id="btnAddGif"
          aria-labelledby="tooltipAddGif"
          onClick={handleAddGifClick}
          aria-label={t('Add gif')}>
          <GifBoxOutlined viewBox="4 4 16 16" />
        </IconButton>
      </Tooltip>
      <GiphySearchDialog onGifSelected={handleGifSelected} />
    </>
  )
}

export default GiphyPluginField
