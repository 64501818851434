import { API, unwrapData } from '../axios-instance'
import { PageResult } from '../shared/types'
import {
  CalendarEvent,
  CreateCelebrationCardParams,
  CreateCelebrationCardResponse,
  ListCalendarEventsEmbeds,
  ListCalendarEventsParams,
  MemberCelebrationPref,
  MemberCelebrationPrefParams,
  TenantCelebrationPref,
  TenantCelebrationPrefParams,
} from './types'

export const listTenantCelebrationPreferences = async () => {
  return await API.get<TenantCelebrationPref>('/celebration/tenantPreferences').then(unwrapData)
}

export const updateTenantCelebrationPreferences = async (params: TenantCelebrationPrefParams) => {
  return await API.put<TenantCelebrationPref>('/celebration/tenantPreferences', params).then(
    unwrapData,
  )
}

export const listMyCelebrationPreferences = async () => {
  return await API.get<MemberCelebrationPref>('/celebration/memberPreferences/my').then(unwrapData)
}

export const listMemberCelebrationPreferences = async (memberId: string) => {
  return await API.get<MemberCelebrationPref>(`/celebration/memberPreferences/${memberId}`).then(
    unwrapData,
  )
}

export const updateMyCelebrationPreferences = async (params: MemberCelebrationPrefParams) => {
  return await API.post<MemberCelebrationPref>('/celebration/memberPreferences/my', params).then(
    unwrapData,
  )
}

export const listCalendarEvents = async (params: ListCalendarEventsParams) => {
  return await API.get<CalendarEvent[]>('/celebration/calendar', { params }).then(unwrapData)
}

export const createCelebrationCard = async (params: CreateCelebrationCardParams) => {
  return await API.put<CreateCelebrationCardResponse>('/celebration/calendar/card', params).then(
    unwrapData,
  )
}

export const listUpcomingCaledarEvents = async (params: ListCalendarEventsParams) => {
  return await API.get<PageResult<CalendarEvent>>('/celebration/calendar/upcoming', {
    params,
  }).then(unwrapData)
}

export const listTvCalendarEvents = async (token: string, embeds: ListCalendarEventsEmbeds[]) => {
  return await API.get<CalendarEvent[]>(`/celebration/tv/${token}/calendar`, {
    params: { embeds },
  }).then(unwrapData)
}
