import DefaultAwardIcon from '@assets/icons/DefaultAwardIcon'
import { TypographyVariant, colors } from '@globals/themes/defaultTheme'
import { Award, MediaVariantEnum } from '@kudos/http-client'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import UserAvatar from '@shared/avatar/UserAvatar'

type AwardNameProps = {
  award: Award
  mediaVariant: MediaVariantEnum
  avatarSize: number
  nameVariant: TypographyVariant
}

const AwardName: React.FC<AwardNameProps> = ({ award, mediaVariant, avatarSize, nameVariant }) => {
  return (
    <>
      <UserAvatar
        size={avatarSize}
        media={award.badgeUrls}
        defaultIcon={<DefaultAwardIcon />}
        mediaVariant={mediaVariant}
      />
      <Paper sx={{ backgroundColor: colors.white, p: 0.5, mt: -1, zIndex: 1 }}>
        <Typography variant={nameVariant} fontWeight="semi-bold" mt={{ xs: 0, md: 0 }}>
          {award.name}
        </Typography>
      </Paper>
    </>
  )
}

export default AwardName
