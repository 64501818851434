import { envConfig } from '@globals/env'
import { tx } from '@transifex/native'
import { useLanguages } from '@transifex/react'
import { useEffect } from 'react'
import { useTenantMetadata } from './useTenantMetadata'

// Initialize translations
tx.init(envConfig.TRANSIFEX_TOKEN ? { token: envConfig.TRANSIFEX_TOKEN } : {})

type LanguageProps = {
  onLanguageChange: (locale: string) => void
}

/**
 * This component doesn't render anything but exists in order to detect when
 * language has changed and update the tx library to know about it. It will not
 * update language until available languages have been fetched and until it
 * notices that the desired language code is available.
 */
const Language: React.FC<LanguageProps> = ({ onLanguageChange }) => {
  const [{ language }] = useTenantMetadata()
  const available = useLanguages()

  useEffect(() => {
    if (available && available.length) {
      if (tx.getCurrentLocale() !== language) {
        if (available.find(({ code }: { code: string }) => code === language)) {
          tx.setCurrentLocale(language)
          onLanguageChange(language)
        }
      }
    }
  }, [language, available])

  return null
}

export default Language
