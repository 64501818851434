import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { RenderContext } from './Nav'
import { StyledListItem } from './Nav.styled'
import { GroupItem, LinkItem, NavItem } from './types'

type NavContentsProps = {
  navItems: NavItem[]
  context: RenderContext
  isMobile: boolean
}

export const NavContents: React.FC<NavContentsProps> = ({ navItems, context, isMobile }) => {
  const items = navItems
    .filter(item => !isMobile || !item.hideOnMobile)
    .map(item => renderNavItem(item, context))

  return <List>{items}</List>
}

const renderNavItem = (navItem: NavItem, context: RenderContext) => {
  if (navItem.type === 'group') {
    return renderGroup(navItem, context)
  } else {
    return renderLink(navItem, context)
  }
}

const renderGroup = ({ icon, name, children, key }: GroupItem, context: RenderContext) => {
  const isExpanded = context.expanded.includes(key)
  return (
    <Fragment key={key}>
      <StyledListItem>
        <ListItemButton key="key" onClick={context.onCollapse.bind(null, key)}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={name} />
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
      </StyledListItem>
      <Collapse component="li" in={isExpanded} timeout="auto" unmountOnExit>
        <List component="menu" disablePadding sx={{ pl: 2 }}>
          {children.map(item => renderNavItem(item, context))}
        </List>
      </Collapse>
    </Fragment>
  )
}

const renderLink = (
  { icon, path, name, key, external, isSelected }: LinkItem,
  context: RenderContext,
) => {
  return (
    <StyledListItem key={key} disablePadding>
      <ListItemButton
        component={Link}
        to={path}
        target={!!external ? '_blank' : undefined}
        selected={isSelected ?? false}
        onClick={context.onRouteChange}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={name} />
        {!!external && <OpenInNewIcon />}
      </ListItemButton>
    </StyledListItem>
  )
}
