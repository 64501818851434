import { spacing } from '@globals/themes/themeConstants'
import Search from '@mui/icons-material/Search'
import { SxProps } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import { useT } from '@transifex/react'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

type DebouncedSearchInputProps = {
  elementId: string
  value?: string
  onChange: (searchText: string) => void
  onFocus?: () => void
  onBlur?: () => void
  placeholder?: string
  isSearching?: boolean
  sx?: SxProps
  startAdornment?: ReactNode
}
const DebouncedSearchInput: FC<DebouncedSearchInputProps> = ({
  elementId,
  value = '',
  onChange,
  onFocus,
  onBlur,
  placeholder,
  isSearching = false,
  sx = {},
  startAdornment = <Search />,
}) => {
  const [inputValue, setInputValue] = useState<string>('')
  const t = useT()

  const textFieldStyle = {
    ...sx,
    width: '100%',
    '& input': {
      paddingLeft: spacing(0.5),
    },
  }

  const [deferredInputValue] = useDebounce(inputValue, 300)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(event.target.value)
  }

  useEffect(() => {
    !!onChange && onChange(deferredInputValue)
  }, [deferredInputValue])

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <TextField
      id={elementId}
      value={inputValue}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={handleChange}
      placeholder={placeholder || t('Search')}
      sx={textFieldStyle}
      InputProps={{
        startAdornment: <InputAdornment position="start">{startAdornment}</InputAdornment>,
        endAdornment: isSearching ? <CircularProgress color="inherit" size={20} /> : null,
      }}
    />
  )
}

export default DebouncedSearchInput
