import { useDialog } from '@globals/dialog/useDialogStore'
import theme, { colors } from '@globals/themes/defaultTheme'
import { spacing, tabletQuery } from '@globals/themes/themeConstants'
import Close from '@mui/icons-material/Close'
import { useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/material/styles'
import { useT } from '@transifex/react'
import BottomDrawer from './BottomDrawer'

const confirmDialogTitleStyle: SxProps = {
  borderBottomWidth: 2,
  borderBottomColor: colors.blueExtraLight,
  borderBottomStyle: 'solid',
  paddingRight: spacing(4),
}
const confirmDialogCloseButtonStyle: SxProps = {
  position: 'absolute',
  right: 8,
  top: 8,
  color: colors.grayMedium,
}
const confirmDialogActionsStyle: SxProps = {
  padding: theme.spacing(2),
}
const noMarginsStyle: SxProps = {
  margin: 0,
}

type ConfirmDialogProps = {
  dialogKey: string
  content: React.ReactNode
  cancelButtonText: React.ReactNode
  confirmButtonText: React.ReactNode
  onClose?: () => void
  onConfirm?: () => void
  onCancel?: () => void
  disableConfirm?: boolean
  title?: React.ReactNode
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = props => {
  const {
    dialogKey,
    content,
    cancelButtonText,
    confirmButtonText,
    title,
    onClose,
    onConfirm,
    onCancel,
    disableConfirm = false,
  } = props
  const isTablet = useMediaQuery(tabletQuery)
  const { closeDialog, isOpen } = useDialog(dialogKey)

  const t = useT()

  const handleCloseDialog = () => {
    if (onClose) {
      onClose()
    }
    closeDialog()
  }

  const Actions = () => (
    <DialogActions sx={confirmDialogActionsStyle}>
      <Button
        id="btn-dialog-cancel"
        data-testid="confirm-dialog-cancel-btn"
        variant="outlined"
        sx={noMarginsStyle}
        fullWidth={isTablet}
        onClick={!!onCancel ? onCancel : handleCloseDialog}>
        {cancelButtonText}
      </Button>
      <Button
        id="btn-dialog-confirm"
        data-testid="confirm-dialog-confirm-btn"
        variant="contained"
        fullWidth={isTablet}
        sx={noMarginsStyle}
        onClick={onConfirm}
        disabled={disableConfirm}>
        {confirmButtonText}
      </Button>
    </DialogActions>
  )

  if (isTablet) {
    return (
      <BottomDrawer drawerKey={dialogKey} onClose={handleCloseDialog} title={title}>
        <Typography variant="body1" component="div">
          {content}
        </Typography>
        <Actions />
      </BottomDrawer>
    )
  }

  return (
    <Dialog
      id={`dialog-confirm-${dialogKey}`}
      open={isOpen}
      scroll="paper"
      fullWidth
      onClose={handleCloseDialog}
      aria-labelledby="confirm-dialog"
      data-testid="confirm-dialog">
      <DialogTitle
        component="div"
        sx={confirmDialogTitleStyle}
        data-testid="confirm-dialog-content">
        {title && (
          <Typography variant="h2" sx={{ mt: 0, mb: 2 }}>
            {title}
          </Typography>
        )}
        <Typography variant="body1" component="div">
          {content}
        </Typography>
      </DialogTitle>
      <IconButton
        id="btn-dialog-close"
        aria-label={t('Close')}
        onClick={handleCloseDialog}
        sx={confirmDialogCloseButtonStyle}>
        <Close />
      </IconButton>
      <Actions />
    </Dialog>
  )
}
