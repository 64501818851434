import { colors } from '@globals/themes/defaultTheme'
import { setSvgIconSize } from '@globals/themes/globalStyles'
import { MediaObjectUrl } from '@kudos/http-client'
import AttachFileRounded from '@mui/icons-material/AttachFileRounded'
import Button from '@mui/material/Button'
import MuiLink from '@mui/material/Link'
import Typography from '@mui/material/Typography'

const buttonStyle = {
  ...setSvgIconSize('1.5rem'),
  mt: { xs: 0, sm: 0 },
  minHeight: 40,
  pl: 2,
  '&:hover': {
    backgroundColor: colors.blueExtraLight,
  },
}

type DocumentAttachmentProps = {
  mediaObject: MediaObjectUrl
}

const DocumentAttachment: React.FC<DocumentAttachmentProps> = ({ mediaObject }) => {
  return (
    <Button
      component={MuiLink}
      href={mediaObject.url}
      sx={buttonStyle}
      rel="noopener noreferrer"
      id={`btnDocumentAttachment-${mediaObject.originalFilename}`}
      variant="outlined"
      startIcon={<AttachFileRounded sx={{ color: `${colors.bluePrimary} !important` }} />}>
      <Typography
        sx={{
          color: colors.grayDark,
        }}>
        {mediaObject.originalFilename}
      </Typography>
    </Button>
  )
}

export default DocumentAttachment
