import { colors } from '@globals/themes/defaultTheme'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Tooltip from '@mui/material/Tooltip'
import { FC, ReactNode, useState } from 'react'

type InfoTooltipProps = {
  title: ReactNode
  color?: string
}

const InfoTooltip: FC<InfoTooltipProps> = ({ title, color = colors.grayPrimary }) => {
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box>
        <Tooltip
          arrow
          onClose={handleTooltipClose}
          open={open}
          title={title}
          slotProps={{
            popper: {
              disablePortal: true,
            },
          }}>
          <InfoOutlinedIcon sx={{ color }} onClick={handleTooltipOpen} />
        </Tooltip>
      </Box>
    </ClickAwayListener>
  )
}

export default InfoTooltip
