import { API, unwrapData } from '../axios-instance'
import { SetMediaObjectParams } from '../media'
import { createBatchRoutes } from '../shared/batch'
import { PageResult } from '../shared/types'
import {
  BatchAddToTeamsSearchParams,
  CreateTenantMemberParams,
  EditLocaleParams,
  GetTenantMemberParams,
  ListTenantMembersParams,
  Locale,
  TenantMember,
  TenantMemberAvatar,
  TenantMembersCount,
  TenantMembersCountParams,
  UpdateTenantMemberParams,
  BatchSuspendTenantMembersParams,
} from './types'

export const listTenantMembers = async (params: ListTenantMembersParams) => {
  return await API.get<PageResult<TenantMember>>('/identity/tenantMembers', {
    params,
  }).then(unwrapData)
}

export const editTenantMemberLocale = async (params: EditLocaleParams) => {
  return await API.post<Locale>('/identity/tenantMembers/locale', params).then(unwrapData)
}

export const getTenantMemberLocale = async () => {
  return await API.get<Locale>('/identity/tenantMembers/locale').then(unwrapData)
}

export const getTenantMember = async (id: string, params: GetTenantMemberParams) => {
  return await API.get<TenantMember>(`/identity/tenantMembers/${id}`, { params }).then(unwrapData)
}

/**
 * Note: This only sets a browser-detected default value, do not use this to update
 * a member's preference.
 * */
export const updateTenantMemberDefaultTimezone = async (params: { timezone: string }) => {
  return await API.patch<Locale>('/identity/tenantMembers/timezone', params).then(unwrapData)
}

export const updateTenantMember = async (id: string, params: UpdateTenantMemberParams) => {
  return await API.patch<TenantMember>(`/identity/tenantMembers/${id}`, params).then(unwrapData)
}

export const updateTenantMemberRole = async (id: string, params: UpdateTenantMemberParams) => {
  return await API.post<TenantMember>(`/identity/tenantMembers/${id}/role`, params).then(unwrapData)
}

export const createTenantMember = async (params: CreateTenantMemberParams) => {
  return await API.post<TenantMember>('/identity/tenantMembers', params).then(unwrapData)
}

export const suspendTenantMember = async (id: string) => {
  return await API.post<PageResult<TenantMember>>(`/identity/tenantMembers/${id}/suspend`).then(
    unwrapData,
  )
}

export const batchSuspendTenantMembers = createBatchRoutes<
  BatchSuspendTenantMembersParams,
  TenantMember,
  unknown,
  unknown
>('/identity/tenantMembers/batchSuspend')

export const unSuspendTenantMember = async (id: string) => {
  return await API.post<PageResult<TenantMember>>(`/identity/tenantMembers/${id}/unsuspend`).then(
    unwrapData,
  )
}

export const batchAddMembersToTeams = createBatchRoutes<
  BatchAddToTeamsSearchParams,
  TenantMember,
  unknown,
  unknown
>('/identity/tenantMembers/batchAddToTeams')

export const setMemberAvatar = async (id: string, params: SetMediaObjectParams) => {
  return await API.post<TenantMemberAvatar>(`/identity/tenantMembers/${id}/avatar`, params).then(
    unwrapData,
  )
}

export const getTenantMembersCount = async (params?: TenantMembersCountParams) => {
  // NOTE: POST because teamIdIn could exceed the max URL size limit
  return await API.post<TenantMembersCount>('/identity/tenantMembers/count', params).then(
    unwrapData,
  )
}

export const listTenantManagers = async (params: ListTenantMembersParams) => {
  return await API.get<PageResult<TenantMember>>('/identity/tenantMembers/managers', {
    params,
  }).then(unwrapData)
}
