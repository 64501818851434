import { colors } from '@globals/themes/defaultTheme'
import { RecognitionRecipients, TenantMemberDisplayData } from '@kudos/http-client'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/material/styles'
import TeamAvatar from '@shared/avatar/TeamAvatar'
import UserAvatar, { AvatarFontUnit, AvatarSize } from '@shared/avatar/UserAvatar'
import { isTenantMemberDisplayData } from '@shared/avatar/useAvatarConfig'

export const nameContainerStyle: SxProps = {
  maxWidth: 300,
  whiteSpace: 'nowrap',
}

export const nameTextStyle: SxProps = {
  fontSize: '2.25rem',
  marginTop: '0 !important',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

type AvatarAndNameDisplayProps = {
  displayee: RecognitionRecipients
  avatarSize: AvatarSize
  textColor?: string
  SingleRecipientAvatar?: React.FC<{
    recipientDisplayData?: TenantMemberDisplayData
    avatarSize?: AvatarSize
    fontUnit?: AvatarFontUnit
  }>
}

const AvatarAndNameDisplay: React.FC<AvatarAndNameDisplayProps> = ({
  displayee,
  avatarSize,
  textColor = colors.white,
  SingleRecipientAvatar,
}) => {
  const { displayData } = displayee
  let firstName = ''
  let lastName = ''

  if (!displayData) {
    firstName = displayee.name
  } else {
    firstName = 'firstName' in displayData ? displayData.firstName : displayData.name
    lastName = 'lastName' in displayData ? displayData.lastName : ''
  }

  const avatar = () => {
    if (SingleRecipientAvatar) {
      return (
        <SingleRecipientAvatar
          recipientDisplayData={displayData as TenantMemberDisplayData}
          fontUnit="vh"
          avatarSize={avatarSize}
        />
      )
    }
    if (isTenantMemberDisplayData(displayData)) {
      return (
        <UserAvatar firstName={firstName} lastName={lastName} size={avatarSize} fontUnit="vh" />
      )
    }
    return <TeamAvatar size={avatarSize} fontUnit="vh" />
  }

  return (
    <>
      {avatar()}
      <Box sx={nameContainerStyle}>
        <Typography
          align="center"
          sx={{ ...nameTextStyle, color: `${textColor} !important` }}
          pt={2}>
          {firstName}
        </Typography>
        {lastName && (
          <Typography align="center" sx={{ ...nameTextStyle, color: `${textColor} !important` }}>
            {lastName}
          </Typography>
        )}
      </Box>
    </>
  )
}

export default AvatarAndNameDisplay
