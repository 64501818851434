import { useContext } from 'react'
import { StandardPageLayoutContext } from '.'

export const useStandardPageLayout = () => {
  const context = useContext(StandardPageLayoutContext)
  if (context === undefined) {
    throw new Error('useStandardPageLayout must be used within StandardPageLayout')
  }
  return context
}
