import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import StandardPageLayout from '@layouts/standardPageLayout'
import RouteLoading from '@shared/routing/RouteLoading'
import { T } from '@transifex/react'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const ValuesAndQualitiesRoute = lazyWithRetries(() => import('./index'))
const ConfigureValuesRoute = lazyWithRetries(() => import('./valueAndQualitiesConfiguration'))

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.admin.values(),
    element: (
      <StandardPageLayout
        title={<T _str="Values & qualities" />}
        elementId="valuesAndQualitiesRoute"
        maxWidth={900}>
        <Suspense fallback={<RouteLoading />}>
          <ValuesAndQualitiesRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.configureValues(),
    element: (
      <StandardPageLayout
        title={<T _str="Values & qualities configuration" />}
        elementId="configureValuesRoute"
        maxWidth={600}>
        <Suspense fallback={<RouteLoading />}>
          <ConfigureValuesRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.editValue(':valueId'),
    element: (
      <StandardPageLayout
        title={<T _str="Values & qualities configuration" />}
        elementId="editValueRoute"
        maxWidth={600}>
        <Suspense fallback={<RouteLoading />}>
          <ConfigureValuesRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
]

export default routes
