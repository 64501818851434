import { RouteObject } from 'react-router-dom'
import brandingRoutes from './branding/routes'
import languageAndTimeRoutes from './languageAndTime/routes'
import notificationRoutes from './notifications/routes'
import valuesAndQualitiesRoutes from './valuesAndQualities/routes'

const routes: RouteObject[] = [
  ...languageAndTimeRoutes,
  ...notificationRoutes,
  ...brandingRoutes,
  ...valuesAndQualitiesRoutes,
]

export default routes
