import { zodResolver } from '@hookform/resolvers/zod'
import { MediaObjectUrl, PointsExceptionError, Recognition, SendTo } from '@kudos/http-client'
import { Selectable } from '@shared/filters/selections'
import { Attachment } from '@shared/hooks/useFileMediaUpload'
import stripHtml from '@shared/utils/stripHtml'
import { MAX_ATTACHMENTS, MAX_MESSAGE_CHARS } from '@shared/wallPost/constants'
import useInProgressRecognitions from '@tenant/recognition/RecognitionModal/DraftAndSchduledView/useInProgressRecognitions'
import useRecognitionModal from '@tenant/recognition/RecognitionModal/useRecognitionModal'
import { useConfigureRecognitionTabs } from '@tenant/recognition/useConfigureRecognitionTabs'
import { t } from '@transifex/native'
import { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'
import useSendRecognitionMutations from './useSendRecognitionMutations'

export const ISO_TIMESTAMP_REGEX = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/
export const MAX_POINTS_PER_RECIPIENT = 1000

const RecognitionFormSchema = z.object({
  sendTo: z.array(z.custom<SendTo>()).min(1, t('Select a recipient')),
  message: z
    .string()
    .max(MAX_MESSAGE_CHARS, '') // uses the error message from MentionsTextareaField
    .refine((value: string) => stripHtml(value).length > 0, t('Provide a recognition message')),
  qualities: z.array(z.custom<Selectable>()),
  attachments: z
    .array(z.custom<Attachment & { attachmentSrc?: MediaObjectUrl; fileName?: string }>())
    .max(MAX_ATTACHMENTS),
  amount: z
    .number({ message: 'Points amount required' })
    .int()
    .min(0)
    .max(MAX_POINTS_PER_RECIPIENT, {
      message: t('Enter a point amount that is {maxPerRecipient} or less', {
        maxPerRecipient: MAX_POINTS_PER_RECIPIENT,
      }),
    }),
  gif: z.string().url().optional(),
  scheduledAt: z
    .string()
    .nullable()
    .refine(value => value === null || ISO_TIMESTAMP_REGEX.test(value), {
      message: t('Invalid date string'),
    })
    .refine(value => value === null || new Date(value) > new Date(), {
      message: t('Start date must be in the future'),
    }),
})

export type RecognitionFormData = z.infer<typeof RecognitionFormSchema>

const useSendRecognitionForm = (
  setShowAnimation: (showAnimation: boolean) => void,
  qualitiesRequired: boolean,
  recognition?: Recognition,
) => {
  const isEditMode = !!recognition

  const { closeDialog } = useRecognitionModal()

  /** here lies all state management */
  const [pointsExceptions, setPointsExceptions] = useState<PointsExceptionError>()
  const [isFormClear, setIsFormClear] = useState<boolean>(false)

  /** actual form stuff! */
  const defaults = {
    sendTo:
      recognition?.sendTo.map(
        recognitionSendTo =>
          ({
            ...recognitionSendTo.displayData,
            name: recognitionSendTo.name,
            type: recognitionSendTo.type,
          }) as SendTo,
      ) || [],
    message: recognition?.message || '',
    qualities: recognition?.qualities || [],
    attachments:
      recognition?.attachments.map((attachment, index) => ({
        mediaObjectId: attachment,
        attachmentSrc: recognition?.attachmentUrls[index],
        fileName: recognition?.attachmentUrls[index].originalFilename,
      })) || [],
    amount: recognition?.amount || 0,
    gif: recognition?.gifs[0] || undefined,
    scheduledAt: recognition?.scheduledAt || null,
  }

  const clearForm = () => {
    reset({
      sendTo: [],
      message: '',
      qualities: [],
      attachments: [],
      amount: 0,
      gif: undefined,
      scheduledAt: null,
    })
    setIsFormClear(true)
  }

  const form = useForm<RecognitionFormData>({
    resolver: zodResolver(RecognitionFormSchema),
    defaultValues: defaults,
  })
  const { formState, setError, control, reset, getValues } = form
  const { isSubmitSuccessful } = formState

  /** watch for form */
  const sendTo = useWatch({ control, name: 'sendTo' })
  const message = useWatch({ control, name: 'message' })

  const isFormDirty = formState.isDirty && (sendTo.length > 0 || stripHtml(message).length > 0)

  useEffect(() => {
    if (isSubmitSuccessful && !isEditMode && !formState.isDirty) {
      setPointsExceptions(undefined)
      clearForm()
    }
  }, [isSubmitSuccessful, isEditMode, formState.isDirty])

  useEffect(() => {
    if (isFormClear && !isFormDirty) {
      closeDialog()
      setIsFormClear(false)
    }
  }, [isFormClear, isFormDirty])

  useEffect(() => {
    reset(defaults)
  }, [recognition])

  const { publishRecognition, saveDraftRecognition } = useSendRecognitionMutations(
    setShowAnimation,
    setPointsExceptions,
    setError,
    qualitiesRequired,
    recognition?.id,
  )

  const { refetchDraftRecognitions } = useInProgressRecognitions()
  const { refetchDraftRecogitionCount } = useConfigureRecognitionTabs()

  /**
   * This function handles saveDraftRecognotion() without navBlocker().
   */
  const handleSaveAsDraft = () => {
    const values = getValues()
    saveDraftRecognition(values)
    reset(values) // resets form state with new values
  }

  const handleDiscardChanges = () => {
    reset(defaults)
  }

  /**
   * This function handles the async saveDraftRecognition() when
   * navBlocker() is activated.
   */
  const handleKeepChanges = async () => {
    const values = getValues()
    await saveDraftRecognition(values)
    reset(values)
    /**
     * navBlocker() also blocks the onSuccess callback of saveDraftRecognition().
     * Refetching data is required to ensure consistency if/when the user
     * decides to keep the changes.
     */
    refetchDraftRecognitions()
    refetchDraftRecogitionCount()
  }

  const handleResetRecognitionForm = () => {
    clearForm()
  }

  return {
    form,
    isEditMode,
    isFormDirty,
    pointsExceptions,
    setPointsExceptions,
    publishRecognition,
    handleSaveAsDraft,
    handleDiscardChanges,
    handleKeepChanges,
    handleResetRecognitionForm,
  }
}

export default useSendRecognitionForm
