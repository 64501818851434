import AnniversaryBgImage from '@assets/home/AnniversaryBgImage.png'
import BirthdayBgImage from '@assets/home/BirthdayBgImageFull.png'
import OnboardingBgImage from '@assets/home/OnboardingBgImage.png'
import { CalendarEvent, CalendarEventTypeEnum } from '@kudos/http-client'
import CelebrationGreeting from '@shared/celebrations/CelebrationGreeting'
import TvCelebrationWrapper from './TvCelebrationWrapper'

type TvCelebrationProps = {
  celebration: CalendarEvent
}

const TvCelebration: React.FC<TvCelebrationProps> = ({ celebration }) => {
  const backgroundUrls: Record<CalendarEventTypeEnum, string> = {
    [CalendarEventTypeEnum.BIRTHDAY]: BirthdayBgImage,
    [CalendarEventTypeEnum.ANNIVERSARY]: AnniversaryBgImage,
    [CalendarEventTypeEnum.ONBOARDING]: AnniversaryBgImage,
    [CalendarEventTypeEnum.WELCOME]: OnboardingBgImage,
  }

  return (
    <TvCelebrationWrapper
      backgroundUrl={backgroundUrls[celebration.type]}
      memberDisplayData={celebration.memberDisplayData}
      greeting={
        <CelebrationGreeting
          memberDisplayData={celebration.memberDisplayData}
          celebrationType={celebration.type}
        />
      }
      date={celebration.celebratedOn}
    />
  )
}

export default TvCelebration
