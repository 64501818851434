import { spacing, tabletQuery } from '@globals/themes/themeConstants'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const StyledMediaAttachment = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  '& img': {
    display: 'flex',
    borderRadius: spacing(1),
    maxHeight: 192,
    maxWidth: 192,
    [tabletQuery]: {
      maxHeight: 152,
      maxWidth: 152,
    },
  },
}))

export const StyledMediaContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  minWidth: '100%',
  flexWrap: 'nowrap',
  mx: -2,
  pr: 2,
  gap: spacing(1),
  WebkitOverflowScrolling: 'touch',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': { display: 'none' },
  scrollSnapType: 'x mandatory',
  scrollPaddingInlineStart: theme.spacing(2),
}))
