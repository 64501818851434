import Animations, { AnimationVariant, AnimationVariants } from '@assets/lottie'
import { Player } from '@lottiefiles/react-lottie-player'
import { FC, useEffect, useRef, useState } from 'react'

const DEFAULT_ANIMATION_DURATION = 1000 * 3

type AnimationProps = {
  isVisible: boolean
  variant?: AnimationVariant
  duration?: number
  onClose?: () => void
}
const Animation: FC<AnimationProps> = ({
  variant,
  isVisible,
  onClose,
  duration = DEFAULT_ANIMATION_DURATION,
}) => {
  const refTimeout = useRef<number | null>(null)

  const [animationVariant, setAnimationVariant] = useState<AnimationVariant>('confetti')

  const handleClose: React.AnimationEventHandler = _event => {
    !!onClose && onClose()
  }

  useEffect(() => {
    if (isVisible) {
      // randomize new variant if not specified
      if (!!variant) {
        setAnimationVariant(variant)
      } else {
        setRandomizedVariant()
      }

      // set a timeout for how long we want to display this for
      if (refTimeout.current) {
        clearTimeout(refTimeout.current)
      }
      refTimeout.current = setTimeout(handleClose, duration)

      return () => {
        if (!!refTimeout.current) {
          clearTimeout(refTimeout.current)
        }
      }
    }
  }, [isVisible])

  const setRandomizedVariant = () => {
    const randomVariantIndex = Math.floor(Math.random() * AnimationVariants.length)
    setAnimationVariant(AnimationVariants[randomVariantIndex])
  }

  return <Player autoplay={isVisible} loop src={Animations[animationVariant]} />
}

export default Animation
