import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import appRoutes from './appRoutes'

const RouteRenderer = () => {
  const appBrowserRouter = createBrowserRouter(appRoutes)

  return <RouterProvider router={appBrowserRouter} />
}

export default RouteRenderer
