import { useDialog } from '@globals/dialog/useDialogStore'
import { colors } from '@globals/themes/defaultTheme'
import { tabletQuery } from '@globals/themes/themeConstants'
import { useStandardPageLayout } from '@layouts/standardPageLayout/useStandardPageLayout'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { t } from '@transifex/native'
import { ReactNode } from 'react'
import BottomDrawer from './BottomDrawer'

export const DRAWER_WIDTH = 375

const drawerStyle: SxProps = {
  '& .MuiDrawer-paper ': {
    maxHeight: '90%',
  },
}

const titleStyle: SxProps = {
  mt: 0,
  [tabletQuery]: {
    mt: 0,
  },
}

const paperStyle: SxProps = {
  width: DRAWER_WIDTH,
  borderRadius: 0,
  marginTop: 8,
  backgroundColor: colors.whiteDark,
}

type RightDrawerProps = {
  dialogKey: string
  title: ReactNode
  hasActions?: boolean
  children: ReactNode
}

const DialogCloseButton = ({ onClose }: { onClose: () => void }) => {
  return (
    <IconButton aria-label={t('Close dialog button')} id="rightDrawer-closeBtn" onClick={onClose}>
      <Close />
    </IconButton>
  )
}

const RightDrawer: React.FC<RightDrawerProps> = ({
  dialogKey,
  title,
  hasActions = false,
  children,
}) => {
  const { appBarHeight } = useStandardPageLayout()
  const isTablet = useMediaQuery(tabletQuery)
  const { closeDialog, isOpen } = useDialog(dialogKey)

  if (isTablet)
    return (
      <BottomDrawer
        drawerKey={dialogKey}
        title={title}
        onClose={closeDialog}
        drawerStyles={drawerStyle}
        hasActions={hasActions}>
        {children}
      </BottomDrawer>
    )

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={isOpen}
      onClose={closeDialog}
      closeAfterTransition
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{ sx: { ...paperStyle, mt: `${appBarHeight || 0}px` } }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        p={2}
        sx={{ backgroundColor: colors.white }}>
        <Typography variant="h3" sx={titleStyle}>
          {title}
        </Typography>
        {!hasActions && <DialogCloseButton onClose={closeDialog} />}
      </Stack>
      <Divider />
      <Box p={2} mb={hasActions ? 28 : 2}>
        {children}
      </Box>
    </Drawer>
  )
}

export default RightDrawer
