import { colors } from '@globals/themes/defaultTheme'
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded'
import Button from '@mui/material/Button'
import useRecognitionModal, {
  RecognitionModalViews,
} from '@tenant/recognition/RecognitionModal/useRecognitionModal'

import { T } from '@transifex/react'

const iconStyle = {
  width: '2rem',
  height: '2rem',
  color: `${colors.goldPrimary} !important`,
}

const RecognizeSomeoneBtn: React.FC = () => {
  const { openDialog } = useRecognitionModal()
  return (
    <Button
      id="btnRecognizeSomeone"
      variant="outlined"
      size="large"
      sx={{ height: '100%' }}
      startIcon={<StarBorderRoundedIcon fontSize="inherit" sx={iconStyle} />}
      onClick={() => {
        openDialog({
          view: RecognitionModalViews.SendRecognition,
        })
      }}>
      <T _str="Recognize someone" />
    </Button>
  )
}

export default RecognizeSomeoneBtn
