import { useState, useEffect } from 'react'
import Fade from '@mui/material/Fade'
import AvatarAndNameDisplay from './AvatarAndNameDisplay'
import { ReactElement } from 'react'

const FADE_TIMEOUT = 1000

type FadeUserDisplayProps = {
  children: ReactElement<typeof AvatarAndNameDisplay>
  rotationInterval: number
}

const FadeUserDisplay: React.FC<FadeUserDisplayProps> = ({ children, rotationInterval }) => {
  const [fade, setFade] = useState(false)

  useEffect(() => {
    setFade(true)
    const timer = setTimeout(() => {
      setFade(false)
    }, rotationInterval - FADE_TIMEOUT)
    return () => clearTimeout(timer)
  }, [])

  return (
    <Fade in={fade} timeout={FADE_TIMEOUT}>
      <div>{children}</div>
    </Fade>
  )
}

export default FadeUserDisplay
