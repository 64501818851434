import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import ConstrainedWidthLayout from '@layouts/ConstrainedWidthLayout'
import RootLayoutWrapper from '@layouts/RootLayoutWrapper'
import StandardPageLayout from '@layouts/standardPageLayout'
import RouteLoading from '@shared/routing/RouteLoading'
import { T } from '@transifex/react'
import { Suspense, lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const PointsRoute = lazyWithRetries(() => import('.'))
const PeerToPeerRoute = lazyWithRetries(
  () => import('./pointsSetup/allocation/peerToPeer/PeerToPeer'),
)
const CelebrationRoute = lazyWithRetries(
  () => import('./pointsSetup/allocation/celeberation/Celebration'),
)
const PointBanksRoute = lazyWithRetries(
  () => import('./pointsSetup/allocation/pointBanks/PointBanks'),
)

const IncentivePointsRoute = lazyWithRetries(() => import('./pointsSetup/incentivePoints'))
const IndividualPointsReportingRoute = lazy(
  () => import('./pointsReporting/IndividualPointsReporting'),
)

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.admin.individualPointsReporting(':memberId'),
    element: (
      <StandardPageLayout elementId="individualPointsReportingRoute" maxWidth={1080}>
        <Suspense fallback={<RouteLoading />}>
          <IndividualPointsReportingRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.points(':tab?'),
    element: (
      <StandardPageLayout elementId="pointsRoute" maxWidth={1080} title={<T _str="Points" />}>
        <Suspense fallback={<RouteLoading />}>
          <PointsRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.pointsRules('peer'),
    element: (
      <StandardPageLayout
        elementId="peerToPeerRoute"
        maxWidth={1080}
        title={<T _str="Peer-to-peer points" />}>
        <Suspense fallback={<RouteLoading />}>
          <PeerToPeerRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.pointsRules('celebration'),
    element: (
      <StandardPageLayout
        elementId="celebrationRoute"
        maxWidth={1080}
        title={<T _str="Celebration points" />}>
        <Suspense fallback={<RouteLoading />}>
          <CelebrationRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.pointsRules('bank'),
    element: (
      <StandardPageLayout
        elementId="pointBanksRoute"
        maxWidth={1080}
        title={<T _str="Point banks" />}>
        <Suspense fallback={<RouteLoading />}>
          <PointBanksRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },

  {
    path: AppPaths.tenant.admin.incentivePoints(),
    element: (
      <RootLayoutWrapper>
        <ConstrainedWidthLayout>
          <Suspense fallback={<RouteLoading />}>
            <IncentivePointsRoute />
          </Suspense>
        </ConstrainedWidthLayout>
      </RootLayoutWrapper>
    ),
  },
]

export default routes
