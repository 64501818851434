import { spacing } from '@globals/themes/themeConstants'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import { SxProps } from '@mui/material/styles'
import { Fragment } from 'react'

const PAGE_LOADING_ROWS = 6
const PAGE_LOADING_PARAGRAPHS = 2

const skeletonStyle: SxProps = {
  mb: 2,
  mt: 5,
  width: '20vw',
  height: spacing(5),
}

const PageLoading: React.FC = () => (
  <Box height="50dvh">
    {Array.from(new Array(PAGE_LOADING_PARAGRAPHS)).map((_, paraIndex) => (
      <Fragment key={`pageLoadingParagraph-${paraIndex}`}>
        <Skeleton animation="wave" variant="rounded" sx={skeletonStyle} />
        {Array.from(new Array(PAGE_LOADING_ROWS)).map((_, rowIndex) => (
          <Skeleton
            key={`pageLoadingRow-${rowIndex}`}
            animation="wave"
            variant="rounded"
            sx={{ mb: 1, width: rowIndex === PAGE_LOADING_ROWS - 1 ? '50%' : '100%' }}
            height={18}
          />
        ))}
      </Fragment>
    ))}
  </Box>
)

export default PageLoading
