import AppPaths from '@globals/paths'
import { colors } from '@globals/themes/defaultTheme'
import { spacing } from '@globals/themes/themeConstants'
import { Announcement } from '@kudos/http-client'
import Typography from '@mui/material/Typography'
import AnnouncementCardWrapper from '@shared/announcements/AnnouncementCardWrapper'
import { T } from '@transifex/react'
import ScanToQRCode from '../ScanToQRCode'
import ScrollingContent from '../ScrollingContent'

const cardStyle = {
  height: '84vh',
  maxWidth: 'fit-content',
  backgroundColor: colors.grayDark,
  boxShadow: 'none',
}

type TvAnnouncmentProps = {
  announcement: Announcement
}

const TvAnnouncement: React.FC<TvAnnouncmentProps> = ({ announcement }) => {
  return (
    <AnnouncementCardWrapper styles={cardStyle}>
      <ScrollingContent
        header={
          <Typography variant="h2" mt={{ xs: 0, sm: 0 }} textAlign="center">
            {announcement.title}
          </Typography>
        }
        htmlContent={announcement.body}
        maxHeight="66vh"
        contentSx={{ color: colors.grayDark, fontSize: '1.5rem' }}
        attachmentUrls={announcement.attachmentUrls}
      />
      <ScanToQRCode
        text={<T _str="Scan to view post" />}
        path={AppPaths.tenant.admin.announcements(announcement.id)}
        textSx={{ backgroundColor: colors.white, borderRadius: spacing(2), p: 1 }}
      />
    </AnnouncementCardWrapper>
  )
}

export default TvAnnouncement
