import EngagementIcon from '@assets/icons/EngagementIcon'
import { colors } from '@globals/themes/defaultTheme'
import { tabletQuery } from '@globals/themes/themeConstants'
import { PointsExceptionError } from '@kudos/http-client'
import { useMediaQuery } from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { PointsEstimateInfoBox } from '@shared/PointsEstimateInfoBox'
import FormActionTray from '@shared/forms/FormActionTray'
import Submit from '@shared/forms/Submit'
import { t } from '@transifex/native'
import { T } from '@transifex/react'
import { useFormContext } from 'react-hook-form'
import { RecognitionFormData } from '../useSendRecognitionForm'
import PointsException from './PointsException'

type FormSubmissionExceptionsProps = {
  pointsExceptions?: PointsExceptionError
}

const FormSubmissionExceptions: React.FC<FormSubmissionExceptionsProps> = ({
  pointsExceptions,
}) => {
  const { formState } = useFormContext<RecognitionFormData>()
  const isTablet = useMediaQuery(tabletQuery)
  const isSendHidden = pointsExceptions?.details.exceptions.some(
    exception => exception.level === 'error',
  )
  const amount = pointsExceptions?.details.amount || 0
  const balance = pointsExceptions?.details.balance || 0
  return (
    <>
      <Stack spacing={isTablet ? 2 : 3}>
        {pointsExceptions?.details.exceptions.map(exception => (
          <PointsException
            key={exception.type}
            type={exception.type}
            affected={exception.affected}
          />
        ))}
        <PointsEstimateInfoBox>
          <Stack direction="row" useFlexGap gap={1}>
            <EngagementIcon fill={colors.bluePrimary} />
            <Stack direction="column">
              <Typography>
                <T
                  _str="{amount} {points}"
                  amount={<b>{amount}</b>}
                  points={
                    <b>
                      <T _str="{amount, plural, one {point} other {points}} used" />
                    </b>
                  }
                />
              </Typography>
              <Typography>
                <T
                  _str="Remaining points after sending = {balance}"
                  balance={(balance - amount).toLocaleString()}
                />
              </Typography>
            </Stack>
          </Stack>
        </PointsEstimateInfoBox>
      </Stack>
      <FormActionTray>
        {!isSendHidden && (
          <Submit
            elementId="btnSendRecognition"
            submitText={t('Continue sending')}
            submittingText={t('Sending recognition...')}
            formState={formState}
            fullWidth={isTablet}
          />
        )}
      </FormActionTray>
    </>
  )
}

export default FormSubmissionExceptions
