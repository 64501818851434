import { tabletQuery } from '@globals/themes/themeConstants'
import { Box, SxProps } from '@mui/material'

/**
 * Temporary layout for when we are on a page that doesn't have
 * an app bar (this is currently only an edge case for login pages)
 */

const noAppBarLayoutStyle: SxProps = {
  margin: 'auto',
  overflow: 'visible',
  [tabletQuery]: {
    mx: 0,
    mt: -6, // offsets the app bar margins that are set at the app root level
  },
}

// TODO: replace this with a better layout system that won't require this much math
type NoAppBarLayoutProps = {
  maxWidth?: number
  children?: React.ReactNode
}
const NoAppBarLayout: React.FC<NoAppBarLayoutProps> = ({ children, maxWidth }) => (
  <Box sx={noAppBarLayoutStyle} maxWidth={maxWidth}>
    {children}
  </Box>
)

export default NoAppBarLayout
