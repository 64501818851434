import { colors } from '@globals/themes/defaultTheme'
import { tabletQuery } from '@globals/themes/themeConstants'
import CloseIcon from '@mui/icons-material/Close'
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded'
import { SxProps } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { T, useT } from '@transifex/react'
import useRecognitionModal from './useRecognitionModal'

const starIconSyles: SxProps = {
  color: colors.blueDark,
  opacity: 0.25,
  width: 110,
  height: 110,
  position: 'absolute',
  right: 30,
  top: -15,
  transform: 'rotate(-20deg)',
  pointerEvents: 'none',
}

const RecognitionModalHeader = () => {
  const { closeDialog } = useRecognitionModal()
  const t = useT()
  return (
    <Stack
      flexShrink={0}
      height={84}
      pl={3}
      pr={2}
      sx={{
        background: colors.blueCardGradient,
      }}>
      <Stack direction="row" height="100%" justifyContent="space-between" alignItems="center">
        <Typography
          variant="h2"
          sx={{
            mt: 0,
            py: 3,
            [tabletQuery]: {
              mt: 0,
            },
          }}
          color={colors.white}>
          <T _str="Recognize someone" />
        </Typography>
        <IconButton
          id="closeModalBtn"
          aria-label={t('Close button')}
          sx={{
            background: 'transparent',
            mb: 3,
            '&.MuiIconButton-root': {
              backgroundColor: 'transparent !important',
            },
          }}
          onClick={() => closeDialog()}>
          <CloseIcon
            sx={{
              color: `${colors.white} !important`,
            }}
          />
        </IconButton>
      </Stack>
      <StarBorderRoundedIcon sx={starIconSyles} />
    </Stack>
  )
}

export default RecognitionModalHeader
