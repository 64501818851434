import { Stack } from '@mui/material'
import ViewMoreButton from '@shared/pagination/ViewMoreButton'
import { T } from '@transifex/react'
import { useEffect } from 'react'
import BackModalHeader from '../BackModalHeader'
import RecognitionModalViewLayout from '../RecognitionModalViewLayout'
import useRecognitionModal, { RecognitionModalViews } from '../useRecognitionModal'
import InProgressCard from './InProgressCard/InProgressCard'
import useInProgressRecognitions from './useInProgressRecognitions'

const DraftAndSchduledView: React.FC = () => {
  const {
    fetchMoreRecognitions,
    hasMoreRecognitions,
    isFetchingNextPage,
    isLoading,
    isRefetching,
    recognitionsData,
  } = useInProgressRecognitions()

  const { setView } = useRecognitionModal()

  useEffect(() => {
    if (recognitionsData.length === 0 && !isLoading && !isRefetching) {
      setView({
        view: RecognitionModalViews.SendRecognition,
      })
    }
  }, [recognitionsData.length, isLoading])

  return (
    <>
      <BackModalHeader
        onBackClicked={() =>
          setView({
            view: RecognitionModalViews.SendRecognition,
          })
        }
        title={<T _str="In progress" />}
      />
      <RecognitionModalViewLayout>
        <Stack useFlexGap gap={2}>
          {recognitionsData.map(recognition => (
            <InProgressCard key={recognition.id} recognition={recognition} />
          ))}
          {hasMoreRecognitions && !isFetchingNextPage && (
            <ViewMoreButton
              buttonText={<T _str="View more recognitions" />}
              isFetchingNextPage={isFetchingNextPage}
              fetchMore={fetchMoreRecognitions}
              btnVariant="outlined"
            />
          )}
        </Stack>
      </RecognitionModalViewLayout>
    </>
  )
}

export default DraftAndSchduledView
