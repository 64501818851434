import { SxProps } from '@mui/material/styles'
import { PropsWithChildren, useEffect } from 'react'
import { useRouteUpdate } from './RouteUpdateContext'

type RootLayoutWrapperProps = PropsWithChildren & {
  styles?: SxProps
}

const RootLayoutWrapper: React.FC<RootLayoutWrapperProps> = ({ children, styles }) => {
  const { setRouteStyles } = useRouteUpdate()

  useEffect(() => {
    setRouteStyles({
      ...styles,
    })

    return () => {
      setRouteStyles(undefined)
    }
  }, [styles, setRouteStyles])

  return children
}

export default RootLayoutWrapper
