import { RouteObject } from 'react-router-dom'
import adminToolsRoutes from './adminTools/routes'
import engagementRoutes from './engagement/routes'
import logsRoutes from './logs/routes'
import platformSetupRoutes from './platformSetup/routes'
import pointsAndRewardsRoutes from './pointsAndRewards/routes'
import reportsRoutes from './reports/routes'
import userManagementRoutes from './userManagement/routes'

const routes: RouteObject[] = [
  ...adminToolsRoutes,
  ...engagementRoutes,
  ...userManagementRoutes,
  ...pointsAndRewardsRoutes,
  ...logsRoutes,
  ...reportsRoutes,
  ...platformSetupRoutes,
]

export default routes
