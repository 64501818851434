import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

type SetStoreState<T> = (
  slice: T | Partial<T> | ((state: T) => T | Partial<T>),
  replace?: boolean,
  actionName?: string,
) => void

/**
 * A utility function to create a Zustand store with Redux DevTools support.
 * @param creatorFn - Returns the store initial state,  receives a 'set' fn for updating the store.
 * @param storeName - Optional string to name the store, which will be displayed in Redux DevTools.
 *
 * @returns A Zustand store with with Redux DevTools middleware.
 */
export const createStore = <T extends object>(
  creatorFn: (set: SetStoreState<T>) => T,
  storeName?: string,
) => {
  return create<T>()(devtools(creatorFn, { name: storeName }))
}
