import defaultTeamAvatar from '@assets/icons/DefaultTeamAvatar.png'
import { MediaObjectUrl } from '@kudos/http-client'
import { SxProps } from '@mui/material/styles'
import { useT } from '@transifex/react'
import AvatarWrapper from './AvatarWrapper'

export type AvatarSize = number

type AvatarFontUnit = 'px' | 'vh'

type TeamAvatarProps = {
  size?: AvatarSize
  media?: MediaObjectUrl | null
  fontUnit?: AvatarFontUnit
  avatarBoxStyle?: SxProps
}

const TeamAvatar: React.FC<TeamAvatarProps> = ({
  size = 40,
  media,
  fontUnit = 'px',
  avatarBoxStyle,
}) => {
  const t = useT()
  return (
    <AvatarWrapper
      size={size}
      media={media}
      src={defaultTeamAvatar}
      alt={t('Teams avatar image')}
      fontUnit={fontUnit}
      avatarBoxStyle={avatarBoxStyle}
    />
  )
}

export default TeamAvatar
