import { Recognition } from '@kudos/http-client'
import Grid from '@mui/material/Grid2'
import { shuffle } from 'lodash/fp'
import SenderAndReceivers from './SenderAndReceivers'
import TvRecognitionContent from './TvRecognitionContent'

type TvRecognitionProps = {
  recognition: Recognition
}

const TvRecognition: React.FC<TvRecognitionProps> = ({ recognition }) => {
  const shuffledArray = shuffle(recognition.sendTo)

  return (
    <Grid container spacing={3}>
      <SenderAndReceivers sender={recognition.sender} receivers={shuffledArray} />
      <Grid size={{ xs: 8 }} sx={{ ml: 6 }}>
        <TvRecognitionContent recognition={recognition} />
      </Grid>
    </Grid>
  )
}

export default TvRecognition
