import { colors } from '@globals/themes/defaultTheme'
import { tabletQuery } from '@globals/themes/themeConstants'
import { SxProps, useMediaQuery } from '@mui/material'
import Stack from '@mui/material/Stack'
import { DRAWER_WIDTH } from '@shared/dialog/RightDrawer'

const actionTrayStyle = (isMobile: boolean): SxProps => ({
  borderTop: `1px solid ${colors.grayExtraLight}`,
  position: 'fixed',
  bottom: 0,
  right: 0,
  width: isMobile ? '100%' : DRAWER_WIDTH,
  backgroundColor: colors.white,
  p: 2,
})

type FormDrawerActionTrayProps = {
  children: React.ReactNode
}

const FormDrawerActionTray: React.FC<FormDrawerActionTrayProps> = ({ children }) => {
  const isTablet = useMediaQuery(tabletQuery)
  return (
    <Stack
      position="fixed"
      sx={actionTrayStyle(isTablet)}
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="end">
      {children}
    </Stack>
  )
}

export default FormDrawerActionTray
