import { colors } from '@globals/themes/defaultTheme'
import { onFocus, onHover } from '@globals/themes/themeConstants'
import { SxProps } from '@mui/material/styles'
import { useEffect, useState } from 'react'

type CustomChipVariant = 'customColor' | 'baseSelected' | 'baseUnselected'

/**
 * A custom hook that can be used to specify a particular colour for
 * a Mui chip if defined, with a safe fallback to default variant & style
 * @param checked boolean to determine whether or not the checked variant should display
 * @param color optional color value. If null, empty, or undefined this will fall back
 * to basic CheckmarkChip styling
 * @returns an object containing the chipVariant, chipSx, and checkmarkSx
 */
const useCustomColorChipStyle = (checked: boolean, color?: string) => {
  const [chipVariant, setChipVariant] = useState<CustomChipVariant>('baseUnselected')
  const [chipSx, setChipSx] = useState<SxProps>()
  const [checkmarkSx, setCheckmarkSx] = useState<SxProps>()

  useEffect(() => {
    setChipVariant(getChipVariant())
    setChipSx(getChipSx())
    setCheckmarkSx(getCheckFillSx())
  }, [checked, color])

  const getChipVariant = () => {
    if (!!color) {
      return 'customColor'
    } else {
      return checked ? 'baseSelected' : 'baseUnselected'
    }
  }

  const getCheckFillSx = () => {
    return { fill: !!color ? colors.grayDark : colors.white }
  }

  const getChipSx = () => {
    const sx: SxProps = {
      boxShadow: 'none',
    }

    if (!!color) {
      const colorSx: SxProps = {
        ...sx,
        color: colors.grayDark,
        borderColor: color,
        [`${onHover},${onFocus}`]: {
          // Opacity/alpha values appended to color palette colors (format #RGBA):
          // (handy lookup here: https://stackoverflow.com/a/28481374)
          // 1A: 10%
          backgroundColor: `${color}1A`,
          borderColor: color,
        },
      }
      return colorSx
    } else {
      return sx
    }
  }

  return { chipVariant, chipSx, checkmarkSx }
}

export default useCustomColorChipStyle
