import { spacing } from '@globals/themes/themeConstants'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import { SxProps } from '@mui/material/styles'
import { Fragment } from 'react'

const ROUTE_LOADING_ROWS = 5
const ROUTE_LOADING_PARAGRAPHS = 3

const skeletonBodyStyle: SxProps = {
  mb: 2,
  mt: 5,
  height: spacing(16),
}

const skeletonHeaderStyle: SxProps = {
  mt: 10,
  width: '30vw',
  height: spacing(6),
}

const RouteLoading: React.FC = () => (
  <Box height="100dvh">
    <Skeleton animation="wave" variant="rounded" sx={skeletonHeaderStyle} />
    {Array.from(new Array(ROUTE_LOADING_PARAGRAPHS)).map((_, paraIndex) => (
      <Fragment key={`routeLoadingParagraph-${paraIndex}`}>
        <Skeleton animation="wave" variant="rounded" sx={skeletonBodyStyle} />
        {Array.from(new Array(ROUTE_LOADING_ROWS)).map((_, rowIndex) => (
          <Skeleton
            key={`routeLoadingRow-${rowIndex}`}
            animation="wave"
            variant="rounded"
            sx={{ mb: 1, width: rowIndex === ROUTE_LOADING_ROWS - 1 ? '50%' : '100%' }}
            height={18}
          />
        ))}
      </Fragment>
    ))}
  </Box>
)

export default RouteLoading
