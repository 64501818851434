import { colors } from '@globals/themes/defaultTheme'
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation'
import { SxProps } from '@mui/material'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { T } from '@transifex/react'

const scheduleIconStyle: SxProps = {
  backgroundColor: colors.greenExtraLight,
  borderColor: 'transparent',
  '&.MuiChip-root': {
    minHeight: 32,
  },
}
const ScheduledChip = () => {
  return (
    <Chip
      label={
        <Typography color={colors.greenDark} variant="body1">
          <T _str="Scheduled" />
        </Typography>
      }
      sx={scheduleIconStyle}
      icon={<InsertInvitationIcon />}
    />
  )
}

export default ScheduledChip
