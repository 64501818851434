import { useApps } from '@auth/useApps'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import { SxProps } from '@mui/material/styles'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import { AppPermission } from '@shared/permissions'
import { useT } from '@transifex/react'
import { FC, SetStateAction } from 'react'
import useInAppNotifications from './useInAppNotifications'

type InAppNotificationsButtonProps = {
  badgeStyle: SxProps
  buttonStyle: SxProps
  onClick: (event: { currentTarget: SetStateAction<HTMLButtonElement | null> }) => void
}

const InAppNotificationsButton: FC<InAppNotificationsButtonProps> = ({
  badgeStyle,
  buttonStyle,
  onClick,
}) => {
  const t = useT()
  const [hasAppPermission] = useApps()
  const hasNotificationBrowsePermission = hasAppPermission(
    'notificationApp',
    AppPermission.NotificationAppBrowseMessages,
  )
  const { hasUnreadNotifications } = useInAppNotifications(false, hasNotificationBrowsePermission)

  if (!hasNotificationBrowsePermission) {
    return null
  }

  return (
    <Badge variant="dot" invisible={!hasUnreadNotifications} sx={badgeStyle}>
      <IconButton
        aria-label={t('Notifications')}
        sx={buttonStyle}
        onClick={onClick}
        id="btnNotifications">
        <NotificationsOutlinedIcon />
      </IconButton>
    </Badge>
  )
}

export default InAppNotificationsButton
