import AppPaths from '.'

type StaticPaths = {
  [key: string]: string
}

export const pathTitlesStatic: StaticPaths = {
  //AdminPaths
  [AppPaths.tenant.admin.members('users')]: 'Users',
  [AppPaths.tenant.admin.members('teams')]: 'Teams',
  [AppPaths.tenant.admin.members('quick-teams')]: 'Quick teams',
  [AppPaths.tenant.admin.customize()]: 'Branding',
  [AppPaths.tenant.admin.localization()]: 'Language & time',
  [AppPaths.tenant.admin.staff()]: 'HR records',
  [AppPaths.tenant.admin.staffIntegrations()]: 'HRIS import',
  [AppPaths.tenant.admin.staffImportCsv()]: 'CSV import',
  [AppPaths.tenant.admin.security()]: 'Security & access',
  [AppPaths.tenant.admin.addSSO()]: 'Configure SSO',
  [AppPaths.tenant.admin.checkInManage()]: 'Configure check-ins',
  [AppPaths.tenant.admin.analytics('insights')]: 'Burnout insights',
  [AppPaths.tenant.admin.analytics('dashboards')]: 'Check-ins',
  [AppPaths.tenant.admin.createTeam()]: 'Team configuration',
  [AppPaths.tenant.admin.configureQuickTeams()]: 'Configure quick teams',
  [AppPaths.tenant.admin.values()]: 'Values & qualities',
  [AppPaths.tenant.admin.configureValues()]: 'Configure values & qualities',
  [AppPaths.tenant.admin.kudosTvs()]: 'Kudos TV',
  [AppPaths.tenant.admin.points('preferences')]: 'Points preferences',
  [AppPaths.tenant.admin.points('allocation')]: 'Points allocation',
  [AppPaths.tenant.admin.points('reporting')]: 'Points reporting',
  [AppPaths.tenant.admin.points('liabilities')]: 'Points liabilities',
  [AppPaths.tenant.admin.notifications()]: 'Notifications',
  [AppPaths.tenant.admin.incentivePoints()]: 'Incentive points',
  [AppPaths.tenant.admin.rewards('setup')]: 'Reward stores',
  [AppPaths.tenant.admin.rewards('reporting')]: 'Redemption report',
  [AppPaths.tenant.admin.configureTangoRewardProvider()]: 'Add reward store',
  [AppPaths.tenant.admin.logs()]: 'Logs',
  [AppPaths.tenant.admin.pointsRules('bank')]: 'Point banks',
  [AppPaths.tenant.admin.pointsRules('celebration')]: 'Celebration points',
  [AppPaths.tenant.admin.pointsRules('peer')]: 'Peer-to-peer points',
  [AppPaths.tenant.admin.configureCelebrations()]: 'Configure celebrations',
  [AppPaths.tenant.admin.configureAwardType()]: 'Create award',
  [AppPaths.tenant.admin.awards()]: 'Configure awards',
  [AppPaths.tenant.admin.incentives()]: 'Incentives',
  [AppPaths.tenant.admin.configureIncentive()]: 'Create incentive',
  [AppPaths.tenant.admin.configureCards()]: 'Configure cards',
  [AppPaths.tenant.admin.allReports()]: 'Analytics',
  [AppPaths.tenant.admin.announcements()]: 'Configure announcements',
  [AppPaths.tenant.admin.configureAnnouncement()]: 'Create announcement',
  [AppPaths.tenant.admin.flaggedContent('awards')]: 'Flagged awards',
  [AppPaths.tenant.admin.flaggedContent('cards')]: 'Flagged cards',
  [AppPaths.tenant.admin.flaggedContent('comments')]: 'Flagged comments',
  [AppPaths.tenant.admin.flaggedContent('recognition')]: 'Flagged recognitions',

  // AnonPaths
  [AppPaths.anon.userLogin()]: 'User login',
  [AppPaths.anon.userLoginChallenge()]: 'Login challenge',
  [AppPaths.anon.passwordRecovery()]: 'Recover password',
  [AppPaths.anon.tenantLogin()]: 'Tenant login',
  [AppPaths.anon.resetPassword()]: 'Reset password',

  // TenantScopedPaths
  [AppPaths.tenant.home()]: 'Home',
  [AppPaths.tenant.sendRecognition()]: 'Send recognition',
  [AppPaths.tenant.viewRecognition(':id')]: 'Recognition',
  [AppPaths.tenant.viewDraftRecognition(':id')]: 'Edit recognition',
  [AppPaths.tenant.me()]: 'My profile',
  [AppPaths.tenant.editMe()]: 'Edit my profile',
  [AppPaths.tenant.mySettings('notifications')]: 'My notifications',
  [AppPaths.tenant.mySettings('security')]: 'My security',
  [AppPaths.tenant.mySettings('privacy')]: 'My privacy',
  [AppPaths.tenant.changePassword()]: 'Change password',
  [AppPaths.tenant.feedback()]: 'Provide feedback',
  [AppPaths.tenant.resourceHub('all')]: 'All resources',
  [AppPaths.tenant.resourceHub('recommended')]: 'Recommended resources',
  [AppPaths.tenant.resourceHub('favourites')]: 'Favourite resources',
  [AppPaths.tenant.rewardsProviders()]: 'Rewards providers',
  [AppPaths.tenant.yourDirectReports()]: 'Your direct reports',
  [AppPaths.tenant.awards()]: 'Send award',
  [AppPaths.tenant.celebrate('calendar')]: 'Celebrations calendar',
  [AppPaths.tenant.celebrate('cards')]: 'Cards',
  [AppPaths.tenant.configureCard()]: 'Create card',
  [AppPaths.tenant.redemptionHistory()]: 'Redemption history',

  // UserScopedPaths
  [AppPaths.user.tenants()]: 'My tenants',
}
