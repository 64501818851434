import { useDialog } from '@globals/dialog/useDialogStore'
import { colors } from '@globals/themes/defaultTheme'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/material/styles'
import { T, useT } from '@transifex/react'
import React, { ReactNode } from 'react'
import { DialogActionsStyled, DialogCloseButton, DialogContentStyled } from './Dialog.styled'
import Box from '@mui/material/Box'

type CustomDialogProps = {
  dialogKey: string
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  minHeight?: string | number
  onSubmit?: () => Promise<void>
  cancelText?: ReactNode
  submitText?: ReactNode
  title?: ReactNode
  children?: ReactNode
  description?: ReactNode
  onClose?: () => void
  showCancelOnly?: boolean
}

export const CustomDialog: React.FC<CustomDialogProps> = ({
  dialogKey,
  maxWidth,
  minHeight,
  onSubmit,
  cancelText,
  submitText,
  title,
  children,
  description,
  onClose,
  showCancelOnly,
}) => {
  const t = useT()
  const { closeDialog, isOpen } = useDialog(dialogKey)

  const handleOnClose = () => {
    if (onClose) {
      onClose()
    }
    closeDialog()
  }

  const Title = ({ title }: { title: ReactNode }) => (
    <section>
      <Typography variant="h2" sx={{ mt: 0 }} color={colors.grayDark}>
        {title}
      </Typography>
    </section>
  )

  const dialogProps: SxProps = {
    '.MuiDialog-paper': {
      ...(minHeight ? { minHeight } : {}),
    },
  }

  return (
    <Dialog
      aria-label={t('Custom dialog')}
      open={isOpen}
      scroll="paper"
      fullWidth
      sx={dialogProps}
      disableScrollLock={isOpen}
      maxWidth={maxWidth}
      id={`${dialogKey}-dialog`}
      onClose={handleOnClose}
      data-testid="dialog-test-id">
      <DialogTitle sx={{ borderBottom: `1px solid ${colors.grayExtraLight}` }}>
        {title ? <Title title={title} /> : <Box sx={{ height: 16 }} />}
        <DialogCloseButton id={`${dialogKey}-close`} onClose={handleOnClose} />
      </DialogTitle>
      {!!description && <DialogContentStyled>{description}</DialogContentStyled>}
      {children ? (
        <DialogContentStyled>{children}</DialogContentStyled>
      ) : (
        <DialogActionsStyled>
          <Button
            onClick={handleOnClose}
            variant="outlined"
            data-testid="dialog-cancel-btn"
            id="btnOnCancel">
            {cancelText ? cancelText : <T _str="Cancel" />}
          </Button>
          {!showCancelOnly && (
            <Button
              onClick={onSubmit}
              variant="contained"
              data-testid="dialog-send-btn"
              id="btnOnSubmit">
              {submitText ? submitText : <T _str="Send" />}
            </Button>
          )}
        </DialogActionsStyled>
      )}
    </Dialog>
  )
}
