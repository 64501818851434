import { createTheme } from '@mui/material'

/**
 * Intermediary theme. Since we can't use breakpoints
 * when creating a theme, this allows us to make a theme with
 * the breakpoints we want, and pass it into the final
 * theme creation. Currently, we are using default breakpoints anyway :)
 */
export const DefaultTheme = createTheme()
DefaultTheme.breakpoints.values.xs = 0
DefaultTheme.breakpoints.values.sm = 600
DefaultTheme.breakpoints.values.md = 900
DefaultTheme.breakpoints.values.lg = 1200
DefaultTheme.breakpoints.values.xl = 1536

/**
 * Base colours used by our theme. This is merged into one larger
 * export in the defaultTheme.ts file, and colors should continue to be
 * imported from there.
 */
export const DefaultColors = {
  // Primary accents - aqua
  aquaDark: '#258189',
  aquaPrimary: '#44B3BA',
  aquaMedium: '#85DADE',
  aquaLight: '#AEE7E9',
  aquaExtraLight: '#D9F6F7',
  aquaUltraLight: '#F0FBFC',
  // Primary accents - gray
  grayDark: '#0A0C11',
  grayPrimary: '#222733',
  grayMedium: '#656A75',
  grayLight: '#9FA3AD',
  grayExtraLight: '#DEE0E4',
  grayUltraLight: '#F1F2F5',
  // Primary accents - blue
  blueDark: '#1E367C',
  bluePrimary: '#4A6FBB',
  blueMedium: '#8BAADD',
  blueLight: '#BFD7F5',
  blueExtraLight: '#E8F3FD',
  blueUltraLight: '#F8FCFF',

  // Secondary accents - orange
  orangeDark: '#8C3E00',
  orangePrimary: '#BD5400',
  orangeExtraLight: '#FFE6CD',

  // Secondary accents - red
  redDark: '#87321A',
  redPrimary: '#C84A41',
  redExtraLight: '#FFD7D0',
  // Secondary accents - coral
  coralDark: '#CF4031',
  coralPrimary: '#F76A56',
  coralMedium: '#FCA795',
  coralLight: '#FDC4B8',
  coralExtraLight: '#FFE5DF',
  coralUltraLight: '#FFF5F2',
  // Secondary accents - gold
  goldDark: '#D48824',
  goldPrimary: '#FBB942',
  goldMedium: '#FDDD83',
  goldLight: '#FEE9AC',
  goldExtraLight: '#FFFEE5',
  goldUltraLight: '#FFFFF7',
  // Secondary accents - green
  greenDark: '#266043',
  greenPrimary: '#4D8267',
  greenExtraLight: '#D9F3DF',
  // Secondary accents - orchid
  orchidDark: '#934685',
  orchidPrimary: '#C472B7',
  orchidMedium: '#E4ADDD',
  orchidLight: '#EDC9E8',
  orchidExtraLight: '#F1E9FF',
  orchidUltraLight: '#FCF6FC',
  // Secondary accents - iris
  irisDark: '#4848A8',
  irisPrimary: '#7575D7',
  irisMedium: '#AFAFEE',
  irisLight: '#CACAF3',
  irisExtraLight: '#E8E8FB',
  irisUltraLight: '#FCF4FB',
  // Secondary accents - lime
  limeDark: '#54A03C',
  limePrimary: '#83CF64',
  limeMedium: '#BAE9A1',
  limeLight: '#D1F1C1',
  limeExtraLight: '#ECFAE4',
  limeUltraLight: '#F7FDF4',
  // Secondary accents - yellow
  yellowDark: '#E3A934',
  yellowMedium: '#FFD876',
  yellowPrimary: '#F7C34B',
  yellowExtraLight: '#FDF0C9',
  yellowUltraLight: '#FFFAEB',

  // Charts
  blueChart1: '#BFD7F5',
  blueChart2: '#84A5DB',
  blueChart3: '#4A6FB8',
  blueChart4: '#2C448D',
  blueChart5: '#000550',
  chartColor1: '#000550',
  chartColor2: '#2C448D',
  chartColor3: '#4A6FB8',
  chartColor4: '#84A5DB',
  chartColor5: '#BFD7F5',

  // Other
  white: '#FFFFFF',
  whiteDark: '#FAFBFD',
  blueGradient: 'linear-gradient(180deg, #EAF6FF 48px, #FFF 140px)',
  blueCardGradient: 'radial-gradient(63.58% 114.03% at 81.1% 39.21%, #6E8AC9 0%, #4169C1 100%)',
  grayCardGradient: 'radial-gradient(106.81% 117.03% at 81.1% 39.21%, #848A99 0%, #656A75 100%)',
  boxShadow: '0px 2px 8px 0px #7C8CAF4D, 0px 0px 1px 0px #7C8CAF66',
  hoverShadow: '0px 2px 7px 0px rgba(62, 66, 74, 0.15)',
  // Check-ins
  likertBorderColor1: '#EBBBA4',
  likertBorderColor2: '#F0C594',
  likertBorderColor3: '#EBDB71',
  likertBorderColor4: '#C1DD9A',
  likertBorderColor5: '#99D1B7',
  likertActiveBgColor1: '#FCF5F1',
  likertActiveBgColor2: '#FDF6EF',
  likertActiveBgColor3: '#FCFAEA',
  likertActiveBgColor4: '#F6FAF0',
  likertActiveBgColor5: '#F0F8F4',
  likertHoverShadow: '0px 2px 7px 0px rgba(62, 66, 74, 0.15)',
  likertFocusShadow1:
    '0px 2px 6px 1px rgba(186, 117, 85, 0.16), 0px 2px 2px 0px rgba(186, 117, 85, 0.10)',
  likertFocusShadow2:
    '0px 2px 6px 1px rgba(205, 147, 81, 0.16), 0px 2px 2px 0px rgba(205, 147, 81, 0.10)',
  likertFocusShadow3:
    '0px 2px 6px 1px rgba(201, 185, 74, 0.16), 0px 2px 2px 0px rgba(201, 185, 74, 0.10)',
  likertFocusShadow4:
    '0px 2px 6px 1px rgba(144, 184, 89, 0.16), 0px 2px 2px 0px rgba(144, 184, 89, 0.10)',
  likertFocusShadow5:
    '0px 2px 6px 1px rgba(88, 189, 142, 0.16), 0px 2px 2px 0px rgba(88, 189, 142, 0.10)',
  yesNoBorderColorfalse: '#EBBBA4',
  yesNoBorderColortrue: '#99D1B7',
  yesNoActiveBgColorfalse: '#FCF5F1',
  yesNoActiveBgColortrue: '#F0F8F4',
  yesNoFocusShadowfalse:
    '0px 2px 6px 1px rgba(174, 94, 56, 0.16), 0px 2px 2px 0px rgba(174, 94, 56, 0.10)',
  yesNoFocusShadowtrue:
    '0px 2px 6px 1px rgba(88, 189, 142, 0.16), 0px 2px 2px 0px rgba(88, 189, 142, 0.10)',
  yesNoChartFillYes: '#D3F5EF',
  yesNoChartStrokeYes: '#25CCAE',
  yesNoChartNo: '#FF7D95',
  yesNoChartYes: '#D3F5EF',
} as const

export type DefaultColorsT = keyof typeof DefaultColors

/**
 * alias and export the theme spacing to
 * be imported and used in other modules
 */
export const spacing = DefaultTheme.spacing

/**
 * Export breakpoints for use in modules/files
 */
export const themeBreakPoints = DefaultTheme.breakpoints

/**
 * @constant
 * media query for screen width < 600px
 * @media (max-width:600px)
 */
export const mobileQuery = DefaultTheme.breakpoints.down('sm')

/**
 * @constant
 * media query for container width < 600px (i.e. 'preview' mobile)
 * @media (max-width:600px)
 */
export const mobileContainerQuery = '@container (max-width: 600px)'

/**
 * @constant
 * media query for screen width > 0px and < 1200px
 * @media (min-width:0px, max-width:1200px)
 */
export const tabletQuery = DefaultTheme.breakpoints.between('xs', 'lg')

/**
 * @constant
 * media query for screen width > than 1200px
 * @media (min-width:1200px)
 */
export const desktopQuery = DefaultTheme.breakpoints.up('lg')

/**
 * @constant
 * hover state selector that can be used in styles
 */
export const onHover = '&:hover'

/**
 * @constant
 * focus state selector that can be used in styles
 */
export const onFocus = '&:focus, &:focus-within, &:focus-visible, &.Mui-focusVisible'

/**
 * @constant
 * active/pressed state selector that can be used in styles
 */
export const onActive = '&:active'

/**
 * @constant
 * disabled state selector that can be used in mui components
 */
export const onDisabled = '&.Mui-disabled'

/**
 * @constant
 * error state selector that can be used in mui components
 */
export const onError = '&.Mui-error'

/**
 * @constant
 * checked state selector that can be used in mui checkboxes/toggle buttons, etc.
 */
export const onChecked = '&.Mui-checked'
