import { spacing } from '@globals/themes/themeConstants'
import { MediaObjectUpload, MediaObjectUrl } from '@kudos/http-client'
import Tooltip from '@mui/material/Tooltip'
import AddAttachmentButton from '@components/button/AddAttachmentButton'
import ResponsiveScrollingTiledList from '@shared/components/ResponsiveScrollingTiledList'
import HiddenFileInput from '@shared/components/mediaUploads/HiddenFileInput'
import MediaAttachmentTile from '@shared/components/mediaUploads/MediaAttachmentTile'
import { AttachmentType } from '@shared/hooks/useFileMediaUpload'
import { MAX_ATTACHMENTS } from '@shared/wallPost/constants'
import { T, useT } from '@transifex/react'
import { FC, useRef, useState } from 'react'
import {
  Control,
  FieldValues,
  UseControllerProps,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import AttachmentField from './AttachmentField'
import ErrorText from './ErrorText'

const MAX_FILE_SIZE = 10 // in MB

type AttachmentField = {
  id: string
  attachmentSrc?: MediaObjectUrl
  attachmentType: AttachmentType
  file?: File | null
}

type AttachmentsFieldProps = UseControllerProps & {
  mutationFn: (params: {
    originalFilename: string
    contentType: string
  }) => Promise<MediaObjectUpload>
}

const AttachmentsField: FC<AttachmentsFieldProps> = ({ control, mutationFn }) => {
  const t = useT()
  const { setValue } = useFormContext()
  const gif = useWatch({ control, name: 'gif' })
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [maxSizeError, setMaxSizeError] = useState(false)

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attachments',
  })

  const attachments = fields as AttachmentField[]

  const handleDeleteAttachmentClick = (index: number) => {
    remove(index)
  }

  const handleAddAttachmentClick = () => {
    setMaxSizeError(false)
    // mimics a click on the hidden file input
    inputFileRef?.current?.click()
  }

  const handleDeleteGifClick = () => {
    setValue('gif', undefined, { shouldValidate: true })
  }

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0]
      const isValidSize = selectedFile.size <= MAX_FILE_SIZE * 1024 * 1024
      if (!isValidSize) {
        setMaxSizeError(true)
      } else {
        append({
          mediaObjectId: '',
          file: selectedFile,
          attachmentType: selectedFile.type.startsWith('image/') ? 'image' : 'document',
        })
      }
    }
  }

  return (
    <>
      <HiddenFileInput
        ref={inputFileRef}
        type="file"
        accept="image/jpg, .png, .webp, .pdf, .docx, .pptx, .gif"
        id="fileUploadAttachment"
        onChange={handleFileInputChange}
      />
      <ResponsiveScrollingTiledList marginTop={spacing(2)} scrollToLastItem>
        {!!attachments.length &&
          attachments.map((field: AttachmentField, index: number) => (
            <AttachmentField
              key={field.id}
              name="attachments"
              control={control as unknown as Control<FieldValues>}
              attachmentSrc={field.attachmentSrc}
              file={field.file}
              index={index}
              attachmentType={field.attachmentType}
              onDelete={handleDeleteAttachmentClick}
              mutationFn={mutationFn}
            />
          ))}
        {!!gif && (
          <MediaAttachmentTile
            src={gif}
            alt={t('GIF attachment')}
            onDelete={handleDeleteGifClick}
          />
        )}
      </ResponsiveScrollingTiledList>
      <Tooltip
        id="tooltipAddAttachment"
        sx={{ width: 2000 }}
        title={
          <>
            <T _str="JPG, PNG, GIF, WebP, PDF, DOCX, PPTX" />
            <br />
            <T _str="{maxSize}MB Max file size" maxSize={MAX_FILE_SIZE} />
          </>
        }
        placement="bottom-start"
        slotProps={{
          tooltip: {
            sx: {
              maxWidth: 500,
              width: 'auto',
              whiteSpace: 'normal',
            },
          },
        }}>
        <span>
          <AddAttachmentButton
            buttonId="btnAddAnnouncementAttachment"
            ariaLabelledBy="tooltipAddAnnouncementAttachment"
            onClick={handleAddAttachmentClick}
            disabled={attachments?.length >= MAX_ATTACHMENTS}
          />
        </span>
      </Tooltip>
      {maxSizeError && (
        <ErrorText>
          {<T _str="The file exceeds the maximum size limit, upload a smaller file" />}
        </ErrorText>
      )}
    </>
  )
}

export default AttachmentsField
