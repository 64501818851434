export default class AnonPaths {
  public static userLogin() {
    return '/'
  }
  public static userLoginChallenge() {
    return '/challenge'
  }

  public static passwordRecovery() {
    return '/password-recovery'
  }

  public static tenantLogin() {
    return '/tenant/login'
  }
  public static resetPassword() {
    return '/new-password'
  }

  public static watchKudosTv(token: string) {
    return `/tv/watch/${token}`
  }
}
