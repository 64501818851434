import { colors } from '@globals/themes/defaultTheme'
import { TenantMemberDisplayData } from '@kudos/http-client'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import UserAvatar from '@shared/avatar/UserAvatar'
import { nameContainerStyle, nameTextStyle } from './AvatarAndNameDisplay'

type SenderAvatarAndNameDiplayProps = {
  sender: TenantMemberDisplayData
  avatarSize: number
  textColor?: string
}

const SenderAvatarAndNameDisplay: React.FC<SenderAvatarAndNameDiplayProps> = ({
  sender,
  avatarSize,
  textColor = colors.white,
}) => {
  return (
    <>
      <UserAvatar
        firstName={sender.firstName}
        lastName={sender.lastName}
        size={avatarSize}
        fontUnit="vh"
      />
      <Box sx={nameContainerStyle}>
        <Typography
          align="center"
          sx={{ ...nameTextStyle, color: `${textColor} !important` }}
          pt={2}>
          {sender.firstName}
        </Typography>
        {sender.lastName && (
          <Typography align="center" sx={{ ...nameTextStyle, color: `${textColor} !important` }}>
            {sender.lastName}
          </Typography>
        )}
      </Box>
    </>
  )
}

export default SenderAvatarAndNameDisplay
