import Typography from '@mui/material/Typography'
import TimeAgo from '@shared/TimeAgo'
import Box from '@mui/material/Box'
import { colors } from '@globals/themes/defaultTheme'

const containerStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
}

const TvTimeAgo = ({ date }: { date: string }) => {
  return (
    <Box sx={containerStyle}>
      <Typography variant="subtitle1" sx={{ color: colors.white }}>
        <TimeAgo date={date} />
      </Typography>
    </Box>
  )
}

export default TvTimeAgo
