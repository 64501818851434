import {
  MediaObjectUpload,
  SignedUploadField,
  UploadMediaParams,
  uploadMedia,
} from '@kudos/http-client'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'

export type AttachmentType = 'image' | 'document' | 'gif'

export type Attachment = {
  mediaObjectId: string
  file?: File | null
  attachmentType: AttachmentType
}

export type InitiateUploadResponseParams = {
  initiateUploadResponse: MediaObjectUpload
  file: File
}

export type FileMediaUploadParams = {
  mutationFn?: (params: {
    originalFilename: string
    contentType: string
  }) => Promise<MediaObjectUpload>
}
export const useFileMediaUpload = ({ mutationFn }: FileMediaUploadParams) => {
  // locally generated object url for the uploaded resource
  const [attachmentSrc, setAttachmentSrc] = useState<string | undefined>()

  // the media object id that is returned after upload
  const [mediaObjectId, setMediaObjectId] = useState<string>()

  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()

  const initiateUploadMutation = useMutation({
    mutationFn,
  })

  const completeUploadMutation = useMutation({
    mutationFn: ({ url, data }: UploadMediaParams) => uploadMedia({ url, data }),
  })

  const initiateUpload = async (file: File) => {
    setIsUploading(true)
    try {
      // request the upload from backend
      const initiateUploadResponse = await initiateUploadMutation.mutateAsync({
        originalFilename: file.name,
        contentType: file.type,
      })

      // parse out signed upload url and aws requirements
      const { awsFormData, mediaObjectId, uploadUrl } = handleInitiateUploadResponse({
        initiateUploadResponse,
        file,
      })

      // complete the upload
      await completeUploadMutation.mutateAsync({
        url: uploadUrl,
        data: awsFormData,
      })

      // set final media object before clearing uploading flag
      setMediaObjectId(mediaObjectId)
      setIsUploading(false)
      return mediaObjectId
    } catch (error) {
      setError(error as Error)
      setIsUploading(false)
    }
  }

  const handleInitiateUploadResponse = ({
    initiateUploadResponse,
    file,
  }: InitiateUploadResponseParams) => {
    const uploadFields = initiateUploadResponse.upload.fields
    const mediaObjectId = initiateUploadResponse.mediaObject.id
    const uploadUrl = new URL(initiateUploadResponse.upload.url).href
    const awsFormData = new FormData() // 'multipart/form-data' constructor

    uploadFields.forEach(([key, value]: SignedUploadField) => {
      awsFormData.append(key, value)
    })
    awsFormData.append('file', file)

    return { awsFormData, mediaObjectId, uploadUrl }
  }

  const createObjectUrl = (file?: File) => {
    if (!!file) {
      // render the image locally in the component while
      // it uploads
      const srcUrl = URL.createObjectURL(file)
      setAttachmentSrc(srcUrl)
    } else if (!!attachmentSrc) {
      // revoke the generated object url for the file
      URL.revokeObjectURL(attachmentSrc)
      setAttachmentSrc('')
    }
  }

  return {
    createObjectUrl,
    initiateUpload,
    attachmentSrc,
    mediaObjectId,
    isUploading,
    error,
  }
}
