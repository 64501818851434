import {
  MediaObjectUrl,
  MediaVariantEnum,
  TeamDisplayData,
  TenantMemberDisplayData,
} from '@kudos/http-client'
import useMediaUrl from '@shared/hooks/useMediaUrl'
import { useEffect, useState } from 'react'

type AvatarFontSize = number

/**
 * Utility type guard function due to the union type for displayData
 * @param displayData
 * @returns boolean derived from 'is' predicate
 */
export const isTenantMemberDisplayData = (
  displayData: TenantMemberDisplayData | TeamDisplayData | undefined,
): displayData is TenantMemberDisplayData => {
  return (displayData as TenantMemberDisplayData)?.firstName !== undefined
}

const useAvatarConfig = (
  size: number,
  media: MediaObjectUrl | null,
  mediaVariant?: MediaVariantEnum,
) => {
  const [avatarVariant, setAvatarVariant] = useState<MediaVariantEnum | undefined>(mediaVariant)
  const [fontSize, setFontSize] = useState<AvatarFontSize>(14)

  const { url: avatarUrl } = useMediaUrl(media, mediaVariant ?? avatarVariant)

  useEffect(() => {
    switch (size) {
      case 40:
        setFontSize(16)
        setAvatarVariant(MediaVariantEnum.AVATAR_SM)
        break
      case 48:
        setFontSize(20)
        setAvatarVariant(MediaVariantEnum.AVATAR_SM)
        break
      case 88:
        setFontSize(24)
        setAvatarVariant(MediaVariantEnum.AVATAR_MD)
        break
      case 120:
        setFontSize(40)
        setAvatarVariant(MediaVariantEnum.AVATAR_LG)
        break
      default:
        setFontSize(Math.round(size / 3))
        setAvatarVariant(MediaVariantEnum.AVATAR_XL)
        break
    }
  }, [size, mediaVariant])

  return {
    avatarUrl,
    fontSize,
  }
}

export default useAvatarConfig
