import { SxProps } from '@mui/material/styles'
import SvgIcon from '@mui/material/SvgIcon'

type GiftIconProps = {
  sx?: SxProps
}

const GiftIcon: React.FC<GiftIconProps> = ({ sx }) => (
  <SvgIcon viewBox="0 0 24 24" sx={sx}>
    <rect width="24" height="24" fillOpacity={0} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 8H11V6C11 4.89543 10.1046 4 9 4C7.89543 4 7 4.89543 7 6C7 7.10457 7.89543 8 9 8ZM12 3.35418C11.2671 2.52376 10.1947 2 9 2C6.79086 2 5 3.79086 5 6C5 6.72857 5.19479 7.41165 5.53513 8H4C2.89543 8 2 8.89543 2 10V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V10C22 8.89543 21.1046 8 20 8H18.4649C18.8052 7.41165 19 6.72857 19 6C19 3.79086 17.2091 2 15 2C13.8053 2 12.7329 2.52376 12 3.35418ZM15 10H13V12H20V10H15ZM20 14H13V20H20V14ZM11 12V10H9H4V12H11ZM4 14V20H11V14H4ZM15 8C16.1046 8 17 7.10457 17 6C17 4.89543 16.1046 4 15 4C13.8954 4 13 4.89543 13 6V8H15Z"
    />
  </SvgIcon>
)

export default GiftIcon
