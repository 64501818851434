import { useState, useLayoutEffect } from 'react'

const getDimensions = (container?: HTMLElement | null) => {
  if (!!container) {
    const scrollWidth = container.offsetWidth - container.clientWidth
    return {
      width: container.offsetWidth - scrollWidth,
      height: container.offsetHeight,
    }
  } else {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    }
  }
}

const useContainerDimensions = (containerRef?: React.RefObject<HTMLDivElement>) => {
  const [dimensions, setDimensions] = useState(getDimensions(containerRef?.current))

  const handleResize = async () => {
    const dimensions = getDimensions(containerRef?.current)
    setDimensions(dimensions)
  }

  useLayoutEffect(() => {
    handleResize()
  }, [containerRef?.current?.offsetWidth, containerRef?.current?.clientWidth])

  return dimensions
}

export default useContainerDimensions
