import { envConfig } from '@globals/env'
import { initAPI } from '@kudos/http-client'
import { LicenseInfo } from '@mui/x-license'
import FroalaEditor from 'froala-editor'
import { StrictMode } from 'react'
import * as ReactDOM from 'react-dom/client'
import { App } from './App'

// Initialize the API with appropriate URL for this stage
initAPI({ apiUrl: envConfig.API_URL })

// Set the license key for the MUI X pro components
LicenseInfo.setLicenseKey(envConfig.MUI_PRO_KEY)

// Set the license key for Froala enterprise
FroalaEditor.DEFAULTS.key = envConfig.FROALA_ENTERPRISE_KEY

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
)
