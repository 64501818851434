import { T } from '@transifex/react'
import { FieldErrors, FieldValues } from 'react-hook-form'
import ErrorText from './ErrorText'

const FormErrors = <T extends FieldValues>({ errors }: { errors: FieldErrors<T> }) => {
  // Hide completely if no root errors populated yet
  const { root: { message } = {} } = errors

  if (message === undefined) return null

  // Assume that field errors have been properly shown next to each field, so we only
  // need to handle the root error

  return (
    <ErrorText rightAligned>
      {message ? message : <T _str="Oops. Something went wrong." />}
    </ErrorText>
  )
}

export default FormErrors
