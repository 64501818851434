import { colors } from '@globals/themes/defaultTheme'
import { MemberInAppNotification, PageResult } from '@kudos/http-client'
import { MenuList } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import TimeAgo from '@shared/TimeAgo'
import UserAvatar from '@shared/avatar/UserAvatar'
import ViewMoreButton from '@shared/pagination/ViewMoreButton'
import { T, useT } from '@transifex/react'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import useInAppNotifications from './useInAppNotifications'

type NotificationsListProps = {
  open: boolean
  onClose: () => void
  layoutStyle: object
}

const NotificationsList: FC<NotificationsListProps> = ({ open, onClose, layoutStyle }) => {
  const t = useT()
  const {
    notifications,
    fetchNextPage,
    isFetchingNextPage,
    markNotificationAsRead,
    markAllNotificationsAsRead,
  } = useInAppNotifications(open)

  const menuItemStyle = (isUnread: boolean) => ({
    ...layoutStyle,
    backgroundColor: isUnread ? colors.blueUltraLight : 'transparent',
    '&:hover': {
      backgroundColor: colors.blueExtraLight,
    },
  })

  const onNotificationClick = (notification: MemberInAppNotification) => {
    markNotificationAsRead(notification.id)
    if (!!notification.resourcePath) {
      onClose()
    }
  }

  const onMarkAllAsRead = () => {
    markAllNotificationsAsRead()
    onClose()
  }

  return (
    <>
      <Box sx={layoutStyle}>
        <Typography variant="h3" sx={{ mt: '0 !important' }}>
          <T _str="Notifications" />
        </Typography>
        <Button id="btnMarkAllAsRead" variant="text" sx={{ mt: 2 }} onClick={onMarkAllAsRead}>
          <T _str="Mark all notifications as viewed" />
        </Button>
      </Box>
      <MenuList id="notificationsPopoverList">
        {!!notifications?.pages[0].results?.length ? (
          <Box>
            {notifications.pages.map((page: PageResult<MemberInAppNotification>) => (
              <Box key={`notificationsList-${page.page}`}>
                {page.results.map((notification: MemberInAppNotification) => (
                  <MenuItem
                    key={notification.id}
                    id={`basicPopoverMenuItem-${notification.id}`}
                    component={Link}
                    to={notification.resourcePath || '#'}
                    onClick={onNotificationClick.bind(null, notification)}
                    sx={menuItemStyle(!notification.readAt)}>
                    <ListItemAvatar>
                      <UserAvatar
                        firstName={notification.fromMember?.firstName}
                        lastName={notification.fromMember?.lastName}
                        media={notification.fromMember?.avatarUrls}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={notification.message}
                      secondary={<TimeAgo date={notification.createdAt} />}
                      primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                    />
                  </MenuItem>
                ))}
              </Box>
            ))}
            {notifications.pages[notifications.pages.length - 1].next && (
              <ViewMoreButton
                buttonText={<T _str="View more notifications" />}
                isFetchingNextPage={isFetchingNextPage}
                fetchMore={fetchNextPage}
              />
            )}
          </Box>
        ) : (
          <MenuItem id={'basicPopoverMenuItemEmptyText'}>
            <ListItemText primary={t('No notifications')} />
          </MenuItem>
        )}
      </MenuList>
    </>
  )
}

export default NotificationsList
