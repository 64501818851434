import { colors } from '@globals/themes/defaultTheme'
import SvgIcon from '@mui/material/SvgIcon'

const DraftIcon = () => (
  <SvgIcon>
    <path
      d="M2 21.0125C1.45 21.0125 0.979167 20.8167 0.5875 20.425C0.195833 20.0334 0 19.5625 0 19.0125V5.01255C0 4.46255 0.195833 3.99172 0.5875 3.60005C0.979167 3.20838 1.45 3.01255 2 3.01255H8.525C8.85833 3.01255 9.10833 3.11672 9.275 3.32505C9.44167 3.53338 9.525 3.76255 9.525 4.01255C9.525 4.26255 9.4375 4.49172 9.2625 4.70005C9.0875 4.90838 8.83333 5.01255 8.5 5.01255H2V19.0125H16V12.4875C16 12.1542 16.1042 11.9042 16.3125 11.7375C16.5208 11.5709 16.75 11.4875 17 11.4875C17.25 11.4875 17.4792 11.5709 17.6875 11.7375C17.8958 11.9042 18 12.1542 18 12.4875V19.0125C18 19.5625 17.8042 20.0334 17.4125 20.425C17.0208 20.8167 16.55 21.0125 16 21.0125H2ZM6 14.0125V11.5875C6 11.3209 6.05 11.0667 6.15 10.825C6.25 10.5834 6.39167 10.3709 6.575 10.1875L15.175 1.58755C15.375 1.38755 15.6 1.23755 15.85 1.13755C16.1 1.03755 16.35 0.987549 16.6 0.987549C16.8667 0.987549 17.1208 1.03755 17.3625 1.13755C17.6042 1.23755 17.825 1.38755 18.025 1.58755L19.425 3.01255C19.6083 3.21255 19.75 3.43338 19.85 3.67505C19.95 3.91672 20 4.16255 20 4.41255C20 4.66255 19.9542 4.90838 19.8625 5.15005C19.7708 5.39172 19.625 5.61255 19.425 5.81255L10.825 14.4125C10.6417 14.5959 10.4292 14.7417 10.1875 14.85C9.94583 14.9584 9.69167 15.0125 9.425 15.0125H7C6.71667 15.0125 6.47917 14.9167 6.2875 14.725C6.09583 14.5334 6 14.2959 6 14.0125ZM8 13.0125H9.4L15.2 7.21255L14.5 6.51255L13.775 5.81255L8 11.5875V13.0125Z"
      fill={colors.grayPrimary}
    />
  </SvgIcon>
)

export default DraftIcon
