import EngagementIcon from '@assets/icons/EngagementIcon'
import { colors } from '@globals/themes/defaultTheme'
import { previewRecipientCount } from '@kudos/http-client'
import { Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { PointsEstimateInfoBox } from '@shared/PointsEstimateInfoBox'
import CheckmarkChip from '@shared/components/CheckmarkChip'
import TextField from '@shared/forms/TextField'
import { useMemberPoints } from '@shared/hooks/useMemberPoints'
import { useMutation } from '@tanstack/react-query'
import { T, useT } from '@transifex/react'
import { useEffect, useMemo, useState } from 'react'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'
import { RecognitionFormData } from './useSendRecognitionForm'

type ChipSelect = { id: string; label: string }

const points: ChipSelect[] = [
  { id: '0', label: '0' },
  { id: '5', label: '5' },
  { id: '10', label: '10' },
  { id: '25', label: '25' },
  { id: '50', label: '50' },
]
const PointsSelectionField: React.FC = () => {
  const t = useT()
  const [disabledValues, setDisabledValues] = useState<string[]>([])
  const [isUsingCustomAmount, setIsUsingCustomAmount] = useState<boolean>(false)
  const [recipientCount, setRecipientCount] = useState<number>(0)

  const { availablePoints, arePointsLoaded } = useMemberPoints()

  const { control, setValue, setError, trigger } = useFormContext<RecognitionFormData>()

  const {
    field: { value: amount, onChange },
  } = useController({ control, name: 'amount' })

  const sendTo = useWatch({ control, name: 'sendTo' })

  const { mutateAsync: previewRecipients } = useMutation({
    mutationFn: previewRecipientCount,
  })
  useEffect(() => {
    if (sendTo.length > 0) {
      previewRecipients({ sendTo }).then(({ recipientCount }) => {
        setRecipientCount(recipientCount)
      })
    } else {
      setRecipientCount(0)
    }
  }, [sendTo.length])

  const maxPerRecipient = useMemo(() => {
    if (recipientCount && recipientCount > 0) {
      return Math.floor(availablePoints / recipientCount)
    } else {
      return availablePoints
    }
  }, [availablePoints, recipientCount])

  useEffect(() => {
    if (amount > maxPerRecipient) {
      setError('amount', {
        type: 'maxLength',
        message: t('Enter a point amount that is {maxPerRecipient} or less', { maxPerRecipient }),
      })
    } else {
      // If the amount is less than or equal to max points per recipient
      // let's trigger revalidation on the amount field as it has
      // other validations rules defined in the schema that
      // may need to be applied.
      trigger('amount')
    }
  }, [amount, maxPerRecipient, setError])

  useEffect(() => {
    const setPointOptions = points.map(p => parseInt(p.id, 10))
    if (!setPointOptions.includes(amount)) {
      setIsUsingCustomAmount(true)
    }
  }, [amount])

  useEffect(() => {
    updateDisabledChips()
  }, [recipientCount, availablePoints])

  const updateDisabledChips = () => {
    // A list of all the values we need to disable.
    const calculatedDisabled = points.filter(p => Number(p.id) > maxPerRecipient).map(p => p.id)

    // If we've not set a custom amount, and their total exceeds,
    // then automatically set to the next biggest one on the list.
    if (!isUsingCustomAmount && amount * recipientCount > availablePoints) {
      const maxAllowed = points
        .filter((c: ChipSelect) => !calculatedDisabled.includes(c.id))
        .sort((a: ChipSelect, b: ChipSelect) => (Number(a.id) < Number(b.id) ? -1 : 1))
        .pop()

      !!maxAllowed && setValue('amount', parseInt(maxAllowed.id, 10))
    }

    setDisabledValues(calculatedDisabled)
  }

  const totalSpend = useMemo(() => {
    if (recipientCount > 0 && amount > 0) {
      return recipientCount * amount
    } else {
      return 0
    }
  }, [recipientCount, amount])

  const showEstimate = useMemo(() => {
    return amount > 0 && recipientCount > 0 && totalSpend <= availablePoints
  }, [amount, recipientCount])

  const handleFixedSelection = (value: number) => {
    onChange(value)
    setIsUsingCustomAmount(false)
  }

  const openCustomSelection = () => {
    onChange()
    setIsUsingCustomAmount(true)
  }

  return (
    <>
      <Box pb={2}>
        <Stack useFlexGap direction="row" flexWrap="wrap" spacing={1}>
          {points.map(p => (
            <Tooltip
              id={`tooltipCheckmarkChip-${p.id}`}
              key={p.id}
              title={disabledValues?.includes(p.id) ? <T _str="Not enough points" /> : null}
              placement="top">
              <Stack>
                <CheckmarkChip
                  id={`pointsAmountChip-${p.id}`}
                  aria-labelledby={`tooltipCheckmarkChip-${p.id}`}
                  checked={!isUsingCustomAmount && amount === Number(p.id)}
                  label={p.label}
                  value={p.id}
                  disabled={disabledValues?.includes(p.id) ?? false}
                  onChange={value => handleFixedSelection(parseInt(value, 10))}
                />
              </Stack>
            </Tooltip>
          ))}
          <CheckmarkChip
            id={'pointsAmountChip-other'}
            checked={isUsingCustomAmount}
            label="Other"
            value=""
            disabled={availablePoints === 0}
            onChange={openCustomSelection}
          />
        </Stack>
      </Box>
      {isUsingCustomAmount && (
        <Box width={150}>
          <NumericFormat
            name="amount"
            label={<T _str="Custom point amount" />}
            labelStyle={{ mt: 0 }}
            customInput={TextField}
            allowNegative={false}
            allowLeadingZeros={false}
            decimalScale={0}
            value={amount || ''}
            onChange={e => onChange(parseInt(e.target.value, 10))}
          />
        </Box>
      )}
      {!!arePointsLoaded && !showEstimate && (
        <PointsEstimateInfoBox>
          <Stack direction="row" useFlexGap gap={1}>
            <EngagementIcon fill={colors.bluePrimary} />
            <Typography>
              <T
                _str="You have {remaining} points left to give"
                remaining={<b>{availablePoints}</b>}
              />
            </Typography>
          </Stack>
        </PointsEstimateInfoBox>
      )}
      {showEstimate && (
        <PointsEstimateInfoBox>
          <Stack direction="row" useFlexGap gap={1}>
            <EngagementIcon fill={colors.bluePrimary} />
            <Stack direction="column">
              <Typography>
                <T
                  _str="{points} points x {totalRecipients} recipients = {totalSpend} points"
                  points={amount}
                  totalRecipients={recipientCount}
                  totalSpend={totalSpend}
                />
              </Typography>
              <Typography>
                <T
                  _str="After sending this message, you will have {remaining} points left to give"
                  remaining={<b>{availablePoints - totalSpend}</b>}
                />
              </Typography>
            </Stack>
          </Stack>
        </PointsEstimateInfoBox>
      )}
    </>
  )
}

export default PointsSelectionField
