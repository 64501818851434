import { colors } from '@globals/themes/defaultTheme'
import { spacing } from '@globals/themes/themeConstants'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const PointsEstimateInfoBox = styled(Box)({
  backgroundColor: colors.blueExtraLight,
  borderRadius: spacing(1),
  padding: spacing(2),
  '> p': {
    color: colors.blueDark,
  },
})
