import theme from '@globals/themes/defaultTheme'
import { spacing, tabletQuery } from '@globals/themes/themeConstants'
import Stack from '@mui/material/Stack'
import { SxProps } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { PropsWithChildren } from 'react'
import BottomNavMobile from './BottomNavMobile'
import { StyledDrawer } from './Nav.styled'

type NavDrawerMobileProps = PropsWithChildren & {
  isMobileDrawerOpen: boolean
  onMobileDrawerToggle: () => void
}

const bottomNavMenuWrapperProps: SxProps = {
  zIndex: theme.zIndex.drawer + 1,
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
}

const mobileDrawerProps: SxProps = {
  '& .MuiDrawer-paper': {
    paddingBottom: spacing(8),
  },
}

export const NavDrawerMobile: React.FC<NavDrawerMobileProps> = ({
  children,
  isMobileDrawerOpen,
  onMobileDrawerToggle,
}) => {
  const isTablet = useMediaQuery(tabletQuery)
  return (
    <>
      <Stack sx={bottomNavMenuWrapperProps}>
        <BottomNavMobile
          isMobileDrawerOpen={isMobileDrawerOpen}
          onMobileDrawerToggle={onMobileDrawerToggle}
        />
      </Stack>
      <StyledDrawer
        variant="temporary"
        anchor="right"
        sx={isTablet ? mobileDrawerProps : {}}
        open={isMobileDrawerOpen}
        onClose={onMobileDrawerToggle}>
        {children}
      </StyledDrawer>
    </>
  )
}
