import { colors } from '@globals/themes/defaultTheme'
import { spacing } from '@globals/themes/themeConstants'
import DeleteOutline from '@mui/icons-material/DeleteOutline'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import { SxProps } from '@mui/material/styles'
import { T, useT } from '@transifex/react'
import { FC } from 'react'
import MediaImage from '../MediaImage'

const boxStyle: SxProps = {
  position: 'relative',
  overflow: 'hidden',
}

const backdropStyle: SxProps = {
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  opacity: 0.25,
  color: colors.white,
}

type MediaAttachmentTileProps = {
  mediaObjectId?: string
  src?: string
  alt: string
  isUploading?: boolean
  index?: number
  size?: 'sm' | 'md'
  onDelete?: (mediaObjectId?: string) => void
  disabled?: boolean
}

const MediaAttachmentTile: FC<MediaAttachmentTileProps> = ({
  mediaObjectId,
  src,
  alt,
  isUploading = false,
  index = -1,
  onDelete,
  disabled,
}) => {
  const t = useT()

  const size = spacing(15)

  const handleDeleteClick = async () => {
    if (!!onDelete) onDelete(mediaObjectId)
  }

  // NOTE: because the nested Box is positioned 'relative', the outer Stack
  // wrapper allows the component to layout correctly in a parent grid/stack.
  // It appears to be unneccessary, but it is not :)
  return (
    <Stack>
      <Box component={Paper} sx={boxStyle} width={size} height={size}>
        {!!src && (
          <MediaImage
            alt={alt ?? t('Attachment image {index}', { index })}
            imgSrc={src}
            sx={{ objectFit: 'cover' }}
            width={size}
            height={size}
          />
        )}
        <Backdrop open={isUploading} sx={backdropStyle}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {!isUploading && !!onDelete && (
          <Tooltip id="tooltipDeleteImage" title={<T _str="Delete attachment" />} placement="top">
            <IconButton
              aria-label={t('Delete attachment')}
              onClick={handleDeleteClick}
              color="primary"
              id="btnDeleteImage"
              aria-labelledby="tooltipDeleteImage"
              size="small"
              component="span"
              sx={{
                position: 'absolute',
                right: spacing(0.5),
                top: spacing(0.5),
                backgroundColor: colors.white,
              }}
              disabled={isUploading || disabled}>
              <DeleteOutline />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Stack>
  )
}

export default MediaAttachmentTile
