import {
  Announcement,
  Award,
  CalendarEvent,
  KudosTvItem as KudosTvItemType,
  Recognition,
} from '@kudos/http-client'
import { ErrorBoundary } from '@rollbar/react'
import React from 'react'
import TvAnnouncement from './TvAnnouncement'
import TvAward from './TvAward'
import TvCelebration from './TvCelebration'
import TvRecognition from './TvRecognition'

type KudosTvItemProps = {
  item: KudosTvItemType
}

const KudosTvItem: React.FC<KudosTvItemProps> = ({ item }) => {
  const items: Record<KudosTvItemType['__entity_type__'], React.ReactNode> = {
    recognition: <TvRecognition recognition={item as Recognition} />,
    award: <TvAward award={item as Award} />,
    announcement: <TvAnnouncement announcement={item as Announcement} />,
    calendarEvent: <TvCelebration celebration={item as CalendarEvent} />,
  }

  if (!item?.__entity_type__) return null

  const Item = items[item.__entity_type__]

  if (!Item) return null

  return <ErrorBoundary>{Item}</ErrorBoundary>
}

export default KudosTvItem
