import { colors } from '@globals/themes/defaultTheme'
import { spacing, tabletQuery } from '@globals/themes/themeConstants'
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import { SxProps, styled } from '@mui/material/styles'
import { DRAWER_WIDTH } from './Nav.constants'

export const KudosLogo = styled('img')({
  width: 58,
  display: 'inline-block',
  verticalAlign: 'middle',
  opacity: 0.3,
  paddingBottom: 4,
})

export const PoweredBy = styled(Typography)({
  width: '100%',
  textAlign: 'center',
})

export const StyledListItem = styled(ListItem)({
  fontWeight: 600,
  padding: 0,
  '& .MuiListItemButton-root': {
    paddingLeft: 24,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 5,
    boxSizing: 'border-box',
    border: '1px solid transparent', // 'transparent' required to stop shifts on focus
  },
  '& .MuiListItemButton-root:hover': {
    backgroundColor: colors.blueExtraLight,
  },
  '& .MuiListItemButton-root:focus': {
    backgroundColor: `${colors.white} !important`,
    border: `1px solid ${colors.bluePrimary}`,
    '& *': {
      color: `${colors.blueDark} !important`,
    },
  },
  '& .Mui-focusVisible': {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.bluePrimary,
    backgroundColor: 'transparent !important',
  },
  '& .Mui-selected': {
    backgroundColor: `${colors.blueUltraLight} !important`,
    '& *': {
      color: `${colors.bluePrimary} !important`,
    },
  },
  '& .Mui-selected.Mui-focusVisible': {
    backgroundColor: `${colors.blueUltraLight} !important`,
    opacity: 1,
  },
  '& .Mui-disabled': {
    opacity: 1,
    backgroundColor: colors.grayUltraLight,
    '& svg': {
      color: colors.grayPrimary,
    },
    '& span': {
      color: colors.grayMedium,
    },
  },
  '& .MuiTypography-root': { fontWeight: 600 },
  '& .MuiListItemIcon-root': { minWidth: 32 },
})

export const StyledArrowBackIcon = styled(ArrowBackIcon)({
  paddingLeft: 8,
  stroke: 'currentcolor',
  strokeWidth: 1,
})

export const NavContainer = styled('div')({
  padding: 0,
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 8,
  paddingBottom: 8,
})

export const StyledDrawer = styled(Drawer)(() => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: DRAWER_WIDTH,
    boxSizing: 'border-box',
    borderRadius: 0,
    boxShadow: 'none',
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    paddingTop: spacing(6),
    paddingBottom: spacing(6),
    height: '100dvh',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}))

export const IconButtonStyled = styled(IconButton)({
  height: 40,
  '&.MuiAppBar-root': {
    backgroundColor: colors.bluePrimary,
  },
})

export const appBarTypographyStyle: SxProps = {
  [tabletQuery]: {
    mt: 1,
  },
}
