import { useTimelineFiltersStore } from '@globals/stores/useTimelineFiltersStore'
import {
  AnnouncementEmbeds,
  AwardEmbeds,
  RecognitionEmbeds,
  listTimelineItems,
} from '@kudos/http-client'
import { SearchState } from '@shared/search/state'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { TimelineFilterFields } from './filters/types'

export const recognitionEmbeds: RecognitionEmbeds[] = [
  'sender',
  'qualities',
  'sendTo',
  'attachmentUrls',
  'endorsementStats',
  'reactionCounts',
  'comments',
  'ownReaction',
  'recipientCount',
]

export const awardEmbeds: AwardEmbeds[] = [
  'recipientCount',
  'badgeUrls',
  'sender',
  'sendTo',
  'comments',
  'reactionCounts',
  'endorsementStats',
  'ownReaction',
]

export const announcementEmbeds: AnnouncementEmbeds[] = [
  'createdBy',
  'attachmentUrls',
  'status',
  'readAt',
]

export const TIMELINE_ITEMS_QK = 'timelineItemsQueryKey'

const useTimelineItems = () => {
  const { timelineFilters, setTimelineFilters } = useTimelineFiltersStore()
  const [filters, setFilters] = useState<SearchState<TimelineFilterFields>>(timelineFilters)

  const parseTimelineFilters = (filters: SearchState<TimelineFilterFields>) => {
    const { teamIdIn, itemTypeIn } = filters

    return {
      teamIdIn,
      itemTypeIn,
    }
  }

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } = useInfiniteQuery({
    queryKey: [TIMELINE_ITEMS_QK],
    queryFn: ({ pageParam = null }: { pageParam: null | string }) =>
      listTimelineItems({
        cursor: pageParam,
        recognitionEmbeds,
        awardEmbeds,
        announcementEmbeds,
        ...parseTimelineFilters(filters),
      }),
    staleTime: 1000 * 60 * 1, // 1 minute
    getNextPageParam: lastPage => lastPage.next,
    initialPageParam: null,
  })

  useEffect(() => {
    setTimelineFilters(filters)
    refetch()
  }, [filters])

  return {
    timelineData: data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    filters,
    setFilters,
  }
}

export default useTimelineItems
