import Button from '@mui/material/Button'

type SaveAsDraftButtonProps = {
  buttonId: string
  buttonText: React.ReactNode
  fullWidth: boolean
  isDisabled?: boolean
  onSaveAsDraftClick: () => void
  variant: 'contained' | 'outlined'
}

const SaveAsDraftButton: React.FC<SaveAsDraftButtonProps> = ({
  buttonId,
  buttonText,
  fullWidth,
  isDisabled,
  onSaveAsDraftClick,
  variant,
}) => (
  <Button
    id={buttonId}
    component="button"
    fullWidth={fullWidth}
    variant={variant}
    disabled={isDisabled}
    onClick={onSaveAsDraftClick}>
    {buttonText}
  </Button>
)

export default SaveAsDraftButton
