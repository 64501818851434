import { formatDistanceToNow } from 'date-fns'
import { useT } from '@transifex/react'
import { useMemo } from 'react'

type TimeAgoProps = {
  date: string
}

const TimeAgo = ({ date }: TimeAgoProps) => {
  const t = useT()
  const formatted = useMemo(() => {
    try {
      return `${formatDistanceToNow(new Date(date))} ${t('ago')}`
    } catch {
      return '(invalid)'
    }
  }, [date])
  return formatted
}

export default TimeAgo
