import Autocomplete from '@mui/material/Autocomplete'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { useT } from '@transifex/react'
import { ReactElement, ReactNode } from 'react'

export type AutoCompleteOption = {
  id: string
  value: string
  icon?: ReactElement
}

type StandardAutoCompleteSearchProps = {
  onSearchTextChanged: (searchText: string) => void
  onSelectedOptionsChanged: (option: AutoCompleteOption[]) => void
  label?: ReactNode
  placeholder: string
  value: AutoCompleteOption[]
  searchText: string
  options: AutoCompleteOption[]
  renderChip: (
    option: AutoCompleteOption,
    onDelete: (event: React.MouseEvent<HTMLButtonElement>) => void,
    index: number,
  ) => ReactNode
  id?: string
}
const StandardAutoCompleteSearch: React.FC<StandardAutoCompleteSearchProps> = ({
  onSearchTextChanged,
  onSelectedOptionsChanged,
  label,
  placeholder,
  value,
  searchText,
  options,
  renderChip,
  id = 'autocompleteSearch',
}) => {
  const t = useT()
  return (
    <Stack direction="column" useFlexGap gap={0.25}>
      {label}
      <Autocomplete
        multiple
        id={id}
        placeholder={placeholder}
        value={value}
        options={options}
        autoComplete
        fullWidth
        inputValue={searchText}
        noOptionsText={t('No results found')}
        getOptionLabel={option => (option as AutoCompleteOption).value}
        onChange={(_, newOptions) => {
          onSelectedOptionsChanged(newOptions as AutoCompleteOption[])
          onSearchTextChanged('')
        }}
        renderTags={(value, getTagProps) =>
          value.map((value, index) => {
            const { onDelete } = getTagProps({ index })
            return renderChip(value, onDelete, index)
          })
        }
        renderInput={params => {
          return (
            <TextField
              {...params}
              fullWidth
              placeholder={placeholder}
              onChange={e => onSearchTextChanged(e.target.value)}
              size="small"
            />
          )
        }}
      />
    </Stack>
  )
}

export default StandardAutoCompleteSearch
