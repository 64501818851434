import { GiphyFetch } from '@giphy/js-fetch-api'
import { IGif } from '@giphy/js-types'
import { Grid } from '@giphy/react-components'
import { tabletQuery } from '@globals/themes/themeConstants'
import { useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { SxProps } from '@mui/material/styles'
import useContainerDimensions from '@shared/hooks/useContainerDimensions'
import DebouncedSearchInput from '@shared/search/DebouncedSearchInput'
import { FC, useRef, useState } from 'react'
import { useDebounce } from 'use-debounce'
import useGiphyDialog from './useGiphyDialog'

const gridContainerStyle: SxProps = {
  overflowY: 'scroll',
  '& .giphy-grid': {
    maxHeight: 400,
  },
}

type GiphySearchContentProps = {
  handleGifClick: (gif: IGif, e: React.SyntheticEvent<HTMLElement, Event>) => void
}

const GiphySearchContent: FC<GiphySearchContentProps> = ({ handleGifClick }) => {
  const isTablet = useMediaQuery(tabletQuery)

  const [searchValue, setSearchValue] = useState<string>('')
  const [debouncedSearchValue] = useDebounce(searchValue, 300)

  const containerRef = useRef<HTMLDivElement>(null)
  const dimensions = useContainerDimensions(containerRef)

  const { giphyApiKey } = useGiphyDialog()

  const giphyApi = new GiphyFetch(giphyApiKey)

  const giphyFetchFn = (offset: number) => {
    const fetchOptions = { offset, limit: 10 }

    if (debouncedSearchValue) {
      return giphyApi.search(debouncedSearchValue, fetchOptions)
    } else {
      return giphyApi.trending(fetchOptions)
    }
  }

  const handleSearchInputChange = (searchText: string) => {
    setSearchValue(searchText)
  }

  return (
    <Stack mt={2} spacing={2}>
      <DebouncedSearchInput elementId="giphySearchInput" onChange={handleSearchInputChange} />
      <Box id="giphyGridContainer" height={400} ref={containerRef} sx={gridContainerStyle}>
        <Grid
          columns={isTablet ? 3 : 5}
          fetchGifs={giphyFetchFn}
          gutter={8}
          hideAttribution
          key={debouncedSearchValue}
          onGifClick={handleGifClick}
          width={dimensions.width}
        />
      </Box>
    </Stack>
  )
}

export default GiphySearchContent
