import { setSvgIconSize } from '@globals/themes/globalStyles'
import AttachFileRounded from '@mui/icons-material/AttachFileRounded'
import Button from '@mui/material/Button'
import { T, useT } from '@transifex/react'

type AddAttachmentButtonProps = {
  buttonId: string
  onClick: () => void
  disabled?: boolean
  ariaLabelledBy?: string
}

const AddAttachmentButton: React.FC<AddAttachmentButtonProps> = ({
  buttonId,
  onClick,
  ariaLabelledBy,
  disabled = false,
}) => {
  const t = useT()

  return (
    <Button
      id={buttonId}
      aria-label={t('Add attachment button')}
      sx={{ ...setSvgIconSize('1.5rem'), mt: 2 }}
      onClick={onClick}
      variant="text"
      disabled={disabled}
      aria-labelledby={ariaLabelledBy}
      startIcon={<AttachFileRounded />}>
      <T _str="Add attachment" />
    </Button>
  )
}

export default AddAttachmentButton
