import { API, unwrapData } from '../axios-instance'
import { MediaObjectUrl, UploadMediaParams } from './types'

export const uploadMedia = async ({ url, data }: UploadMediaParams) => {
  return await API.post<undefined>(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: undefined,
    },
  }).then(unwrapData)
}

export const getMediaObjectUrls = async (mediaId: string) => {
  return await API.get<MediaObjectUrl>(`/media/mediaObjects/${mediaId}/urls`).then(unwrapData)
}
