import { useAuth } from '@auth/useAuth'
import AppPaths from '@globals/paths'
import { Token, retrieveTenantAccessToken } from '@kudos/http-client'
import { LinearProgress, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { T } from '@transifex/react'
import { useEffect, useRef } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'

export const TenantLoginRoute = () => {
  const [searchParams] = useSearchParams()
  const { initialize, isTenantScope } = useAuth()

  const state = searchParams.get('state')
  const next = searchParams.get('next')

  if (!state) return <Navigate to={AppPaths.anon.userLogin()} />

  // if 'next' path is set for deep linking, either by SSO or by the
  // password flow, send the user to their original destination
  if (isTenantScope && !!next) return <Navigate to={next} />

  // otherwise, if we are authenticated and have a tenant,
  // default the user to their home page
  if (isTenantScope) return <Navigate to={AppPaths.tenant.home()} />

  return <RetrieveTenantToken key={state} state={state} onSuccess={initialize} />
}

const RetrieveTenantToken = ({
  state,
  onSuccess,
}: {
  state: string
  onSuccess: (token: Token) => void
}) => {
  const {
    isPending,
    isError,
    mutate: retrieveToken,
  } = useMutation({
    mutationFn: retrieveTenantAccessToken,
    onSuccess: tenantAccessToken => {
      onSuccess(tenantAccessToken)
    },
  })

  const hasRetrieved = useRef<boolean>(false)

  useEffect(() => {
    // Must only retrieve the token once on first mount
    if (!hasRetrieved.current) retrieveToken({ state })
    hasRetrieved.current = true
  }, [state])

  return (
    <>
      <Typography>
        <T _str="Logging into tenant..." />
      </Typography>
      {isPending && <LinearProgress />}
      {isError && 'Oops. Something went wrong logging you in.'}
    </>
  )
}

export default TenantLoginRoute
