import { Theme } from '@mui/material'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import theme from './defaultTheme'

export type ThemeStoreState = {
  toggleTheme: () => void
  isDarkTheme: boolean
  getCurrentTheme: () => Theme
}

export const useThemeStore = create<ThemeStoreState>()(
  devtools(
    persist(
      (set, get) => ({
        toggleTheme: () => set(state => ({ isDarkTheme: !state.isDarkTheme })),
        isDarkTheme: false,
        // Dark mode not supported at the moment but might be later. For now we keep
        // the ability to store and remember theme but don't actually expose it to the user.
        getCurrentTheme: () => (get().isDarkTheme ? theme : theme),
      }),
      {
        name: 'theme-storage',
      },
    ),
    { store: 'useThemeStore' },
  ),
)
