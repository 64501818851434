import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import StandardPageLayout from '@layouts/standardPageLayout'
import RouteLoading from '@shared/routing/RouteLoading'
import { T } from '@transifex/react'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'
import { RecognitionModalViews } from './RecognitionModal/useRecognitionModal'

const ViewRecognitionRoute = lazyWithRetries(() => import('./viewRecognition'))
const HomeRoute = lazyWithRetries(() => import('@tenant/home'))

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.viewRecognition(':id'),
    element: (
      <StandardPageLayout
        elementId="viewRecognitionRoute"
        title={<T _str="Recognize" />}
        maxWidth={600}>
        <Suspense fallback={<RouteLoading />}>
          <ViewRecognitionRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.sendRecognition(),
    element: (
      <Suspense fallback={<RouteLoading />}>
        <HomeRoute openRecognitionView={RecognitionModalViews.SendRecognition} />,
      </Suspense>
    ),
  },
  {
    path: AppPaths.tenant.viewDraftRecognitions(),
    element: (
      <Suspense fallback={<RouteLoading />}>
        <HomeRoute openRecognitionView={RecognitionModalViews.DraftAndInProgressRecognitions} />,
      </Suspense>
    ),
  },
  {
    path: AppPaths.tenant.viewDraftRecognition(':id'),
    element: (
      <Suspense fallback={<RouteLoading />}>
        <HomeRoute openRecognitionView={RecognitionModalViews.SendRecognition} />,
      </Suspense>
    ),
  },
]

export default routes
