import { colors } from '@globals/themes/defaultTheme'
import { PointsExceptionType } from '@kudos/http-client'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import Chip from '@mui/material/Chip'
import { T } from '@transifex/react'
import { ReactNode } from 'react'

const labelMapping: Record<PointsExceptionType, ReactNode> = {
  insufficientPoints: <T _str="Points adjusted" />,
  notEligible: <T _str="Points removed" />,
  restriction: <T _str="Points adjusted" />,
}

type PointsExceptionChipProps = {
  exceptionType: PointsExceptionType
}

const PointsExceptionChip: React.FC<PointsExceptionChipProps> = ({ exceptionType }) => (
  <Chip
    label={labelMapping[exceptionType]}
    icon={<WarningAmberOutlinedIcon sx={{ color: `${colors.goldDark} !important` }} />}
    sx={{
      backgroundColor: colors.orangeExtraLight,
      '& .MuiChip-label': { color: colors.orangeDark },
      width: 'fit-content',
    }}
  />
)

export default PointsExceptionChip
