import { AvatarProps } from '@mui/material/Avatar'
import { PaperProps } from '@mui/material/Paper/Paper'
import { Interpolation } from '@mui/material/styles'
import { Theme } from '@mui/material/styles/createTheme'
import { mobileQuery, onActive, onFocus, onHover, spacing } from './themeConstants'

export const ActivityColors = {
  hoverShadow: '0px 2px 7px 0px rgba(62, 66, 74, 0.15)',
  focusShadow: '0px 2px 6px 1px rgba(60, 95, 174, 0.16), 0px 2px 2px 0px rgba(60, 95, 174, 0.10)',
  checkInAquaPrimary: '#1A7680',
  checkInWhite: '#FFF',
} as const

export type ActivityColorsT = keyof typeof ActivityColors

declare module '@mui/material/Avatar' {
  interface AvatarPropsVariantOverrides {
    header: true
    nudge: true
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    nudge: true
  }
}

export const ActivityAvatarVariants: {
  props: Partial<AvatarProps>
  style: Interpolation<{
    theme: Theme
  }>
}[] = [
  {
    props: { variant: 'nudge' },
    style: {
      opacity: 1,
      width: spacing(4),
      height: spacing(4),
    },
  },
  {
    props: { variant: 'header' },
    style: {
      opacity: 1,
      width: spacing(6),
      height: spacing(6),
    },
  },
]

export const ActivityPaperVariants: {
  props: Partial<PaperProps>
  style: Interpolation<{
    theme: Theme
  }>
}[] = [
  {
    props: { variant: 'nudge', color: 'checkIn' },
    style: {
      borderRadius: spacing(2),
      cursor: 'pointer',
      padding: spacing(4),
      textAlign: 'left',
      backgroundColor: ActivityColors.checkInAquaPrimary,
      boxShadow: 'unset',
      border: 'unset',
      [onHover]: {
        boxShadow: ActivityColors.hoverShadow,
      },
      [onFocus]: {
        boxShadow: ActivityColors.focusShadow,
      },
      [onActive]: {
        backgroundColor: ActivityColors.checkInAquaPrimary,
      },
      [mobileQuery]: {
        padding: spacing(3),
      },
    },
  },
]
