import theme from '@globals/themes/defaultTheme'
import { tabletQuery } from '@globals/themes/themeConstants'
import Close from '@mui/icons-material/Close'
import { SxProps } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { FC } from 'react'

const closeButtonStyle: SxProps = {
  position: 'absolute',
  right: theme.spacing(5),
  top: theme.spacing(5),
  [tabletQuery]: {
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
}

type CloseButtonProps = {
  id: string
  ariaLabel: string
  onClose: () => void
  sx?: SxProps
}
const CloseButton: FC<CloseButtonProps> = ({ id, ariaLabel, onClose, sx }) => {
  return (
    <IconButton
      aria-label={ariaLabel}
      id={id}
      onClick={onClose}
      sx={[closeButtonStyle, ...(Array.isArray(sx) ? sx : [sx])]}>
      <Close />
    </IconButton>
  )
}

export default CloseButton
