import CheckOutlined from '@mui/icons-material/CheckOutlined'
import Chip from '@mui/material/Chip'
import { FC } from 'react'
import useCustomColorChipStyle from '../hooks/useCustomColorChipStyle'
import Tooltip from '@mui/material/Tooltip'

type CheckmarkChipProps = {
  id: string
  checked?: boolean
  label: string
  value: string
  color?: string
  disabled?: boolean
  onChange: (value: string) => void
  tooltipTitle?: string
}
const CheckmarkChip: FC<CheckmarkChipProps> = ({
  id,
  checked = false,
  label,
  value,
  onChange,
  disabled,
  color,
  tooltipTitle,
}) => {
  const { chipVariant, chipSx, checkmarkSx } = useCustomColorChipStyle(checked, color)

  const handleClick = () => {
    !!onChange && onChange(value)
  }

  return (
    <Tooltip title={tooltipTitle} placement="top">
      <Chip
        id={id}
        icon={checked ? <CheckOutlined sx={checkmarkSx} /> : undefined}
        label={label}
        onClick={handleClick}
        variant={chipVariant}
        disabled={disabled ?? false}
        sx={chipSx}
      />
    </Tooltip>
  )
}

export default CheckmarkChip
