import { Announcement } from '../announcement'
import { Award } from '../award'
import { API, unwrapData } from '../axios-instance'
import { Recognition } from '../recognition'
import { CursorResult } from '../shared/types'

export type PinItemParams = {
  days?: number
}

export type TimelineItemsParams = {
  announcementEmbeds?: string[]
  awardEmbeds?: string[]
  recognitionEmbeds?: string[]
  cursor?: string | null
  pageSize?: number
  itemIdIn?: string[]
  memberIdIn?: string[]
  teamIdIn?: string[]
  itemTypeIn?: string[]
}

export type PinnedTimelineItemsParams = {
  announcementEmbeds?: string[]
  awardEmbeds?: string[]
  recognitionEmbeds?: string[]
  pageSize?: number
}

export type TimelineItem = Recognition | Announcement | Award

// call endpoint with itemId of a pinned item to unpin
export const pinItem = async (itemId: string, params?: PinItemParams) => {
  return await API.put(`timeline/items/${itemId}/pin`, params).then(unwrapData)
}

export const listTimelineItems = async (params: TimelineItemsParams) => {
  return await API.get<CursorResult<TimelineItem>>('timeline/items', { params }).then(unwrapData)
}

export const listPinnedTimelineItems = async (params: PinnedTimelineItemsParams) => {
  return await API.get<CursorResult<TimelineItem>>('timeline/items/pinned', { params }).then(
    unwrapData,
  )
}
