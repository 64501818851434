import DefaultTenantLogo from '@assets/images/DefaultTenantLogo.png'
import { colors } from '@globals/themes/defaultTheme'
import { useTenantMetadata } from '@globals/useTenantMetadata'
import { MediaObjectUrl, MediaVariantEnum } from '@kudos/http-client'
import Box from '@mui/material/Box'
import MediaImage from '@shared/components/MediaImage'
import useMediaUrl from '@shared/hooks/useMediaUrl'
import { FC } from 'react'

export const TENANT_LOGO_WIDTH = 264
export const TENANT_LOGO_HEIGHT = 52

type TenantLogoProps = {
  logoMediaUrls?: MediaObjectUrl
}
export const TenantLogo: FC<TenantLogoProps> = ({ logoMediaUrls }) => {
  const [metadata] = useTenantMetadata()
  const mediaUrls = logoMediaUrls === undefined ? metadata.logoUrls : logoMediaUrls

  const { url: logoUrl } = useMediaUrl(mediaUrls, MediaVariantEnum.LOGO)

  return (
    // this box adds the necessary extra width when the logo chosen
    // is smaller than expected. it will fill the extra space for layout
    // purposes (which the img component won't do)
    // see myTenants/index.tsx for an example of where this is required.
    <Box maxWidth={TENANT_LOGO_WIDTH} maxHeight={TENANT_LOGO_HEIGHT}>
      <MediaImage
        alt={metadata.name}
        imgSrc={logoUrl || DefaultTenantLogo}
        width={TENANT_LOGO_WIDTH}
        height={TENANT_LOGO_HEIGHT}
        objectFit="contain"
        backgroundColor={colors.white}
      />
    </Box>
  )
}

export default TenantLogo
