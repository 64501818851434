import { SxProps } from '@mui/material'
import { colors } from './defaultTheme'
import { onDisabled } from './themeConstants'

export const WhiteSpaceNoWrap: SxProps = {
  whiteSpace: 'nowrap',
}

export const WhiteSpacePreWrap: SxProps = {
  whiteSpace: 'pre-wrap',
}

/**
 * @param size CSS font-size property for the MuiSvgIcon e.g. '1.5rem'
 * @returns SxProps that removes left+rignt margin and sets the icon size
 */
export const setSvgIconSize = (size: '1.5rem' | '2rem'): SxProps => {
  return {
    // apply to the base component e.g. MuiIconButton-root, MuiButton-root
    '.MuiSvgIcon-root': {
      fontSize: size,
    },
    // apply to all children of the base component e.g. MuiButton-startIcon
    '& > *, & .MuiButton-startIcon, & .MuiButton-endIcon': {
      marginLeft: 0,
      marginRight: 0,
      '.MuiSvgIcon-root': {
        fontSize: size,
      },
    },
    // apply to all children of the base component when the button is disabled
    [onDisabled]: {
      '.MuiSvgIcon-root': {
        color: colors.grayMedium,
      },
    },
  }
}
