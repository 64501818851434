import { AxiosError } from 'axios'
import { API, unwrapData } from '../axios-instance'
import { Batch, BatchItem, BatchResult, BatchRoutes } from './batch.types'
import { BatchNotFoundError } from './errors'
import { PageResult } from './types'

const handleBatchErrors = (error: AxiosError) => {
  if (error.response?.status === 404) {
    throw new BatchNotFoundError()
  }
  throw error
}

export const createBatchRoutes = <FiltersT, ItemT, ParamsT, ErrorsT>(
  basePath: string,
): BatchRoutes<FiltersT, ItemT, ParamsT, ErrorsT> => {
  return {
    init: (body, filters) => API.post<Batch>(basePath, body, { params: filters }).then(unwrapData),
    preview: (batchId, pagination: { page: number; pageSize: number }) =>
      API.get<PageResult<BatchItem<ItemT, ParamsT, ErrorsT>>>(`${basePath}/${batchId}`, {
        params: pagination,
      })
        .then(unwrapData)
        .catch(handleBatchErrors),
    update: (batchId, actions) =>
      API.put<true>(`${basePath}/${batchId}`, actions).then(unwrapData).catch(handleBatchErrors),
    execute: (batchId, body) =>
      API.post<BatchResult>(`${basePath}/${batchId}`, body)
        .then(unwrapData)
        .catch(handleBatchErrors),
  }
}
