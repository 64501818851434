import { ActionButton } from '@components/button/types'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import Button from '@mui/material/Button'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { SxProps } from '@mui/material/styles'
import { useT } from '@transifex/react'
import { flatten, map as mapFp } from 'lodash/fp'
import { useState } from 'react'
import { Link } from 'react-router-dom'

type MoreActionsMenuProps = {
  actions: ActionButton[]
  buttonStyle?: SxProps
}

const MoreActionsMenu: React.FC<MoreActionsMenuProps> = ({ actions, buttonStyle }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const t = useT()

  const visibleActions = actions.filter(action =>
    action.visible === undefined ? true : action.visible,
  )
  if (visibleActions.length === 0) return null

  const regularButtonActions = visibleActions.filter(action => action.type !== 'split')
  const splitButtons = visibleActions.filter(action => action.type === 'split')
  const splitButtonsActions = flatten(mapFp('actions', splitButtons))

  const allVisibleActions = [...regularButtonActions, ...splitButtonsActions]

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        id="btnMoreActionButtons"
        aria-label={t('More action buttons')}
        onClick={handleClick}
        sx={buttonStyle}>
        <MoreVertOutlinedIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          sx: {
            '& .MuiMenuItem-root': {
              minWidth: 150,
            },
          },
        }}>
        {allVisibleActions.map(action =>
          !!action.to ? (
            <MenuItem
              sx={{ color: action.color || 'inherit' }}
              id={`menuItem-${action.key}`}
              key={action.key}
              component={Link}
              onClick={handleClose}
              to={action.to!}
              disabled={action.disabled}>
              {action.startIcon && (
                <ListItemIcon sx={{ '& .MuiSvgIcon-root': { color: action.color } }}>
                  {action.startIcon}
                </ListItemIcon>
              )}
              {action.name}
            </MenuItem>
          ) : (
            <MenuItem
              sx={{ color: action.color || 'inherit' }}
              id={`menuItem-${action.key}`}
              key={action.key}
              onClick={() => {
                action.onClick! && action.onClick()
                handleClose()
              }}
              disabled={action.disabled}>
              {action.startIcon && (
                <ListItemIcon sx={{ '& .MuiSvgIcon-root': { color: action.color } }}>
                  {action.startIcon}
                </ListItemIcon>
              )}
              {action.name}
            </MenuItem>
          ),
        )}
      </Menu>
    </>
  )
}

export default MoreActionsMenu
