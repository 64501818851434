import { colors } from '@globals/themes/defaultTheme'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { SxProps } from '@mui/material/styles'
import { PropsWithChildren } from 'react'

const loginContainerStyle: SxProps = {
  minHeight: { xs: '75dvh', sm: '100dvh' },
  maxWidth: 640,
  width: '100%',
  margin: 'auto',
}

const loginPaperSx: SxProps = {
  padding: { xs: '24px', sm: '48px' },
  borderRadius: '8px',
  width: '100%',
  boxShadow: colors.boxShadow,
}

const CenteredBoxLayout: React.FC<PropsWithChildren> = ({ children }) => (
  <Stack
    spacing={0}
    direction="row"
    alignItems="center"
    justifyContent="center"
    sx={loginContainerStyle}>
    <Paper sx={loginPaperSx}>{children}</Paper>
  </Stack>
)

export default CenteredBoxLayout
