import Stack from '@mui/material/Stack'
import MuiTextField from '@mui/material/TextField'
import { SxProps } from '@mui/material/styles'
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers-pro'
import { useT } from '@transifex/react'
import { isValid } from 'date-fns'
import { Control, FieldValues, Path, useController, useFormContext } from 'react-hook-form'
import ErrorText from './ErrorText'
import FieldLabel from './FieldLabel'
import HelpText from './HelpText'

type DateTimePickerFieldProps<T extends FieldValues> = {
  name: Path<T>
  control?: Control<T>
  label?: React.ReactNode
  placeholder?: string
  required?: boolean
  fullWidth?: boolean
  help?: string
  disabled?: boolean
  labelStyle?: SxProps
  disablePast?: boolean
  timeSteps?: {
    hours?: number
    minutes?: number
    seconds?: number
  }
  endAdornment?: React.ReactNode
}

const DateTimePickerField = <T extends FieldValues>({
  control,
  label,
  name,
  help,
  placeholder,
  fullWidth = true,
  required = true,
  disabled = false,
  labelStyle,
  disablePast = false,
  timeSteps = {
    hours: 1,
  },
  endAdornment,
}: DateTimePickerFieldProps<T>) => {
  const t = useT()
  const {
    field: { value, onChange, ref },
    fieldState: { error },
  } = useController({
    control,
    name,
  })
  const { setError, clearErrors } = useFormContext()

  const handleChange = (date: Date | null) => {
    if (!isValid(date))
      return setError(name, { type: 'manual', message: t('Please enter a valid date and time') })
    clearErrors(name)
    return onChange(date?.toISOString())
  }

  return (
    <Stack width="100%">
      {!!label && (
        <FieldLabel htmlFor={name} required={required} disabled={disabled} labelStyle={labelStyle}>
          {label}
        </FieldLabel>
      )}
      {help && <HelpText disabled={disabled}>{help}</HelpText>}
      <Stack direction="row" spacing={2} justifyContent="center">
        <MuiDateTimePicker
          name={name}
          closeOnSelect={false}
          disablePast={disablePast}
          value={!!value ? new Date(value) : null}
          onChange={handleChange}
          format="dd/MM/yyyy hh:mm aa"
          slots={{ textField: MuiTextField }}
          timeSteps={timeSteps}
          slotProps={{
            textField: {
              placeholder,
              disabled,
              fullWidth,
              error: Boolean(error),
              id: name,
              ref,
              variant: 'outlined',
            },
            actionBar: {
              actions: ['today', 'clear', 'accept'],
            },
            digitalClockSectionItem: {
              sx: {
                borderRadius: 8,
              },
            },
          }}
        />
        {!!endAdornment && endAdornment}
      </Stack>
      {error && <ErrorText>{error.message}</ErrorText>}
    </Stack>
  )
}

export default DateTimePickerField
