import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import RouteLoading from '@shared/routing/RouteLoading'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const LogsRoute = lazyWithRetries(() => import('.'))

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.admin.logs(),
    element: (
      <Suspense fallback={<RouteLoading />}>
        <LogsRoute />
      </Suspense>
    ),
  },
]

export default routes
