import { tabletQuery } from '@globals/themes/themeConstants'
import { useMediaQuery } from '@mui/material'
import Stack, { StackProps } from '@mui/material/Stack'
import { ReactNode } from 'react'

type FormActionTrayProps = StackProps & {
  children: ReactNode | ReactNode[]
}

const FormActionTray: React.FC<FormActionTrayProps> = ({ children, ...restProps }) => {
  const isTablet = useMediaQuery(tabletQuery)

  return (
    <Stack
      direction={isTablet ? 'column-reverse' : 'row'}
      justifyContent="flex-end"
      spacing={2}
      mt={3}
      {...restProps}>
      {children}
    </Stack>
  )
}

export default FormActionTray
