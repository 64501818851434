import ConstrainedWidthLayout from '@layouts/ConstrainedWidthLayout'
import { Typography } from '@mui/material'
import { CodeOutput } from '@shared/CodeOutput.styled'
import { T } from '@transifex/react'
import { useEffect, useState } from 'react'
import { useLocation, useRouteError } from 'react-router-dom'

const sessionKey = 'lazyLoadFailureRetry'

type SessionItem = {
  value: string
  expiry: number
}

/**
 * Stores a session in localStorage with an expiry time.
 * @param {string} key - the session key
 * @param {string} value - the session item value
 * @param {number} ttl - the expiring time for the session (milliseconds)
 */
const setWithExpiry = (key: string, value: string, ttl: number): void => {
  const item: SessionItem = {
    value: value,
    expiry: new Date().getTime() + ttl,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

/**
 * Retrieves a value from localStorage and checks expiry.
 * @param {string} key - the session key
 * @returns {string | null} - The session value or null if expired/invalid
 */
const getWithExpiry = (key: string): string | null => {
  const itemString = window.localStorage.getItem(key)
  if (!itemString) return null

  const item = JSON.parse(itemString)
  const isExpired = new Date().getTime() > item.expiry

  if (isExpired) {
    localStorage.removeItem(key)
    return null
  }

  return item.value
}

export const ErrorRoute = () => {
  const [showError, setShowError] = useState<boolean>(false)
  const location = useLocation()
  const error = useRouteError() as Error

  // Handle failed lazy loading of a JS/CSS chunk.
  useEffect(() => {
    const chunkFailedMessage =
      /(Loading chunk [\d]+ failed|Failed to fetch dynamically imported module: [^]*)/
    if (error?.message && chunkFailedMessage.test(error.message) && !getWithExpiry(sessionKey)) {
      setWithExpiry(sessionKey, 'true', 10000)
      window.location.reload()
    } else {
      setShowError(true)
    }
  }, [error?.message])

  return (
    !!showError && (
      <ConstrainedWidthLayout>
        <Typography variant="h1">
          <T _str="Error in route" />
        </Typography>
        <CodeOutput>{JSON.stringify(location, null, 4)}</CodeOutput>
        <Typography variant="h2">
          <T _str="Error message" />
        </Typography>
        <Typography>{error.message}</Typography>
      </ConstrainedWidthLayout>
    )
  )
}

export default ErrorRoute
