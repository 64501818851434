import { IGif } from '@giphy/js-types'
import { SearchContextManager } from '@giphy/react-components'
import { useTenantMetadata } from '@globals/useTenantMetadata'
import Box from '@mui/material/Box'
import { CustomDialog } from '@shared/dialog/CustomDialog'
import { FC } from 'react'
import GiphySearchContent from './GiphySearchContent'
import useGiphyDialog from './useGiphyDialog'

type GiphySearchDialogProps = {
  onGifSelected: (url: string) => void
}
const GiphySearchDialog: FC<GiphySearchDialogProps> = ({ onGifSelected }) => {
  const [{ language }] = useTenantMetadata()

  const { closeGiphyDialog, giphyDialogKey, giphyApiKey } = useGiphyDialog()

  const handleGifClick = (gif: IGif, e: React.SyntheticEvent<HTMLElement, Event>) => {
    !!onGifSelected && onGifSelected(gif.images.original.url)
    closeGiphyDialog()
    e.preventDefault()
  }

  return (
    <Box display="none">
      <SearchContextManager
        apiKey={giphyApiKey}
        shouldDefaultToTrending
        options={{ lang: language }}>
        <CustomDialog maxWidth="sm" dialogKey={giphyDialogKey}>
          <GiphySearchContent handleGifClick={handleGifClick} />
        </CustomDialog>
      </SearchContextManager>
    </Box>
  )
}

export default GiphySearchDialog
