import KudosLogo from '@assets/images/DialogLogo.png'
import NotFoundImage from '@assets/images/DomainError.png'
import { KUDOS } from '@globals/paths/useDocumentTitle'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { T } from '@transifex/react'
import { useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { ButtonStyles, PageTitleImage, StackStyles } from './RoutingStyles'

type NotFoundRouteProps = {
  message?: React.ReactNode
  to?: string
  buttonText?: React.ReactNode
}

const NotFoundRoute: React.FC<NotFoundRouteProps> = ({ message, to, buttonText }) => {
  useEffect(() => {
    document.title = `${KUDOS} - Oops, not found!`
  }, [])

  return (
    <Stack useFlexGap spacing={3} sx={StackStyles}>
      <PageTitleImage src={KudosLogo} alt="Kudos logo" />
      <Typography variant="h1" textAlign="center">
        {message ? message : <T _str="Page not found" />}
      </Typography>
      <img src={NotFoundImage} alt="Person looking through a pair of binoculars" />
      {to && buttonText && (
        <Button
          id="btnNotFoundRoute"
          variant="contained"
          component={RouterLink}
          sx={ButtonStyles}
          to={to}>
          {buttonText}
        </Button>
      )}
    </Stack>
  )
}

export default NotFoundRoute
