import { useDialog } from '@globals/dialog/useDialogStore'
import { colors } from '@globals/themes/defaultTheme'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import IosShareIcon from '@mui/icons-material/IosShare'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import { T, useT } from '@transifex/react'
import { SyntheticEvent, useEffect, useMemo, useState } from 'react'
import PWAInstallationModal from './PWAInstallationModal'

const dialogKeyIos = 'pwaIsoInstallationPrompt'
const dialogKeyOther = 'pwaInstallationPrompt'

type BeforeInstallPromptEvent = Event & {
  prompt: () => void
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>
}

const cardStyle = {
  p: 2,
  boxShadow: 'none',
  backgroundColor: colors.blueExtraLight,
}

const PWAInstallationPrompt = () => {
  const t = useT()
  const [promptInstall, setPromptInstall] = useState<Event | null>(null)
  const { openDialog: openDialogIos } = useDialog(dialogKeyIos)
  const { openDialog: openDialogOther } = useDialog(dialogKeyOther)

  const isIOS = useMemo(() => {
    if ((navigator as Navigator & { standalone?: boolean }).standalone) {
      //user has already installed the app
      return false
    }
    const ua = window.navigator.userAgent
    const isIPad = !!ua.match(/iPad/i)
    const isIPhone = !!ua.match(/iPhone/i)
    return isIPad || isIPhone
  }, [window.navigator.userAgent])

  useEffect(() => {
    const handler = (e: Event) => {
      e.preventDefault()
      setPromptInstall(e)
    }
    window.addEventListener('beforeinstallprompt', handler)

    return () => window.removeEventListener('transitionend', handler)
  }, [])

  const onClick = (evt: SyntheticEvent<HTMLElement, Event>) => {
    evt.preventDefault()

    if (isIOS) {
      return openDialogIos()
    }

    if (!promptInstall) {
      return openDialogOther()
    }
    ;(promptInstall as BeforeInstallPromptEvent).prompt()
  }

  return (
    <>
      <Card sx={cardStyle}>
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          <T _str="Get instant updates and quick access with the Kudos app" />
        </Typography>
        <Button variant="outlined" id="btnPWAInstall" onClick={onClick}>
          <T _str="Download app" />
        </Button>
      </Card>
      <PWAInstallationModal
        dialogKey={dialogKeyIos}
        listItem1={{
          text: t("In Safari, press the 'Share' button on the menu bar below"),
          icon: <IosShareIcon />,
        }}
        listItem2={{ text: t("Press 'Add to Home Screen'"), icon: <AddBoxOutlinedIcon /> }}
      />
      <PWAInstallationModal
        dialogKey={dialogKeyOther}
        listItem1={{ text: t('Press the three-dot icon more menu ') }}
        listItem2={{ text: t("Press 'Add to Home Screen'") }}
      />
    </>
  )
}

export default PWAInstallationPrompt
