import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import StandardPageLayout from '@layouts/standardPageLayout'
import RouteLoading from '@shared/routing/RouteLoading'
import { T } from '@transifex/react'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const TeamDetailsRoute = lazyWithRetries(() => import('./teamDetails'))
const TeamConfigurationRoute = lazyWithRetries(() => import('./teamConfiguration'))
const TeamAutomationRoute = lazyWithRetries(() => import('./automation'))
const BatchAddToTeamsRoute = lazyWithRetries(() => import('./batchActions'))

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.admin.createTeam(),
    element: (
      <StandardPageLayout
        elementId="teamConfigurationRoute"
        title={<T _str="Team configuration" />}
        maxWidth={600}>
        <Suspense fallback={<RouteLoading />}>
          <TeamConfigurationRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.viewTeam(':id'),
    element: (
      <StandardPageLayout elementId="teamDetailsRoute" maxWidth={900}>
        <Suspense fallback={<RouteLoading />}>
          <TeamDetailsRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.configureTeamAutomation(':id'),
    element: (
      <StandardPageLayout
        elementId="teamAutomationRoute"
        maxWidth={900}
        title={<T _str="Configure team automation" />}>
        <Suspense fallback={<RouteLoading />}>
          <TeamAutomationRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.batchAddToTeams(':batchId'),
    element: (
      <StandardPageLayout
        elementId="batchAddToTeamsRoute"
        maxWidth={600}
        title={<T _str="Add to manual team" />}>
        <Suspense fallback={<RouteLoading />}>
          <BatchAddToTeamsRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.editTeam(':teamId'),
    element: (
      <StandardPageLayout elementId="teamsConfigurationRoute" maxWidth={600}>
        <Suspense fallback={<RouteLoading />}>
          <TeamConfigurationRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
]

export default routes
