import { API } from '../axios-instance'
import { MediaObjectUrl } from '../media/types'

export type TenantMetadata = {
  name: string
  language: string
  logoUrls: MediaObjectUrl | null
  bannerUrls: MediaObjectUrl | null
}

export const getTenantMetadata = async (domain: string) => {
  return await API.get<TenantMetadata>('/identity/tenants/metadata', {
    params: { domain },
  }).then(response => response.data)
}
