import { createTheme } from '@mui/material'
import {
  ActivityAvatarVariants,
  ActivityColors,
  ActivityPaperVariants,
} from './activityThemeVariants'
import {
  DefaultColors,
  DefaultTheme,
  mobileQuery,
  onActive,
  onDisabled,
  onFocus,
  onHover,
  spacing,
} from './themeConstants'

export type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'body1'
  | 'body2'
  | 'subtitle1'
  | 'caption'
  | 'button'

/** These overrides allow us to define new Chip variants */
declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    disabled: true
    success: true
    info: true
    warn: true
    error: true
    critical: true
    nonInteractive: true
    baseSelected: true
    baseUnselected: true
    customColor: true
  }
  export type ChipVariant = keyof ChipPropsVariantOverrides
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1: true
    h2: true
    h3: true
    body1: true
    body2: true
    subtitle1: true
    caption: true
    button: true
  }
}

/** These overrides allow us to define new Icon variants */
declare module '@mui/material/Icon' {
  interface IconPropsVariantOverrides {
    green: true
    orange: true
    blue: true
    grey: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    cardButton: true
  }
  interface ButtonOwnProps {
    id: string
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonOwnProps {
    id: string
    'aria-label': string
  }
}

export const colors = {
  ...DefaultColors,
  ...ActivityColors,
} as const

const theme = createTheme({
  breakpoints: {
    values: {
      xs: DefaultTheme.breakpoints.values.xs, // 0
      sm: DefaultTheme.breakpoints.values.sm, // 600
      md: DefaultTheme.breakpoints.values.md, // 900
      lg: DefaultTheme.breakpoints.values.lg, // 1200
      xl: DefaultTheme.breakpoints.values.xl, // 1536
    },
  },
  palette: {
    primary: {
      main: colors.bluePrimary,
      light: colors.blueLight,
      dark: colors.blueDark,
      contrastText: colors.white,
    },
    error: {
      main: colors.redPrimary,
      light: colors.coralPrimary,
      dark: colors.redDark,
      contrastText: colors.white,
    },
  },
  typography: {
    fontFamily: 'Figtree',
    htmlFontSize: 16,
    h1: {
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: 1.5,
      color: colors.grayPrimary,
      marginTop: DefaultTheme.spacing(6),
      [mobileQuery]: {
        fontSize: '1.5rem',
        fontWeight: 600,
        lineHeight: 1.4,
      },
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: 1.5,
      color: colors.grayPrimary,
      marginTop: DefaultTheme.spacing(5),
      [mobileQuery]: {
        fontSize: '1.25rem',
        fontWeight: 600,
        lineHeight: 1.4,
        marginTop: DefaultTheme.spacing(3),
      },
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: 1.5,
      color: colors.grayPrimary,
      marginTop: DefaultTheme.spacing(5),
      [mobileQuery]: {
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: 1.4,
        marginTop: DefaultTheme.spacing(3),
      },
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      color: colors.grayPrimary,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 600,
      color: colors.grayPrimary,
      lineHeight: 1.5,
    },
    subtitle1: {
      fontSize: '1.25rem',
      fontWeight: 'normal',
      color: colors.grayPrimary,
      lineHeight: 1.4,
    },
    caption: {
      fontSize: '0.875rem',
      fontWeight: 'normal',
      color: colors.grayMedium,
      lineHeight: 1.5,
    },
    button: {
      textTransform: 'none',
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.4,
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          textDecoration: 'none',
          [onHover]: {
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          color: colors.greenDark,
          backgroundColor: colors.greenExtraLight,

          '& .MuiSvgIcon-root': {
            color: colors.greenDark,
          },
          '& .MuiButtonBase-root': {
            '& .MuiSvgIcon-root': {
              color: colors.greenDark,
            },
          },
        },
        standardInfo: {
          color: colors.blueDark,
          backgroundColor: colors.blueExtraLight,

          '& .MuiSvgIcon-root': {
            color: colors.blueDark,
          },
          '& .MuiButtonBase-root': {
            '& .MuiSvgIcon-root': {
              color: colors.blueDark,
            },
          },
        },
        standardError: {
          color: colors.redDark,
          backgroundColor: colors.coralMedium,

          '& .MuiSvgIcon-root': {
            color: colors.redDark,
          },
          '& .MuiButtonBase-root': {
            '& .MuiSvgIcon-root': {
              color: colors.redDark,
            },
          },
        },
        standardWarning: {
          color: colors.orangeDark,
          backgroundColor: colors.goldMedium,

          '& .MuiSvgIcon-root': {
            color: colors.orangeDark,
          },
          '& .MuiButtonBase-root': {
            '& .MuiSvgIcon-root': {
              color: colors.orangeDark,
            },
          },
        },
      },
    },
    MuiPaper: {
      variants: [...ActivityPaperVariants],
      styleOverrides: {
        root: {
          borderRadius: DefaultTheme.spacing(1),
          boxShadow: 'none',
          border: `1px solid ${colors.grayExtraLight}`,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontSize: '1rem',
          lineHeight: '140%',
          borderRadius: 100,
          boxShadow: 'none',
          marginTop: 24,
          gap: 8,
          padding: 0,
          minHeight: 48,
          minWidth: 0,
        },
        sizeLarge: {
          padding: '12px 24px',
          height: 64,
          fontSize: '1.25rem',
        },
        sizeSmall: {
          padding: '0',
        },
        containedPrimary: {
          backgroundColor: colors.bluePrimary,
          padding: '8px 24px',
          [mobileQuery]: {
            padding: '12px 24px',
          },
          color: colors.white,
          '&:hover': {
            backgroundColor: colors.blueDark,
            boxShadow: colors.hoverShadow,
          },
          '&:focus': {
            boxShadow: colors.boxShadow,
          },
          '&:active': {
            backgroundColor: colors.blueMedium,
            boxShadow: 'none',
          },
          '&:disabled': {
            backgroundColor: colors.grayMedium,
            borderColor: colors.grayMedium,
            color: colors.white,
          },
          '.MuiSvgIcon-root': {
            color: colors.white,
          },
        },
        outlinedPrimary: {
          borderWidth: 2,
          padding: '8px 24px',
          [mobileQuery]: {
            padding: '12px 24px',
          },
          color: colors.grayDark,
          backgroundColor: colors.white,
          borderColor: colors.bluePrimary,
          '&:hover': {
            borderWidth: 2,
            backgroundColor: colors.white,
            borderColor: colors.blueDark,
            boxShadow: colors.hoverShadow,
          },
          '&:focus': {
            boxShadow: colors.boxShadow,
          },
          '&:active': {
            backgroundColor: colors.white,
            borderColor: colors.bluePrimary,
            boxShadow: 'none',
          },
          '&:disabled': {
            backgroundColor: colors.white,
            color: colors.grayMedium,
            borderColor: colors.grayMedium,
          },
          '.MuiSvgIcon-root': {
            color: colors.grayDark,
          },
        },
        text: {
          color: colors.bluePrimary,
          padding: '0 !important', // NOTE: See TextVariantButtonSpacing in globalStyles.ts
          minHeight: 0,
          minWidth: 0,
          borderRadius: 0,
          marginTop: 0,
          '&:hover': {
            padding: 0,
            borderRadius: 0,
            backgroundColor: 'unset',
            boxShadow: 'unset',
            color: colors.blueDark,
          },
          '&:focus': {
            padding: 0,
            borderRadius: 0,
            backgroundColor: 'unset',
            boxShadow: 'unset',
            color: colors.blueDark,
          },
          '&:active': {
            padding: 0,
            borderRadius: 0,
            backgroundColor: 'unset',
            boxShadow: 'unset',
            color: colors.bluePrimary,
          },
          '&:disabled': {
            color: colors.grayMedium,
          },
          '.MuiSvgIcon-root': {
            color: colors.bluePrimary,
          },
        },
        startIcon: {
          marginRight: 0,
        },
        endIcon: {
          margin: 0,
        },
      },
      variants: [
        {
          props: { variant: 'cardButton' },
          style: {
            border: `1px solid ${colors.bluePrimary}`,
            borderRadius: DefaultTheme.spacing(2),
            boxShadow: colors.boxShadow,
            color: colors.grayDark,
            minHeight: 96,
            justifyContent: 'flex-start',
            padding: DefaultTheme.spacing(4),
            [mobileQuery]: {
              padding: DefaultTheme.spacing(3),
              width: 'auto',
            },
            [onHover]: {
              backgroundColor: colors.blueUltraLight,
              border: `1px solid ${colors.blueDark}`,
              boxShadow: colors.hoverShadow,
            },
            [onFocus]: {
              boxShadow: `${colors.boxShadow}, ${colors.boxShadow}`,
              '.MuiButton-startIcon svg': {
                color: colors.grayDark,
              },
            },
            '&:active': {
              backgroundColor: colors.blueUltraLight,
              border: `1px solid ${colors.blueMedium}`,
              boxShadow: colors.boxShadow,
            },
            '&.Mui-disabled, &.Mui-disabled': {
              color: colors.grayMedium,
              border: `1px solid ${colors.grayMedium}`,
              '.MuiTypography-root': {
                color: colors.grayMedium,
              },
            },
            '.MuiTypography-root': {
              marginTop: 0,
              [DefaultTheme.breakpoints.between(600, 700)]: {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              },
            },
            '.MuiButton-startIcon svg': {
              [mobileQuery]: {
                fontSize: 28,
              },
              fontSize: 32,
            },
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          color: colors.grayExtraLight,
          borderColor: colors.grayExtraLight,
          '&::before, &::after': {
            borderColor: colors.grayExtraLight,
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            color: colors.bluePrimary,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minHeight: 48,
          '&.Mui-disabled': {
            backgroundColor: colors.grayUltraLight,
            filter: 'none',
            borderColor: colors.grayExtraLight,
          },
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
          },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
        input: {
          '&.Mui-disabled': {
            color: colors.grayMedium,
          },
          '&::placeholder': {
            color: colors.grayPrimary,
            opacity: 1,
          },
        },
        multiline: {
          height: 160,
          overflowY: 'auto',
          display: 'block',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          backgroundColor: colors.white,
          border: `1px solid ${colors.grayExtraLight}`,
          '&:hover': {
            border: `1px solid ${colors.blueMedium}`,
          },
          '&:focus': {
            border: `1px solid ${colors.blueDark}`,
            boxShadow: colors.boxShadow,
          },
          '&:active': {
            border: `1px solid ${colors.bluePrimary}`,
          },
          '&.Mui-disabled': {
            border: `1px solid ${colors.grayExtraLight}`,
            backgroundColor: colors.grayUltraLight,
          },
          '&.Mui-error': {
            border: `1px solid ${colors.redPrimary}`,
          },
          maxWidth: 684,
        },
        notchedOutline: {
          border: 'none',
        },
        input: {
          padding: '8px 16px',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        fullWidth: {
          maxWidth: 684,
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          marginTop: 24,
          maxWidth: 684,
          overflow: 'visible',
          [mobileQuery]: {
            textWrap: 'wrap',
          },
          '&.Mui-disabled': {
            color: colors.grayDark,
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          color: colors.grayDark,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            borderBottom: `1px solid ${colors.grayMedium}`,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 400,
          color: colors.grayDark,
          lineHeight: 1.5,
        },
        head: {
          padding: '16px 8px',
          '&:first-of-type': {
            padding: '16px 8px 16px 24px',
          },
          fontWeight: 700,
        },
        body: {
          padding: '16px 8px',
          '&:first-of-type': {
            padding: '16px 8px 16px 24px',
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            fontSize: '1rem',
            fontWeight: 700,
            color: colors.grayDark,
            lineHeight: 1.5,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            color: colors.bluePrimary,
          },
          '&.Mui-checked': {
            color: colors.bluePrimary,
            '&:hover, &:focus': {
              color: colors.blueDark,
            },
          },
          '&.Mui-disabled': {
            color: colors.grayMedium,
          },
        },
      },
    },

    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: colors.blueDark,
        },
      },
      variants: [
        {
          props: { color: 'warning' },
          style: {
            color: colors.orangePrimary,
          },
        },
        {
          props: { color: 'success' },
          style: {
            color: colors.greenPrimary,
          },
        },
        {
          props: { color: 'error' },
          style: {
            color: colors.coralDark,
          },
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        root: {
          padding: '0 8px',
          borderWidth: 1,
          minHeight: 36,
          borderRadius: 18,
          backgroundColor: colors.white,
          '.MuiChip-label': {
            fontSize: '1rem',
          },
          '& svg': {
            color: 'inherit !important',
          },
        },
      },
      variants: [
        {
          props: { variant: 'critical' },
          style: {
            color: colors.redDark,
            backgroundColor: colors.coralMedium,
          },
        },
        {
          props: { variant: 'success' },
          style: {
            color: colors.greenDark,
            backgroundColor: colors.greenExtraLight,
          },
        },
        {
          props: { variant: 'info' },
          style: {
            color: colors.blueDark,
            backgroundColor: colors.blueExtraLight,
          },
        },
        {
          props: { variant: 'warn' },
          style: {
            color: colors.orangeDark,
            backgroundColor: colors.orangeExtraLight,
          },
        },
        {
          props: { variant: 'disabled' },
          style: {
            color: colors.grayPrimary,
            backgroundColor: colors.grayUltraLight,
          },
        },
        {
          props: { variant: 'error' },
          style: {
            color: colors.redDark,
            backgroundColor: colors.redExtraLight,
          },
        },
        {
          props: { variant: 'baseSelected' },
          style: {
            color: colors.white,
            backgroundColor: colors.bluePrimary,
            border: `1px solid ${colors.bluePrimary}`,
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1.5,
            '&:hover': {
              border: `1px solid ${colors.blueDark}`,
              backgroundColor: colors.blueDark,
            },
            '&:focus': {
              border: `1px solid ${colors.blueDark}`,
              backgroundColor: colors.blueDark,
            },
            '&:active': {
              border: `1px solid ${colors.blueMedium}`,
              backgroundColor: colors.blueMedium,
            },
            '&.Mui-disabled': {
              backgroundColor: colors.grayMedium,
            },
            '& svg': {
              color: `${colors.white} !important`,
            },
          },
        },
        {
          props: { variant: 'baseUnselected' },
          style: {
            color: colors.grayDark,
            backgroundColor: colors.white,
            border: `1px solid ${colors.bluePrimary}`,
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1.5,
            '&:hover': {
              border: `1x solid ${colors.bluePrimary}`,
              backgroundColor: colors.blueExtraLight,
            },
            '&:focus': {
              border: `1px solid ${colors.blueDark}`,
              boxShadow: colors.boxShadow,
            },
            '&:active': {
              border: `1px solid ${colors.blueMedium}`,
              backgroundColor: colors.blueUltraLight,
            },
            '&.Mui-disabled': {
              border: `1px solid ${colors.grayLight}`,
              backgroundColor: colors.grayUltraLight,
            },
          },
        },
        {
          props: { variant: 'nonInteractive' },
          style: {
            cursor: 'default',
            color: colors.grayDark,
            backgroundColor: colors.white,
            border: `1px solid ${colors.bluePrimary}`,
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1.5,
          },
        },
        {
          props: { variant: 'customColor' },
          style: {
            borderWidth: 1,
            backgroundColor: colors.white,
            borderStyle: 'solid',
            boxSizing: 'border-box',
          },
        },
      ],
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          //thumb - unchecked
          color: colors.white,
          // track - checked
          '&.Mui-checked+.MuiSwitch-track': {
            opacity: 1,
          },
        },
        colorPrimary: {
          // thumb - checked
          '&.Mui-checked': {
            color: colors.white,
          },
        },
        track: {
          // track - unchecked
          opacity: 1,
          backgroundColor: colors.grayMedium,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: 16,
          color: colors.white,
          backgroundColor: colors.grayDark,
          borderRadius: 8,
          fontSize: '1rem',
          fontWeight: 400,
          lineHeight: 1.5,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: colors.blueExtraLight,
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          [mobileQuery]: {
            minHeight: DefaultTheme.spacing(5),
          },
        },
      },
    },
    MuiAvatar: {
      variants: [...ActivityAvatarVariants],
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-inputRoot': {
            minWidth: 200,
          },
          '& .MuiInputAdornment-root': {
            paddingLeft: DefaultTheme.spacing(1),
          },
          '.MuiSvgIcon-root': {
            color: colors.grayPrimary,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            color: colors.bluePrimary,
          },
          '&.Mui-checked': {
            color: colors.bluePrimary,
            '&:hover, &:focus': {
              color: colors.blueDark,
            },
            '&:active': {
              color: colors.blueMedium,
            },
          },
          '&.Mui-disabled': {
            color: colors.grayMedium,
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
          color: colors.bluePrimary,
          '& svg': {
            color: colors.bluePrimary,
          },
          [onHover]: {
            backgroundColor: colors.blueExtraLight,
            color: colors.blueDark,
            boxShadow: colors.hoverShadow,
          },
          [onFocus]: {
            backgroundColor: colors.blueExtraLight,
            color: colors.blueDark,
            boxShadow: colors.boxShadow,
          },
          [onActive]: {
            backgroundColor: colors.blueExtraLight,
            color: colors.blueMedium,
          },
          '& .MuiSvgIcon-root': {
            color: 'inherit',
          },
          [`${onDisabled} .MuiSvgIcon-root`]: {
            color: colors.grayMedium,
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          '& .MuiListItemText-primary': {
            fontWeight: 600,
            color: colors.grayDark,
          },
          '& .MuiListItemText-secondary': {
            color: colors.grayPrimary,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
        },
        expandIconWrapper: {
          '& .MuiSvgIcon-root': {
            fontSize: 48,
            color: colors.bluePrimary,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: spacing(2),
          boxShadow: 'none',
          border: `1px solid ${colors.grayExtraLight}`,
        },
      },
    },
  },
})

export default theme
