import AwardSparkleBackground from '@assets/images/AwardSparkleBackground.svg'
import AppPaths from '@globals/paths'
import { colors } from '@globals/themes/defaultTheme'
import { spacing } from '@globals/themes/themeConstants'
import { Award, MediaVariantEnum } from '@kudos/http-client'
import Grid from '@mui/material/Grid2'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import WallPostDate from '@shared/wallPost/WallPostDate'
import AwardName from '@tenant/awards/awardsWall/award/AwardName'
import SingleRecipientAvatar from '@tenant/awards/awardsWall/award/SingleRecipientAvatar'
import { T } from '@transifex/react'
import { shuffle } from 'lodash/fp'
import ScanToQRCode from '../ScanToQRCode'
import ScrollingContent from '../ScrollingContent'
import SenderAndReceivers from '../TvRecognition/SenderAndReceivers'

const paperStyle = {
  background: `url(${AwardSparkleBackground})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'top',
  backgroundColor: colors.white,
  borderRadius: spacing(3),
  width: 'auto',
  margin: 'auto',
  height: '78vh',
  p: 4,
}

type TvAwardProps = {
  award: Award
}

const TvAward: React.FC<TvAwardProps> = ({ award }) => {
  const shuffledArray = shuffle(award.sendTo)

  return (
    <Paper sx={paperStyle}>
      <Grid container spacing={3}>
        <SenderAndReceivers
          sender={award.sender}
          receivers={shuffledArray}
          textColor={colors.grayDark}
          SingleRecipientAvatar={SingleRecipientAvatar}
        />
        <Grid size={{ xs: 8 }} sx={{ ml: 6 }}>
          <ScrollingContent
            header={
              <Stack direction="row" alignItems="center" spacing={3} mb={4}>
                <AwardName
                  award={award}
                  mediaVariant={MediaVariantEnum.AWARD_BADGE_DESKTOP}
                  avatarSize={96}
                  nameVariant="h2"
                />
              </Stack>
            }
            htmlContent={award.message}
            maxHeight="68vh"
            contentSx={{ color: colors.grayDark, fontSize: '2.5rem' }}>
            <Stack direction="row" justifyContent="end">
              <WallPostDate date={award.createdAt} textVariant="h3" />
            </Stack>
          </ScrollingContent>
          <ScanToQRCode
            text={<T _str="Scan to view post" />}
            path={AppPaths.tenant.viewAward(award.id)}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default TvAward
