import { createStore } from '@globals/createStore'

type DialogDetails<T = unknown> = {
  isOpen: boolean
  dialogData?: T
}

type DialogStoreState = {
  dialogs: Record<string, DialogDetails>
  open: <T>(key: string, data?: T) => void
  close: (key: string) => void
  setDialogData: <T>(key: string, data: T) => void
}

const useDialogStore = createStore<DialogStoreState>(set => ({
  dialogs: {},
  open: (key, data) =>
    set(
      state => ({
        dialogs: { ...state.dialogs, [key]: { isOpen: true, dialogData: data } },
      }),
      false,
      `${key}_Open`,
    ),
  close: key =>
    set(
      state => ({
        dialogs: {
          ...state.dialogs,
          [key]: { ...state.dialogs[key], isOpen: false, dialogData: null },
        },
      }),
      false,
      `${key}_Close`,
    ),
  setDialogData: (key, data) =>
    set(state => ({
      dialogs: {
        ...state.dialogs,
        [key]: {
          ...state.dialogs[key],
          dialogData: data,
        },
      },
    })),
}))

export default useDialogStore

/**
 * Hook to expose dialog state and actions to components
 */
export const useDialog = <T = unknown>(key: string) => {
  const { dialogs, open, close, setDialogData } = useDialogStore()

  const dialog = (dialogs[key] as DialogDetails<T>) ?? {
    isOpen: false,
    dialogData: undefined,
  }

  const openDialog = (data?: T) => open<T>(key, data)
  const closeDialog = () => close(key)
  const setData = (data: T) => setDialogData(key, data)

  return { ...dialog, openDialog, closeDialog, setData }
}
