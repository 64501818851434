import Box from '@mui/material/Box'
import { SxProps } from '@mui/material/styles'
import MentionsTextarea from '@shared/components/mentionsTextarea/MentionsTextarea'
import { T } from '@transifex/react'
import { PropsWithChildren, ReactNode } from 'react'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import ErrorText from '../ErrorText'
import FieldLabel from '../FieldLabel'
import HelpText from '../HelpText'

const DEFAULT_MAX_CHARS = 150

type MentionsTextareaFieldProps<T extends FieldValues> = PropsWithChildren & {
  name: Path<T>
  control: Control<T>
  label?: ReactNode
  ariaLabel: string
  placeholder?: ReactNode
  required?: boolean
  help?: ReactNode
  disabled?: boolean
  onBlur?: () => void
  labelStyle?: SxProps
  autoFocus?: boolean | undefined
  maxChars?: number
  isComment?: boolean
  isEmoji?: boolean
  setEmoji?: (isEmoji: boolean) => void
  isMentionsSupported?: boolean
  onFormSubmit?: () => void
}

const MentionsTextareaField = <T extends FieldValues>({
  control,
  label,
  ariaLabel,
  placeholder,
  name,
  help,
  required = true,
  disabled = false,
  labelStyle,
  maxChars = DEFAULT_MAX_CHARS,
  isComment,
  children,
  isMentionsSupported = false,
  onFormSubmit,
}: MentionsTextareaFieldProps<T>) => {
  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            {!!label && (
              <FieldLabel
                htmlFor={name}
                required={required}
                disabled={disabled}
                labelStyle={labelStyle}>
                {label}
              </FieldLabel>
            )}
            {!!help && <HelpText disabled={disabled}>{help}</HelpText>}
            <MentionsTextarea
              ariaLabel={ariaLabel}
              elementId={name}
              value={value}
              error={!!error}
              placeholder={placeholder}
              isComment={isComment}
              onChange={onChange}
              isMentionsSupported={isMentionsSupported}
              onFormSubmit={onFormSubmit}>
              {children}
            </MentionsTextarea>
            {maxChars < (value?.length || 0) && (
              <ErrorText>
                <T _str="Your message is too long" />
              </ErrorText>
            )}
            {error?.message && <ErrorText>{error.message}</ErrorText>}
          </>
        )}
      />
    </Box>
  )
}

export default MentionsTextareaField
