import SvgIcon from '@mui/material/SvgIcon'

const ToolIcon = () => (
  <SvgIcon viewBox="0 0 24 24">
    <rect width="24" height="24" fillOpacity={0} />
    <path d="M9 15C7.33333 15 5.91667 14.4167 4.75 13.25C3.58333 12.0833 3 10.6667 3 9C3 8.66667 3.025 8.33333 3.075 8C3.125 7.66667 3.21667 7.35 3.35 7.05C3.43333 6.88333 3.5375 6.75833 3.6625 6.675C3.7875 6.59167 3.925 6.53333 4.075 6.5C4.225 6.46667 4.37917 6.47083 4.5375 6.5125C4.69583 6.55417 4.84167 6.64167 4.975 6.775L7.6 9.4L9.4 7.6L6.775 4.975C6.64167 4.84167 6.55417 4.69583 6.5125 4.5375C6.47083 4.37917 6.46667 4.225 6.5 4.075C6.53333 3.925 6.59167 3.7875 6.675 3.6625C6.75833 3.5375 6.88333 3.43333 7.05 3.35C7.35 3.21667 7.66667 3.125 8 3.075C8.33333 3.025 8.66667 3 9 3C10.6667 3 12.0833 3.58333 13.25 4.75C14.4167 5.91667 15 7.33333 15 9C15 9.38333 14.9667 9.74583 14.9 10.0875C14.8333 10.4292 14.7333 10.7667 14.6 11.1L19.65 16.1C20.1333 16.5833 20.375 17.175 20.375 17.875C20.375 18.575 20.1333 19.1667 19.65 19.65C19.1667 20.1333 18.575 20.375 17.875 20.375C17.175 20.375 16.5833 20.125 16.1 19.625L11.1 14.6C10.7667 14.7333 10.4292 14.8333 10.0875 14.9C9.74583 14.9667 9.38333 15 9 15ZM9 13C9.43333 13 9.86667 12.9333 10.3 12.8C10.7333 12.6667 11.125 12.4583 11.475 12.175L17.55 18.25C17.6333 18.3333 17.7458 18.3708 17.8875 18.3625C18.0292 18.3542 18.1417 18.3083 18.225 18.225C18.3083 18.1417 18.35 18.0292 18.35 17.8875C18.35 17.7458 18.3083 17.6333 18.225 17.55L12.15 11.5C12.45 11.1667 12.6667 10.7792 12.8 10.3375C12.9333 9.89583 13 9.45 13 9C13 8 12.6792 7.12917 12.0375 6.3875C11.3958 5.64583 10.6 5.2 9.65 5.05L11.5 6.9C11.7 7.1 11.8 7.33333 11.8 7.6C11.8 7.86667 11.7 8.1 11.5 8.3L8.3 11.5C8.1 11.7 7.86667 11.8 7.6 11.8C7.33333 11.8 7.1 11.7 6.9 11.5L5.05 9.65C5.2 10.6 5.64583 11.3958 6.3875 12.0375C7.12917 12.6792 8 13 9 13Z" />
  </SvgIcon>
)

export default ToolIcon
