import MenuItem from '@mui/material/MenuItem'
import { SxProps } from '@mui/material/styles'
import { Link } from 'react-router-dom'

const menuItemStyles: SxProps = {
  wordWrap: 'break-word',
  whiteSpace: 'normal',
}

type SplitButtonMenuItemProps = {
  id: string
  children: React.ReactNode
  disabled?: boolean
  to?: string
  onClick?: (event: React.MouseEvent) => void
  selected?: boolean
}

const SplitButtonMenuItem: React.FC<SplitButtonMenuItemProps> = ({
  id,
  children,
  disabled,
  to,
  onClick,
  selected,
}) => {
  return !!to ? (
    <MenuItem
      sx={menuItemStyles}
      id={id}
      component={Link}
      to={to!}
      disabled={disabled}
      selected={selected}>
      {children}
    </MenuItem>
  ) : (
    <MenuItem
      sx={menuItemStyles}
      id={id}
      onClick={onClick!}
      disabled={disabled}
      selected={selected}>
      {children}
    </MenuItem>
  )
}

export default SplitButtonMenuItem
