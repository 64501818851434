export const fullNameFromParts = (firstName?: string | null, lastName?: string | null) => {
  return `${firstName ?? ''} ${lastName ?? ''}`.trim()
}

export const fullNameWithEmail = (
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
) => {
  return `${firstName ?? ''} ${lastName ?? ''} ${email && `(${email})`}`.trim()
}

export const initialsForName = (firstName?: string | null, lastName?: string | null) => {
  const first = (firstName && firstName[0]) || ''
  const last = (lastName && lastName[0]) || ''
  return `${first}${last}`
}
