import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import StandardPageLayout from '@layouts/standardPageLayout'
import RouteLoading from '@shared/routing/RouteLoading'
import { T } from '@transifex/react'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const MeRoute = lazyWithRetries(() => import('../me'))
const EditProfileRoute = lazyWithRetries(() => import('./editProfile'))
const MemberProfileRoute = lazyWithRetries(() => import('../memberProfile'))

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.me(),
    element: (
      <StandardPageLayout elementId="meRoute" maxWidth={1080}>
        <Suspense fallback={<RouteLoading />}>
          <MeRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.editMe(),
    element: (
      <StandardPageLayout
        elementId="editProfileRoute"
        maxWidth={600}
        title={<T _str="Edit profile" />}>
        <Suspense fallback={<RouteLoading />}>
          <EditProfileRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.memberProfile(':tenantMemberId'),
    element: (
      <StandardPageLayout elementId="meRoute" maxWidth={1080}>
        <Suspense fallback={<RouteLoading />}>
          <MemberProfileRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
]

export default routes
