import { ReportType } from '@kudos/http-client'
import { AnalyticsTabs } from '@tenant/admin/adminTools/burnoutInsights/useAnalyticsTabs'
import { ContentTabs } from '@tenant/admin/adminTools/flaggedContent/useContentTabs'
import { PointsTabs } from '@tenant/admin/pointsAndRewards/points/usePointsTabs'
import { RewardsTabs } from '@tenant/admin/pointsAndRewards/rewards/useRewardsTabs'
import { ReportSharingTabs } from '@tenant/admin/reports/reportSharing/useReportSharingTabs'
import { MemberTabs } from '@tenant/admin/userManagement/members/useMembersTabs'

type PointRulesGroup = 'peer' | 'celebration' | 'bank'
export type PointAllocationAnchors = 'userPoints' | 'celebrationPoints' | 'pointsBank'
export default class AdminPaths {
  // Members
  public static members(tab: MemberTabs | ':tab?') {
    return `/manage/${tab}`
  }
  public static member(memberId: string) {
    return `/user/${memberId}`
  }
  public static customize() {
    return '/customize'
  }

  // Staff
  public static staff() {
    return '/staffing'
  }
  public static staffIntegrations() {
    return '/staffing/import/integrations'
  }
  public static staffImportCsv() {
    return '/staffing/import/csv'
  }
  public static batchInviteStaff(batchId: string) {
    return `/staffing/batch-invite/${batchId}`
  }
  public static staffMember(staffId: string) {
    return `/staffing/${staffId}`
  }

  // Security
  public static security() {
    return '/tenant/security'
  }
  public static addSSO() {
    return '/tenant/security/add-sso'
  }
  public static samlProvider(samlProviderId: string) {
    return `/tenant/security/sso/${samlProviderId}`
  }

  // Check-ins
  public static checkInManage() {
    return '/check-ins/manage'
  }
  public static editAudience(categoryId: string) {
    return `/check-ins/category/${categoryId}/audience`
  }

  // Analytics & Insights
  public static analytics(tab?: AnalyticsTabs | ':tab?') {
    return `/analytics/${tab ?? ''}`
  }
  public static insightDetails(insightId: string) {
    return `/analytics/insight/${insightId}`
  }

  // Teams
  public static createTeam() {
    return '/team/create'
  }
  public static viewTeam(teamId: string) {
    return `/team/${teamId}`
  }
  public static configureTeamAutomation(teamId: string) {
    return `/team/${teamId}/automation`
  }
  public static batchAddToTeams(batchId: string) {
    return `/team/batch-add/${batchId}`
  }
  public static editTeam(teamId: string) {
    return `/team/${teamId}/edit`
  }
  public static configureQuickTeams() {
    return '/team/quick-teams/configure'
  }

  // Values and qualities
  public static values() {
    return '/values'
  }
  public static configureValues() {
    return '/values/configure'
  }
  public static editValue(valueId: string) {
    return `/values/${valueId}/edit`
  }

  // Integrations
  public static kudosTvs() {
    return '/integrations/kudos-tvs'
  }

  // Content management
  public static flaggedContent(tab?: ContentTabs | ':tab?') {
    return `/content/flagged/${tab ?? ''}`
  }

  // Points & budget management
  public static points(tab?: PointsTabs | ':tab?', anchor?: PointAllocationAnchors) {
    let tabUrl = `/points/${tab ?? ''}`
    if (!!anchor) {
      tabUrl = `${tabUrl}#${anchor}`
    }
    return tabUrl
  }
  public static incentivePoints() {
    return '/points/incentive'
  }
  public static individualPointsReporting(memberId: string) {
    return `/points/reporting/${memberId}`
  }
  public static pointsRules(type: PointRulesGroup | ':type?') {
    return `/points/${type}`
  }

  // Notifications
  public static notifications() {
    return '/notifications'
  }

  // Rewards
  public static rewards(tab: RewardsTabs | ':tab?') {
    return `/rewards/${tab}`
  }
  public static editRewardProvider(rewardProviderId: string) {
    return `/rewards/${rewardProviderId}/edit`
  }
  public static configureTangoRewardProvider(rewardProviderId?: string | ':rewardProviderId?') {
    const baseUrl = '/rewards/configure'
    return rewardProviderId ? `${baseUrl}/${rewardProviderId}` : baseUrl
  }
  public static tangoRewardProvider(rewardProviderId: string) {
    return `/rewards/store/${rewardProviderId}`
  }
  public static addTangoRewardFunds(rewardProviderId: string) {
    return `/rewards/store/${rewardProviderId}/add-funds`
  }

  // Logs
  public static logs() {
    return '/logs'
  }

  // Celebrations
  public static configureCelebrations() {
    return '/celebrations/configure'
  }

  // Awards
  public static awards() {
    return '/awards/setup'
  }
  public static configureAwardType(awardTypeId?: string | ':awardTypeId?') {
    const baseUrl = '/awards/configure'
    return awardTypeId ? `${baseUrl}/${awardTypeId}` : baseUrl
  }

  // Incentives
  public static incentives(incentiveId?: string | ':id?') {
    const baseUrl = '/incentives'
    return incentiveId ? `${baseUrl}/${incentiveId}` : baseUrl
  }
  public static configureIncentive(incentiveId?: string | ':id?') {
    const baseUrl = '/incentives/configure'
    return incentiveId ? `${baseUrl}/${incentiveId}` : baseUrl
  }
  public static duplicateIncentive(incentiveId: string) {
    return `/incentives/duplicate/${incentiveId}`
  }

  // Cards
  public static configureCards() {
    return '/cards/configure'
  }

  // Reports
  public static allReports() {
    return '/reports/all'
  }
  public static reports(
    type: ReportType | ':type?',
    action?: 'share',
    tab?: ReportSharingTabs | ':tab?',
  ) {
    const baseUrl = `/reports/${type}`
    return action ? `${baseUrl}/${action}/${tab}` : baseUrl
  }

  // Announcements
  public static announcements(announcementId?: string | ':id?') {
    const baseUrl = '/announcements'
    return announcementId ? `${baseUrl}/${announcementId}` : baseUrl
  }
  public static configureAnnouncement(announcementId?: string | ':id?') {
    const baseUrl = '/announcements/configure'
    return announcementId ? `${baseUrl}/${announcementId}` : baseUrl
  }

  // Language and time
  public static localization() {
    return '/localization'
  }
}
