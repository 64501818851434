import AppPaths from '@globals/paths'
import { RouteUpdateProvider } from '@layouts/RouteUpdateContext'
import NotFound from '@shared/routing/NotFound'
import { RouteObject } from 'react-router-dom'
import { AwaitAuth } from './AwaitAuth'
import DocumentTitleWrapper from './DocumentTitleWrapper'
import { GuestRoutes } from './GuestRoutes'
import { TenantScopeRoutes } from './TenantScopeRoutes'
import { UserScopeRoutes } from './UserScopeRoutes'
import ErrorRoute from './errors'
import KudosTvRoute from './guest/kudosTv'
import guestRoutes from './guest/routes'
import TenantLoginRoute from './guest/tenantLogin'
import Logout from './protected/logout'
import tenantScopeRoutes from './protected/tenant/routes'
import userScopeRoutes from './protected/user/routes'

const appRoutes: RouteObject[] = [
  {
    path: AppPaths.anon.tenantLogin(),
    errorElement: <ErrorRoute />,
    element: <TenantLoginRoute />,
  },
  {
    path: AppPaths.anon.watchKudosTv(':tvToken'),
    errorElement: <ErrorRoute />,
    element: <KudosTvRoute />,
  },
  {
    element: (
      <RouteUpdateProvider>
        <DocumentTitleWrapper>
          <AwaitAuth />
        </DocumentTitleWrapper>
      </RouteUpdateProvider>
    ),
    children: [
      // Unauthenticated routes
      {
        element: <GuestRoutes />,
        children: guestRoutes,
      },
      // Authenticated Routes
      {
        path: AppPaths.logout(),
        element: <Logout />,
      },
      {
        element: <UserScopeRoutes />,
        errorElement: <ErrorRoute />,
        children: userScopeRoutes,
      },
      {
        element: <TenantScopeRoutes />,
        errorElement: <ErrorRoute />,
        children: tenantScopeRoutes,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]

export default appRoutes
