import AutoCompleteChip from '@components/standardAutoCompleteSearch/AutoCompleteChip'
import { SendTargetType } from '@kudos/http-client'
import { SxProps } from '@mui/material/styles'
import TeamAvatar from '@shared/avatar/TeamAvatar'
import UserAvatar from '@shared/avatar/UserAvatar'
import { TeamSendTo, TenantMemberSendTo } from '@shared/components/recipientSearch/types'

const avatarBoxStyle: SxProps = {
  display: 'flex',
}

type SendToChipPropType = {
  name: string
  recipient: TeamSendTo | TenantMemberSendTo
  onDelete: (e: React.MouseEvent<HTMLButtonElement>) => void
}
const SendToChip: React.FC<SendToChipPropType> = ({ name, recipient, onDelete }) => {
  return (
    <AutoCompleteChip
      avatar={
        recipient.type === SendTargetType.TENANT_MEMBER ? (
          <UserAvatar
            size={25}
            firstName={(recipient as TenantMemberSendTo).firstName}
            lastName={(recipient as TenantMemberSendTo).lastName}
            media={(recipient as TenantMemberSendTo).avatarUrls}
            avatarBoxStyle={avatarBoxStyle}
          />
        ) : (
          <TeamAvatar avatarBoxStyle={avatarBoxStyle} size={25} />
        )
      }
      label={name}
      onDelete={onDelete}
    />
  )
}

export default SendToChip
