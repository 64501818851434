import { CelebrateSubTabs } from '@tenant/celebrate/useCelebrateSubTabs'
import { CelebrateTabs } from '@tenant/celebrate/useCelebrateTabs'
import { ResourceHubTabs } from '@tenant/resourceHub/useResourceHubTabs'
import { MySettingsTabs } from '@tenant/settings/useMySettingsTabs'
import AdminPaths from './admin'

type HomepageAnchors = 'forYou' | 'recognitions' | 'awards'

export default class TenantScopedPaths {
  public static me() {
    return '/profile/me'
  }
  public static home(anchor?: HomepageAnchors) {
    let homeUrl = '/home'
    if (!!anchor) {
      homeUrl = `${homeUrl}#${anchor}`
    }
    return homeUrl
  }
  public static viewDraftRecognition(id: string | ':id?') {
    return `/recognition/send/in-progress/${id}/edit`
  }
  public static viewDraftRecognitions() {
    return '/recognition/send/in-progress'
  }
  public static sendRecognition() {
    return '/recognition/send'
  }
  public static viewRecognition(id: string | ':id?') {
    return `/recognitions/${id}`
  }
  public static resourceHub(tab: ResourceHubTabs | ':tab?') {
    return `/resource-hub/${tab}`
  }
  public static viewResource(resourceId: string) {
    return `/resource-hub/resources/${resourceId}`
  }
  public static viewResourceComplete(resourceId: string) {
    return `/resource-hub/resources/${resourceId}/complete`
  }
  public static mySettings(tab: MySettingsTabs | ':tab?') {
    return `/settings/${tab}`
  }
  public static changePassword() {
    return '/settings/change-password'
  }
  public static nextCheckInQuestion(categoryId: string) {
    return `/check-ins/category/${categoryId}/question`
  }
  public static editMe() {
    return '/profile/me/edit'
  }
  public static memberProfile(tenantMemberId: string) {
    return `/profile/${tenantMemberId}`
  }
  public static feedback() {
    return '/feedback'
  }
  public static rewardsProviders() {
    return '/rewards/providers'
  }
  public static redemptionHistory() {
    return '/rewards/redemption-history'
  }
  public static tangoReward(rewardProviderId: string, tangoRewardId: string) {
    return `/rewards/providers/${rewardProviderId}/rewards/${tangoRewardId}`
  }
  public static rewardProvider(rewardProviderId: string) {
    return `/rewards/providers/${rewardProviderId}`
  }
  public static yourDirectReports() {
    return '/direct-reports'
  }
  public static celebrate(
    tab?: CelebrateTabs | ':tab?',
    subTab?: CelebrateSubTabs | ':subTab?',
    searchParams?: string,
  ) {
    let rootPath = `/celebrate/${tab ?? ''}`
    if (!!subTab) rootPath += `/${subTab}`
    if (!!searchParams) rootPath += `?${searchParams}`
    return rootPath
  }
  public static awards() {
    return '/awards'
  }
  public static viewAward(id: string | ':id?') {
    return `/award/${id}`
  }
  public static configureCard(cardId?: string | ':cardId?', searchParams?: string) {
    let baseUrl = '/celebrate/card'
    baseUrl = cardId ? `${baseUrl}/${cardId}` : baseUrl
    return searchParams ? `${baseUrl}?${searchParams}` : baseUrl
  }

  // hidden nav paths
  public static viewCard(cardId: string | ':cardId') {
    return `/card/${cardId}`
  }

  public static viewCardContributors(cardId: string | ':cardId') {
    return `/card/${cardId}/contributors`
  }

  public static admin = AdminPaths
}
