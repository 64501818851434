export enum MediaVariantEnum {
  AVATAR_SM = 'avatar-sm',
  AVATAR_MD = 'avatar-md',
  AVATAR_LG = 'avatar-lg',
  AVATAR_XL = 'avatar-xl',
  RECOGNITION_ATTACHMENT_DESKTOP = 'recognition-attachment-desktop',
  RECOGNITION_ATTACHMENT_MOBILE = 'recognition-attachment-mobile',
  LOGO_SQUARE = 'logo-square',
  LOGO = 'logo',
  AWARD_BADGE_DESKTOP = 'award-badge-desktop',
  AWARD_BADGE_MOBILE = 'award-badge-mobile',
  INCENTIVE_BADGE_DESKTOP = 'incentive-badge-desktop',
  INCENTIVE_BADGE_MOBILE = 'incentive-badge-mobile',
  CARD_THUMBNAIL = 'card-thumbnail',
  CARD_FULLSIZE = 'card-fullsize',
  ANNOUNCEMENT_ATTACHMENT_DESKTOP = 'announcement-attachment-desktop',
  ANNOUNCEMENT_ATTACHMENT_MOBILE = 'announcement-attachment-mobile',
  BANNER = 'banner',
}

export enum MediaObjectTypeEnum {
  DOC = 'doc', // e.g. common document type e.g pdf/doc
  IMAGE = 'image', // e.g. common image type e.g. jpg/png
  GRAPHIC = 'graphic', // e.g. vector graphic
  FILE = 'file', // least specific type, could be anything, we don't know
  GIF = 'gif',
}

export type MediaObject = {
  id: string
  url: string
}

export type SignedUploadField = [string, string]

export type SignedUpload = {
  url: string
  fields: SignedUploadField[]
}

export type MediaObjectUpload = {
  mediaObject: MediaObject
  upload: SignedUpload
}

export type MediaObjectUrlVariant = {
  url: string
  variant: MediaVariantEnum
}

export type MediaObjectUrl = {
  type?: MediaObjectTypeEnum
  originalFilename: string
  url: string
  variants: MediaObjectUrlVariant[]
}

export type UploadMediaParams = {
  url: string
  data: FormData
}

export type InitiateUploadParams = {
  originalFilename: string
  contentType: string
}

export type SetMediaObjectParams = {
  mediaObjectId: string | null
}
