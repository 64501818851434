import { AnimationVariant } from '@assets/lottie'
import theme, { colors } from '@globals/themes/defaultTheme'
import Backdrop from '@mui/material/Backdrop'
import Fade from '@mui/material/Fade'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/material/styles'
import { FC, ReactNode } from 'react'
import Animation from './Animation'

const FADE_DURATION = 1000 * 2

const backdropStyle: SxProps = {
  backgroundColor: colors.white,
  opacity: 0.75,
  zIndex: 9999,
}

type FullScreenAnimationProps = {
  title: ReactNode
  isVisible: boolean
  variant?: AnimationVariant
  onClose?: () => void
}
const FullScreenAnimation: FC<FullScreenAnimationProps> = ({
  title,
  variant,
  isVisible,
  onClose,
}) => {
  const handleClose = () => {
    !!onClose && onClose()
  }

  return (
    <Fade
      onClick={() => handleClose()}
      in={isVisible}
      timeout={{
        enter: theme.transitions.duration.enteringScreen,
        exit: FADE_DURATION,
      }}>
      <Backdrop open={isVisible} sx={backdropStyle}>
        <Stack height="100dvh" px={2}>
          <Typography variant="h1" alignSelf="center" textAlign="center">
            {title}
          </Typography>
          <Animation variant={variant} isVisible={isVisible} onClose={handleClose} />
        </Stack>
      </Backdrop>
    </Fade>
  )
}

export default FullScreenAnimation
