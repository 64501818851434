import { colors } from '@globals/themes/defaultTheme'
import { CloseOutlined } from '@mui/icons-material'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/material/styles'
import { ReactElement } from 'react'
export const autoCompleteChipStyle: SxProps = {
  backgroundColor: colors.grayUltraLight,
  borderColor: 'transparent',
  m: 0.25,
  '&.MuiChip-root': {
    minHeight: 32,
  },
}

const chipLabelStyles: SxProps = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}

export type AutoCompleteChipProps = {
  label: string
  onDelete: (e: React.MouseEvent<HTMLButtonElement>) => void
  labelStyles?: SxProps
  avatar?: ReactElement
  chipStyle?: SxProps
  icon?: ReactElement
}

const AutoCompleteChip: React.FC<AutoCompleteChipProps> = ({
  label,
  onDelete,
  labelStyles = chipLabelStyles,
  avatar,
  chipStyle = autoCompleteChipStyle,
  icon,
}) => {
  return (
    <Chip
      variant="outlined"
      size="small"
      avatar={avatar}
      label={
        <Typography variant="body1" sx={labelStyles}>
          {label}
        </Typography>
      }
      sx={chipStyle}
      onDelete={onDelete}
      deleteIcon={<CloseOutlined />}
      icon={icon}
    />
  )
}

export default AutoCompleteChip
