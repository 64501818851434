import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { KEY_DOWN_COMMAND } from 'lexical'
import { FC, useEffect } from 'react'

type SubmitOnEnterPluginProps = {
  onEnter?: () => void
}

const SubmitOnEnterPlugin: FC<SubmitOnEnterPluginProps> = ({ onEnter }) => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    const removeListener = editor.registerCommand(
      KEY_DOWN_COMMAND,
      (event: KeyboardEvent) => {
        // NOTE: Allowing shift+enter for multi-line
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault()
          onEnter?.()
          return true
        }
        return false
      },
      0,
    )

    return () => {
      removeListener()
    }
  }, [editor, onEnter])

  return null
}

export default SubmitOnEnterPlugin
