import Alert from '@mui/material/Alert'
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/material/styles'
import { SyntheticEvent } from 'react'
import { useToasterStore } from './useToasterStore'

const anchorOrigin: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}

const alertSx: SxProps = {
  width: '100%',
  '& .MuiAlert-action .MuiIconButton-root': {
    color: 'inherit',
    background: 'inherit',
    fontSize: 'inherit',
  },
}

export const ToasterUI = () => {
  const [hideToast, toasts] = useToasterStore(({ hideToast, toasts }) => [hideToast, toasts])

  const handleClose = (_event?: SyntheticEvent | Event, reason?: string) => {
    reason !== 'clickaway' && hideToast()
  }

  const [activeToast] = toasts

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={Boolean(activeToast)}
      onClose={handleClose}
      autoHideDuration={activeToast ? activeToast.autoHideDuration : undefined}>
      {activeToast && (
        <Alert onClose={handleClose} severity={activeToast.severity} sx={alertSx}>
          <Typography variant="body1" color="inherit">
            {activeToast.message}
          </Typography>
        </Alert>
      )}
    </Snackbar>
  )
}

export default ToasterUI
