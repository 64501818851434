import theme from '@globals/themes/defaultTheme'
import Drawer from '@mui/material/Drawer'
import { modalStyle, RecognitionModalContent } from './RecognitionModal'
import { RecognitionDialogData } from './useRecognitionModal'

type RecognitionMobileDrawerProps = {
  isOpen: boolean
  onClose: () => void
  recognitionDialogData?: RecognitionDialogData
  contents: RecognitionModalContent
}

const RecognitionMobileDrawer: React.FC<RecognitionMobileDrawerProps> = ({
  isOpen,
  onClose,
  recognitionDialogData,
  contents,
}) => {
  return (
    <Drawer
      sx={{ zIndex: theme.zIndex.drawer + 1 }}
      PaperProps={{
        sx: {
          ...modalStyle,
          width: '100vw',
          borderRadius: '16px 16px 0 0',
        },
      }}
      anchor="bottom"
      open={isOpen}
      onClose={() => onClose()}>
      {recognitionDialogData && contents[recognitionDialogData.view].content}
    </Drawer>
  )
}

export default RecognitionMobileDrawer
