import { FC } from 'react'
import Popover from '@mui/material/Popover'
import NotificationsList from './NotificationsList'

const POPOVER_WIDTH = 684
const POPOVER_HEIGHT = 464

type DesktopNotificationsPopoverProps = {
  id: string | undefined
  anchorEl: HTMLButtonElement | null
  open: boolean
  onClose: () => void
  layoutStyle?: object // Temporary until we build a separate mobile view
}

const defaultLayoutStyle = {
  px: 3,
  py: 2,
}

const DesktopNotificationsPopover: FC<DesktopNotificationsPopoverProps> = ({
  id,
  anchorEl,
  open,
  onClose,
  layoutStyle,
}) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      slotProps={{ paper: { sx: { width: POPOVER_WIDTH, maxHeight: POPOVER_HEIGHT } } }}>
      <NotificationsList
        open={open}
        onClose={onClose}
        layoutStyle={layoutStyle || defaultLayoutStyle}
      />
    </Popover>
  )
}

export default DesktopNotificationsPopover
