import { CalendarEvent, ListCalendarEventsEmbeds, listCalendarEvents } from '@kudos/http-client'
import { SearchState, boolValue, firstValue } from '@shared/search/state'
import { ArraySearchFn, SearchDefaults } from '@shared/search/types'
import { CalendarFilterFields, CalendarSortFields } from './types'

export const calendarEmbeds: ListCalendarEventsEmbeds[] = ['member']

export const searchCalendarDefaults: SearchDefaults<CalendarFilterFields, CalendarSortFields> = {
  filters: {},
  sorting: [],
}

export const parseCalendarFilters = (filters: SearchState<CalendarFilterFields>) => {
  const { typeIn, dateRangePreset, dateRange, memberIdIn, teamIdIn, ownReports } = filters
  return {
    dateRangePreset: firstValue(dateRangePreset),
    dateRange,
    typeIn,
    memberIdIn,
    teamIdIn,
    ownReports: boolValue(ownReports),
  }
}

export const fetchEventsCalendar: ArraySearchFn<
  CalendarEvent,
  CalendarFilterFields,
  CalendarSortFields
> = filters =>
  listCalendarEvents({
    embeds: calendarEmbeds,
    ...parseCalendarFilters(filters),
  })
