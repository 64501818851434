import { colors } from '@globals/themes/defaultTheme'
import { Recognition } from '@kudos/http-client'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { hasPermission, RecognitionPermission } from '@shared/permissions'
import stripHtml from '@shared/utils/stripHtml'
import DeleteRecognition from '@tenant/recognition/RecognitionModal/DraftAndSchduledView/DeleteRecognition'
import { T } from '@transifex/react'
import { format, parseISO } from 'date-fns'
import { isEmpty } from 'lodash'
import useRecognitionModal, { RecognitionModalViews } from '../../useRecognitionModal'
import DraftChip from '../statusChips/DraftChip'
import ScheduledChip from '../statusChips/ScheduledChip'
import RecognitionRecipientsTitle from './RecognitionRecipientsTitle'

type InProgressCardsType = {
  recognition: Recognition
}
const InProgressCard: React.FC<InProgressCardsType> = ({ recognition }) => {
  const message = stripHtml(recognition.message)
  const canEditRecognition = hasPermission(recognition, RecognitionPermission.Edit)
  const canDeleteRecognition = hasPermission(recognition, RecognitionPermission.Delete)

  const { setView } = useRecognitionModal()
  const scheduledDate = recognition.scheduledAt && parseISO(recognition.scheduledAt)
  const lastEdited = format(parseISO(recognition.updatedAt), 'MMM dd, yyyy')
  return (
    <Card
      variant="outlined"
      sx={{
        boxShadow: 0,
      }}>
      <Stack
        height={64}
        p={2}
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        useFlexGap
        gap={1}
        borderBottom={`1px solid ${colors.grayExtraLight}`}>
        <RecognitionRecipientsTitle recipients={recognition.sendTo} />
        {recognition.scheduledAt ? <ScheduledChip /> : <DraftChip />}
      </Stack>

      <Stack p={2} useFlexGap gap={2}>
        <Typography
          variant="body1"
          sx={{
            wordBreak: 'break-word',
          }}>
          {isEmpty(message) ? <T _str="Add recognition message" /> : message}
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body1" color={colors.grayPrimary}>
            {scheduledDate ? (
              <T
                _str="Scheduled to send: {sendDate} at {sendTime}"
                sendDate={format(scheduledDate, 'MMM dd, yyyy')}
                sendTime={format(scheduledDate, 'h:mm aa')}
              />
            ) : (
              <T _str="Last edited: {lastEditDate}" lastEditDate={lastEdited} />
            )}
          </Typography>
          <Stack flexDirection="row" alignItems="center" useFlexGap gap={0.5}>
            {canDeleteRecognition && <DeleteRecognition recognitionId={recognition.id} />}
            {canEditRecognition && (
              <Button
                id="editRecognitionBtn"
                variant="text"
                sx={{
                  color: colors.bluePrimary,
                }}
                onClick={() =>
                  setView({
                    view: RecognitionModalViews.SendRecognition,
                    recognitionId: recognition.id,
                  })
                }>
                <T _str="Edit" />
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Card>
  )
}

export default InProgressCard
