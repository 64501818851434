import { useDialog } from '@globals/dialog/useDialogStore'
import { envConfig } from '@globals/env'

const giphyDialogKey = 'giphySearchDialog'
const giphyApiKey = envConfig.GIPHY_API_KEY

const useGiphyDialog = () => {
  const { openDialog, closeDialog } = useDialog(giphyDialogKey)

  return {
    openGiphyDialog: openDialog,
    closeGiphyDialog: closeDialog,
    giphyDialogKey,
    giphyApiKey,
  }
}

export default useGiphyDialog
