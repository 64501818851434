import { AxiosError } from 'axios'
import { ErrorOption, FieldValues, Path, UseFormSetError } from 'react-hook-form'

/**
 * Extract field and root-level errors from an Axios Error response.
 * Final errors shape will be:
 * {
 *  <fieldName> {message: "Message", type: "invalid", types: {invalid: "Message", required: "Required"} }
 *  root: {message: "Root Message", type: "invalid"}
 * }
 *
 * Supports multiple errors for each form field, but only one type/message for the root of the form. This is
 * a limitation of react-hook-forms errors format.
 *
 * @param e the error object to extract form errors from
 * @param setError the react-hook-form callback to update form error state
 * @param fields the fields to extract field level errors for. A whitelist.
 */
export const setErrorsFromApiError = <T extends FieldValues>(
  e: Error | AxiosError,
  setError: UseFormSetError<T>,
  fields: Path<T>[],
) => {
  // Local field level map of encountered errors
  const errorsByField = new Map<Path<T> | 'root', ErrorOption>()
  const addError = (field: Path<T> | 'root', type: string, message: string) => {
    if (field === 'root') {
      // Root errors are singular and overwrite any previously set ones
      errorsByField.set(field, { type, message })
    } else {
      // Field errors are merged
      const existing = errorsByField.get(field)
      if (existing) {
        // Overwrite with the last error type/message
        existing.type = type
        existing.message = message
        // Accumulate multiple errors in types
        existing.types = { ...existing.types, [type]: message }
      } else {
        errorsByField.set(field, { types: { [type]: message }, type, message })
      }
    }
  }

  if (e instanceof AxiosError) {
    if (e.response) {
      const { error, msg } = e.response.data

      if (error === 'ValidationError') {
        const details = e.response.data.details || []
        for (const item of details) {
          const { type, path = [], message } = item
          const [fieldName] = path
          addError(
            fields.includes(fieldName) ? fieldName : 'root',
            type || 'custom',
            message || 'Unknown Error',
          )
        }
      } else if (error) {
        addError('root', error, msg || error)
      }
    }
  }

  // Fallback to default error if we couldn't extract anything from payload
  if (!errorsByField.size) {
    addError('root', 'unknown', 'Oops. Something went wrong')
  }

  // Push final list of errors to form state
  for (const field of errorsByField.keys()) {
    const err = errorsByField.get(field)
    if (err) {
      setError(field, err)
    }
  }
}
