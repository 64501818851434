import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import RouteLoading from '@shared/routing/RouteLoading'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const HomeRoute = lazyWithRetries(() => import('./'))

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.home(),
    element: (
      <Suspense fallback={<RouteLoading />}>
        <HomeRoute />
      </Suspense>
    ),
  },
]

export default routes
