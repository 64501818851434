import AwardIcon from '@assets/icons/AwardIcon'
import CelebrationIcon from '@assets/icons/CelebrationIcon'
import EngagementIcon from '@assets/icons/EngagementIcon'
import GiftIcon from '@assets/icons/GiftIcon'
import PlatformSetupIcon from '@assets/icons/PlatformSetupIcon'
import ToolIcon from '@assets/icons/ToolIcon'
import AppPaths from '@globals/paths'
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined'
import { AppPermission } from '@shared/permissions'
import { T } from '@transifex/react'
import { NavHiddenItem, NavItem } from './types'

export const userNavConfig: NavItem[] = [
  {
    type: 'link',
    name: <T _str="My tenants" />,
    path: AppPaths.user.tenants(),
    icon: <DashboardOutlinedIcon />,
    key: 'tenants',
  },
]

export const tenantNavHiddenConfig: NavHiddenItem[] = [
  {
    path: AppPaths.tenant.viewCard(':cardId'),
    key: 'viewCard',
    type: 'hiddenNav',
    pathMatch: [/^\/card\//],
  },
  {
    path: AppPaths.tenant.viewCardContributors(':cardId'),
    key: 'viewCardContributors',
    type: 'hiddenNav',
    pathMatch: [/^\/card\//],
  },
]

export const tenantNavConfig: NavItem[] = [
  {
    type: 'link',
    name: <T _str="Home" _comment="Translated" />,
    path: AppPaths.tenant.home(),
    icon: <HomeOutlinedIcon />,
    hideOnMobile: true,
    key: 'home',
    pathMatch: [/^\/check-ins\/category/, /^\/announcements\/[^]*/, /^\/recognition\/send\/[^]*/],
  },
  {
    type: 'link',
    name: <T _str="Me" />,
    path: AppPaths.tenant.me(),
    icon: <PersonOutlineOutlinedIcon />,
    key: 'me',
    pathMatch: [/^\/profile\/me/],
  },
  {
    type: 'link',
    name: <T _str="Celebrate" />,
    path: AppPaths.tenant.celebrate(),
    icon: <CelebrationIcon />,
    key: 'celebrate',
    pathMatch: [/^\/celebrate\//],
    visible: hasAppPermission =>
      hasAppPermission('celebrationApp', AppPermission.CelebrationAppBrowse) ||
      hasAppPermission('cardApp', AppPermission.CardAppBrowse),
  },
  {
    type: 'link',
    name: <T _str="Awards" />,
    path: AppPaths.tenant.awards(),
    icon: <AwardIcon />,
    key: 'awards',
    pathMatch: [/^\/awards\//],
    visible: hasAppPermission =>
      hasAppPermission('awardApp', AppPermission.AwardAppSendOwnAwardType),
  },
  {
    type: 'link',
    icon: <FolderOutlinedIcon />,
    name: <T _str="Resource hub" />,
    path: AppPaths.tenant.resourceHub('recommended'),
    key: 'resourceHub',
    visible: hasAppPermission => hasAppPermission('resourceApp', AppPermission.ResourceAppBrowse),
    pathMatch: [/^\/resource-hub\//],
  },
  {
    type: 'link',
    name: <T _str="Rewards" />,
    path: AppPaths.tenant.rewardsProviders(),
    icon: <GiftIcon />,
    key: 'rewards',
    pathMatch: [/^\/rewards\/providers/, /^\/rewards\/redemption-history/],
    visible: hasAppPermission => hasAppPermission('rewardsApp', AppPermission.RewardsAppRedeem),
  },
]

export const adminNavConfig: NavItem[] = [
  {
    type: 'group',
    icon: <GroupOutlinedIcon />,
    name: <T _str="User management" />,
    key: 'userManagement',
    children: [
      {
        type: 'link',
        name: <T _str="HR records" />,
        path: AppPaths.tenant.admin.staff(),
        key: 'hrRecords',
        visible: hasAppPermission =>
          hasAppPermission('identityApp', AppPermission.IdentityAppBrowse),
        pathMatch: [/^\/staffing(\/|$)/],
      },
      {
        type: 'link',
        name: <T _str="Users & teams" />,
        path: AppPaths.tenant.admin.members('users'),
        key: 'usersAndTeams',
        pathMatch: [
          // Note, this can be done in one RegEx, but we
          // split it apart for readability
          /^\/manage\/(teams|users|quick-teams)/,
          /^\/team(\/|$)/,
          /^\/user(\/|$)/,
        ],
        visible: hasAppPermission =>
          hasAppPermission('identityApp', AppPermission.IdentityAppBrowse),
      },
      {
        type: 'link',
        name: <T _str="Security & access" />,
        path: AppPaths.tenant.admin.security(),
        key: 'securityAndAccess',
        visible: hasAppPermission =>
          hasAppPermission('identityApp', AppPermission.IdentityAppBrowse),
        pathMatch: [/^\/tenant\/security/],
      },
    ],
  },
  {
    type: 'group',
    icon: <PlatformSetupIcon />,
    name: <T _str="Platform set up" />,
    key: 'platformSetup',
    children: [
      {
        type: 'link',
        name: <T _str="Branding" />,
        path: AppPaths.tenant.admin.customize(),
        key: 'branding',
        visible: hasAppPermission =>
          hasAppPermission('identityApp', AppPermission.IdentityAppBrowse),
        pathMatch: [/^\/customize/],
      },
      {
        type: 'link',
        name: <T _str="Values & qualities" />,
        path: AppPaths.tenant.admin.values(),
        key: 'valuesAndQualities',
        visible: hasAppPermission =>
          hasAppPermission('recognitionApp', AppPermission.RecognitionAppCreateValue),
        pathMatch: [/^\/values(\/|$)/],
      },
      {
        type: 'link',
        name: <T _str="Language & time" />,
        path: AppPaths.tenant.admin.localization(),
        key: 'languageAndTime',
        visible: hasAppPermission =>
          hasAppPermission('identityApp', AppPermission.IdentityAppBrowse),
        pathMatch: [/^\/localization/],
      },
      {
        type: 'link',
        name: <T _str="Notifications" />,
        path: AppPaths.tenant.admin.notifications(),
        key: 'notifications',
        visible: hasAppPermission =>
          hasAppPermission(
            'recognitionApp',
            AppPermission.RecognitionAppManageNotificationSettings,
          ),
        pathMatch: [/^\/notifications/],
      },
    ],
  },
  {
    type: 'group',
    icon: <GiftIcon />,
    name: <T _str="Points & rewards" />,
    key: 'pointsAndRewards',
    children: [
      {
        type: 'link',
        name: <T _str="Points" />,
        path: AppPaths.tenant.admin.points('allocation'),
        key: 'points',
        visible: hasAppPermission => hasAppPermission('pointsApp', AppPermission.PointsAppBrowse),
        pathMatch: [/^\/points(\/|$)/],
      },
      {
        type: 'link',
        name: <T _str="Rewards" />,
        path: AppPaths.tenant.admin.rewards('setup'),
        key: 'rewards',
        pathMatch: [
          /^\/rewards\/setup/,
          /^\/rewards\/reporting/,
          /^\/rewards\/([^/]+)\/edit/,
          /^\/rewards\/configure(\/|$)/,
          /^\/rewards\/store(\/|$)/,
          /^\/rewards\/store\/[^/]+\/add-funds/,
        ],
        visible: hasAppPermission => hasAppPermission('rewardsApp', AppPermission.RewardsAppAdmin),
      },
    ],
  },
  {
    type: 'group',
    icon: <EngagementIcon />,
    name: <T _str="Engagement" />,
    key: 'engagement',
    children: [
      {
        type: 'link',
        name: <T _str="Awards" />,
        path: AppPaths.tenant.admin.awards(),
        key: 'awards',
        visible: hasAppPermission =>
          hasAppPermission('awardApp', AppPermission.AwardAppCreateAwardType),
        pathMatch: [/^\/awards\/configure/, /^\/awards\/setup/],
      },
      {
        type: 'link',
        name: <T _str="Incentives" />,
        path: AppPaths.tenant.admin.incentives(),
        key: 'incentives',
        visible: hasAppPermission =>
          hasAppPermission('incentiveApp', AppPermission.IncentiveAppReview),
        pathMatch: [/^\/incentives\/configure/, /^\/incentives/],
      },
      {
        type: 'link',
        name: <T _str="Announcements" />,
        path: AppPaths.tenant.admin.announcements(),
        key: 'announcements',
        visible: hasAppPermission =>
          hasAppPermission('announcementApp', AppPermission.AnnouncementAppManage),
        pathMatch: [/^\/announcements\/configure/, /^\/announcements/],
      },
      {
        type: 'link',
        name: <T _str="Check-ins" />,
        path: AppPaths.tenant.admin.checkInManage(),
        key: 'checkIns',
        visible: hasAppPermission => hasAppPermission('checkInApp', AppPermission.CheckInAppManage),
        pathMatch: [/^\/check-ins\/manage$/, /^\/check-ins\/category\/[^/]+\/audience$/],
      },
      {
        type: 'link',
        name: <T _str="Celebrations" />,
        path: AppPaths.tenant.admin.configureCelebrations(),
        key: 'celebrations',
        visible: hasAppPermission =>
          hasAppPermission('celebrationApp', AppPermission.CelebrationAppEditTenantPreferences),
        pathMatch: [/^\/celebrations\/configure/],
      },
      {
        type: 'link',
        name: <T _str="Cards" />,
        path: AppPaths.tenant.admin.configureCards(),
        key: 'cards',
        visible: hasAppPermission => hasAppPermission('cardApp', AppPermission.CardAppManage),
        pathMatch: [/^\/cards\/configure/],
      },
    ],
  },
  {
    type: 'group',
    icon: <ToolIcon />,
    name: <T _str="Admin tools" />,
    key: 'adminTools',
    children: [
      {
        type: 'link',
        name: <T _str="Kudos TV" />,
        path: AppPaths.tenant.admin.kudosTvs(),
        key: 'kudosTv',
        visible: hasAppPermission => hasAppPermission('tvApp', AppPermission.TvAppBrowse),
        pathMatch: [/^\/integrations\/kudos-tvs/],
      },
      {
        type: 'link',
        name: <T _str="Flagged content" />,
        path: AppPaths.tenant.admin.flaggedContent('awards'),
        key: 'flaggedContent',
        visible: hasAppPermission =>
          hasAppPermission('recognitionApp', AppPermission.RecognitionAppModerate) ||
          hasAppPermission('commentApp', AppPermission.CommentAppModerate),
        pathMatch: [/^\/content\/flagged\//],
      },
      {
        type: 'link',
        name: <T _str="Burnout insights" />,
        path: AppPaths.tenant.admin.analytics('insights'),
        key: 'burnoutInsights',
        visible: hasAppPermission =>
          hasAppPermission('checkInApp', AppPermission.CheckInAppViewAnalytics) ||
          hasAppPermission('metricApp', AppPermission.MetricAppBrowse),
        pathMatch: [/^\/analytics\//],
      },
    ],
  },
  {
    type: 'link',
    name: <T _str="Analytics" />,
    path: AppPaths.tenant.admin.allReports(),
    key: 'allReports',
    icon: <SummarizeOutlinedIcon />,
    visible: hasAppPermission => hasAppPermission('reportingApp', AppPermission.ReportingAppBrowse),
    pathMatch: [/^\/reports\//],
  },
  {
    type: 'link',
    name: <T _str="Logs" />,
    path: AppPaths.tenant.admin.logs(),
    icon: <ContentPasteOutlinedIcon />,
    key: 'logs',
    visible: () => false, // TODO: RE: SYN-1296 - hide nav but make route accessible
    // visible: hasAppPermission => hasAppPermission('auditingApp', AppPermission.AuditingAppBrowse),
  },
  {
    type: 'link',
    name: <T _str="Admin guide" />,
    path: '#', // TODO: To be determined - update path
    icon: <LiveHelpOutlinedIcon />,
    key: 'guide',
    external: true,
    visible: () => false, // TODO: RE:SYN-919 - hide unused nav items
  },
]
