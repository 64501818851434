import { useAuth } from '@auth/useAuth'
import AppPaths from '@globals/paths'
import { Navigate, Outlet, useSearchParams } from 'react-router-dom'

export const GuestRoutes = () => {
  const { isAuthenticated, isUserScope } = useAuth()
  const [searchParams] = useSearchParams()
  const next = searchParams.get('next')

  const destination = !!next ? next : AppPaths.tenant.home()

  const redirectPath = isUserScope
    ? `${AppPaths.user.tenants()}?${searchParams}` // pass destination url to tenants page to process there
    : destination // or else take the user there, with a fallback to home if no destination exists

  return !isAuthenticated ? <Outlet /> : <Navigate to={redirectPath} />
}
