import KudosStarIcon from '@assets/icons/KudosStar'
import AppPaths from '@globals/paths'
import { colors } from '@globals/themes/defaultTheme'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import { T } from '@transifex/react'
import QRCode from 'react-qr-code'

const QR_CODE_SIZE = '8vh'

const containerStyle: SxProps = {
  float: 'right',
  alignItems: 'center',
  paddingBottom: -100,
}

const boxStyle: SxProps = {
  position: 'fixed',
  bottom: 8,
  left: 8,
  backgroundColor: colors.grayDark,
  zIndex: 100,
}

const RecognizeQRCode = () => {
  return (
    <Box sx={boxStyle}>
      <Stack sx={containerStyle} direction="row" spacing={1}>
        <Card sx={{ width: QR_CODE_SIZE, height: QR_CODE_SIZE }}>
          <QRCode
            size={256}
            style={{ height: QR_CODE_SIZE, width: QR_CODE_SIZE, padding: '4px 4px' }}
            value={`${window.location.origin}${AppPaths.tenant.sendRecognition()}`}
            viewBox="0 0 256 256"
          />
        </Card>
        <KudosStarIcon />
        <Typography variant="subtitle1" sx={{ color: colors.white, mt: 0 }}>
          <T _str="Recognize someone" />
        </Typography>
      </Stack>
    </Box>
  )
}

export default RecognizeQRCode
