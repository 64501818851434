import { useAuth } from '@auth/useAuth'
import { useEffect, useRef } from 'react'
import { Navigate } from 'react-router-dom'
import { logout } from '@kudos/http-client'

export const LogOutRoute = () => {
  const { clearAuth, isAuthenticated } = useAuth()

  if (!isAuthenticated) return <Navigate to="/" />

  return <AutoLogOut onLoggedOut={clearAuth} />
}

const AutoLogOut = ({ onLoggedOut }: { onLoggedOut: () => void }) => {
  const hasLoggedOut = useRef<boolean>(false)
  useEffect(() => {
    if (!hasLoggedOut.current) {
      logout()
        .catch(() => {
          // Token may have already expired. Continue regardless so
          // that clear the local auth state and can recover.
        })
        .finally(onLoggedOut)
    }
    hasLoggedOut.current = true
  }, [])

  return <p>Logging out...</p>
}

export default LogOutRoute
