import {
  FederatedSearchRecipientsResults,
  listRecognitionRecipients,
  SendTargetType,
} from '@kudos/http-client'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { TeamSendTo, TenantMemberSendTo } from '@shared/components/recipientSearch/types'
import useRecipientSearch from '@shared/components/recipientSearch/useRecipientSearch'
import ErrorText from '@shared/forms/ErrorText'
import { fullNameFromParts } from '@shared/utils/nameFormatting'
import { RecognitionFormData } from '../useSendRecognitionForm'

import { T, useT } from '@transifex/react'
import { useEffect, useState } from 'react'
import { useFormContext, useFormState, useWatch } from 'react-hook-form'

import StandardAutoCompleteSearch, {
  AutoCompleteOption,
} from '@components/standardAutoCompleteSearch/StandardAutoCompleteSearch'
import SendToChip from './SendToChip'

const getRecipients = (recipients: FederatedSearchRecipientsResults | null) => {
  const teamOptions: TeamSendTo[] =
    recipients?.teams.map(team => ({
      id: team.id,
      name: team.name,
      memberCount: team.activeMemberCount,
      type: SendTargetType.TEAM,
    })) || []

  const tenantMemberOptions: TenantMemberSendTo[] =
    recipients?.tenantMembers.map(member => ({
      id: member.id,
      firstName: member.firstName,
      lastName: member.lastName,
      avatarUrls: member.avatarUrls,
      email: member.email,
      name: fullNameFromParts(member.firstName, member.lastName),
      type: SendTargetType.TENANT_MEMBER,
    })) || []
  return [...teamOptions, ...tenantMemberOptions]
}

const SendToAutoCompleteSelection: React.FC = () => {
  const [searchText, setSearchText] = useState<string>('')
  const [options, setOptions] = useState<AutoCompleteOption[]>([])

  const { control, setValue } = useFormContext<RecognitionFormData>()
  const { errors } = useFormState({ control, name: 'sendTo' })
  const selectedRecipients = useWatch({ control, name: 'sendTo' })

  const t = useT()

  const { results } = useRecipientSearch({
    isSearchEnabled: true,
    selected: selectedRecipients,
    searchText: searchText,
    recipientSearchFn: listRecognitionRecipients,
  })

  const onSelectedOptionsChanged = (options: AutoCompleteOption[]) => {
    const ids = options.map(option => option.id)
    const newRecipients = [...selectedRecipients, ...getRecipients(results)].filter(value =>
      ids.includes(value.id),
    )
    setValue('sendTo', newRecipients, { shouldValidate: true })
  }
  useEffect(() => {
    const recipients = getRecipients(results)
    setOptions(
      recipients.map(option => ({
        value: option.name,
        id: option.id,
      })),
    )
  }, [results])

  return (
    <Stack useFlexGap gap={1} width={'100%'}>
      <StandardAutoCompleteSearch
        label={
          <Typography variant="body2">
            <T _str="To" />
          </Typography>
        }
        placeholder={t('Search for an individual or team...')}
        value={selectedRecipients.map(option => ({
          value: option.name,
          id: option.id,
        }))}
        searchText={searchText}
        options={options}
        onSearchTextChanged={setSearchText}
        onSelectedOptionsChanged={onSelectedOptionsChanged}
        renderChip={(option, onDelete, index) => {
          const recipient = selectedRecipients.find(value => value.id === option.id)!
          return (
            <SendToChip key={index} name={option.value} recipient={recipient} onDelete={onDelete} />
          )
        }}
      />
      {errors.sendTo && <ErrorText>{errors.sendTo.message}</ErrorText>}
    </Stack>
  )
}

export default SendToAutoCompleteSelection
