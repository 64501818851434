import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import StandardPageLayout from '@layouts/standardPageLayout'
import RouteLoading from '@shared/routing/RouteLoading'
import { T } from '@transifex/react'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const AnalyticsRoute = lazyWithRetries(() => import('.'))
const InsightDetailsRoute = lazyWithRetries(() => import('./insights/insightDetails'))

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.admin.insightDetails(':insightId?'),
    element: (
      <StandardPageLayout
        maxWidth={1080}
        elementId="insightsDetailsRoute"
        title={<T _str="Insight details" />}>
        <Suspense fallback={<RouteLoading />}>
          <InsightDetailsRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.admin.analytics(':tab?'),
    element: (
      <StandardPageLayout maxWidth={1080} title={<T _str="Analytics" />} elementId="analyticsRoute">
        <Suspense fallback={<RouteLoading />}>
          <AnalyticsRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
]

export default routes
