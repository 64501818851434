import { spacing } from '@globals/themes/themeConstants'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/material/styles'
import { Selectable } from '@shared/filters/selections'
import { T } from '@transifex/react'
import { FC, useEffect, useState } from 'react'
import CheckmarkChip from './CheckmarkChip'
import InfiniteScrollBox from './InfiniteScrollBox'

const chipBoxStyle = (hideScrollbar: boolean): SxProps => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: `${spacing(2)} ${spacing(1)}`,
  overflowY: 'auto',
  whiteSpace: 'nowrap',
  alignContent: 'flex-start',
  scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    width: 'min-content',
  },
  maxHeight: !hideScrollbar ? 180 : null,
})

export type CheckmarkChipSelectorProps = {
  elementId: string
  options?: Selectable[]
  values: Selectable[]
  tooltipTitle?: (item: Selectable) => string
  itemsSelectedLabel?: string
  onChange: (values: Selectable[]) => void
  fetchNextPage?: () => void
  hasNextPage?: boolean
  isFetchingNextPage?: boolean
  selectedChips?: Selectable[]
  onRemoveChip?: (id: string) => void
  searchValue?: string
  hideScrollbar?: boolean
}
/**
 * @param itemsSelectedLabel Optional prop that renders a count of checked chips
 * @returns A list of checked/unchecked CheckmarkChip components
 */
const CheckmarkChipSelector: FC<CheckmarkChipSelectorProps> = ({
  elementId,
  values,
  tooltipTitle,
  itemsSelectedLabel,
  onChange,
  options = null,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  selectedChips,
  onRemoveChip,
  searchValue,
  hideScrollbar = false,
}) => {
  const [searchResultChips, setSearchResultChips] = useState<Selectable[]>([])
  const scrollProps = { fetchNextPage, hasNextPage, isFetchingNextPage }

  const mergeSelectedChips = (
    selectedChips: Selectable[],
    searchChips: Selectable[],
  ): Selectable[] => {
    const merged = [...selectedChips]
    searchChips.forEach(searchChip => {
      if (!merged.some(selectedChip => selectedChip.id === searchChip.id)) {
        merged.push(searchChip)
      }
    })
    return merged
  }

  const handleClearSearch = () => {
    const mergedSelections = mergeSelectedChips(values, searchResultChips)
    onChange(mergedSelections)
    setSearchResultChips([])
  }

  useEffect(() => {
    if (!searchValue?.trim()) {
      handleClearSearch()
    }
  }, [searchValue])

  const handleChipChange = (id: string) => {
    const isInSelection = values.some(x => x.id === id)
    let newValues: Selectable[] = values

    if (isInSelection) {
      newValues = values.filter(x => x.id !== id)
    } else if (options) {
      const option = options.find(x => x.id === id)
      if (option) {
        newValues = [...values, option]
        setSearchResultChips(prev => mergeSelectedChips(prev, [option]))
      }
    }
    onChange(newValues)
  }

  const getTooltipTitle = (item: Selectable) => (tooltipTitle ? tooltipTitle(item) : '')

  return (
    <Stack id={elementId} spacing={3} useFlexGap>
      {!!itemsSelectedLabel && (
        <Typography variant="h3" sx={{ mt: { xs: 0, md: 0 } }}>
          <T
            _str={'Selected {itemsSelectedLabel} ({numSelected})'}
            numSelected={values.length}
            itemsSelectedLabel={itemsSelectedLabel}
          />
        </Typography>
      )}

      {!!selectedChips && !!selectedChips.length && (
        <>
          <Box sx={chipBoxStyle(hideScrollbar)}>
            {selectedChips.map(item => (
              <CheckmarkChip
                id={`${elementId}-chip-${item.id}`}
                key={item.id}
                label={item.name}
                color={item.color}
                value={item.id}
                checked
                onChange={() => onRemoveChip?.(item.id)}
                tooltipTitle={getTooltipTitle(item)}
              />
            ))}
          </Box>
          {options && options.length > 0 && <Divider />}
        </>
      )}

      <InfiniteScrollBox sx={chipBoxStyle(hideScrollbar)} {...scrollProps}>
        {options?.map(item => (
          <CheckmarkChip
            id={`${elementId}-chip-${item.id}`}
            key={item.id}
            label={item.name}
            color={item.color}
            value={item.id}
            checked={values.some(x => item.id === x.id)}
            onChange={handleChipChange}
            tooltipTitle={getTooltipTitle(item)}
          />
        ))}
        {!options &&
          values.map(item => (
            <CheckmarkChip
              id={`${elementId}-chip-${item.id}`}
              key={item.id}
              label={item.name}
              color={item.color}
              value={item.id}
              checked
              onChange={handleChipChange}
              tooltipTitle={getTooltipTitle(item)}
            />
          ))}
      </InfiniteScrollBox>
    </Stack>
  )
}

export default CheckmarkChipSelector
