import AwardLaurel from '@assets/images/AwardLaurel.png'
import { TenantMemberDisplayData } from '@kudos/http-client'
import Box from '@mui/material/Box'
import TeamAvatar from '@shared/avatar/TeamAvatar'
import UserAvatar, { AvatarFontUnit, AvatarSize } from '@shared/avatar/UserAvatar'
import { isTenantMemberDisplayData } from '@shared/avatar/useAvatarConfig'

const backgroundStyle = {
  background: `url(${AwardLaurel})`,
  backgroundSize: 'cover',
  p: '40px 64px',
}

type SingleRecipientAvatarProps = {
  recipientDisplayData?: TenantMemberDisplayData
  avatarSize?: AvatarSize
  fontUnit?: AvatarFontUnit
}

const SingleRecipientAvatar: React.FC<SingleRecipientAvatarProps> = ({
  recipientDisplayData,
  avatarSize = 120,
  fontUnit = 'px',
}) => {
  return (
    <Box sx={backgroundStyle}>
      {isTenantMemberDisplayData(recipientDisplayData) ? (
        <UserAvatar
          firstName={recipientDisplayData?.firstName}
          lastName={recipientDisplayData?.lastName}
          media={recipientDisplayData?.avatarUrls}
          size={avatarSize}
          fontUnit={fontUnit}
        />
      ) : (
        <TeamAvatar size={avatarSize} fontUnit={fontUnit} />
      )}
    </Box>
  )
}

export default SingleRecipientAvatar
