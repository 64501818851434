import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import FullscreenLayout from '@layouts/FullscreenLayout'
import StandardPageLayout from '@layouts/standardPageLayout'
import RouteLoading from '@shared/routing/RouteLoading'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const ViewCardRoute = lazyWithRetries(() => import('./viewCard'))
const ViewContributorsRoute = lazyWithRetries(() => import('./viewContributors'))
const ConfigureCardRoute = lazyWithRetries(() => import('./configureCard'))

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.configureCard(':cardId?'),
    element: (
      <StandardPageLayout elementId="configureCardRoute" maxWidth={1080}>
        <Suspense fallback={<RouteLoading />}>
          <ConfigureCardRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.viewCard(':id'),
    element: (
      <FullscreenLayout>
        <Suspense fallback={<RouteLoading />}>
          <ViewCardRoute />
        </Suspense>
      </FullscreenLayout>
    ),
  },
  {
    path: AppPaths.tenant.viewCardContributors(':cardId'),
    element: (
      <Suspense fallback={<RouteLoading />}>
        <ViewContributorsRoute />
      </Suspense>
    ),
  },
]

export default routes
