import { colors } from '@globals/themes/defaultTheme'
import { spacing } from '@globals/themes/themeConstants'
import { MediaObjectUrl } from '@kudos/http-client'
import Box from '@mui/material/Box'
import { useT } from '@transifex/react'
import { DISPLAY_AS_IMAGE } from '@shared/attachments/Attachments'

const boxStyle = {
  display: 'flex',
  justifyContent: 'flex-start',
  height: 300,
  margin: 1,
  '& img': {
    border: `3px solid ${colors.white}`,
    borderRadius: spacing(1),
  },
}

const containerStyle = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
}

type TvAttachmentsProps = {
  attachments: MediaObjectUrl[]
  gifs: string[]
}

const TvAttachments: React.FC<TvAttachmentsProps> = ({ attachments, gifs }) => {
  const t = useT()

  return (
    <Box sx={containerStyle}>
      {attachments?.map((attachment, index) => {
        if (attachment.type && DISPLAY_AS_IMAGE.includes(attachment.type)) {
          return (
            <Box sx={boxStyle} key={`Attachment-${index}`}>
              <img src={attachment.url} alt={t('Attachment')} />
            </Box>
          )
        }
        return null
      })}
      {gifs?.map((gif, index) => (
        <Box sx={boxStyle} key={`Gif-${index}`}>
          <img src={gif} alt={t('Gif')} />
        </Box>
      ))}
    </Box>
  )
}

export default TvAttachments
