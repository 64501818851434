import { colors } from '@globals/themes/defaultTheme'
import { tabletQuery } from '@globals/themes/themeConstants'
import { MediaObjectUrl } from '@kudos/http-client'
import { useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/material/styles'
import UserAvatar from '@shared/avatar/UserAvatar'
import { ReactNode } from 'react'

const cardStyle: SxProps = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}

const headerStyle: SxProps = {
  width: '100%',
  borderBottom: `1px solid ${colors.grayUltraLight}`,
  alignItems: 'center',
}

const titleChipStyle: SxProps = {
  width: '25%',
  flex: '1 1 33%',
  verticalAlign: 'middle',
  alignItems: 'center',
}

const titleStyle: SxProps = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  flex: '1 2 auto',
  pr: 1,
  mt: 0,
  [tabletQuery]: {
    mt: 0,
  },
}

type StandardCardProps = {
  title: ReactNode
  subtitle?: ReactNode
  attachmentUrls?: MediaObjectUrl | null
  defaultIcon?: ReactNode
  chip?: ReactNode
  children?: ReactNode
  borderColor?: string
  bottomLeftActions?: ReactNode
  bottomRightActions?: ReactNode
  onClick?: () => void
}

const StandardCard: React.FC<StandardCardProps> = ({
  title,
  subtitle,
  attachmentUrls,
  defaultIcon,
  chip,
  children,
  borderColor,
  bottomLeftActions,
  bottomRightActions,
  onClick,
}) => {
  const isTablet = useMediaQuery(tabletQuery)

  return (
    <Card
      sx={{
        ...cardStyle,
        borderTop: !!borderColor
          ? `6px solid ${borderColor}`
          : `1px solid ${colors.grayExtraLight}`,
        cursor: !!onClick ? 'pointer' : 'auto',
      }}
      onClick={onClick}>
      <Stack direction="row" spacing={1.5} p={2} sx={headerStyle}>
        {(!!attachmentUrls || !!defaultIcon) && (
          <UserAvatar media={attachmentUrls} size={50} defaultIcon={defaultIcon} />
        )}
        <Stack direction="row" sx={titleChipStyle}>
          <Stack sx={titleStyle}>
            <Typography
              variant={isTablet ? 'h2' : 'h3'}
              component="h2"
              fontWeight={700}
              sx={titleStyle}>
              {title}
            </Typography>
            <Typography variant="caption" color={colors.grayMedium} sx={titleStyle}>
              {subtitle}
            </Typography>
          </Stack>
          {chip}
        </Stack>
      </Stack>
      {!!children && <Box sx={{ p: 2 }}>{children}</Box>}
      {(bottomLeftActions || bottomRightActions) && (
        <Stack direction="row" px={2} pb={2}>
          {bottomLeftActions}
          <Box sx={{ flexGrow: 1 }} />
          {bottomRightActions}
        </Stack>
      )}
    </Card>
  )
}

export default StandardCard
