import { API, unwrapData } from '../axios-instance'
import { Credentials, MFAResponse, Token } from './types'

export const initiatePasswordLogin = async ({
  credentials,
}: {
  credentials: Credentials | null
}) => {
  return await API.post<Token | MFAResponse>('/identity/passwords/login', credentials).then(
    unwrapData,
  )
}

export const logout = async () => {
  return await API.post<true>('/identity/logout').then(unwrapData)
}

type InitiatePasswordRecoveryParams = {
  email: string | null
  domain: string
}

export const initiatePasswordRecovery = async (params: InitiatePasswordRecoveryParams) => {
  return await API.post('/identity/passwordIdentities/recovery/', params)
}

type ApplyPasswordResetParams = {
  email: string | null
  code: string | null
  newPassword: string | null
  domain: string
}

export const applyPasswordReset = async (params: ApplyPasswordResetParams) => {
  return await API.post('/identity/passwords/reset', params)
}

export const accessTokenInfo = async (token: string) => {
  return await API.get<Token>('/identity/accessToken/info', {
    headers: { Authorization: token },
  }).then(unwrapData)
}
