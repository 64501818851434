import { colors } from '@globals/themes/defaultTheme'
import { spacing } from '@globals/themes/themeConstants'
import { MediaObjectUrl } from '@kudos/http-client'
import { MenuOption } from '@lexical/react/LexicalTypeaheadMenuPlugin'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/material/styles'
import UserAvatar from '@shared/avatar/UserAvatar'
import { fullNameFromParts } from '@shared/utils/nameFormatting'
import { FC } from 'react'

const listItemStyle: SxProps = {
  display: 'flex',
  flex: '1 0 auto',
  outline: 'none',
  cursor: 'pointer',
  borderRadius: spacing(1),
}

export class MentionTypeaheadOption extends MenuOption {
  id: string
  firstName: string
  lastName: string
  media?: MediaObjectUrl | null

  constructor(id: string, firstName: string, lastName: string, media?: MediaObjectUrl | null) {
    super(id)
    this.id = id
    this.firstName = firstName
    this.lastName = lastName
    this.media = media
  }
}

type MentionsTypeaheadMenuItemProps = {
  index: number
  isSelected: boolean
  onClick: () => void
  onMouseEnter: () => void
  option: MentionTypeaheadOption
}
export const MentionsTypeaheadMenuItem: FC<MentionsTypeaheadMenuItemProps> = ({
  index,
  isSelected,
  option,
  onClick,
  onMouseEnter,
}) => {
  return (
    <ListItem
      key={option.key}
      tabIndex={-1}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={`typeahead-item-${index}`}
      sx={{
        ...listItemStyle,
        backgroundColor: isSelected ? colors.grayLight : colors.white,
      }}
      onMouseEnter={onMouseEnter}
      onClick={onClick}>
      <ListItemAvatar>
        <UserAvatar
          firstName={option?.firstName ?? 'A'}
          lastName={option?.lastName ?? 'A'}
          media={option?.media}
          size={48}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography ml={1} fontWeight={600}>
            {fullNameFromParts(option?.firstName, option?.lastName)}
          </Typography>
        }
      />
    </ListItem>
  )
}

export default MentionsTypeaheadMenuItem
