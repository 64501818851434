import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import StandardPageLayout from '@layouts/standardPageLayout'
import RouteLoading from '@shared/routing/RouteLoading'
import { T } from '@transifex/react'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const SettingsRoute = lazyWithRetries(() => import('../settings/index'))
const ChangePasswordRoute = lazyWithRetries(() => import('../settings/changePassword'))

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.changePassword(),
    element: (
      <StandardPageLayout
        elementId="changePasswordRoute"
        maxWidth={600}
        title={<T _str="Change password" />}>
        <Suspense fallback={<RouteLoading />}>
          <ChangePasswordRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.mySettings(':tab?'),
    element: (
      <StandardPageLayout elementId="settingsRoute" maxWidth={600} title={<T _str="My settings" />}>
        <Suspense fallback={<RouteLoading />}>
          <SettingsRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
]

export default routes
