import { colors } from '@globals/themes/defaultTheme'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { t } from '@transifex/native'
import { ReactNode } from 'react'
import useRecognitionModal from './useRecognitionModal'

type BackModalHeaderProps = {
  title: ReactNode
  onBackClicked: () => void
}
const BackModalHeader: React.FC<BackModalHeaderProps> = ({ title, onBackClicked }) => {
  const { closeDialog } = useRecognitionModal()
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      height={60}
      alignItems="center"
      borderBottom={`1px solid ${colors.grayExtraLight}`}
      p={2}>
      <Stack direction="row" alignItems="center" useFlexGap gap={1}>
        <IconButton
          id="expandCheckInCategoryRow"
          aria-label={t('Back button')}
          size="small"
          onClick={() => onBackClicked()}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="subtitle1">{title}</Typography>
      </Stack>
      <IconButton
        id="closeModalButton"
        aria-label={t('Close button')}
        size="small"
        onClick={() => closeDialog()}>
        <CloseIcon />
      </IconButton>
    </Stack>
  )
}

export default BackModalHeader
