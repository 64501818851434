import AppPaths from '@globals/paths'
import { colors } from '@globals/themes/defaultTheme'
import { Announcement, Award, CalendarEvent, Recognition } from '@kudos/http-client'
import { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import PageLoading from '@shared/loaders/PageLoading'
import { shuffle } from 'lodash/fp'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import 'swiper/css'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import KudosTvItem from './KudosTvItem'
import RecognizeQRCode from './RecognizeQRCode'
import useFetchKudosTv from './useFetchKudosTv'

const RECOGNITION_ROTATION_INTERVAL = 40000

const containerStyle: SxProps = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  backgroundColor: colors.grayDark,
  maxWidth: '100% !important',
  padding: '40px 60px 0 !important',
}

const boxStyle: SxProps = {
  overflow: 'hidden',
  margin: 0,
  padding: '0 !important',
}

type TvContent = CalendarEvent | Announcement | Recognition | Award

const KudosTvRoute = () => {
  const { tvToken } = useParams()
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(0)
  const [shuffledContent, setShuffledContent] = useState<TvContent[]>([])
  const { tvContent, refetchContent, isFetched, isError } = useFetchKudosTv(tvToken as string)

  const maxSteps = tvContent?.length || 0

  useEffect(() => {
    if (activeStep === maxSteps - 1) {
      refetchContent()
      setActiveStep(0)
    }
  }, [activeStep, maxSteps, refetchContent])

  useEffect(() => {
    if (isFetched) {
      setShuffledContent(shuffle(tvContent))
    }
  }, [isFetched])

  if (!isFetched) {
    return <PageLoading />
  }

  if (isError) {
    navigate(AppPaths.anon.userLogin())
    return null
  }

  return (
    <Container sx={containerStyle}>
      <Box sx={boxStyle}>
        <Swiper
          modules={[Autoplay]}
          pagination={false}
          autoplay={{
            delay: RECOGNITION_ROTATION_INTERVAL,
            disableOnInteraction: false,
          }}
          onSlideChange={swiper => setActiveStep(swiper.realIndex)}
          loop={true}
          autoHeight={true}
          slidesPerView={1}
          style={{ width: '100%' }}>
          {shuffledContent?.map((content, index) => (
            <SwiperSlide key={`kudosTv-${content.__entity_type__}-${index}`}>
              {Math.abs(activeStep - index) <= 2 ? <KudosTvItem item={content} /> : null}
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <RecognizeQRCode />
    </Container>
  )
}

export default KudosTvRoute
