import { RouteObject } from 'react-router-dom'
import checkInQuestionRoute from './activities/checkIns/routes'
import awardsSetupRoutes from './admin/engagement/awards/routes'
import adminRoutes from './admin/routes'
import awardsRoutes from './awards/routes'
import cardRoutes from './cards/routes'
import celebrateRoutes from './celebrate/routes'
import feedbackRoutes from './feedback/routes'
import homeRoute from './home/routes'
import memberRoutes from './me/routes'
import recognitionRoutes from './recognition/routes'
import resourceHubRoutes from './resourceHub/routes'
import rewardsRoutes from './rewards/routes'
import settingsRoutes from './settings/routes'
import yourDirectReportsRoute from './yourDirectReports/routes'

const routes: RouteObject[] = [
  ...adminRoutes,
  ...memberRoutes,
  ...settingsRoutes,
  ...homeRoute,
  ...recognitionRoutes,
  ...resourceHubRoutes,
  ...checkInQuestionRoute,
  ...feedbackRoutes,
  ...rewardsRoutes,
  ...yourDirectReportsRoute,
  ...awardsSetupRoutes,
  ...awardsRoutes,
  ...celebrateRoutes,
  ...cardRoutes,
]

export default routes
