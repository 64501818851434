import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import StandardPageLayout from '@layouts/standardPageLayout'
import RouteLoading from '@shared/routing/RouteLoading'
import { T } from '@transifex/react'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const CelebrateRoute = lazyWithRetries(() => import('./'))

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.celebrate(':tab?', ':subTab?'),
    element: (
      <StandardPageLayout elementId="celebrateRoute" maxWidth={1080} title={<T _str="Celebrate" />}>
        <Suspense fallback={<RouteLoading />}>
          <CelebrateRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
]

export default routes
