import { lazyWithRetries } from '@globals/lazyWithRetries'
import AppPaths from '@globals/paths'
import StandardPageLayout from '@layouts/standardPageLayout'
import RouteLoading from '@shared/routing/RouteLoading'
import { T } from '@transifex/react'
import { Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const RewardsProvidersRoute = lazyWithRetries(() => import('.'))
const TangoRewardsRoute = lazyWithRetries(() => import('./rewardProvider/tango'))
const TangoRewardRoute = lazyWithRetries(() => import('./rewardProvider/tango/reward'))
const RedemptionHistoryRoute = lazyWithRetries(() => import('./redemptionHistory'))

const routes: RouteObject[] = [
  {
    path: AppPaths.tenant.rewardsProviders(),
    element: (
      <StandardPageLayout
        elementId="rewardsProviderRoute"
        maxWidth={900}
        title={<T _str="Select a rewards provider" />}>
        <Suspense fallback={<RouteLoading />}>
          <RewardsProvidersRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.rewardProvider(':rewardProviderId'),
    element: (
      <StandardPageLayout
        elementId="tangoRewardProvider"
        maxWidth={1080}
        title={<T _str="Rewards" />}>
        <Suspense fallback={<RouteLoading />}>
          <TangoRewardsRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.redemptionHistory(),
    element: (
      <StandardPageLayout
        elementId="redemptionHistory"
        maxWidth={1440}
        title={<T _str="Redemption history" />}>
        <Suspense fallback={<RouteLoading />}>
          <RedemptionHistoryRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
  {
    path: AppPaths.tenant.tangoReward(':rewardProviderId', ':tangoRewardId'),
    element: (
      <StandardPageLayout elementId="tangoReward" maxWidth={600}>
        <Suspense fallback={<RouteLoading />}>
          <TangoRewardRoute />
        </Suspense>
      </StandardPageLayout>
    ),
  },
]

export default routes
