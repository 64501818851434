import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { FC } from 'react'

type ViewMoreButtonProps = {
  buttonText: React.ReactNode
  isFetchingNextPage?: boolean
  fetchMore?: () => void
  btnVariant?: 'outlined' | 'text'
}

const ViewMoreButton: FC<ViewMoreButtonProps> = ({
  buttonText,
  isFetchingNextPage,
  fetchMore,
  btnVariant = 'text',
}) => {
  return (
    <Stack direction="column" alignItems="center" m={1}>
      <Button
        id="viewMoreBtn"
        variant={btnVariant}
        disabled={isFetchingNextPage as boolean}
        onClick={fetchMore as () => void}>
        {buttonText}
      </Button>
    </Stack>
  )
}

export default ViewMoreButton
