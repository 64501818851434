import { API, unwrapData } from '../axios-instance'
import { TeamDisplayData, TenantMemberDisplayData } from '../identity'
import { MediaObjectUrl } from '../media'
import {
  FederatedSearchRecipientsParams,
  FederatedSearchRecipientsResults,
  PageResult,
  PaginatedSearch,
  WithPermissions,
} from '../shared'

export enum AnnouncementStatus {
  SCHEDULED = 'scheduled',
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  DRAFT = 'draft',
}
export type ReadRate = {
  markedAsRead: number
  total: number
}

export type Announcement = WithPermissions<{
  id: string
  tenantId: string
  createdById: string
  title: string
  body: string
  startAt: string | null
  endAt: string | null
  notifiedAt: string | null
  notify: boolean
  tenantWide: boolean
  attachments: string[]
  createdAt: string
  updatedAt: string
  // embeds
  status: AnnouncementStatus
  createdBy: TenantMemberDisplayData
  readRate: ReadRate | null
  attachmentUrls: MediaObjectUrl[]
  audience: TeamDisplayData[] | true
  readAt: string | null
  __entity_type__: 'announcement'
}>

export type AnnouncementParticipant = {
  announcementId: string
  memberId: string
  createdAt: string
  updatedAt: string
  __entity_type__: 'announcementParticipant'
}

export type AnnouncementAudience = {
  announcementId: string
  viewerId: string
  createdAt: Date
  updatedAt: Date
  __entity_type__: 'announcementAudience'
}

export type CreateAnnouncementParams = {
  title: string
  body: string
  attachments: string[]
  startAt: string | null
  endAt: string | null
  tenantWide: boolean
  teamIds: string[]
  notify: boolean
}

export type AnnouncementEmbeds =
  | 'status'
  | 'createdBy'
  | 'readAt'
  | 'audience'
  | 'readRate'
  | 'attachmentUrls'

export type ListAnnouncementsParams = PaginatedSearch<
  {
    statusIn?: AnnouncementStatus[]
  },
  AnnouncementEmbeds
>

export const createAnnouncement = async (params: CreateAnnouncementParams) => {
  return await API.post<Announcement>('/announcement/announcements', params).then(unwrapData)
}

export const cloneAnnouncement = async (announcementId: string) => {
  return await API.post<Announcement>(`/announcement/announcements/${announcementId}/clone`).then(
    unwrapData,
  )
}

export const deleteAnnouncement = async (announcementId: string) => {
  return await API.delete<boolean>(`/announcement/announcements/${announcementId}`).then(unwrapData)
}

export const listAnnouncementAudience = async (params: FederatedSearchRecipientsParams) => {
  return await API.get<FederatedSearchRecipientsResults>('/announcement/audiences', {
    params,
  }).then(unwrapData)
}

export const listAnnouncements = async (params: ListAnnouncementsParams) => {
  return await API.get<PageResult<Announcement>>('/announcement/announcements', {
    params,
  }).then(unwrapData)
}

export const emailPreviewAnnouncement = async (params: CreateAnnouncementParams) => {
  return await API.post<{ email: string }>('/announcement/announcements/emailPreview', params).then(
    unwrapData,
  )
}

export const getAnnouncement = async (announcementId: string, embeds?: AnnouncementEmbeds[]) => {
  return await API.get<Announcement>(`/announcement/announcements/${announcementId}`, {
    params: { embeds },
  }).then(unwrapData)
}

export const editAnnouncement = async (
  announcementId: string,
  params: CreateAnnouncementParams,
) => {
  return await API.patch<Announcement>(
    `/announcement/announcements/${announcementId}`,
    params,
  ).then(unwrapData)
}

export const markAnnouncementAsRead = async (announcementId: string) => {
  return await API.put<boolean>(`/announcement/announcements/${announcementId}/participation`).then(
    unwrapData,
  )
}

export const listTvAnnouncements = async (token: string, embeds?: AnnouncementEmbeds[]) => {
  return await API.get<Announcement[]>(`/announcement/tv/${token}/announcements`, {
    params: { embeds },
  }).then(unwrapData)
}
