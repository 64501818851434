import ErrorOutline from '@mui/icons-material/ErrorOutline'
import FormHelperText from '@mui/material/FormHelperText'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/material/styles'
import { ReactNode } from 'react'

const errorContainerStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
}

type ErrorTextProps = {
  children: ReactNode | ReactNode[]
  rightAligned?: boolean
}

const ErrorText = ({ children, rightAligned = false }: ErrorTextProps) => {
  return (
    <FormHelperText
      error
      sx={{
        ...errorContainerStyle,
        justifyContent: rightAligned ? 'flex-end' : 'flex-start',
      }}>
      <Stack component="span" direction="row" spacing={1}>
        <ErrorOutline fontSize="medium" color="error" />
        <Typography component="span" color="error">
          {children}
        </Typography>
      </Stack>
    </FormHelperText>
  )
}

export default ErrorText
