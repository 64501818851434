import { RECONITION_GOAL_QK } from '@components/RecognitionCounter'
import {
  CreateRecognitionParams,
  ErrorState,
  PointsExceptionError,
  RecognitionStatus,
  createRecognition,
  editRecognition,
} from '@kudos/http-client'
import { useApiError } from '@shared/errors/useApiError'
import { useInvalidateQuery } from '@shared/hooks/useInvalidateQuery'
import { allPointsQueryKey } from '@shared/hooks/useMemberPoints'
import { useMutation } from '@tanstack/react-query'
import { TIMELINE_ITEMS_QK } from '@tenant/home/user/timeline/useTimelineItems'
import useRecognitionModal, {
  RecognitionModalViews,
} from '@tenant/recognition/RecognitionModal/useRecognitionModal'
import { useConfigureRecognitionTabs } from '@tenant/recognition/useConfigureRecognitionTabs'
import { useT } from '@transifex/react'
import { AxiosError } from 'axios'
import { isFinite } from 'lodash/fp'
import { UseFormSetError } from 'react-hook-form'
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom'
import { RecognitionFormData } from './useSendRecognitionForm'

const useSendRecognitionMutations = (
  setIsShowAnimation: (showAnimation: boolean) => void,
  setPointsException: (pointsExceptions?: PointsExceptionError) => void,
  setError: UseFormSetError<RecognitionFormData>,
  qualitiesRequired: boolean,
  recognitionId?: string,
) => {
  const t = useT()
  const { refetchDraftRecogitionCount } = useConfigureRecognitionTabs()
  const [_searchParams, setSearchParams] = useSearchParams()
  const invalidateQuery = useInvalidateQuery()
  const { handleApiError } = useApiError<RecognitionFormData>()
  const { setView } = useRecognitionModal()
  const isEditMode = !!recognitionId

  const createParams = (
    data: RecognitionFormData,
    status: RecognitionStatus,
    ignoreExceptions: boolean = false,
  ) => {
    const params: CreateRecognitionParams = {
      message: data.message,
      amount: Number(data.amount),
      attachments: (data.attachments ?? []).map(x => x.mediaObjectId),
      qualityIds: data.qualities.map(quality => quality.id),
      sendTo: data.sendTo,
      gifs: !!data.gif ? [data.gif] : [],
      scheduledAt: data.scheduledAt,
      status,
      ignoreExceptions,
    }

    return params
  }

  const setErrors = (error: Error) => {
    handleApiError(error, setError, ['message', 'qualities', 'amount', 'sendTo'])
  }

  const { mutateAsync: createOrSaveRecognitionMutation } = useMutation({
    mutationFn: (params: CreateRecognitionParams) => {
      return isEditMode ? editRecognition(recognitionId, params) : createRecognition(params)
    },
  })

  const publishRecognition = (
    data: RecognitionFormData,
    ignoreExceptions: boolean = false,
    resetRecognitionForm: () => void,
  ) => {
    if (qualitiesRequired && data.qualities.length === 0) {
      setError('qualities', { message: t('Qualities are required') })
      return
    }
    createOrSaveRecognitionMutation(
      createParams(data, RecognitionStatus.PUBLISHED, ignoreExceptions),
      {
        onSuccess: async () => {
          setIsShowAnimation(true)
          await invalidateQuery([allPointsQueryKey])
          await invalidateQuery([TIMELINE_ITEMS_QK])
          await invalidateQuery([RECONITION_GOAL_QK])
          if (isEditMode) {
            refetchDraftRecogitionCount()
          }
          resetRecognitionForm()
          setPointsException()
        },
        onError: (error: Error) => {
          const errorType = (error as AxiosError<ErrorState>)?.response?.data?.error

          if (errorType === 'ValidationError') {
            setErrors(error)
            return
          }
          if (errorType === 'PointsExceptionError') {
            setSearchParams({ pointsException: 'true' } as URLSearchParamsInit)
            setPointsException(
              (error as AxiosError<ErrorState>)?.response?.data as unknown as PointsExceptionError,
            )
            return
          }
          handleApiError(error)
        },
      },
    )
  }

  const saveDraftRecognition = async (data: RecognitionFormData) => {
    await createOrSaveRecognitionMutation(
      createParams(
        {
          ...data,
          amount: isFinite(data.amount) ? data.amount : 0,
        },
        RecognitionStatus.DRAFT,
      ),
      {
        onSuccess: () => {
          refetchDraftRecogitionCount()
          setView({
            view: RecognitionModalViews.DraftAndInProgressRecognitions,
          })
        },
        onError: error => setErrors(error),
      },
    )
  }

  return {
    publishRecognition,
    saveDraftRecognition,
  }
}

export default useSendRecognitionMutations
