import { useAuth } from '@auth/useAuth'
import AppPaths from '@globals/paths'
import { Navigate, Outlet, createSearchParams, useLocation } from 'react-router-dom'

export const UserScopeRoutes = () => {
  const { isAuthenticated, isUserScope } = useAuth()
  const { pathname } = useLocation()

  // grab the destination url here so that if the user is unauthenticated
  // we can set it as a search parameter for use after the login flow.
  // the tenant login page will handle this and send the user to the
  // intended destination after login, falling back to the Home page if it
  // isn't set.
  const deepLinkParams = createSearchParams({
    next: pathname,
  })

  const redirectPath = isAuthenticated
    ? `${AppPaths.tenant.me()}?${deepLinkParams}`
    : `${AppPaths.anon.userLogin()}?${deepLinkParams}`

  return isUserScope ? <Outlet /> : <Navigate to={redirectPath} />
}
