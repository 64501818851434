import { tabletQuery } from '@globals/themes/themeConstants'
import Box from '@mui/material/Box'
import { SxProps } from '@mui/material/styles'
import { Nav } from '@nav/Nav'
import RecognitionModal from '@tenant/recognition/RecognitionModal/RecognitionModal'
import React from 'react'
import { useRouteUpdate } from './RouteUpdateContext'

const APPBAR_OFFSET = 56

const rootLayoutContainerStyle: SxProps = {
  flexGrow: 1,
  overflowX: 'auto',
  overflowY: 'auto',
  position: 'relative',
  p: 0,
  width: '100vw',
  [tabletQuery]: {
    // NOTE: Calculated offset for the appbar that is
    // rendered with the Nav component below it
    marginBottom: `${APPBAR_OFFSET}px`,
  },
}

const rootLayoutScrollStyle: SxProps = {
  flexGrow: 1,
  overflowX: 'auto',
  overflowY: 'hidden',
  position: 'relative',
  minHeight: '100%',
  px: 6,
  pb: 6,
  [tabletQuery]: {
    px: 2,
    pb: 3,
    // NOTE: Calculated offset for the appbar that is
    // rendered with the Nav component below it
    marginBottom: `${APPBAR_OFFSET}px`,
  },
}

export const MAIN_SCROLL_PORT_ID = 'main-scroll-port'

const RootLayout: React.FC<{ isAuthenticated: boolean; children: React.ReactElement | null }> = ({
  isAuthenticated,
  children,
}) => {
  const { routeStyles, parentRef } = useRouteUpdate()

  return (
    <>
      <Box display="flex" alignItems="flex-start">
        {isAuthenticated && <Nav />}
        <Box
          sx={rootLayoutContainerStyle}
          component="main"
          id={MAIN_SCROLL_PORT_ID}
          ref={parentRef}>
          <Box
            sx={[
              rootLayoutScrollStyle,
              ...(Array.isArray(routeStyles) ? routeStyles : [routeStyles]),
            ]}>
            {children}
          </Box>
        </Box>
      </Box>
      {isAuthenticated && <RecognitionModal />}
    </>
  )
}

export default RootLayout
