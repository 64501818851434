import { colors } from '@globals/themes/defaultTheme'
import { tabletQuery } from '@globals/themes/themeConstants'
import { useEffect } from 'react'
import { useRouteUpdate } from './RouteUpdateContext'

const fullscreenLayoutStyle = {
  m: 0,
  p: 0,
  [tabletQuery]: {
    p: 0,
    m: 0,
  },
}

type FullscreenLayoutProps = {
  children: React.ReactNode
}

const FullscreenLayout: React.FC<FullscreenLayoutProps> = ({ children }) => {
  const { setRouteStyles } = useRouteUpdate()

  useEffect(() => {
    setRouteStyles({
      ...fullscreenLayoutStyle,
      backgroundColor: colors.blueUltraLight,
    })

    return () => {
      setRouteStyles(undefined)
    }
  }, [setRouteStyles])

  return children
}

export default FullscreenLayout
