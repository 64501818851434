import { useDialog } from '@globals/dialog/useDialogStore'
import { colors } from '@globals/themes/defaultTheme'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import BottomDrawer from '@shared/dialog/BottomDrawer'
import { T } from '@transifex/react'

const listIconStyle = {
  minWidth: 36,
  '> svg': {
    color: colors.blueDark,
  },
}

type ListItem = {
  text: string
  icon?: React.ReactNode
}

type PWAInstallationModalProps = {
  dialogKey: string
  listItem1: ListItem
  listItem2: ListItem
}

const PWAInstallationModal: React.FC<PWAInstallationModalProps> = ({
  dialogKey,
  listItem1,
  listItem2,
}) => {
  const { closeDialog } = useDialog(dialogKey)

  return (
    <BottomDrawer drawerKey={dialogKey} title={<T _str="Add to home screen" />}>
      <Typography variant="body1">
        <T _str="This site has app functionality, add it to your home screen for the best experience" />
      </Typography>
      <List>
        <ListItem disablePadding>
          {listItem1.icon && <ListItemIcon sx={listIconStyle}>{listItem1.icon}</ListItemIcon>}
          <ListItemText primary={listItem1.text} />
        </ListItem>
        <ListItem disablePadding>
          {listItem2.icon && <ListItemIcon sx={listIconStyle}>{listItem2.icon}</ListItemIcon>}
          <ListItemText primary={listItem2.text} />
        </ListItem>
      </List>
      <Stack alignContent="left">
        <Button variant="outlined" onClick={closeDialog} id="btnCloseIosInstallPrompt">
          <T _str="Close" />
        </Button>
      </Stack>
    </BottomDrawer>
  )
}

export default PWAInstallationModal
