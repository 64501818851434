import { API, unwrapData } from '../axios-instance'
import { Comment, ThreadType } from '../comment/types'
import { TenantMemberDisplayData } from '../identity'
import {
  FederatedSearchRecipientsParams,
  FederatedSearchRecipientsResults,
  PageResult,
} from '../shared/types'
import {
  CountResponse,
  CreateCommentParams,
  CreateEndorsementParams,
  CreateReactionParams,
  FlagParams,
  GetCommentsParams,
  ListCommentsReactionsParams,
  ListEndorsementsParams,
  ListReactionsParams,
  UpdateWallPostMessageParams,
} from '../shared/wallPost/types'
import {
  CreateQualityParams,
  CreateRecognitionParams,
  CreateValueParams,
  FlagCommentParams,
  ListRecognitionRecipientsSummaryParams,
  ListRecognitionsParams,
  ListSuggestedRecipientsParams,
  ListValuesParams,
  ManagerDirectReportRanking,
  ManagerRanking,
  PeerRecognition,
  PreviewRecipientsResults,
  Quality,
  Recognition,
  RecognitionEmbeds,
  RecognitionEndorsement,
  RecognitionGoal,
  RecognitionParams,
  RecognitionReaction,
  RecognitionSummaryRecipient,
  RecognitionWithFlags,
  SendTo,
  SuggestedRecipientsResults,
  Value,
} from './types'

export const listValues = async (params?: ListValuesParams) => {
  return await API.get<Value[]>('/recognition/values', { params }).then(unwrapData)
}

export const createValue = async ({ name, color }: CreateValueParams) => {
  return await API.post<Value>('/recognition/values', { name, color }).then(unwrapData)
}

export const createQuality = async ({ name, valueId }: CreateQualityParams) => {
  return await API.post<Quality>('/recognition/qualities', { name, valueId }).then(unwrapData)
}

export const createRecognition = async (params: CreateRecognitionParams) => {
  return await API.post<Recognition>('/recognition/recognitions', params).then(unwrapData)
}

export const editRecognition = async (recognitionId: string, params: CreateRecognitionParams) => {
  return await API.put<Recognition>(`/recognition/recognitions/${recognitionId}`, params).then(
    unwrapData,
  )
}

export const updateValue = async (valueId: string, { name, color }: CreateValueParams) => {
  return await API.put<Value>(`/recognition/values/${valueId}`, {
    name,
    color,
  }).then(unwrapData)
}

export const listRecognitionRecipients = async (params: FederatedSearchRecipientsParams) => {
  return await API.get<FederatedSearchRecipientsResults>('/recognition/recipients', {
    params,
  }).then(unwrapData)
}

export const listSuggestedRecipients = async (params: ListSuggestedRecipientsParams) => {
  return await API.get<SuggestedRecipientsResults>('/recognition/recipients/suggested', {
    params,
  }).then(unwrapData)
}

export const previewRecipientCount = async (params: { sendTo: SendTo[] }) => {
  return await API.post<PreviewRecipientsResults>('/recognition/recipients/preview', params).then(
    unwrapData,
  )
}

export const updateQuality = async (qualityId: string, { name, valueId }: CreateQualityParams) => {
  return await API.patch<Value>(`/recognition/qualities/${qualityId}`, {
    name,
    valueId,
  }).then(unwrapData)
}

export const deleteValue = async (valueId: string) => {
  return await API.delete<boolean>(`/recognition/values/${valueId}`)
}

export const deleteQuality = async (qualityId: string) => {
  return await API.delete<boolean>(`/recognition/qualities/${qualityId}`)
}

export const listRecognitions = async (params?: ListRecognitionsParams) => {
  return await API.get<PageResult<Recognition>>('/recognition/recognitions', { params }).then(
    unwrapData,
  )
}

export const listRecognitionRecipientsSummary = async (
  params: ListRecognitionRecipientsSummaryParams,
) => {
  return await API.get<PageResult<RecognitionSummaryRecipient>>('/recognition/recipients/summary', {
    params,
  }).then(unwrapData)
}

export const endorseRecognition = async ({ amount, id }: CreateEndorsementParams) => {
  return await API.post<RecognitionEndorsement>(`recognition/recognitions/${id}/endorsements`, {
    amount,
  }).then(unwrapData)
}

export const listRecognitionEndorsements = async (
  recognitionId: string,
  params: ListEndorsementsParams,
) => {
  return await API.get<PageResult<RecognitionEndorsement>>(
    `recognition/recognitions/${recognitionId}/endorsements`,
    { params },
  ).then(unwrapData)
}

export const listFlaggedRecognition = async (params?: ListRecognitionsParams) => {
  return await API.get<PageResult<RecognitionWithFlags>>('/recognition/recognitions/flagged', {
    params,
  }).then(unwrapData)
}

export const flagRecognition = async (id: string) => {
  return await API.put<boolean>(`recognition/recognitions/${id}/flags`).then(unwrapData)
}

export const restoreFlaggedRecognition = async ({ postId }: FlagParams) => {
  return await API.delete<boolean>(`recognition/recognitions/${postId}/flags`)
}

export const reactToRecognition = async ({ reaction, postId }: CreateReactionParams) => {
  return await API.put<RecognitionReaction>(`recognition/recognitions/${postId}/reactions`, {
    reaction,
    recognitionId: postId,
  }).then(unwrapData)
}

export const listRecognitionReactions = async (postId: string, params: ListReactionsParams) => {
  return await API.get<PageResult<RecognitionReaction>>(
    `recognition/recognitions/${postId}/reactions`,
    {
      params,
    },
  ).then(unwrapData)
}

export const createRecognitionComment = async ({
  body,
  id,
  tenantMemberId,
  threadId,
}: CreateCommentParams) => {
  return await API.post<Comment>(`recognition/recognitions/${id}/comments`, {
    body,
    tenantMemberId,
    threadId,
    threadType: ThreadType.RECOGNITION,
  }).then(unwrapData)
}

export const listRecognitionComments = async (id: string, params?: GetCommentsParams) => {
  return await API.get<PageResult<Comment>>(`/recognition/recognitions/${id}/comments`, {
    params,
  }).then(unwrapData)
}

export const flagRecognitionComment = async ({ id, commentId }: FlagCommentParams) => {
  return await API.put<boolean>(`recognition/recognitions/${id}/comments/${commentId}/flags`).then(
    unwrapData,
  )
}

export const reactToRecognitionComment = async ({
  reaction,
  postId,
  commentId,
}: CreateReactionParams) => {
  return await API.put<RecognitionReaction>(
    `recognition/recognitions/${postId}/comments/${commentId}/reactions`,
    {
      reaction,
      recognitionId: postId,
      commentId,
    },
  ).then(unwrapData)
}

export const removeRecognitionReaction = async ({
  postId,
}: Pick<CreateReactionParams, 'postId'>) => {
  return await API.delete<boolean>(`recognition/recognitions/${postId}/reactions`).then(unwrapData)
}

export const removeRecognitionCommentReaction = async ({
  postId,
  commentId,
}: Pick<CreateReactionParams, 'postId' | 'commentId'>) => {
  return await API.delete<boolean>(
    `recognition/recognitions/${postId}/comments/${commentId}/reactions`,
  ).then(unwrapData)
}

export const listRecognitionCommentReactions = async (
  commentId: string,
  id: string,
  params: ListCommentsReactionsParams,
) => {
  return await API.get<PageResult<RecognitionReaction>>(
    `recognition/recognitions/${id}/comments/${commentId}/reactions`,
    { params },
  ).then(unwrapData)
}

export const listSpotlightRecognitions = async (embeds: RecognitionEmbeds[], pageSize: number) => {
  return await API.get<Recognition[]>('/recognition/recognitions/spotlight', {
    params: {
      pageSize,
      embeds,
    },
  }).then(unwrapData)
}

export const recognitionCount = async (params: RecognitionParams) => {
  return await API.get<CountResponse>('/recognition/recognitions/count', { params }).then(
    unwrapData,
  )
}

export const deleteRecognition = async (recognitionId: string) => {
  return await API.delete<boolean>(`/recognition/recognitions/${recognitionId}`)
}

export const editRecognitionMessage = async (
  params: UpdateWallPostMessageParams,
  recognitionId: string,
) => {
  return await API.patch<Recognition>(`/recognition/recognitions/${recognitionId}`, params).then(
    unwrapData,
  )
}

export const getRecognition = async (recognitionId: string, embeds?: RecognitionEmbeds[]) => {
  return await API.get<Recognition>(`/recognition/recognitions/${recognitionId}`, {
    params: { embeds },
  }).then(unwrapData)
}

export const getRecognitionGoal = async () => {
  return await API.get<RecognitionGoal>('/recognition/recognitions/goal').then(unwrapData)
}

export const getUnrecognizedMember = async () => {
  return await API.get<TenantMemberDisplayData[]>('/recognition/recipients/unrecognized').then(
    unwrapData,
  )
}

export const managerRankings = async (limit: number) => {
  return await API.get<ManagerRanking>('/recognition/managers/rankings', {
    params: { limit },
  }).then(unwrapData)
}

export const managerDirectReportRankings = async () => {
  return await API.get<ManagerDirectReportRanking[]>(
    '/recognition/managers/directReportRankings',
  ).then(unwrapData)
}

export const getPeerRecognitions = async (limit: number) => {
  return await API.get<PeerRecognition[]>('/recognition/peers', {
    params: { limit },
  }).then(unwrapData)
}

export const listTvRecognitions = async (token: string, embeds?: RecognitionEmbeds[]) => {
  return await API.get<Recognition[]>(`/recognition/tv/${token}/recognitions`, {
    params: { embeds },
  }).then(unwrapData)
}
