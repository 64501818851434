import { useCurrentMember } from '@globals/useCurrentMember'
import { CountResponse, recognitionCount, RecognitionStatus } from '@kudos/http-client'
import { useQuery } from '@tanstack/react-query'
import { RECOGNITION_COUNT_QUERY_KEY } from '@tenant/recognition/useConfigureRecognitionTabs'

const useDraftRecognitionsCount = () => {
  const { member } = useCurrentMember()

  const { data: draftRecognitionCount, refetch: refetchDraftRecogitionCount } =
    useQuery<CountResponse>({
      queryKey: RECOGNITION_COUNT_QUERY_KEY,
      queryFn: () =>
        recognitionCount({
          statusIn: [RecognitionStatus.DRAFT],
          senderIdIn: member?.id ? [member.id] : [],
        }),
      enabled: !!member?.id,
    })
  return {
    draftRecognitionCount,
    refetchDraftRecogitionCount,
  }
}

export default useDraftRecognitionsCount
