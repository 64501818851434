import AppPaths from '@globals/paths'
import theme, { colors } from '@globals/themes/defaultTheme'
import { spacing } from '@globals/themes/themeConstants'
import CloseIcon from '@mui/icons-material/Close'
import HomeIcon from '@mui/icons-material/Home'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import PersonIcon from '@mui/icons-material/Person'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import Paper from '@mui/material/Paper'
import { SxProps } from '@mui/material/styles'
import useRecognitionModal, {
  RecognitionModalViews,
} from '@tenant/recognition/RecognitionModal/useRecognitionModal'
import { T } from '@transifex/react'
import { useLocation, useNavigate } from 'react-router-dom'

const bottomNavProps: SxProps = {
  zIndex: theme.zIndex.drawer + 1,
  borderTopWidth: 1,
  borderTopColor: colors.blueLight,
  borderTopStyle: 'solid',
  borderRadius: 0,
  justifyContent: 'space-between',
  padding: spacing(0.5, 0),
  button: {
    minWidth: 0,
    borderRadius: spacing(2),
    margin: spacing(0.5, 1.5),
  },
  'button.Mui-selected': {
    backgroundColor: colors.blueUltraLight,
    borderRadius: spacing(2),
    span: {
      fontSize: '0.75rem',
    },
    '> svg': {
      color: colors.bluePrimary,
    },
  },
}

type BottomNavMobileProps = {
  isMobileDrawerOpen: boolean
  onMobileDrawerToggle: () => void
}

const BottomNavMobile: React.FC<BottomNavMobileProps> = ({
  isMobileDrawerOpen,
  onMobileDrawerToggle,
}) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { openDialog } = useRecognitionModal()

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    if (newValue !== 'more') {
      navigate(newValue)
      isMobileDrawerOpen && onMobileDrawerToggle()
    } else {
      onMobileDrawerToggle()
    }
  }

  const isCurrentPath = (path: string): boolean => {
    return pathname === path
  }

  return (
    <Paper elevation={0} sx={bottomNavProps}>
      <BottomNavigation showLabels value={pathname} onChange={handleChange}>
        <BottomNavigationAction
          label={<T _str="Home" />}
          value={AppPaths.tenant.home()}
          icon={isCurrentPath(AppPaths.tenant.home()) ? <HomeIcon /> : <HomeOutlinedIcon />}
        />
        <BottomNavigationAction
          label={<T _str="Recognize" />}
          onClick={() =>
            openDialog({
              view: RecognitionModalViews.SendRecognition,
            })
          }
          icon={<StarBorderIcon />}
        />
        <BottomNavigationAction
          label={<T _str="Me" />}
          value={AppPaths.tenant.me()}
          icon={isCurrentPath(AppPaths.tenant.me()) ? <PersonIcon /> : <PermIdentityIcon />}
        />
        <BottomNavigationAction
          label={isMobileDrawerOpen ? <T _str="Close" /> : <T _str="More" />}
          value={'more'}
          icon={isMobileDrawerOpen ? <CloseIcon /> : <MoreVertIcon />}
        />
      </BottomNavigation>
    </Paper>
  )
}

export default BottomNavMobile
