import { tabletQuery } from '@globals/themes/themeConstants'
import Avatar from '@mui/material/Avatar'
import { SxProps } from '@mui/material/styles'
import { ReactNode } from 'react'

export type AvatarSizes = 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall'

export type AvatarIconProps = {
  avatarBackground: string
  avatarColor: string
  avatarIcon: ReactNode
  size?: AvatarSizes
  customStyles?: object
}

const avatarStyles: SxProps = {
  '& .MuiSvgIcon-root': {
    fontSize: 'inherit',
    color: 'inherit',
  },
}

const avatarSizeStyles: Record<AvatarSizes, SxProps> = {
  xlarge: {
    height: 96,
    width: 96,
    fontSize: 64,
    [tabletQuery]: {
      height: 88,
      width: 88,
      fontSize: 56,
    },
  },
  large: {
    height: 72,
    width: 72,
    fontSize: 48,
  },
  medium: {
    height: 48,
    width: 48,
    fontSize: 40,
  },
  small: {
    height: 40,
    width: 40,
    fontSize: 24,
  },
  xsmall: {
    height: 32,
    width: 32,
    fontSize: 18,
  },
}

const AvatarIcon: React.FC<AvatarIconProps> = ({
  avatarBackground,
  avatarColor,
  avatarIcon,
  size = 'small',
  customStyles = {} as SxProps,
}) => (
  <Avatar
    sx={{
      ...avatarStyles,
      ...avatarSizeStyles[size],
      color: avatarColor,
      background: avatarBackground,
      ...customStyles,
    }}>
    {avatarIcon}
  </Avatar>
)

export default AvatarIcon
