import { TeamDisplayData, TenantMemberDisplayData } from '../identity/types'
import { MediaObjectUrl } from '../media/types'
import { PaginatedSearch, Percentage, WithPermissions } from '../shared/types'
import { ReactionValue, WallPost } from '../shared/wallPost/types'

export type CreateRecognitionParams = {
  message: string
  amount: number
  attachments: string[]
  qualityIds: string[]
  sendTo: SendTo[]
  gifs: string[]
  scheduledAt: string | null
  status: RecognitionStatus
  ignoreExceptions: boolean
}

export type RecognitionRecipients = SendTo & {
  displayData?: TenantMemberDisplayData | TeamDisplayData
}

export type Recognition = WithPermissions<
  WallPost & {
    status: RecognitionStatus
    qualityIds: string[]
    attachments: string[]
    tenantWide: boolean
    scheduledAt: string | null
    publishStartedAt: string | null
    errorState: ErrorState | null
    // Embeds
    qualities: Quality[]
    ownReaction?: RecognitionReaction
    recipientCount: number
    ownRecipient: Recipient | null
    __entity_type__: 'recognition'
  }
>

export type RecognitionFlag = {
  id: string
  recognitionId: string
  flaggedById: string
  flaggedBy?: TenantMemberDisplayData
  dismissedById: string | null
  dismissedAt: string | null
  createdAt: string
  __entity_type__: 'recognitionFlag'
}

export enum PointsExceptionType {
  RESTRICTION = 'restriction',
  INSUFFICIENT_POINTS = 'insufficientPoints',
  NOT_ELIGIBLE = 'notEligible',
}

export type PointsException = {
  type: PointsExceptionType
  affected: number
  level: 'warning' | 'error'
}

export type PointsExceptionError = {
  type: 'PointsExceptionError'
  msg: string
  details: {
    amount: number
    balance: number
    exceptions: PointsException[]
  }
}

export type RecognitionWithFlags = Recognition & {
  flags: RecognitionFlag[]
}

export type Recipient = {
  recognitionId: string
  memberId: string
  transactionId: string | null
  amount: number | null
  __entity_type__: 'recipient'
}

export interface ErrorState {
  error: string
  msg: string
}

export type SendTo = {
  id: string
  type: SendTargetType
  name: string
}

export enum SendTargetType {
  TENANT_MEMBER = 'tenantMember',
  TEAM = 'team',
}

export enum RecognitionStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}

export type Quality = {
  id: string
  name: string
  valueId: string
  createdAt: string
  archivedAt: string | null
  __entity_type__: 'quality'
}

export type Value = WithPermissions<{
  id: string
  name: string
  color: string
  createdAt: string
  archivedAt: string | null
  tenantId: string
  qualities?: Quality[]
  __entity_type__: 'value'
}>

export type ValueEmbeds = 'qualities'

export type RecognitionEmbeds =
  | 'attachmentUrls'
  | 'qualities'
  | 'recipientCount'
  | 'reactionCounts'
  | 'sender'
  | 'sendTo'
  | 'comments'
  | 'endorsementStats'
  | 'ownReaction'
  | 'ownRecipient'

export type ListValuesParams = {
  idIn?: string[]
  embeds?: ValueEmbeds[]
}

export type RecognitionReaction = {
  recognitionId: string
  memberId: string
  reaction: ReactionValue
  memberDisplayData?: TenantMemberDisplayData
  createdAt: string
  updatedAt: string
  __entity_type__: 'reaction'
}

export type CreateValueParams = {
  name: string
  color: string
}

export type CreateQualityParams = {
  name: string
  valueId: string
}

export type ListRecognitionRecipientsSummaryParams = PaginatedSearch<
  {
    days?: number
    ownReports?: boolean
  },
  'members'
>

export type ListSuggestedRecipientsParams = {
  notIdIn: string[]
}

export type SummaryRecipient = {
  firstName?: string
  lastName?: string
  avatarUrls?: MediaObjectUrl | null
  jobTitle?: string | null
}

export type RecognitionSummaryRecipient = {
  memberId: string
  receivedCount: number
  memberDisplayData: SummaryRecipient
}

export type SuggestedRecipientsResults = TenantMemberDisplayData[]

export type PreviewRecipientsResults = {
  recipientCount: number
}

export type RecognitionParams = {
  idIn?: string[]
  statusIn?: RecognitionStatus[]
  senderIdIn?: string[]
  recipientIdIn?: string[]
  sentByOrToIdIn?: string[]
  publishedAfter?: Date
}

export type ListRecognitionsParams = PaginatedSearch<RecognitionParams, RecognitionEmbeds>

export type FlagCommentParams = {
  id: string
  commentId: string
}

export type RecognitionEndorsement = {
  recognitionId: string
  memberId: string
  amount: number
  memberDisplayData?: TenantMemberDisplayData
  createdAt: string
  __entity_type__: 'endorsement'
}

export type RecognitionGoal = {
  count: number
  goal: number
}

export type ManagerRankingData = {
  rank: number
  value: number
  total: number
  percent: Percentage
  memberDisplayData: TenantMemberDisplayData
  diff: {
    percent: Percentage
  }
}

export type TopSendRate = {
  type: 'topSendRate'
  data: ManagerRankingData[]
  updatedAt: string
}

export type BottomSendRate = {
  type: 'bottomSendRate'
  data: ManagerRankingData[]
  updatedAt: string
}

export type AverageSendRate = {
  type: 'averageSendRate'
  updatedAt: string
  data: { percent: Percentage; diff: { percent: Percentage | null } }
}

export type MySendRate = {
  type: 'mySendRate'
  data: {
    ranks: number | null
    total: number
    diff: {
      percent: Percentage
    }
  }
  updatedAt: string
}

export type ManagerRanking = [TopSendRate, BottomSendRate, MySendRate, AverageSendRate]

export type ManagerDirectReportRanking = {
  id: string
  count: number
  memberDisplayData: TenantMemberDisplayData
  diff: {
    percent: Percentage | null
  }
}

export type PeerRecognition = {
  memberId: string
  memberDisplayData: TenantMemberDisplayData
  lastRecognizedAt: null | string
}
