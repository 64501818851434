import { useApps } from '@auth/useApps'
import { MAIN_SCROLL_PORT_ID } from '@layouts/RootLayout'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { adminNavConfig, tenantNavConfig, userNavConfig } from './config'
import { NavItem } from './types'
import { calculateVisible, defaultExpanded, isAdminPath, isTenantNavHiddenPath } from './utils'

type NavMode = 'admin' | 'default' | 'hidden'

type UseNavHook = {
  onCollapse: (arg0: string) => void
  expanded: string[]
  mode: NavMode
  visibleItems: NavItem[]
  setMode: (mode: NavMode) => void
  canViewAdmin: boolean
}

/**
 * Handles the state management of the navigation. Determining what set of nav items should be shown
 * and controlling the expand/collapse state of groups.
 */
export const useNav = (isAuthenticated: boolean, isTenantScope: boolean): UseNavHook => {
  const [mode, setMode] = useState<NavMode>('default')
  const { pathname } = useLocation()
  const [hasAppPermission] = useApps()

  const canViewAdmin = useMemo(() => {
    if (isTenantScope) {
      return calculateVisible(adminNavConfig, hasAppPermission, pathname).length > 0
    }
    return false
  }, [hasAppPermission, isTenantScope, pathname])

  // This ensures that the correct menu model (admin/default) is
  // selected when they load the page directly into a section.
  // Since isAdminPath actually iterates the links, we memo the
  // operation to prevent unnecessary calls.
  useEffect(() => {
    if (isTenantNavHiddenPath(pathname)) {
      return setMode('hidden')
    }
    if (isAdminPath(pathname) && canViewAdmin) {
      return setMode('admin')
    }
    return setMode('default')
  }, [pathname, canViewAdmin])

  useEffect(() => {
    const main = document.getElementById(MAIN_SCROLL_PORT_ID)
    if (main) main.scrollTop = 0
  }, [pathname])

  const visibleItems = useMemo(() => {
    const getItems = () => {
      if (isAuthenticated) {
        if (isTenantScope) {
          if (mode === 'admin') {
            return adminNavConfig
          }
          return tenantNavConfig
        }
        return userNavConfig
      }
      return []
    }
    return calculateVisible(getItems(), hasAppPermission, pathname)
  }, [hasAppPermission, isTenantScope, isAuthenticated, mode, pathname])

  const [expanded, setExpanded] = useState<string[]>(defaultExpanded(visibleItems, pathname))

  useEffect(() => {
    // Restore default expansion state when visible items change
    const newExpanded = defaultExpanded(visibleItems, pathname)
    // NOTE: This fixes excessive re-renders. Only update 'expanded' if it actually changes.
    setExpanded(prevExpanded =>
      JSON.stringify(prevExpanded) === JSON.stringify(newExpanded) ? prevExpanded : newExpanded,
    )
  }, [visibleItems, pathname])

  const onCollapse = useCallback(
    (key: string) => {
      if (expanded.includes(key)) {
        setExpanded(expanded.filter(k => k !== key))
      } else {
        setExpanded([...expanded, key])
      }
    },
    [expanded],
  )

  return {
    visibleItems,
    expanded,
    onCollapse,
    mode,
    setMode,
    canViewAdmin,
  }
}
