import { ReactNode } from 'react'
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form'
import FormActionTray from './FormActionTray'
import FormDrawerActionTray from './FormDrawerActionTray'
import FormErrors from './FormErrors'

type FormContextWrapperProps<T extends FieldValues> = {
  formMethods: UseFormReturn<T>
  onSubmit: (data: T) => void
  children: ReactNode
  formActions?: ReactNode
  formDrawerActions?: ReactNode
}

const FormContextWrapper = <T extends FieldValues>({
  formMethods,
  onSubmit,
  children,
  formActions,
  formDrawerActions,
}: FormContextWrapperProps<T>) => {
  const { handleSubmit, formState } = formMethods

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {children}
        <FormErrors errors={formState.errors} />
        {!!formActions && <FormActionTray>{formActions}</FormActionTray>}
        {!!formDrawerActions && <FormDrawerActionTray>{formDrawerActions}</FormDrawerActionTray>}
      </form>
    </FormProvider>
  )
}

export default FormContextWrapper
