import { MediaObjectUrl, MediaVariantEnum } from '@kudos/http-client'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import { SxProps } from '@mui/material'
import { fullNameFromParts, initialsForName } from '@shared/utils/nameFormatting'
import { useT } from '@transifex/react'
import { ReactNode } from 'react'
import AvatarWrapper from './AvatarWrapper'
import useAvatarConfig from './useAvatarConfig'

export type AvatarSize = number

export type AvatarFontUnit = 'px' | 'vh'

type UserAvatarProps = {
  firstName?: string | null
  lastName?: string | null
  size?: AvatarSize
  media?: MediaObjectUrl | null
  /* optional override for images that do not have avatar variants */
  mediaVariant?: MediaVariantEnum
  src?: string
  fontUnit?: AvatarFontUnit
  hideDropShadow?: boolean
  defaultIcon?: ReactNode
  avatarBoxStyle?: SxProps
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  firstName,
  lastName,
  size = 40,
  media,
  mediaVariant,
  src,
  fontUnit = 'px',
  hideDropShadow = false,
  defaultIcon,
  avatarBoxStyle = {},
}) => {
  const t = useT()
  const { avatarUrl } = useAvatarConfig(size, media || null, mediaVariant)
  const renderAvatarContent = () => {
    if (!!src || !!avatarUrl) {
      return null
    }
    return initialsForName(firstName, lastName) || defaultIcon || <PersonOutlineOutlinedIcon />
  }

  return (
    <AvatarWrapper
      size={size}
      media={media}
      src={src}
      alt={t('User profile avatar image')}
      fontUnit={fontUnit}
      hideDropShadow={hideDropShadow}
      avatarBoxStyle={avatarBoxStyle}
      tooltipTitle={fullNameFromParts(firstName, lastName)}>
      {renderAvatarContent()}
    </AvatarWrapper>
  )
}

export default UserAvatar
