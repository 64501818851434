import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { SxProps } from '@mui/material/styles'
import { T } from '@transifex/react'
import { PropsWithChildren } from 'react'

type InfiniteScrollBoxProps = PropsWithChildren & {
  id?: string
  fetchNextPage?: () => void
  hasNextPage?: boolean
  isFetchingNextPage?: boolean
  scrollPadding?: number
  sx?: SxProps
}

const InfiniteScrollBox: React.FC<InfiniteScrollBoxProps> = ({
  id,
  children,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  scrollPadding = 40,
  sx,
}) => {
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget
    if (scrollHeight - scrollTop <= clientHeight + scrollPadding) {
      if (!!hasNextPage && !isFetchingNextPage) {
        fetchNextPage?.()
      }
    }
  }

  return (
    <Box id={id} sx={sx} onScroll={handleScroll}>
      {children}
      {isFetchingNextPage && (
        <Typography variant="body1" textAlign="center">
          <T _str="Loading more..." />
        </Typography>
      )}
    </Box>
  )
}

export default InfiniteScrollBox
