import Confetti from './ConfettiLottie.json'
import Fireworks from './FireworksLottie.json'
import HighFive from './HighFiveLottie.json'

export const AnimationVariants = ['confetti', 'fireworks', 'highFive'] as const

export type AnimationVariant = (typeof AnimationVariants)[number]

const Animations: {
  [key in AnimationVariant]: string | object
} = {
  confetti: Confetti,
  fireworks: Fireworks,
  highFive: HighFive,
}

export default Animations
