import { colors } from '@globals/themes/defaultTheme'
import {
  desktopQuery,
  mobileContainerQuery,
  spacing,
  tabletQuery,
} from '@globals/themes/themeConstants'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import { SxProps, Theme } from '@mui/material/styles'
import { FC, useState } from 'react'

const skeletonStyle = (loading: boolean) => ({
  display: loading ? 'block' : 'none',
  borderRadius: spacing(1.5),
  [desktopQuery]: {
    height: '100%',
    aspectRatio: '1 / 1',
  },
  [tabletQuery]: {
    height: '100%',
    width: '100%',
  },
  [mobileContainerQuery]: {
    height: '100%',
    width: '100%',
  },
})

const paperImgStyle: SxProps = {
  boxShadow: 'none',
  backgroundColor: colors.grayUltraLight,
  borderRadius: 0,
  border: 'none',
}

type PaperImageProps = {
  alt?: string
  imgSrc?: string
  defaultImgSrc?: string
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
  sx?: SxProps<Theme>
  width?: string | number
  height?: string | number
  backgroundColor?: string
}
const MediaImage: FC<PaperImageProps> = ({
  alt,
  imgSrc,
  defaultImgSrc,
  width,
  height,
  objectFit = 'cover',
  backgroundColor = colors.grayUltraLight,
}) => {
  const [loading, setLoading] = useState<boolean>(true)

  return (
    <Box maxWidth={width} maxHeight={height}>
      <Skeleton
        animation="wave"
        variant="rounded"
        sx={{ ...skeletonStyle(loading), width: '100%', height: '100%' }}
      />
      <Paper
        component="img"
        alt={alt}
        src={imgSrc ?? defaultImgSrc ?? ''}
        sx={{
          ...paperImgStyle,
          objectFit,
          backgroundColor,
          display: loading ? 'none' : 'unset',
          width: '100%',
          height: '100%',
        }}
        onLoad={() => setLoading(false)}
      />
    </Box>
  )
}

export default MediaImage
