import { API, unwrapData } from '../axios-instance'
import { PageResult } from '../shared/types'
import {
  Account,
  BudgetItem,
  BudgetItemEstimate,
  BudgetSetting,
  BudgetSettingParams,
  CreateBudgetItemParams,
  EstimateBudgetItemParams,
  GetBudgetSettingsParams,
  ListAccountsParams,
  ListBudgetItemsParams,
  ListTransactionPointsParams,
  ListTransactionRecipientsParams,
  RedeemableAccountsParams,
  RedeemablePointsParams,
  TotalRedeemablePoints,
  TransactionRecipient,
} from './types'

export const listAccounts = async (params: ListAccountsParams, tenantMemberId?: string) => {
  const rootPath = !!tenantMemberId
    ? `/points/accounts/owner/${tenantMemberId}`
    : '/points/accounts/my'
  return await API.get<PageResult<Account>>(rootPath, { params }).then(unwrapData)
}

export const getBudgetSettings = async (params: GetBudgetSettingsParams) => {
  return await API.get<BudgetSetting>('/points/budgetSettings', { params }).then(unwrapData)
}

export const updateBudgetSettings = async (params: BudgetSettingParams) => {
  return await API.put<BudgetSetting>('/points/budgetSettings', params).then(unwrapData)
}

export const createBudgetItem = async (formData: CreateBudgetItemParams) => {
  return await API.post<BudgetItem>('/points/budgetItems', {
    ...formData,
  }).then(unwrapData)
}

export const listBudgetItems = async (params: ListBudgetItemsParams) => {
  return await API.get<PageResult<BudgetItem>>('/points/budgetItems', { params }).then(unwrapData)
}

export const deleteBudgetItem = async (budgetItemId: string) => {
  return await API.delete<boolean>(`/points/budgetItems/${budgetItemId}`).then(unwrapData)
}

export const getBudgetItem = async (budgetItemId: string) => {
  return await API.get<PageResult<BudgetItem>>('/points/budgetItems', {
    params: {
      page: 1,
      pageSize: 1,
      idIn: [budgetItemId],
      embeds: ['audience'],
    },
  })
    .then(unwrapData)
    .then(data => data.results[0])
}

export const updateBudgetItem = async (budgetItemId: string, formdata: CreateBudgetItemParams) => {
  return await API.put<BudgetItem>(`/points/budgetItems/${budgetItemId}`, {
    ...formdata,
  }).then(unwrapData)
}

export const listTransactionRecipients = async (params: ListTransactionRecipientsParams) => {
  return await API.get<PageResult<TransactionRecipient>>('/points/transactionRecipients', {
    params,
  }).then(unwrapData)
}

export const listPointsTransactions = async (params: ListTransactionPointsParams) => {
  return await API.get<PageResult<TransactionRecipient>>('/points/transactionRecipients', {
    params,
  }).then(unwrapData)
}

export const estimateBudgetItem = async (params: EstimateBudgetItemParams) => {
  return await API.post<BudgetItemEstimate>('/points/budgetItems/estimate', params).then(unwrapData)
}

export const listPointsBanks = async (params: ListBudgetItemsParams) => {
  return await API.get<PageResult<BudgetItem>>('/points/pointsBanks', { params }).then(unwrapData)
}

export const getTotalRedeemablePoints = async (params: RedeemablePointsParams) => {
  return await API.get<TotalRedeemablePoints>('/points/accounts/redeemable/total', { params }).then(
    unwrapData,
  )
}

export const listRedeemableAccounts = async (params: RedeemableAccountsParams) => {
  return await API.get<PageResult<Account>>('/points/accounts/redeemable', { params }).then(
    unwrapData,
  )
}

export const exportRedeemableAccounts = async (
  params?: RedeemableAccountsParams,
): Promise<BlobPart> => {
  return await API.get<BlobPart>('/points/accounts/redeemable/export', {
    params,
    responseType: 'blob',
  }).then(unwrapData)
}
