import { CalendarEventTypeEnum, TenantMemberDisplayData } from '@kudos/http-client'
import { fullNameFromParts } from '@shared/utils/nameFormatting'
import { T } from '@transifex/react'
import { ReactNode } from 'react'

type CelebrationGreetingProps = {
  memberDisplayData?: TenantMemberDisplayData
  celebrationType: CalendarEventTypeEnum
}

const CelebrationGreeting: React.FC<CelebrationGreetingProps> = ({
  memberDisplayData,
  celebrationType,
}) => {
  const fullName = fullNameFromParts(memberDisplayData?.firstName, memberDisplayData?.lastName)

  const eventGreetingObj: Record<CalendarEventTypeEnum, ReactNode> = {
    [CalendarEventTypeEnum.ANNIVERSARY]: (
      <T _str="Happy anniversary, {name}!" name={<b>{fullName}</b>} />
    ),
    [CalendarEventTypeEnum.BIRTHDAY]: <T _str="Happy birthday, {name}" name={<b>{fullName}</b>} />,
    [CalendarEventTypeEnum.ONBOARDING]: (
      <T _str="Welcome aboard, {name}!" name={<b>{fullName}</b>} />
    ),
    [CalendarEventTypeEnum.WELCOME]: <T _str="Welcome aboard, {name}!" name={<b>{fullName}</b>} />,
  }

  return eventGreetingObj[celebrationType]
}

export default CelebrationGreeting
